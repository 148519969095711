<template>
  <div v-if="list">
    <v-data-table
      hide-default-header
      dense
      :headers="headers"
      :items="list"
      item-key="name"
      :items-per-page="perPage"
      :footer-props="footerProps"
      mobile-breakpoint="0"
      hide-default-footer
      class="table-valign-top-mobile table-medical-info"
    >
      <template v-slot:header="header_item">
        <thead class="v-data-table-header">
          <tr class="">
            <th
              class="header-padding"
              :class="`${header.className} ${
                header.value == 'current' || header.value == 'previous'
                  ? 'td-stretch'
                  : ''
              } `"
              v-for="header in header_item.props.headers"
              :key="header.value"
            >
              <div
                class="d-flex align-start py-2 text-no-wrap"
                :class="`${header.className} ${
                  header.className == 'text-center' ? 'justify-center' : ''
                } ${header.className == 'text-left' ? 'justify-start' : ''} ${
                  header.className == 'text-right' ? 'justify-end' : ''
                }`"
              >
                {{ header.text }}
                <br />
                {{ header.value == "current" ? getCurrentDate() : "" }}
                {{ header.value == "previous" ? getPreviousDate() : "" }}
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="td-stretch pt-2 pt-sm-0 link" style="width: 150px">
            <localized-link
              :to="`/patient/medical-record/${item.key}`"
              class="link-detail pt-2 text-uppercase text-no-wrap"
            >
              {{ item.name }}
            </localized-link>
          </td>
          <td valign="top" class="pt-2 text-uppercase">{{ item.unit }}</td>
          <td valign="top" class="text-right pt-2">
            {{
              item.previous_result != null &&
              !checkObjectIsEmpty(item.current_result)
                ? parseFloat(item.previous_result.value).toFixed(2)
                : $t("no_data")
            }}
          </td>
          <td valign="top" class="text-right pt-2">
            {{
              item.current_result != null &&
              !checkObjectIsEmpty(item.current_result)
                ? parseFloat(item.current_result.value).toFixed(2)
                : $t("no_data")
            }}
          </td>
          <td valign="top" class="text-right pt-2">
            {{
              checkObjectIsEmpty(item.current_result) ||
              checkObjectIsEmpty(item.previous_result) ||
              item.previous_result == null ||
              item.current_result == null
                ? "-"
                : parseFloat(
                    parseFloat(item.current_result.value) -
                      parseFloat(
                        !checkObjectIsEmpty(item.previous_result)
                          ? item.previous_result.value
                          : 0
                      ).toFixed(2)
                  )
            }}
          </td>
          <td
            valign="top"
            class="pt-2 text-uppercase"
            :class="{
              'text-danger':
                !checkObjectIsEmpty(item.current_result) &&
                item.current_result.min != null &&
                item.current_result.max != null
                  ? parseFloat(item.current_result.value) >
                      parseFloat(item.current_result.max) ||
                    parseFloat(item.current_result.value) <
                      parseFloat(item.current_result.min)
                  : false,
              'text-success':
                !checkObjectIsEmpty(item.current_result) &&
                item.current_result.min != null &&
                item.current_result.max != null
                  ? parseFloat(item.current_result.value) >=
                      parseFloat(item.current_result.min) &&
                    parseFloat(item.current_result.value) <=
                      parseFloat(item.current_result.max)
                  : false,
            }"
          >
            {{
              checkObjectIsEmpty(item.current_result) ||
              item.current_result == null
                ? "-"
                : item.current_result.min != null &&
                  item.current_result.max != null
                ? item.current_result.value >= item.current_result.min &&
                  item.current_result.max >= item.current_result.value
                  ? $t("good")
                  : $t("warning")
                : "-"
            }}
          </td>
          <td>
            <localized-link
              :to="`/patient/medical-record/${item.key}${
                medicalNumber == '' ? '' : `?medical=${medicalNumber}`
              }`"
              class="link-detail pt-2 text-uppercase"
            >
              <ChartMedicalRecord :data="getDataChart(item.list_history)" />
            </localized-link>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ChartMedicalRecord from "@/views/v2/Patient/Chart/ChartMedicalRecord.vue";
import moment from "moment";

export default {
  components: {
    ChartMedicalRecord,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    medicalNumber: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dialog: false,
    items: [],
    headers: [],
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    perPage: 25,
  }),
  created() {
    this.headers = [
      {
        text: this.$t("page.profile.test_name"),
        align: "start",
        sortable: false,
        value: "name",
        className: "text-center",
      },
      {
        text: this.$t("page.profile.unit"),
        value: "unit",
        sortable: false,
        className: "text-center",
      },
      {
        text: this.$t("page.profile.previous_test"),
        value: "previous",
        sortable: false,
        className: "text-center",
        bordered: true,
      },
      {
        text: this.$t("page.profile.current_test"),
        value: "current",
        sortable: false,
        className: "text-center",
      },
      {
        text: this.$t("page.profile.diff"),
        value: "diff1",
        sortable: false,
        className: "text-center",
        bordered: true,
      },
      {
        text: this.$t("page.profile.notes"),
        value: "notes",
        sortable: false,
        className: "text-center",
      },
      {
        text: this.$t("page.profile.chart"),
        className: "text-center tb-wide0",
        value: "chart",
        sortable: false,
      },
    ];
  },
  methods: {
    checkObjectIsEmpty(obj) {
      return (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      );
    },
    getDataChart(data) {
      if (data.length > 0) {
        return data.map(item => parseFloat(item.value));
      } else return [];
    },
    getData: function (event) {
      console.log(event);
      this.dialog = true;
    },
    getValueItem(value) {
      console.log(value);
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    getCurrentDate() {
      if (this.list.length > 0) {
        const indexCheck = this.list.findIndex(
          res =>
            res.previous_result != null &&
            res.previous_result.date != undefined &&
            res.current_result != null &&
            res.current_result.date != undefined
        );
        return this.checkObjectIsEmpty(
          this.list[indexCheck == -1 ? 0 : indexCheck].current_result
        ) || this.list[indexCheck == -1 ? 0 : indexCheck].current_result == null
          ? ""
          : this.formatDate(
              this.list[indexCheck == -1 ? 0 : indexCheck].current_result.date
            );
      }
    },
    getPreviousDate() {
      const indexCheck = this.list.findIndex(
        res =>
          res.previous_result != null &&
          res.previous_result.date != undefined &&
          res.current_result != null &&
          res.current_result.date != undefined
      );
      if (this.list.length > 0) {
        return this.checkObjectIsEmpty(
          this.list[indexCheck == -1 ? 0 : indexCheck].previous_result
        ) ||
          this.list[indexCheck == -1 ? 0 : indexCheck].previous_result == null
          ? ""
          : this.formatDate(
              this.list[indexCheck == -1 ? 0 : indexCheck].previous_result.date
            );
      }
    },
  },
};
</script>
<style>
.table-medical-info thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-medical-info thead tr th {
  height: 38px !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

td {
  a {
    color: black;
    vertical-align: text-top;
  }
  width: 80px;
  text-transform: uppercase;
}
th {
  width: 100px;
  vertical-align: top;
}

.link-detail {
  vertical-align: super;
}

.header-padding {
  padding: 0 16px;
}
.background-ratio-left {
  border-left: 1px solid black;
}
.background-ratio-right {
  border-right: 1px solid black;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  color: black;
  &.text-danger {
    color: red;
  }
  &.text-success {
    color: green;
  }
  &.text-warning {
    color: orange;
  }
}
.link a {
  text-decoration: none !important;
  font-weight: 700;
  color: #00a4b3;
}
</style>
