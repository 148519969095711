<template>
  <CustomSection
    class="section pa-3 pa-md-5 mt-12 mt-md-0 mt-lg-0 bg-white-profile"
  >
    <NavbarOne
      class="d-md-none d-lg-none"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <TabContent
      :listTab="$store.state.listMenu"
      :listContent="listContent"
      class="patient"
      :activeTab="$store.state.activeTab"
      @changeTab="id => $store.commit('setActiveTab', id)"
      @close="id => close(id)"
      @getDatFamily="par => getDataFamily(par)"
    >
      <!--  @click.stop="$emit('close', item.id)" -->
      <template v-slot:title="{ title, family, id }">
        <div class="overflow-elipse">
          {{ title }}
        </div>
        <v-btn
          icon
          class="ic-close"
          v-if="family == true"
          x-small
          @click.stop="close(id)"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
      <template v-slot:tabs_content="content">
        <card-profile-patient
          v-if="$store.state.activeTab == '1' || $store.state.activeTab == '2'"
          :data="content"
        />
        <card v-if="content.id == '3'">
          <template v-slot:body>
            <RelationshipInfo
              :familyList="myFamily"
              :approvalFamily="myFamilyApproval"
              @getApprovalFamily="getfamilyApproval()"
              @getFamily="getfamily()"
              @getDatFamily="par => getDataFamily(par)"
            />
          </template>
        </card>
        <card v-if="content.id == '4'">
          <template v-slot:body>
            <PaymentInfo />
          </template>
        </card>
        <card v-if="content.id == '6'">
          <template v-slot:body>
            <MySubscription />
          </template>
        </card>
        <card v-if="content.id > '7'">
          <template v-slot:body>
            <FamilyInfo :listData="listContentFamily" />
          </template>
        </card>

        <div v-if="$store.state.activeTab == '5'" class="pa-md-2">
          <div class="d-flex justify-end mr-n1 mb-4">
            <v-icon color="#00a4b3" @click="dialogFilterOngoing = true"
              >mdi-filter</v-icon
            >
          </div>
          <v-row dense v-if="!loadingOngoing">
            <v-col cols="12">
              <template v-if="historyProduct.length > 0">
                <!-- start order-list -->
                <card-order-list
                  @updateOrderList="getOngoingTest()"
                  v-for="(order, index) of historyProduct"
                  :key="index"
                  :data="order"
                  :plant="listBranch"
                >
                </card-order-list>
                <!-- end order-list -->
              </template>
              <template v-else>
                <v-row>
                  <v-col>
                    <img
                      src="@/assets/images/banner/coming_soon.png"
                      height="160"
                      class="mx-auto d-flex mb-3"
                    />

                    <p class="mb-0 font-weight-bold text-center">
                      {{ $t("no_data") }}
                    </p>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
          <icon-loading v-else class="icon-loading-pagination mx-auto active" />
          <!-- pagination ongoing new-->
          <v-row dense class="justify-space-between" v-if="itemLength > 1">
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-center justify-sm-start align-center"
              style="padding-right: 0px"
            >
              <v-subheader>{{ $t("per_page") }} : </v-subheader>
              <!-- <v-expansion-panel-content
                v-model="pagination_ongoing.per_page"
                :items="pageSizes"
              ></v-select> -->
              <div>
                <v-select
                  class="footerHide"
                  v-model="perPage"
                  :items="itemShowing"
                ></v-select>
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-center justify-sm-end align-center"
            >
              <custom-pagination
                @changePage="val => (page = val)"
                :totalPage="itemLength"
                :activePage="page"
              />
            </v-col>
          </v-row>
        </div>

        <div v-if="$store.state.activeTab == '7'" class="pa-md-2">
          <v-row dense>
            <v-col cols="12" md="6" class="d-none d-md-block">
              <img
                class="image-password"
                height="400"
                src="@/assets/change_password.png"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-col cols="12">
                <h3 style="color: #1a428a">{{ $t("profile_picture") }}</h3>
              </v-col>
              <v-col cols="12">
                <div class="card--cart">
                  <div class="card--image">
                    <img
                      class="image-profie mx-auto"
                      :src="
                        profileImg.photo_profile_path != null
                          ? `${url}profile/${profileImg.photo_profile_path}`
                          : require('@/assets/profile_icon@2x.png')
                      "
                    />
                    <div class="d-flex align-center justify-center">
                      <input
                        type="file"
                        style="display: none"
                        ref="fileInput"
                        @change="onFileSelect"
                      />
                      <c-button
                        rounded
                        shadow
                        bold
                        class="btn-upload"
                        @click="$refs.fileInput.click()"
                      >
                        {{ $t("upload_photo") }}
                      </c-button>
                    </div>
                  </div>
                  <v-dialog v-model="dialogUpload" width="500">
                    <v-card>
                      <v-card-text style="padding-top: 25px !important">
                        <vueCropper
                          v-show="selectedFile"
                          ref="cropper"
                          :src="selectedFile"
                          alt="Source Image"
                          :aspectRatio="1 / 1"
                          :initialAspectRatio="1 / 1"
                        ></vueCropper>
                      </v-card-text>
                      <v-card-actions class="pad-action">
                        <v-btn
                          class="btn-save"
                          @click="saveImage(), (dialogUpload = false)"
                          >Crop</v-btn
                        >
                        <v-btn
                          color="primary"
                          text
                          @click="dialogUpload = false"
                          >Cancel</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="d-none d-md-block"></v-col>
            <v-col
              cols="12"
              md="6"
              style="padding-left: 24px; padding-bottom: 0px"
            >
              <h2 style="color: #1a428a">{{ $t("account_management") }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="d-none d-md-block"></v-col>
            <v-col cols="12" md="6">
              <v-col cols="12">
                <h3 style="color: #1a428a">
                  {{ $t("page.setting.title_change_password") }}
                </h3>
              </v-col>
              <v-col cols="12">
                <v-form v-model="valid" lazy-validation ref="form">
                  <div class="card--cart">
                    <div class="title-change-password">
                      <p>
                        {{ $t("page.setting.subtitle_change_password") }}
                        <b>{{ identity }}</b>
                      </p>
                    </div>
                    <v-col cols="12">
                      <v-text-field
                        class="mt-0"
                        v-model="payload.current_password"
                        :rules="[
                          v =>
                            !!v ||
                            `${$t('page.setting.current_password')} ${$t(
                              'is_required'
                            )}`,
                        ]"
                        :label="$t('page.setting.current_password')"
                        :append-icon="
                          show_now_password ? 'visibility' : 'visibility_off'
                        "
                        :type="show_now_password ? 'text' : 'password'"
                        @click:append="show_now_password = !show_now_password"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        class="mt-0"
                        v-model="payload.new_password"
                        :rules="[
                          v =>
                            !!v ||
                            `${$t('page.setting.new_password')} ${$t(
                              'is_required'
                            )}`,
                        ]"
                        :label="$t('page.setting.new_password')"
                        :append-icon="
                          show_new_password ? 'visibility' : 'visibility_off'
                        "
                        :type="show_new_password ? 'text' : 'password'"
                        @click:append="show_new_password = !show_new_password"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        class="mt-0"
                        v-model="payload.confirm_new_password"
                        :rules="[passwordConfirmRules]"
                        :label="$t('page.setting.confirm_new_password')"
                        :append-icon="
                          show_new_password ? 'visibility' : 'visibility_off'
                        "
                        :type="show_new_password ? 'text' : 'password'"
                        @click:append="show_new_password = !show_new_password"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <div class="d-flex align-center justify-center">
                        <c-button
                          roundedSmall
                          shadow
                          bold
                          :disabled="!valid"
                          @click="validate"
                        >
                          {{ $t("save") }}
                        </c-button>
                      </div>
                    </v-col>
                  </div>
                </v-form>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </template>
    </TabContent>
    <!-- graph and chart -->
    <v-row v-if="$store.state.activeTab == '1'">
      <template>
        <LastMedicalRecord />
      </template>

      <v-col cols="12" :class="{ 'd-none': this.emptyHistory }">
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <card
              rounded
              shadow
              style="height: 100%"
              class="medical-history-box"
            >
              <template v-slot:header>
                <div class="card__header">
                  <div class="d-flex justify-space-between align-start">
                    <heading-card
                      class="heading-card--dark-blue"
                      :title="$t('page.profile.medical_history')"
                    />
                    <div class="d-flex flex-column align-end">
                      <select-show
                        :items="DateFilterItems"
                        :value="dateFilterDefaultValue"
                        @changeDate="nilai => updateChart(nilai)"
                      />
                      <select-show
                        :items="filterGraphItems"
                        title="Filter :"
                        :value="filterGraph"
                        @changeDate="nilai => filterChart(nilai)"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:body>
                <div class="px-3 pt-3 px-md-5 pt-md-5">
                  <v-row v-if="personInfo != null" dense>
                    <v-col cols="7">
                      <chart-title
                        icon="icon-birth"
                        color="#00A4B3"
                        :title="formatDate(personInfo.date_of_birth)"
                      >
                      </chart-title>
                      <chart-title
                        icon="maps"
                        color="#00A4B3"
                        :title="personInfo.place_of_birth"
                      >
                      </chart-title>
                    </v-col>
                    <v-col cols="5">
                      <chart-title
                        icon="weight"
                        color="#00A4B3"
                        :title="personInfo.weight"
                      >
                      </chart-title>
                      <chart-title
                        icon="height"
                        color="#00A4B3"
                        :title="personInfo.height"
                      >
                      </chart-title>
                    </v-col>
                  </v-row>
                </div>
                <v-col cols="12">
                  <div class="card-chart">
                    <ApexMedicalChart
                      :data="graphData"
                      v-if="graphData != null"
                      :activeRange="dateFilterDefaultValue"
                    />
                  </div>
                </v-col>
              </template>
            </card>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="6"
            class="column-recommended-test"
            v-if="recomendedTest != null"
          >
            <card rounded shadow class="recomended-test-box h-full">
              <template v-slot:header>
                <div class="card__header">
                  <div class="d-flex justify-space-between align-center">
                    <heading-card
                      class="heading-card--dark-blue"
                      :title="$t('page.profile.recomended_test')"
                    />
                  </div>
                </div>
              </template>
              <template v-slot:body>
                <v-row class="px-5 pt-5 pb-3">
                  <v-col
                    cols="12"
                    md="6"
                    v-for="(test, index) of recomendedTest"
                    :key="index"
                  >
                    <card-test-item
                      :title="test[`name_${$i18n.locale}`]"
                      :desc="test[`description_${$i18n.locale}`]"
                      :data="test"
                    />
                  </v-col>
                </v-row>
              </template>
            </card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="$store.state.activeTab == '2'">
      <template>
        <VitalSigns />
      </template>

      <template>
        <MedicalRecordV2 />
      </template>

      <v-col cols="12" style="display: none">
        <card rounded shadow class="medical-record-box">
          <template v-slot:header>
            <div
              class="card__header scroll-mobile pa-3 pa-md-5 display-desktop"
            >
              <div class="d-flex justify-space-between align-center">
                <v-row dense>
                  <v-col cols="12" md="6">
                    <heading-card
                      class="heading-card--dark-blue"
                      :title="$t('page.profile.medical_record')"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-btn-toggle
                      class="float-right mr-5"
                      v-model="filterMedicalRecord"
                      dense
                    >
                      <v-btn
                        valign="middle"
                        value="thirty_days_ago"
                        style="width: auto"
                        class="filter"
                      >
                        <span class="font-weight-bold">30D</span>
                      </v-btn>
                      <v-btn
                        valign="middle"
                        value="ninety_days_ago"
                        style="width: auto"
                        class="filter"
                      >
                        <span class="font-weight-bold">90D</span>
                      </v-btn>
                      <v-btn
                        valign="middle"
                        value="one_year_ago_date"
                        style="width: auto"
                        class="filter"
                      >
                        <span class="font-weight-bold">1Y</span>
                      </v-btn>
                      <v-btn
                        valign="middle"
                        value="life_time_date"
                        style="width: auto"
                        class="filter"
                      >
                        <span class="font-weight-bold">MAX</span>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </div>
              <sub-heading-card :title="dateNow" class="mt-3 mt-md-0" />
            </div>

            <div class="card__header pa-3 pa-md-5 display-mobile">
              <div class="d-flex justify-space-between align-center">
                <v-row dense>
                  <v-col cols="6">
                    <heading-card
                      class="heading-card--dark-blue"
                      :title="$t('page.profile.medical_record')"
                    />
                  </v-col>
                  <v-col cols="6">
                    <sub-heading-card :title="dateNow" />
                  </v-col>
                  <v-col cols="12">
                    <v-divider style="border: 1px solid #00a4b3"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-btn-toggle
                      class="float-left"
                      v-model="filterMedicalRecord"
                      dense
                    >
                      <v-btn
                        class="min-width-btn filter"
                        valign="middle"
                        value="thirty_days_ago"
                        style="width: auto"
                      >
                        <span class="font-weight-bold">30D</span>
                      </v-btn>
                      <v-btn
                        class="min-width-btn filter"
                        valign="middle"
                        value="ninety_days_ago"
                        style="width: auto"
                      >
                        <span class="font-weight-bold">90D</span>
                      </v-btn>
                      <v-btn
                        class="min-width-btn filter"
                        valign="middle"
                        value="one_year_ago_date"
                        style="width: auto"
                      >
                        <span class="font-weight-bold">1Y</span>
                      </v-btn>
                      <v-btn
                        class="min-width-btn filter"
                        valign="middle"
                        value="life_time_date"
                        style="width: auto"
                      >
                        <span class="font-weight-bold">MAX</span>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <v-col cols="12">
              <div class="card-chart">
                <MedicalInfo :list="medicalInfo" class="mb-2" />
              </div>
            </v-col>
          </template>
        </card>
      </v-col>

      <!-- 
        tampilan medical record new 
        seperti last medical record di figma hide untuk sementara

        <template>
        <MedicalRecordNew />
      </template> -->
    </v-row>

    <v-row v-if="$store.state.activeTab == '3'">
      <v-col cols="12">
        <card rounded shadow class="pa-3 pa-md-5 relationship-box">
          <template v-slot:header>
            <div style="border-bottom: 1px solid #ebeff2" class="pb-3">
              <div class="d-flex justify-space-between align-center">
                <heading-card
                  class="heading-card--dark-blue"
                  :title="$t('page.profile.relationship_approval_request')"
                />
              </div>
            </div>
          </template>
          <template v-slot:body>
            <RelationshipApprovalRequest
              :familyListApproval="myFamilyApproval"
              @getFamily="getfamily()"
              @getApprovalFamily="getfamilyApproval()"
              v-if="$store.state.activeTab == '3'"
            />
          </template>
        </card>
      </v-col>
    </v-row>

    <v-row v-if="$store.state.activeTab == '2'" class="mt-6" dense>
      <v-col cols="12">
        <card rounded shadow class="your-history-box">
          <template v-slot:header>
            <div class="card__header">
              <div class="d-flex justify-space-between align-center">
                <heading-card
                  class="heading-card--dark-blue"
                  :title="$t(`tab.your_history`)"
                />
                <div class="d-flex justify-end mr-n1">
                  <v-icon color="#00a4b3" @click="dialogFilterHistory = true"
                    >mdi-filter</v-icon
                  >
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="pa-3 pa-md-5">
              <!-- start order-list -->
              <template v-if="!loadingHistory">
                <template v-if="historyPackage.length > 0">
                  <card-order-list
                    v-for="(order, index) of historyPackage"
                    :key="index"
                    :data="order"
                    @updateOrderList="getHistoryTest()"
                  >
                  </card-order-list>
                </template>
                <v-row v-else>
                  <v-col>
                    <img
                      src="@/assets/images/banner/coming_soon.png"
                      height="160"
                      class="mx-auto d-flex mb-3"
                    />

                    <p class="mb-0 font-weight-bold text-center">
                      {{ $t("no_data") }}
                    </p>
                  </v-col>
                </v-row>
              </template>
              <!-- end order-list -->

              <icon-loading
                v-else
                class="icon-loading-pagination mx-auto active"
              />
              <!-- pagination history view new-->
              <v-row
                dense
                class="justify-space-between"
                v-if="itemLengthMedical > 1"
              >
                <v-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-center justify-sm-start align-center"
                  style="padding-right: 0px"
                >
                  <v-subheader>{{ $t("per_page") }} : </v-subheader>
                  <div>
                    <v-select
                      class="footerHide"
                      v-model="perPageMedical"
                      :items="itemShowingMedical"
                    ></v-select>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-center justify-sm-end align-center"
                >
                  <custom-pagination
                    @changePage="val => (pageMedical = val)"
                    :totalPage="itemLengthMedical"
                    :activePage="pageMedical"
                  />
                </v-col>
              </v-row>
            </div>
          </template>
        </card>
      </v-col>
    </v-row>

    <v-row v-if="$store.state.activeTab > '7'">
      <template>
        <VitalSignsFamily
          :listHeaderTableVital="headerTableVital"
          :listItemVital="itemVital"
          :listHeaderTableAnthA="headerTableAnthA"
          :listItemAnthA="itemAnthA"
          :listHeaderTableAnthB="headerTableAnthB"
          :listItemAnthB="itemAnthB"
        />
      </template>

      <template>
        <MedicalRecordV2Family />
      </template>

      <!-- <v-col cols="12" style="padding-top: 0px; margin-top: -5px">
        <MedicalHistory />
      </v-col> -->
      <v-col cols="12">
        <MedicalHistoryFamily />
      </v-col>
    </v-row>
    <DialogInvoice
      :item="dataDialogInvoice"
      :tampilkan="dialog_invoice"
      :tableFilter="tableInvoice"
      @toggleDialog="is_show => toggleDialogInvoice(is_show)"
      v-if="dialog_invoice"
    />
    <DialogInvoiceHomecare
      :item="dataDialogInvoiceHomecare"
      :tampilkan="dialog_invoice_homecare"
      @toggleDialog="is_show => toggleDialogInvoice(is_show)"
      v-if="dialog_invoice_homecare"
    />
    <!-- start filter your history order -->
    <v-dialog v-model="dialogFilterHistory" width="400">
      <dialogFilterYourHistory
        :filterActive="filterActiveHistory"
        history
        @changeFilterActive="param => (filterActiveHistory = param)"
      />
    </v-dialog>
    <!-- end filter your history order -->

    <!-- start filter your Ongoing -->
    <v-dialog v-model="dialogFilterOngoing" width="400">
      <dialogFilterYourHistory
        :filterActive="filterActiveOngoing"
        @changeFilterActive="param => (filterActiveOngoing = param)"
      />
    </v-dialog>
    <!-- end filter your Ongoing -->
  </CustomSection>
</template>

<script>
import Card from "@/components/v2/card/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import moment from "moment";
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import TabContent from "@/components/v2/tabs/index.vue";
import RelationshipInfo from "@/views/v2/Patient/Tabs/RelationshipInfo.vue";
import MySubscription from "@/components/v2/tabs/my_subscription.vue";
import RelationshipApprovalRequest from "@/views/v2/Patient/Tabs/RelationshipApprovalRequest.vue";
import PaymentInfo from "@/views/v2/Patient/Tabs/PaymentInfo.vue";
import MedicalInfo from "@/views/v2/Patient/Tabs/MedicalInfo.vue";
import ApexMedicalChart from "@/views/v2/Patient/Chart/ApexMedicalChart.vue";
import SelectShow from "@/components/v2/select/SelectShow.vue";
import SubHeadingCard from "@/components/Heading/SubHeadingCard.vue";
import ChartTitle from "@/components/v2/chart/Title.vue";
import CustomSection from "@/components/v2/section/index.vue";
import CardTestItem from "@/components/v3/cards/card-test.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import DialogInvoice from "@/components/v2/dialog/dialogInvoice.vue";
import DialogInvoiceHomecare from "@/components/v2/dialog/dialogInvoiceHomecare.vue";
import FamilyInfo from "@/views/v2/Patient/family/index.vue";
import LastMedicalRecord from "@/views/v2/Patient/profile/last_medical_record.vue";
import VitalSigns from "@/views/v2/Patient/profile/vital_signs.vue";
import VitalSignsFamily from "@/views/v2/Patient/profile/vital_signs_family.vue";
import MedicalRecordV2 from "@/views/v2/Patient/profile/medical_record_v2.vue";
import MedicalRecordV2Family from "@/views/v2/Patient/profile/medical_record_v2_family.vue";
import MedicalHistoryFamily from "@/views/v2/Patient/family/medical_history_family.vue";

import axios from "axios";
import CButton from "@/components/v2/button/index.vue";

import CardProfilePatient from "@/components/v2/card/card_profile_patient.vue";
import IconLoading from "@/components/elements/IconLoading.vue";
import CardOrderList from "@/components/v3/cards/card-order-list.vue";
import CustomPagination from "@/components/v3/pagination.vue";
import dialogFilterYourHistory from "@/components/v3/popup/dialogFilterYourHistory.vue";

export default {
  metaInfo() {
    return {
      script: [
        {
          src: process.env.VUE_APP_MIDTRANS_URL,
          "data-client-key": process.env.VUE_APP_MIDTRANS_CLIENT_KEY,
          defer: true,
          callback: () => this.$store.commit("loadMidtrans"),
        },
      ],
    };
  },
  components: {
    DialogInvoice,
    DialogInvoiceHomecare,
    Card,
    HeadingCard,
    TabContent,
    RelationshipInfo,
    ApexMedicalChart,
    RelationshipApprovalRequest,
    PaymentInfo,
    SelectShow,
    SubHeadingCard,
    MedicalInfo,
    ChartTitle,
    CustomSection,
    CardTestItem,
    NavbarOne,
    FamilyInfo,
    LastMedicalRecord,
    CButton,
    CardProfilePatient,
    MySubscription,
    IconLoading,
    VitalSigns,
    MedicalRecordV2,
    VitalSignsFamily,
    MedicalRecordV2Family,
    MedicalHistoryFamily,
    CardOrderList,
    CustomPagination,
    dialogFilterYourHistory,
  },
  data: () => ({
    listBranch: [],
    medicalRecord: "btn-style-your-history",
    //url image
    url: process.env.VUE_APP_API_TRUST,
    url_his: "",
    profileImg: {},
    // medical record history load
    emptyHistory: false,
    dataNext: false,
    dataprevious: false,
    dataOffset: 0,
    currentPage: 1,
    pageDataTable: 1,
    pageSizes: [5, 10, 15],
    dataNextMedical: false,
    datapreviousMedical: false,
    dataOffsetMedical: 0,
    currentPageMedical: 1,
    pageDataTableMedical: 1,
    pageSizesMedical: [5, 10, 15],
    dataDialogInvoice: {
      order_number: null,
      subscription: false,
      list: [],
      data: {
        metadata: {},
      },
    },
    dataDialogInvoiceHomecare: {
      order_number: null,
    },
    tableInvoice: [],
    dialog_invoice: false,
    dialog_invoice_homecare: false,
    payload: {
      registration_number: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      idProfileImg: "",
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    menu: false,
    registNo: "",
    panel: [0],
    sizes: 150,
    foreColor: "#FFFFFF",
    dialogQrPackage: false,
    groupItemCategory: [],
    groupItemSubcategory: [],
    tableHomeCare: [],
    orderHomecareDetail: [],
    download: {},
    preview: {},
    rating: 0,
    dialogEdit: false,
    editedIndex: -1,
    dialogEditPackage: false,
    editedIndexPackage: -1,
    isActiveHome: false,
    editedItem: {
      comment: "",
      rating: 0,
    },
    defaultItem: {
      comment: "",
      rating: 0,
    },
    editedItemPackage: {
      comment: "",
      rating: 0,
    },
    defaultItemPackage: {
      comment: "",
      rating: 0,
    },
    errorMessages: "",
    dialog_order: false,
    dialog_order_mcu: false,
    dialog_reschedule_test: false,
    dialog_reschedule_homecare: false,
    dialog_reschedule_subscription: false,
    // reschedule
    dataTmpReschedule: {},
    dataDialogOrderMCU: {
      order_id: null,
      list: [],
    },
    dataDialogOrder: {
      order_id: null,
      list: [],
    },
    headerTable: [],
    checkItem: "",
    // start ongoing
    historyProduct: [],
    dialogFilterOngoing: false,
    filterActiveOngoing: {
      filter_date: [],
      branch: "",
      type_test: "",
      shared: false,
    },
    // end ongoing
    // start history
    historyPackage: [],
    dialogFilterHistory: false,
    filterActiveHistory: {
      filter_date: [],
      branch: "",
      type_test: "",
      shared: false,
    },
    // end history
    showNavbar: true,
    showCart: true,
    showCartDesktop: false,
    showSearch: true,
    showLogo: false,
    personInfo: null,
    indexIdCard: 0,
    dateNow: moment().format("DD-MMM-YYYY, dddd").toUpperCase(),
    monthNow: moment().format("MMMM YYYY"),
    DateFilterItems: [],
    dateFilterDefaultValue: "this_year",
    thisWeek: [],
    activeDoughnut: 1,
    percentDoughnut: 0,
    colorPercent: "#2ED47A",
    listPatient: null,
    listMenu: null,
    listContent: null,
    activeTab: "",
    dialog: false,
    dialogQris: false,
    dataChartWeight: null,
    dataMedicalRecord: null,
    recomendedTest: null,
    medicalInfo: [],
    graphData: null,
    filterGraph: "body_weight",
    filterGraphItems: [],
    headQR: {
      plant: "",
      order_number: "",
      booking_date: "",
      estimated_service_at: "",
      product_order: {},
      patient: "",
      barcode: "",
    },
    urlEnkrip: "",
    urlEnkripPl: "",
    urlEnkripOn: "",
    urlEnkripBd: "",
    urlEnkripEsa: "",
    urlEnkripPo: "",
    urlEnkripPt: "",
    urlEnkripBc: "",
    urlEnkripHc: "",
    urlEnkripPlHc: "",
    urlEnkripOnHc: "",
    urlEnkripBdHc: "",
    urlEnkripEsaHc: "",
    urlEnkripPoHc: "",
    urlEnkripPtHc: "",
    urlEnkripBcHc: "",
    direction: "top",
    fabPackage: false,
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: "slide-y-reverse-transition",
    urlTemp: "",
    myFamily: [],
    myFamilyApproval: [],
    patientId: "",
    valid: true,
    show_now_password: false,
    show_new_password: false,
    identity: {},
    mime_type: "",
    cropedImage: "",
    autoCrop: false,
    selectedFile: "",
    image: "",
    files: "",
    dialogUpload: false,
    // pagination data
    pagination_history: {
      per_page: 5,
      active: 1,
    },
    pagination_ongoing: {
      per_page: 5,
      active: 1,
    },
    loadingHistory: false,
    loadingOngoing: false,
    //dateMinHomecare: new Date(),
    dateMinHomecare: moment().format("YYYY-MM-DD HH:MM:SS").toUpperCase(),
    //pagination new
    page: 1,
    itemsTotal: 0,
    itemLength: 1,
    perPage: 5,
    itemShowing: [5, 10, 15],
    pageMedical: 1,
    itemsTotalMedical: 0,
    itemLengthMedical: 1,
    perPageMedical: 5,
    itemShowingMedical: [5, 10, 15],
    filterMedicalRecord: "life_time_date",
    listContentFamily: null,
    filterMedicalRecordFamily: "thirty_days_ago",
    headerTableVital: [],
    itemVital: [
      {
        blood_pressure: "-",
        heart_rate: "-",
        temperature: "-",
        respiratory_rate: "-",
      },
    ],
    headerTableAnthA: [],
    itemAnthA: [
      {
        body_height: "-",
        body_weight: "-",
        body_mass_index: "-",
      },
    ],
    headerTableAnthB: [],
    itemAnthB: [
      {
        belly_circumference: "-",
        chest_circumference: "-",
        head_circumference: "-",
      },
    ],
    mrTest: null,
  }),
  watch: {
    filterMedicalRecordFamily() {
      this.$store.commit("setHelper", {
        ...this.$store.state.helper,
        filter_time: this.filterMedicalRecordFamily,
      });
      this.getDataFamily();
    },
    // pagination watch getOngoingTest New
    page: function () {
      this.getOngoingTest();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getOngoingTest();
      }
    },
    // start get history
    // pagination watch getHistory New
    pageMedical: function () {
      this.getHistoryTest();
    },
    perPageMedical: function () {
      if (this.pageMedical != 1) {
        this.pageMedical = 1;
      } else {
        this.getHistoryTest();
      }
    },
    // pagination watch
    pagination_history: {
      handler(newVal, oldVal) {
        if (newVal.per_page != oldVal.per_page) {
          this.pagination_history.active = 0;
        } else {
          this.getHistoryTest();
        }
      },
      deep: true,
    },
    filterActiveHistory: {
      handler() {
        if (this.pageMedical == 1) {
          this.getHistoryTest();
        } else {
          this.pageMedical = 1;
        }
      },
      deep: true,
    },
    // end get history
    // start ongoing
    pagination_ongoing: {
      handler(newVal, oldVal) {
        if (newVal.per_page != oldVal.per_page) {
          this.pagination_ongoing.active = 0;
        } else {
          this.getOngoingTest();
        }
      },
      deep: true,
    },
    filterActiveOngoing: {
      handler() {
        if (this.page == 1) {
          this.getOngoingTest();
        } else {
          this.page = 1;
        }
      },
      deep: true,
    },
    // end ongoing

    filterMedicalRecord() {
      this.$store.commit("setHelper", {
        ...this.$store.state.helper,
        filter_time: this.filterMedicalRecord,
      });
      this.getMedicalInfo();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogEditPackage(val) {
      val || this.closeEditPackage();
    },
  },
  async mounted() {
    // var dateHomecare = new Date();
    // //dateHomecare.setHours(dateHomecare.getHours()-8);
    // this.dateMinHomecare = format(parseISO(dateHomecare.toISOString()), "yyyy-MM-dd HH:MM:SS");

    this.profileImg = this.dataProfile;
    this.payload.idProfileImg = this.dataProfile.upload_id;
    this.identity = this.dataProfile.email.toLowerCase();

    this.getOngoingTest();
    this.getHistoryTest();

    if (typeof this.$route.query.tab !== "undefined") {
      if (this.$route.query.tab.toUpperCase().includes("FAMILY")) {
        this.$store.commit("setActiveTab", 3);
      } else if (this.$route.query.tab.toUpperCase().includes("RESULT")) {
        this.$store.commit("setActiveTab", 2);
      } else if (this.$route.query.tab.toUpperCase().includes("MEDICAL")) {
        this.$store.commit("setActiveTab", 2);
      } else if (
        this.$route.query.tab.toUpperCase().includes("ORDER") ||
        this.$route.query.tab.toUpperCase().includes("SERVICE")
      ) {
        this.$store.commit("setActiveTab", 5);
      } else if (this.$route.query.tab.toUpperCase().includes("SUBSCRIPTION")) {
        this.$store.commit("setActiveTab", 6);
      } else if (this.$route.query.tab.toUpperCase().includes("PAYMENTINFO")) {
        this.$store.commit("setActiveTab", 4);
      }
    } else {
      this.$store.commit("setActiveTab", 1);
    }
  },
  created() {
    this.getPlant();
    // if(this.empty){
    this.getListFilterGraph();
    // }
    if (this.$route.query.transaction_status) {
      if (this.$route.query.transaction_status == "pending") {
        this.$router.push(
          `/${this.$i18n.locale}/payment-pending?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}&home_care=${this.$route.query.home_care}`
        );
      } else {
        this.$router.push(
          `/${this.$i18n.locale}/payment-success?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}&home_care=${this.$route.query.home_care}`
        );
      }
    }
    this.urlTemp = process.env.VUE_APP_BASE_URL;
    this.url_his = process.env.VUE_APP_URL_HIS;
    this.filterGraphItems = [];
    this.headerTable = [
      {
        text: "ID",
        value: "patient_id_number",
        sortable: false,
        width: "50px",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient",
        sortable: false,
        width: "150px",
      },
      {
        text: this.$t("page.profile.booking_code"),
        value: "booking_code",
        sortable: false,
        width: "140px",
      },
      {
        text: this.$t("test"),
        value: "product_order",
        sortable: false,
        width: "350px",
      },
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        width: "160px",
      },
    ];
    this.DateFilterItems = [
      // { dateValue: "today", dateLabel: this.$t("today") },
      // { dateValue: "this_week", dateLabel: this.$t("this_week") },
      { dateValue: "this_month", dateLabel: this.$t("this_month") },
      { dateValue: "last_month", dateLabel: this.$t("last_month") },
      { dateValue: "last_3_month", dateLabel: this.$t("last_3_month") },
      { dateValue: "this_year", dateLabel: this.$t("this_year") },
      // { dateValue: "life_time", dateLabel: this.$t("lifetime") },
    ];

    //  default filterMedicalRecord 30D //
    //this.filterMedicalRecord = this.$store.state.helper.filter_time;
    // end default filterMedicalRecord 30D //

    this.getDateThisWeek();
    if (this.dataProfile) {
      this.getProfile();
    }
    this.getRecomendedTest();
    this.getMedicalRecord();
    this.getGraphData();
    if (this.empty) {
      this.getMedicalInfo();
    }
    // family
    this.getfamily();
    this.getfamilyApproval();
  },
  methods: {
    // start function reschedule check timezone
    async getPlant() {
      const resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.statusCode == 200) {
        this.listBranch = await resp.results.filter(item => {
          // dissable selain nrc bali
          // return item.type == "BLA" && (item.id == 12 || item.id == 4);
          return item.type == "VIRTU DIGILABA";
        });
      }
    },
    // end function reschedule check timezone

    async getListFilterGraph() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-column-filter`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        let filterGroup = [];
        let keys = [];
        Object.keys(resp.results).forEach(key => {
          const indexfind = keys.findIndex(value => key.includes(value));
          if (indexfind < 0) {
            keys.push(key);
          }
        });
        keys.forEach(key => {
          if (
            key == "diastole_pressure" ||
            key == "body_weight" ||
            key == "body_height" ||
            key == "sistole_pressure"
          ) {
            filterGroup = [
              ...filterGroup,
              {
                dateValue: key,
                dateLabel:
                  resp.results[
                    this.$i18n.locale == "id"
                      ? key
                      : `${key}_${this.$i18n.locale}`
                  ],
              },
            ];
          }
        });
        this.filterGraphItems = filterGroup;
      } catch (error) {
        console.log("Error 403!");
      }
    },
    closeReschedule() {
      this.dataTmpReschedule = {};
      this.dialog_reschedule_homecare = false;
      this.dialog_reschedule_test = false;
      this.dialog_reschedule_subscription = false;
    },
    // family approval
    async getfamily() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/list`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.myFamily = resp.results;
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getfamilyApproval() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/list-approval`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.myFamilyApproval = resp.results;
      } catch (error) {
        console.log("Error 403!");
      }
    },

    toggleDialogInvoice(toggle) {
      this.dialog_invoice = toggle;
      this.dialog_invoice_homecare = toggle;
    },
    async openDialogInvoice(homecare, item, subs) {
      if (homecare) {
        this.dataDialogInvoiceHomecare.order_number = item;
        this.dataDialogInvoice.subscription = subs;
      } else {
        this.dataDialogInvoice.order_number = item;
        this.dataDialogInvoice.subscription = subs;
      }
      this.getOrderDetailInvoiceMCU(homecare, subs);
    },
    async getOrderDetailInvoiceMCU(homecare, subs) {
      try {
        if (!homecare) {
          let order_number = this.dataDialogInvoice.order_number;
          const res = await API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
              order_number +
              ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          this.dataDialogInvoice.data = res.results;
          this.dataDialogInvoice.list = res.results.data;

          //cari harga total karena diskon belum kehitung
          let grand_total =
            parseInt(this.dataDialogInvoice.data.metadata.amount_total) -
            parseInt(this.dataDialogInvoice.data.metadata.total_discount);
          this.dataDialogInvoice.data.metadata.grand_total = grand_total;

          let groupCategory = this.dataDialogInvoice.data.data.reduce(
            (r, a) => {
              r[a.sub_category] = [...(r[a.sub_category] || []), a];
              return r;
            },
            {}
          );

          this.groupItemCategory = Object.entries(groupCategory).map(arr => ({
            nameCategory: arr[0],
            dataCategory: arr[1],
          }));

          let filterItem = [];
          this.groupItemCategory.forEach(element => {
            filterItem = [
              ...filterItem,
              { text: element.nameCategory, type: "category" },
            ];
            let groupSubcategory = element.dataCategory.reduce((r, a) => {
              r[
                a.product_name
                // this.$i18n.locale == "id"
                //   ? a.product_name
                //   : a[`product_name_${this.$i18n.locale}`]
              ] = [
                ...(r[
                  a.product_name
                  // this.$i18n.locale == "id"
                  //   ? a.product_name
                  //   : a[`product_name_${this.$i18n.locale}`]
                ] || []),
                a,
              ];
              return r;
            }, {});
            this.groupItemSubategory = Object.entries(groupSubcategory).map(
              arr => ({
                nameSubcategory: arr[0],
                dataSubcategory: arr[1],
              })
            );

            this.groupItemSubategory.forEach(element => {
              filterItem = [
                ...filterItem,
                { text: element.nameSubcategory, type: "subcategory" },
              ];
              element.dataSubcategory.forEach(element => {
                filterItem = [...filterItem, { ...element, type: "item" }];
              });
            });
          });

          if (subs != true) {
            this.tableInvoice = filterItem;
          } else {
            this.tableInvoice = this.dataDialogInvoice.list;
          }
          this.dialog_invoice = true;
        } else {
          let order_number = this.dataDialogInvoiceHomecare.order_number;
          const resp = await API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail-homecare?order_number=` +
              order_number +
              ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          if (resp.statusCode == 200) {
            this.dataDialogInvoiceHomecare = {};
            const { results } = resp;
            let dataInvoice = {
              order_number: order_number,
              document_info: {
                invoice_number: results.invoice_number,
                invoice_date: results.invoice_date,
                plant_name: results.plant_name,
                medical_record_number: results.medical_record_number,
                practitioner_name: results.practitioner_name,
                payment_method: results.payment_method_name,
                gender: results[`gender_name_${this.$i18n.locale}`],
                currency: results.currency,
                total_payment: results.total_payment,
              },
              customer_info: {
                name: results.name,
                address: results.address,
                email: results.email_contact,
                dob: results.person_dob,
                age: results.age,
                district_city: `${results.subdistrict_name}, ${results.district_name}, ${results.city_name}`,
                postal_province: `${results.province_name} ${results.postal_code}, ${results.country_name}`,
                mobile_phone:
                  results.celluar_contact == null
                    ? "-"
                    : `${results.celluar_contact_code}${results.celluar_contact}`,
                home_contact:
                  results.home_contact == null
                    ? "-"
                    : `${results.home_contact_code}${results.home_contact}`,
              },
              amount_total: 0,
              total_discount: 0,
              grand_total: 0,
              price_discount: 0,
              tables: [],
            };

            results.participant.forEach(item => {
              dataInvoice.tables = [
                ...dataInvoice.tables,
                { type: "name", name: item.patient_name },
              ];
              item.participant_test.forEach(participant => {
                participant.product_data.forEach((category, indexCategory) => {
                  if (indexCategory == 0) {
                    dataInvoice.tables = [
                      ...dataInvoice.tables,
                      {
                        type: "category",
                        category_id: participant.category_name,
                        category_en: participant.category_name_en,
                      },
                    ];
                  }
                  dataInvoice.tables = [
                    ...dataInvoice.tables,
                    {
                      type: "sub_category",
                      sub_category_id: category.product_name,
                      sub_category_en: category.product_name_en,
                    },
                  ];
                  dataInvoice.tables = [
                    ...dataInvoice.tables,
                    {
                      type: "item",
                      product: category,
                    },
                  ];
                  dataInvoice.amount_total =
                    parseFloat(category.product_price) +
                    parseFloat(dataInvoice.amount_total);
                  dataInvoice.total_discount =
                    parseFloat(category.product_discount) +
                    parseFloat(dataInvoice.total_discount);
                  dataInvoice.price_discount =
                    parseFloat(
                      (category.product_discount / 100) * category.product_price
                    ) + parseFloat(dataInvoice.price_discount);
                });
              });
            });
            dataInvoice.grand_total =
              parseFloat(dataInvoice.amount_total) -
              parseFloat(dataInvoice.price_discount);
            this.dataDialogInvoiceHomecare = dataInvoice;
            this.dialog_invoice_homecare = true;
          }
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getMedicalInfo() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-chart?show_by=${this.filterMedicalRecord}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        let respList = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-column-filter`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.medicalInfo = [];
        if (resp.results.length > 0) {
          Object.keys(resp.results[0]).forEach(async key => {
            if (resp.results[0][key].list_history.length > 0) {
              await resp.results[0][key].list_history.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              });
            }
            if (
              key != "respiratory_rate" &&
              key != "oxygen_saturation" &&
              key != "head_circumference" &&
              key != "chest_circumference" &&
              key != "belly_circumference"
            ) {
              this.medicalInfo = [
                ...this.medicalInfo,
                {
                  ...resp.results[0][key],
                  key: key,
                  name: respList.results[
                    this.$i18n.locale == "id"
                      ? key
                      : `${key}_${this.$i18n.locale}`
                  ],
                  note: "Good",
                },
              ];
            }
          });
        } else {
          this.medicalInfo = [];
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    updateChart(value) {
      this.dateFilterDefaultValue = value;
      this.getGraphData();
    },
    filterChart(value) {
      this.filterGraph = value;
      this.getGraphData();
    },
    async getGraphData() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-chart?show_by=${this.dateFilterDefaultValue}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (this.dateFilterDefaultValue == "life_time") {
          Object.keys(resp.results[0]).forEach(key => {
            if (resp.results[0][key].list_history.length == 0) {
              const index = this.filterGraphItems.findIndex(
                i => i.dateValue == key
              );
              if (index > -1) {
                this.filterGraphItems.splice(index, 1);
                if (this.filterGraphItems.length > 0) {
                  this.emptyHistory = false;
                } else {
                  this.emptyHistory = true;
                }
              }
            }
          });
        }

        // set weight and height
        let respVit = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-chart?show_by=last_record`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        if (
          respVit.results.length > 0 &&
          respVit.results[0][this.filterGraph].list_history.length > 0
        ) {
          this.personInfo = {
            ...this.personInfo,
            weight: `${
              respVit.results[0].body_weight.list_history.length > 0
                ? parseFloat(
                    respVit.results[0].body_weight.current_result.value
                  ).toFixed(2)
                : "-"
            } Kg`,
            height: `${
              respVit.results[0].body_height.list_history != undefined
                ? parseFloat(
                    respVit.results[0].body_height.current_result.value
                  ).toFixed(2)
                : "-"
            } Cm`,
          };
        }

        if (
          resp.results.length > 0 &&
          resp.results[0][this.filterGraph].list_history.length > 0
        ) {
          this.graphData = resp.results[0][this.filterGraph].list_history.sort(
            (a, b) => {
              return new Date(a.date) - new Date(b.date);
            }
          );
        } else {
          this.graphData = [];
        }
      } catch (error) {
        this.emptyHistory = true;
        console.log("Error 403!");
      }
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getRecomendedTest() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get-recommendation`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.results.length > 0) {
          this.recomendedTest = resp.results;
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getMedicalRecord() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/get-grouped?show_by=life_time`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataMedicalRecord = resp.results.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getProfile() {
      try {
        const indexID = this.dataProfile.person.registered_ids.findIndex(
          data => data.identity_type.code.toUpperCase() == "ID"
        );
        this.indexIdCard = indexID > 0 ? indexID : 0;
        this.patientId = this.dataProfile.person.patient.id;

        this.personInfo = {
          ...this.personInfo,
          date_of_birth: this.dataProfile.person.date_of_birth,
          place_of_birth: this.dataProfile.person.place_of_birth.toUpperCase(),
        };

        this.listMenu = [
          {
            id: 1,
            title: this.$t("tab.summary"),
            family: false,
          },
          {
            id: 2,
            title: this.$t("tab.medical_record_info"),
            family: false,
          },
          {
            id: 3,
            title: this.$t("tab.family_member_info"),
            family: false,
          },
          {
            id: 4,
            title: this.$t("tab.payment_info"),
            family: false,
          },
          {
            id: 5,
            title: this.$t("tab.my_booking"),
            family: false,
          },
          {
            id: 6,
            title: this.$t("menu.my_subscription"),
          },
          {
            id: 7,
            title: this.$t("menu.setting"),
          },
        ];
        this.listContent = [
          {
            id: 1,
            data: [
              {
                title: "",
                value:
                  this.dataProfile.person.patient.title +
                  " " +
                  this.dataProfile.person.personal_info.name,
              },
              {
                title: this.$t("field.id_card"),
                value:
                  this.dataProfile.person.personal_info.registered_ids[
                    this.indexIdCard
                  ].description == null
                    ? "-"
                    : this.dataProfile.person.personal_info.registered_ids[
                        this.indexIdCard
                      ].description,
              },
              {
                title: "",
                value: this.dataProfile.person.personal_info.address,
              },
              {
                title: this.$t("page.profile.medical_record"),
                value:
                  this.dataProfile.person.patient.medical_record_number == null
                    ? "-"
                    : this.dataProfile.person.patient.medical_record_number,
              },
              {
                title: "",
                value: `${
                  this.dataProfile.person.personal_info.city == null
                    ? "-"
                    : `${this.dataProfile.person.personal_info.city}, `
                }${
                  this.dataProfile.person.address.district == null
                    ? "-"
                    : this.dataProfile.person.address.district
                }`,
              },
              {
                title: this.$t("field.gender"),
                value:
                  this.dataProfile.person.gender.name == null
                    ? "-"
                    : this.dataProfile.person.gender.name,
              },
              {
                title: "",
                value: `${
                  this.dataProfile.person.personal_info.state == null
                    ? "-"
                    : this.dataProfile.person.personal_info.state
                } ${
                  this.dataProfile.person.personal_info.postal_code == null
                    ? "-"
                    : `${this.dataProfile.person.personal_info.postal_code}, `
                } ${
                  this.dataProfile.person.personal_info.country == null
                    ? "-"
                    : this.dataProfile.person.personal_info.country
                }`,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  this.dataProfile.person.personal_info.marital_status == null
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status,
              },

              {
                title: this.$t("field.mobile_phone"),
                value:
                  this.dataProfile.person.personal_info.contacts
                    .mobile_phone_number == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts
                        .mobile_phone_number,
              },
              {
                title: this.$t("field.religion"),
                value:
                  this.dataProfile.person.personal_info.religion == null
                    ? "-"
                    : this.dataProfile.person.personal_info.religion,
              },

              {
                title: this.$t("field.phone"),
                value:
                  this.dataProfile.person.personal_info.contacts
                    .home_phone_number == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts
                        .home_phone_number,
              },
              {
                title: this.$t("field.ethnic"),
                value:
                  this.dataProfile.person.ethnic == null
                    ? "-"
                    : this.dataProfile.person.ethnic.name,
              },
              {
                title: this.$t("field.email"),
                value:
                  this.dataProfile.person.personal_info.contacts.email == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts.email,
              },
              {
                title: this.$t("field.currency"),
                value:
                  this.dataProfile.person.personal_info.currency == null
                    ? "IDR"
                    : this.dataProfile.person.personal_info.currency,
              },
            ],
            // sort mobile
            dataMobile: [
              {
                title: "",
                value:
                  this.dataProfile.person.patient.title +
                  " " +
                  this.dataProfile.person.personal_info.name,
              },
              {
                title: "",
                value: this.dataProfile.person.personal_info.address,
              },
              {
                title: "",
                value: `${
                  this.dataProfile.person.personal_info.city == null
                    ? "-"
                    : `${this.dataProfile.person.personal_info.city}, `
                }${
                  this.dataProfile.person.address.district == null
                    ? "-"
                    : this.dataProfile.person.address.district
                }`,
              },
              {
                title: "",
                value: `${
                  this.dataProfile.person.personal_info.state == null
                    ? "-"
                    : this.dataProfile.person.personal_info.state
                } ${
                  this.dataProfile.person.personal_info.postal_code == null
                    ? "-"
                    : `${this.dataProfile.person.personal_info.postal_code}, `
                } ${
                  this.dataProfile.person.personal_info.country == null
                    ? "-"
                    : this.dataProfile.person.personal_info.country
                }`,
              },
              {
                title: this.$t("field.mobile_phone"),
                value:
                  this.dataProfile.person.personal_info.contacts
                    .mobile_phone_number == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts
                        .mobile_phone_number,
              },
              {
                title: this.$t("field.phone"),
                value:
                  this.dataProfile.person.personal_info.contacts
                    .home_phone_number == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts
                        .home_phone_number,
              },
              {
                title: this.$t("field.email"),
                value:
                  this.dataProfile.person.personal_info.contacts.email == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts.email,
              },
              {
                title: this.$t("field.id_card"),
                value:
                  this.dataProfile.person.personal_info.registered_ids[
                    this.indexIdCard
                  ].description == null
                    ? "-"
                    : this.dataProfile.person.personal_info.registered_ids[
                        this.indexIdCard
                      ].description,
              },
              {
                title: this.$t("page.profile.medical_record"),
                value:
                  this.dataProfile.person.patient.medical_record_number == null
                    ? "-"
                    : this.dataProfile.person.patient.medical_record_number,
              },
              {
                title: this.$t("field.gender"),
                value:
                  this.dataProfile.person.gender.name == null
                    ? "-"
                    : this.dataProfile.person.gender.name,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  this.dataProfile.person.personal_info.marital_status == null
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status,
              },
              {
                title: this.$t("field.religion"),
                value:
                  this.dataProfile.person.personal_info.religion == null
                    ? "-"
                    : this.dataProfile.person.personal_info.religion,
              },

              {
                title: this.$t("field.ethnic"),
                value:
                  this.dataProfile.person.ethnic == null
                    ? "-"
                    : this.dataProfile.person.ethnic.name,
              },
              {
                title: this.$t("field.currency"),
                value:
                  this.dataProfile.person.personal_info.currency == null
                    ? "IDR"
                    : this.dataProfile.person.personal_info.currency,
              },
            ],
          },
          {
            id: 2,
            data: [
              {
                title: "",
                value:
                  this.dataProfile.person.patient.title +
                  " " +
                  this.dataProfile.person.personal_info.name,
              },
              {
                title: this.$t("field.id_card"),
                value:
                  this.dataProfile.person.personal_info.registered_ids[
                    this.indexIdCard
                  ].description == null
                    ? "-"
                    : this.dataProfile.person.personal_info.registered_ids[
                        this.indexIdCard
                      ].description,
              },
              {
                title: "",
                value: this.dataProfile.person.personal_info.address,
              },
              {
                title: this.$t("page.profile.medical_record"),
                value:
                  this.dataProfile.person.patient.medical_record_number == null
                    ? "-"
                    : this.dataProfile.person.patient.medical_record_number,
              },
              {
                title: "",
                value: `${
                  this.dataProfile.person.personal_info.city == null
                    ? "-"
                    : `${this.dataProfile.person.personal_info.city}, `
                }${
                  this.dataProfile.person.address.district == null
                    ? "-"
                    : this.dataProfile.person.address.district
                }`,
              },
              {
                title: this.$t("field.gender"),
                value:
                  this.dataProfile.person.gender.name == null
                    ? "-"
                    : this.dataProfile.person.gender.name,
              },
              {
                title: "",
                value: `${
                  this.dataProfile.person.personal_info.state == null
                    ? "-"
                    : this.dataProfile.person.personal_info.state
                } ${
                  this.dataProfile.person.personal_info.postal_code == null
                    ? "-"
                    : `${this.dataProfile.person.personal_info.postal_code}, `
                } ${
                  this.dataProfile.person.personal_info.country == null
                    ? "-"
                    : this.dataProfile.person.personal_info.country
                }`,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  this.dataProfile.person.personal_info.marital_status == null
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status,
              },
              {
                title: this.$t("field.mobile_phone"),
                value:
                  this.dataProfile.person.personal_info.contacts
                    .mobile_phone_number == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts
                        .mobile_phone_number,
              },
              {
                title: this.$t("field.religion"),
                value:
                  this.dataProfile.person.personal_info.religion == null
                    ? "-"
                    : this.dataProfile.person.personal_info.religion,
              },

              {
                title: this.$t("field.phone"),
                value:
                  this.dataProfile.person.personal_info.contacts
                    .home_phone_number == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts
                        .home_phone_number,
              },

              {
                title: this.$t("field.ethnic"),
                value:
                  this.dataProfile.person.ethnic == null
                    ? "-"
                    : this.dataProfile.person.ethnic.name,
              },

              {
                title: this.$t("field.email"),
                value:
                  this.dataProfile.person.personal_info.contacts.email == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts.email,
              },
              {
                title: this.$t("field.currency"),
                value:
                  this.dataProfile.person.personal_info.currency == null
                    ? "IDR"
                    : this.dataProfile.person.personal_info.currency,
              },
            ],
            // sort mobile
            dataMobile: [
              {
                title: "",
                value:
                  this.dataProfile.person.patient.title +
                  " " +
                  this.dataProfile.person.personal_info.name,
              },
              {
                title: "",
                value: this.dataProfile.person.personal_info.address,
              },
              {
                title: "",
                value: `${
                  this.dataProfile.person.personal_info.city == null
                    ? "-"
                    : `${this.dataProfile.person.personal_info.city}, `
                }${
                  this.dataProfile.person.address.district == null
                    ? "-"
                    : this.dataProfile.person.address.district
                }`,
              },
              {
                title: "",
                value: `${
                  this.dataProfile.person.personal_info.state == null
                    ? "-"
                    : this.dataProfile.person.personal_info.state
                } ${
                  this.dataProfile.person.personal_info.postal_code == null
                    ? "-"
                    : `${this.dataProfile.person.personal_info.postal_code}, `
                } ${
                  this.dataProfile.person.personal_info.country == null
                    ? "-"
                    : this.dataProfile.person.personal_info.country
                }`,
              },

              {
                title: this.$t("field.mobile_phone"),
                value:
                  this.dataProfile.person.personal_info.contacts
                    .mobile_phone_number == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts
                        .mobile_phone_number,
              },
              {
                title: this.$t("field.phone"),
                value:
                  this.dataProfile.person.personal_info.contacts
                    .home_phone_number == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts
                        .home_phone_number,
              },
              {
                title: this.$t("field.email"),
                value:
                  this.dataProfile.person.personal_info.contacts.email == null
                    ? "-"
                    : this.dataProfile.person.personal_info.contacts.email,
              },
              {
                title: this.$t("field.id_card"),
                value:
                  this.dataProfile.person.personal_info.registered_ids[
                    this.indexIdCard
                  ].description == null
                    ? "-"
                    : this.dataProfile.person.personal_info.registered_ids[
                        this.indexIdCard
                      ].description,
              },
              {
                title: this.$t("page.profile.medical_record"),
                value:
                  this.dataProfile.person.patient.medical_record_number == null
                    ? "-"
                    : this.dataProfile.person.patient.medical_record_number,
              },
              {
                title: this.$t("field.gender"),
                value:
                  this.dataProfile.person.gender.name == null
                    ? "-"
                    : this.dataProfile.person.gender.name,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  this.dataProfile.person.personal_info.marital_status == null
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status,
              },
              {
                title: this.$t("field.religion"),
                value:
                  this.dataProfile.person.personal_info.religion == null
                    ? "-"
                    : this.dataProfile.person.personal_info.religion,
              },

              {
                title: this.$t("field.ethnic"),
                value:
                  this.dataProfile.person.ethnic == null
                    ? "-"
                    : this.dataProfile.person.ethnic.name,
              },
              {
                title: this.$t("field.currency"),
                value:
                  this.dataProfile.person.personal_info.currency == null
                    ? "IDR"
                    : this.dataProfile.person.personal_info.currency,
              },
            ],
          },
          {
            id: 3,
          },
          {
            id: 4,
          },
          {
            id: 5,
          },
          {
            id: 6,
          },
          {
            id: 7,
          },
        ];
        if (this.$store.state.activeTab == "" && this.listContent != null)
          this.$store.commit("setActiveTab", this.listContent[0].id);
        this.$store.commit("setListContent", this.listContent);

        // this.activeTab = this.$store.state.activeTab;
      } catch (error) {
        console.log("Error 403!");
      }
      if (this.$store.state.activeTab == "")
        this.$store.commit("setActiveTab", this.listContent[0].id);
      this.$store.commit("setListMenu", this.listMenu);

      // this.$store.commit("setActiveTab", this.listContent[0].id);
      // this.activeTab = this.$store.state.activeTab;
    },
    getDateThisWeek() {
      const date = moment();
      const startThisWeek = moment(date).startOf("isoWeek");
      const endThisWeek = moment(date).endOf("isoWeek");
      this.thisWeek = this.getListDateRange(startThisWeek, endThisWeek);
    },
    getListDateRange(startDate, endDate) {
      var start = new Date(startDate);
      var end = new Date(endDate);
      var dates = [];

      while (start <= end) {
        dates.push(new Date(start));
        start.setDate(start.getDate() + 1);
      }
      return dates;
    },
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    toggleDialogOrderMCU(toggle) {
      this.dialog_order_mcu = toggle;
    },
    editItem(item) {
      this.editedIndex = this.historyProduct.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    editItemPackage(itemPackage) {
      this.editedIndexPackage = this.historyPackage.indexOf(itemPackage);
      this.editedItemPackage = Object.assign({}, itemPackage);
      this.dialogEditPackage = true;
    },
    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeEditPackage() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEditPackage = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItemPackage = Object.assign({}, this.defaultItemPackage);
        this.editedIndexPackage = -1;
      });
    },
    formatDate(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },

    save() {
      if (this.$refs.form.validate() == true) {
        // reference instance ke variabel karena tidak akan terbaca pada proses async

        var vm = this;
        // Jika ada edit item, karena jika diedit index akan selalu lebih dari -1
        if (this.editedIndex > -1) {
          try {
            let form = {
              order_number:
                typeof this.editedItem.order_number !== "undefined"
                  ? this.editedItem.order_number
                  : "",
              rating:
                typeof this.editedItem.rating !== "undefined"
                  ? this.editedItem.rating
                  : "",
              comment:
                typeof this.editedItem.comment !== "undefined"
                  ? this.editedItem.comment.toString().toUpperCase()
                  : "",
            };

            // Begin api request
            (async function () {
              const res = await API.post(
                `${process.env.VUE_APP_API_TRUST}invoices/save-rating`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("success_insert_data"));
                setTimeout(function () {
                  location.reload();
                }, 200);
              } else if (res.statusCode == 403) {
                // vm.toast("error", res.message);
                Object.keys(res.errors).forEach(element => {
                  vm.toast(
                    "error",
                    typeof res.errors[element] == "object"
                      ? res.errors[element][0]
                      : res.errors[element]
                  );
                });
              } else {
                vm.$swal({
                  icon: "error",
                  text: res.message,
                  //text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.closeEdit();
        }
      }
    },

    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },

    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
    close(id) {
      const filterTab = this.listMenu.filter(el => el.id !== id);
      const filterContent = this.listContent.filter(el => el.id !== id);

      this.listMenu = filterTab;
      this.listContent = filterContent;
      this.$store.state.listMenu = filterTab;
      this.$store.state.listContent = filterContent;
      this.$store.state.activeTab = 3;
      this.$store.commit("setActiveTab", 3);
    },
    onClickItem(par) {
      const name = this.listProductItem.find(element => element.id == par);
      this.productName = name.productName;
      this.productStatus = name.status;
      this.productCurrent = name.currentRes;
      this.productRange = name.desiredRange;
      this.productDesc = name.desc;
    },
    // pagination
    // start get History Test
    async getHistoryTest() {
      this.loadingHistory = true;
      // const offset =
      //   this.pagination_history.active == 1
      //     ? 0
      //     : parseInt(this.pagination_history.active) *
      //         parseInt(this.pagination_history.per_page) -
      //       1;
      //  `${process.env.VUE_APP_API_TRUST}registration/get-order-list?approved=true&offset=${offset}&per_page=${this.pagination_history.per_page}`,
      let startPage = (this.pageMedical - 1) * this.perPageMedical;
      let showPage = this.perPageMedical;
      const { shared, filter_date, branch, type_test } =
        this.filterActiveHistory;
      const sortedDate = [...filter_date].sort((a, b) => {
        return moment(a) - moment(b);
      });
      await API.get(
        `${
          process.env.VUE_APP_API_TRUST
        }registration/get-order-list?approved=true&offset=${startPage}&per_page=${showPage}&shared=${
          shared ? true : ""
        }&start_date=${sortedDate.length > 0 ? sortedDate[0] : ""}&end_date=${
          sortedDate.length > 0
            ? sortedDate.length > 1
              ? sortedDate[1]
              : sortedDate[0]
            : ""
        }${
          type_test == null || type_test == "" ? "" : `&type=${type_test}`
        }&plant_id=${branch == null ? "" : branch}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        if (res.statusCode === 200) {
          // const filterProduct = res.results.filter(dataByProduct => {
          //   return dataByProduct.approved == true;
          // });

          const testProduct = res.results.filter(dataProduct => {
            return dataProduct.status != "failure";
          });

          this.historyPackage = testProduct;
          this.itemsTotalMedical = res.total;
          this.itemLengthMedical = Math.ceil(this.itemsTotalMedical / showPage);

          // this.datapreviousMedical = res.prev_page;
          // this.dataNextMedical = res.next_page;
        }
        this.loadingHistory = false;
      });
    },
    // end get history test

    // get Ongoing Test
    async getOngoingTest() {
      this.loadingOngoing = true;
      // const offset =
      //   this.pagination_ongoing.active == 1
      //     ? 0
      //     : parseInt(this.pagination_ongoing.active) *
      //         parseInt(this.pagination_ongoing.per_page) -
      //       1;
      //`${process.env.VUE_APP_API_TRUST}registration/get-order-list?approved=false&offset=${offset}&per_page=${this.pagination_ongoing.per_page}`,
      let startPage = (this.page - 1) * this.perPage;
      let showPage = this.perPage;
      const { filter_date, branch, type_test } = this.filterActiveOngoing;
      const sortedDate = [...filter_date].sort((a, b) => {
        return moment(a) - moment(b);
      });
      await API.get(
        `${
          process.env.VUE_APP_API_TRUST
        }registration/get-order-list?approved=false&offset=${startPage}&per_page=${showPage}&status=paid&upcoming=true&start_date=${
          sortedDate.length > 0 ? sortedDate[0] : ""
        }&end_date=${
          sortedDate.length > 0
            ? sortedDate.length > 1
              ? sortedDate[1]
              : sortedDate[0]
            : ""
        }${
          type_test == null || type_test == "" ? "" : `&type=${type_test}`
        }&plant_id=${branch == null ? "" : branch}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        if (res.statusCode === 200) {
          // const filterProduct = res.results.filter(dataByProduct => {
          //   return dataByProduct.approved == false;
          // });

          const testProduct = res.results.filter(dataProduct => {
            return dataProduct.status != "failure";
          });

          this.historyProduct = testProduct;
          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          // this.dataprevious = res.prev_page;
          // this.dataNext = res.next_page;
        }
        this.loadingOngoing = false;
      });
    },
    // next page ongoing test
    async nextPageFun() {
      this.pagination_ongoing.active++;
      this.getOngoingTest();
    },
    // next page history test
    async nextPageFunMedical() {
      this.pagination_history.active++;
      this.getHistoryTest();
    },
    // previous ongoing Test
    async previousPageFun() {
      this.pagination_ongoing.active--;
      this.getOngoingTest();
    },
    // previous history Test
    async previousPageFunMedical() {
      this.pagination_history.active--;
      this.getHistoryTest();
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      this.mime_type = file.type;
      this.file_name = file.name;

      if (this.mime_type == "image/jpeg") {
        if (typeof FileReader === "function") {
          this.dialogUpload = true;
          const reader = new FileReader();
          reader.onload = event => {
            this.selectedFile = event.target.result;
            this.$refs.cropper.replace(this.selectedFile);
          };
          reader.readAsDataURL(file);
        } else {
          this.$swal({
            icon: "error",
            text: this.$t("page.profile.file_reader"),
          });
        }
      } else {
        this.$swal({
          icon: "error",
          html:
            this.$t("page.profile.mime_type") +
            `<br>` +
            this.$t("page.profile.mime_type_jpg"),
        });
      }
    },
    saveImage() {
      this.$store.commit("setLoading", true);
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        const fd = new FormData();
        fd.append("photo", blob, "name.jpeg");
        fd.append("upload_id", this.payload.idProfileImg);
        axios
          .post(`${process.env.VUE_APP_API_TRUST}upload-pp`, fd, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          })
          .then(res => {
            this.$store.commit("setLoading", false);
            if (res.status === 200) {
              this.$swal({
                icon: "success",
                text: res.data.message,
                showCancelButton: false,
                confirmButtonColor: "#7367f0",
                confirmButtonText: "OK",
              }).then(() => {
                window.location.href = `/${this.$i18n.locale}/patient`;
              });
            }
          })
          .catch(err => {
            this.$swal({
              icon: "error",
              text: err.response.data.errors.photo[0],
            });
          });
      }, "image/jpeg");
    },
    passwordConfirmRules(value) {
      if (value !== this.payload.new_password) {
        return `${this.$t("page.setting.confirm_new_password")} ${this.$t(
          "must_valid"
        )}`;
      } else if (value == "") {
        return `${this.$t("page.setting.confirm_new_password")} ${this.$t(
          "is_required"
        )}`;
      } else {
        return true;
      }
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        const { current_password, new_password } = this.payload;
        const dataReady = {
          current_password,
          new_password,
        };

        API.post(
          `${process.env.VUE_APP_API_TRUST}change-password?lang=${this.$i18n.locale}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        )
          .then(res => {
            if (res.statusCode === 200) {
              this.payload = {
                current_password: "",
                new_password: "",
              };
              this.$swal({
                icon: "success",
                text: this.$t("success_change_password"),
                showCancelButton: false,
                confirmButtonColor: "#7367f0",
                confirmButtonText: "OK",
              }).then(() => {
                window.location.href = `/${this.$i18n.locale}/patient/profile`;
              });
            } else if (res.statusCode == 400) {
              //this.toast("error", res.message);
              Object.keys(res.errors).forEach(element => {
                this.toast(
                  "error",
                  typeof res.errors[element] == "object"
                    ? res.errors[element][0]
                    : res.errors[element]
                );
              });
              // if (res.statusCode == 403)
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
                //text: "Password must be at least 8 characters.",
              });
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              text: "Error Change Password!",
            });
          });
      }
    },
    onClickOutside() {
      this.dialog_reschedule_homecare = false;
      this.dialog_reschedule_test = false;
      this.dialog_reschedule_subscription = false;
    },
    diffHours(date) {
      var dateHomecareNow = new Date();
      dateHomecareNow.setHours(dateHomecareNow.getHours());
      var dateBookHomecare = new Date(date);
      var diff =
        (dateBookHomecare.getTime() - dateHomecareNow.getTime()) / 1000;
      diff /= 60 * 60;
      var hasil = Math.round(diff);
      //console.log(hasil,'hasil')
      if (hasil < 8) {
        return false;
        //console.log("hilang")
      } else {
        //console.log("muncul")
        return true;
      }
    },
    async getDataFamily(par) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-by-medical-record?show_by=last_record&medical_record_number=${par}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200 && res.results.vitality_sign.length > 0) {
          const vital = res.results.vitality_sign[0];
          this.headerTableVital = [
            {
              text: `${this.$t("R.blood_pressure")} (${
                vital.sistole_pressure.unit
              })`,
              value: "blood",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.heart_rate")} (${vital.heart_rate.unit})`,
              value: "heart_rate",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.temperature")} (${
                vital.body_temperature.unit
              })`,
              value: "body_temperature",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.heart_rate")} (${
                vital.respiratory_rate.unit
              })`,
              value: "respiratory_rate",
              sortable: false,
              align: "center",
            },
          ];
          this.itemVital = [
            {
              blood_pressure:
                Object.keys(vital.sistole_pressure.current_result).length == 0
                  ? "-"
                  : `${parseFloat(
                      vital.sistole_pressure.current_result.value
                    ).toFixed(2)} / ${parseFloat(
                      vital.diastole_pressure.current_result.value
                    ).toFixed(2)}`,
              heart_rate:
                Object.keys(vital.heart_rate.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.heart_rate.current_result.value).toFixed(
                      2
                    ),
              body_temperature:
                Object.keys(vital.body_temperature.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.body_temperature.current_result.value
                    ).toFixed(2),
              respiratory_rate:
                Object.keys(vital.respiratory_rate.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.respiratory_rate.current_result.value
                    ).toFixed(2),
            },
          ];
          this.headerTableAnthA = [
            {
              text: `${this.$t("R.height")} (${vital.body_height.unit})`,
              value: "body_height",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.weight")} (${vital.body_weight.unit})`,
              value: "body_weight",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.body_mass_index")} (${
                vital.body_mass_index.unit
              })`,
              value: "body_mass_index",
              sortable: false,
              align: "center",
              width: "40%",
            },
          ];
          this.itemAnthA = [
            {
              body_height:
                Object.keys(vital.body_height.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.body_height.current_result.value).toFixed(
                      2
                    ),
              body_weight:
                Object.keys(vital.body_weight.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.body_weight.current_result.value).toFixed(
                      2
                    ),
              body_mass_index:
                Object.keys(vital.body_mass_index.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.body_mass_index.current_result.value
                    ).toFixed(2),
            },
          ];
          this.headerTableAnthB = [
            {
              text: `${this.$t("R.abdomen_circumference")} (${
                vital.belly_circumference.unit
              })`,
              value: "belly_circumference",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.chest_circumference")} (${
                vital.chest_circumference.unit
              })`,
              value: "chest_circumference",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.head_circumference")} (${
                vital.head_circumference.unit
              })`,
              value: "head_circumference",
              sortable: false,
              align: "center",
              width: "40%",
            },
          ];
          this.itemAnthB = [
            {
              belly_circumference:
                Object.keys(vital.belly_circumference.current_result).length ==
                0
                  ? "-"
                  : parseFloat(
                      vital.belly_circumference.current_result.value
                    ).toFixed(2),
              chest_circumference:
                Object.keys(vital.chest_circumference.current_result).length ==
                0
                  ? "-"
                  : parseFloat(
                      vital.chest_circumference.current_result.value
                    ).toFixed(2),
              head_circumference:
                Object.keys(vital.head_circumference.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.head_circumference.current_result.value
                    ).toFixed(2),
            },
          ];

          const { customer_info } = res.results;

          this.listContentFamily = [
            {
              id: 1,
              // sort desktop
              data: [
                {
                  title: "",
                  value: `${customer_info.patientTitle} ${customer_info.patientName}`,
                },
                {
                  title: this.$t("field.id_card"),
                  value:
                    customer_info.id_card_number == null
                      ? "-"
                      : customer_info.id_card_number,
                },
                {
                  title: "",
                  value:
                    customer_info.address == null ? "-" : customer_info.address,
                },
                {
                  title: this.$t("page.profile.medical_record"),
                  value:
                    customer_info.medicalRecordNumber == null
                      ? "-"
                      : customer_info.medicalRecordNumber,
                },
                {
                  title: "",
                  value: `${
                    customer_info.city == null ? "-" : `${customer_info.city}, `
                  }${
                    customer_info.district == null
                      ? "-"
                      : customer_info.district
                  }`,
                },
                {
                  title: this.$t("field.gender"),
                  value:
                    customer_info.patientGender == null
                      ? "-"
                      : customer_info.patientGender,
                },
                {
                  title: "",
                  value: `${
                    customer_info.province == null
                      ? "-"
                      : `${customer_info.province} `
                  }${
                    customer_info.postal_code == null
                      ? "-"
                      : `${customer_info.postal_code}, `
                  }${
                    customer_info.country == null ? "-" : customer_info.country
                  }`,
                },
                {
                  title: this.$t("field.marital_status"),
                  value:
                    customer_info.maritalStatus == null
                      ? "-"
                      : customer_info.maritalStatus,
                },
                {
                  title: this.$t("field.email"),
                  value:
                    customer_info.email == null ? "-" : customer_info.email,
                },
                {
                  title: this.$t("field.religion"),
                  value:
                    customer_info.religionName == null
                      ? "-"
                      : customer_info.religionName,
                },
                {
                  title: this.$t("field.mobile_phone"),
                  value:
                    customer_info.mobile_phone == null
                      ? "-"
                      : customer_info.mobile_phone,
                },
                {
                  title: this.$t("field.ethnic"),
                  value:
                    customer_info.ethnic == null ? "-" : customer_info.ethnic,
                },
                {
                  title: this.$t("field.home_phone"),
                  value:
                    customer_info.phone == null ? "-" : customer_info.phone,
                },
                {
                  title: this.$t("field.currency"),
                  value:
                    customer_info.currency == null
                      ? "IDR"
                      : customer_info.currency,
                },
              ],
              // sort mobile
              dataMobile: [
                {
                  title: "",
                  value: `${customer_info.patientTitle} ${customer_info.patientName}`,
                },
                {
                  title: "",
                  value:
                    customer_info.address == null ? "-" : customer_info.address,
                },
                {
                  title: "",
                  value: `${
                    customer_info.city == null ? "-" : `${customer_info.city}, `
                  }${
                    customer_info.district == null
                      ? "-"
                      : customer_info.district
                  }`,
                },
                {
                  title: "",
                  value: `${
                    customer_info.province == null
                      ? "-"
                      : `${customer_info.province} `
                  }${
                    customer_info.postal_code == null
                      ? "-"
                      : `${customer_info.postal_code}, `
                  }${
                    customer_info.country == null ? "-" : customer_info.country
                  }`,
                },
                {
                  title: this.$t("field.email"),
                  value:
                    customer_info.email == null ? "-" : customer_info.email,
                },
                {
                  title: this.$t("field.mobile_phone"),
                  value:
                    customer_info.mobile_phone == null
                      ? "-"
                      : customer_info.mobile_phone,
                },
                {
                  title: this.$t("field.home_phone"),
                  value:
                    customer_info.phone == null ? "-" : customer_info.phone,
                },
                {
                  title: this.$t("field.id_card"),
                  value:
                    customer_info.id_card_number == null
                      ? "-"
                      : customer_info.id_card_number,
                },
                {
                  title: this.$t("page.profile.medical_record"),
                  value:
                    customer_info.medicalRecordNumber == null
                      ? "-"
                      : customer_info.medicalRecordNumber,
                },
                {
                  title: this.$t("field.gender"),
                  value:
                    customer_info.patientGender == null
                      ? "-"
                      : customer_info.patientGender,
                },
                {
                  title: this.$t("field.marital_status"),
                  value:
                    customer_info.maritalStatus == null
                      ? "-"
                      : customer_info.maritalStatus,
                },
                {
                  title: this.$t("field.religion"),
                  value:
                    customer_info.religionName == null
                      ? "-"
                      : customer_info.religionName,
                },
                {
                  title: this.$t("field.ethnic"),
                  value:
                    customer_info.ethnic == null ? "-" : customer_info.ethnic,
                },
                {
                  title: this.$t("field.currency"),
                  value:
                    customer_info.currency == null
                      ? "IDR"
                      : customer_info.currency,
                },
              ],
            },
          ];
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    dat() {
      const item = this.listProductItem.length;
      this.onClickItem(1);
      return Array.from({ length: item }, (k, v) => {
        const product = this.listProductItem[v].productName;
        const productId = this.listProductItem[v].id;
        return {
          product: `${product}`,
          productId: `${productId}`,
        };
      });
    },
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
};
</script>
<style>
.uppercase-input input,
.v-input {
  text-transform: uppercase;
}

.cropper-point.point-n,
.cropper-point.point-s,
.cropper-point.point-e,
.cropper-point.point-w,
.cropper-line.line-n,
.cropper-line.line-s,
.cropper-line.line-e,
.cropper-line.line-w {
  display: none;
}
</style>
<style lang="scss">
.patient {
  margin-bottom: 20px;
  @media screen and (max-width: 976px) {
    margin-bottom: 12px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
  .tab__menu {
    .menu__item {
      flex: inherit;

      @media screen and (max-width: 1536px) {
        flex: 1;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important;
  /* make scrollbar transparent */
}
@media screen and (max-width: 767px) {
  .bg-white-profile {
    background-color: white !important;
  }
  .v-application .mb-6 {
    margin-bottom: 0 !important;
  }
  .patient {
    .tab__content {
      margin: -22px -12px -12px -12px !important;
      box-shadow: unset !important;
      padding-top: 42px !important;
      padding-bottom: 42px !important;
    }
  }
  .medical-history-box {
    margin: 5px -12px 0 !important;
  }
  .recomended-test-box {
    margin-top: 24px !important;
    padding: 12px;
  }
  .relationship-box {
    margin: 24px -12px 12px !important;
  }

  .show-btn-nav-mobile {
    display: block;
  }
  .hide-btn-mobile {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.center-empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-switch {
  color: $main_2;
  margin-right: 8px;
}

.h4 {
  color: $main_2;
}

.list-data {
  border-bottom: 1px solid $gray_2;
  padding: 12px 8px 0 8px;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.card-chart {
  position: relative;
}

.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}

.text-new-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}

.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}

.v-tabs {
  width: 720px;
}

.center-chart {
  background: transparent;
  height: 80%;
  width: 80%;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 72px;
    margin-bottom: 0;
  }
}

.label-doughnut {
  align-self: center;
}

.doughnut-title {
  padding: 4px;
}

.chart-title {
  padding: 0 0 6px 0;
}

.v-input__control {
  height: 30px;
}

.v-input__slot {
  height: 30px;
}

.v-select__selections {
  width: 30px;
  height: 30px;
}

.sticky-table-footer {
  td {
    font-weight: bold;
  }
}

.card-chart .v-data-table {
  td {
    font-size: 12px;
  }
}

.table-patient {
  th {
    .text-gray {
      color: $main_2;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  td {
    .text-gray {
      color: $gray_table;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .text-black {
      color: $black_table;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

// card recomended
.card-popular {
  margin-bottom: 16px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.btn-detail {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  background-color: #ffffff !important;
  color: #1f3c87;
  font-size: 13px;
}

// START CSS ONGOING TEST & HISTORY
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;

  &__section {
    padding: 0 20px;
  }
}

a {
  text-decoration: none;
}

.v-application p {
  margin-bottom: 9px;
}

// stylefilterTab
.header {
  margin-bottom: 11px;
  margin-top: 2px;
}

.tagline {
  font-size: 20px;
  font-weight: bold;
  color: #1f3c87;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
}

.v-label {
  font-size: 12px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 130px;
  padding: 0 16px;
}

.subtitle-order {
  color: #1f3c87;
  font-weight: 700;
}

.button-actions {
  background-color: #00a4b3 !important;
  border-radius: 4px;
  color: white !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  //   text-transform: capitalize;
  // height: 30px !important;
  // min-width: 105px !important;
}

.btn-cancel {
  height: 30px !important;
  min-width: 64px !important;
}

.iconsSet {
  cursor: pointer;
}

.goneText {
  display: none;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}

.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header--active::before {
  opacity: 0 !important;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: transparent !important;
}

.section-expand {
  padding-bottom: 8px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}

.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #6dc6d6;
  font-weight: bold;
}

.head-vir2:hover {
  background: #6dc6d6 !important;
}

.head-vir2 th {
  color: #000 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}

.href-link {
  color: #00a4b3;
  font-weight: 600;
}

.uppercase-input input,
.v-input {
  text-transform: capitalize !important;
}

.br-3 {
  margin-bottom: -4px;
  margin-left: 15px;
}

@media screen and (max-width: 960px) {
  .border-side {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .border-top {
    padding-top: 0px !important;
  }

  .show-btn-nav {
    display: none !important;
  }

  .hide-btn-nav {
    display: block;
  }

  .bottom-mobile-none {
    padding-bottom: 0px !important;
  }

  .card--image {
    padding: 15px 15px !important;
  }
}

@media screen and (min-width: 913px) {
  .border-side {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .delete-padding-left {
    padding-left: 0px !important;
  }

  .delete-padding-top {
    padding-top: 0px !important;
  }
}
.show-btn-nav {
  display: none;
}
@media screen and (max-width: 1280px) {
  .show-btn-nav {
    display: none;
  }

  .hide-btn-nav {
    display: block;
  }
  .m-between {
    display: block !important;
  }
  .d-between {
    display: none !important;
  }
}

@media screen and (min-width: 1026px) {
  // .border-side {
  //   padding-left: 0px !important;
  // }

  .delete-padding-top {
    padding-top: 12px !important;
  }

  .card--cart {
    margin-top: 50px !important;
  }
}

.top-40 {
  margin-top: 40px !important;
}

@media only screen and (max-width: 600px) {
  .column-recommended-test {
    margin-top: -24px;
    padding: 20px 0 0 0;
    .row {
      padding: unset !important;
    }
  }

  .bell-mobile {
    margin-left: -24px;
  }

  .user-card {
    max-width: 56% !important;
    margin-top: -41px;
    margin-left: 153px;
  }

  .notification {
    height: 55px;
    border-radius: 3px;
    margin-left: -97px;
    margin-top: 4px;
  }

  .cart-right {
    z-index: 1;
    margin-left: -101px;
  }

  .ic-close {
    margin-left: 160px !important;
  }

  .pb-35 {
    padding-bottom: 35px;
  }

  .card--image {
    padding: 15px 15px !important;
  }
}

.cart-right {
  margin-left: 17px;
}

.align-end {
  text-align: end;
}

.bottom-none {
  padding-bottom: 0px !important;
}

.top-none {
  padding-top: 0px !important;
}

.column-queue {
  margin-top: -28px;
}

.ic-close {
  position: absolute;
  top: -8px;
  right: -30px;
}

.v-btn--floating {
  position: relative;
}

//css tampilan baru
.popup {
  padding: 8px;
  background: white;
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 16px;

  a {
    text-decoration: none;
    color: black;
  }
}

.title-grid {
  display: grid;
}

.title-grid-h {
  text-align: left;
}

.title-grid-c {
  text-align: left;
  font-size: 8px;
}

.mt5 {
  margin-top: 5px !important;
}

.mb10 {
  margin-bottom: -10px;
}

.mb5 {
  margin-bottom: 5px !important;
}

.ungu {
  color: #233f78;
}

.biru {
  color: #00a4b3;
}

.hijau {
  color: #719e51;
}

.s14 {
  font-size: 14px;
}

.s12 {
  font-size: 12px;
}

.s10 {
  font-size: 10px;
}

.bold {
  font-weight: 600;
}

.rowPad {
  display: block !important;
  border-bottom: 1px solid #cccc;
  padding-bottom: 10px;
}

.box {
  border-right: 1px solid #cccc;
  padding-top: 10px;
  width: 100%;
  margin-top: 3px;
}

.box2 {
  border-right: 1px solid #cccc;
  padding: 15px;
  width: 100%;
  margin-top: 3px;
}

.box3 {
  padding: 15px;
  width: 100%;
}

.box4 {
  padding: 10px 10px 10px 0;
  width: 100%;
  font-size: 10px;
  display: flex;
}

.bt {
  border-top: 1px solid #cccc;
}

.bb {
  border-bottom: 1px solid #cccc;
}

.br {
  border-right: 1px solid #cccc;
}

.bl {
  border-left: 1px solid #cccc;
  // padding-top: 50px;
  // margin-left: -1px;
}

.flexSlide {
  display: flex;
  width: 200px;
  position: relative;
}

.flexSlide > div {
  flex: 0 0 50%;
  border: 1px solid black;
  box-sizing: border-box;
}

.slide-2 {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.card--image {
  padding: 0px 15px 15px 15px;
}

.image-profie {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 35px;
  object-fit: cover;
}

.btn-upload {
  margin-top: 30px;
}

.title-change-password {
  text-align: center;
}

.image-password {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.btn-save {
  background-color: #00a6b6 !important;
  color: white !important;
}

.pad-action {
  justify-content: center;
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}
@media only screen and (max-width: 767px) {
  .col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .m-between {
    display: flex !important;
    justify-content: space-between;
  }
  .d-between {
    display: none !important;
  }
  .vm-invoice {
    background: #00a4b3;
    color: white;
    padding: 6px 6px 6px 10px;
    margin-right: -12px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .m-padding-top {
    padding-top: 5px !important;
  }
}
.m-between {
  display: none;
}
.d-between {
  display: block;
}
.bg-icon-new {
  //cursor: pointer;
  background-color: #00a4b3;
  color: white;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px 0px 0px 5px;
  position: absolute;
  right: 0;
  top: 12px;
  width: auto;
  line-height: 16px;
  //min-height: 120px;

  @media screen and (min-width: 960px) {
    top: 20px;
  }
}
.inner-display {
  display: grid;
  padding-top: 5px;
}

.tooltip-head-invoice .tooltiptext {
  visibility: hidden;
  background-color: #00a4b3;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  right: 40px;
  font-size: 12px;
  min-width: 100px;
}

.tooltip-head-invoice:hover .tooltiptext {
  visibility: visible;
}

.tooltip-head-direction .tooltiptext {
  visibility: hidden;
  background-color: #00a4b3;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  right: 40px;
  font-size: 12px;
  min-width: 100px;
}

.tooltip-head-direction:hover .tooltiptext {
  visibility: visible;
}
.grey-tooltip {
  background: #f3f3f3;
}
.v-btn-toggle > .filter.v-btn.v-btn--active {
  color: inherit;
  opacity: 0.8;
  background: #ddd;
}
</style>
