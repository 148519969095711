<template>
  <v-card-text class="px-3 px-md-5">
    <v-row>
      <v-col cols="12">
        <h3 class="black--text">{{ $t("R.examination_result") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <v-simple-table class="super-dense">
              <template v-slot:default>
                <thead class="no-pad">
                  <tr class="grey-color head-vir2 text-no-wrap">
                    <th class="text-center tb-wide" style="width: 25%">
                      {{ $t("R.examination_code") }}
                    </th>
                    <th
                      class="text-center tb-wide text-no-wrap"
                      style="width: 20%"
                    >
                      {{ $t("R.examination_name") }}
                    </th>
                    <th
                      class="text-center tb-wide text-no-wrap"
                      style="width: 15%"
                    >
                      {{ $t("result") }}
                    </th>
                    <th
                      class="text-center tb-wide text-no-wrap"
                      style="width: 15%"
                    >
                      {{ $t("R.reference_result") }}
                    </th>
                    <th class="text-center text-no-wrap" style="width: 10%">
                      {{ $t("R.unit") }}
                    </th>
                    <th class="text-center text-no-wrap" style="width: 15%">
                      {{ $t("R.notes") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listLabResult" :key="index">
                    <td
                      v-if="item.type == 'category'"
                      colspan="6"
                      class="category-blue"
                    >
                      {{ item.category_id }} | {{ item.category_en }}
                    </td>
                    <td
                      v-else-if="item.type == 'product'"
                      colspan="6"
                      class="grey-color bold"
                    >
                      {{ item.product }}
                    </td>
                    <template v-else>
                      <td
                        class="text-left td-custom text-no-wrap"
                        :class="{
                          true: item.status == 'Critical',
                          false: item.status !== 'Critical',
                        }"
                      >
                        <v-icon
                          color="red"
                          small
                          v-if="item.status == 'Critical'"
                          style="font-size: 12px !important"
                        >
                          mdi-alert
                        </v-icon>
                        {{ item.product_code }}
                      </td>
                      <td class="text-left text-no-wrap">
                        {{
                          $i18n.locale == "id"
                            ? item[`product_item_name`]
                            : item[`product_item_name_${$i18n.locale}`]
                        }}
                      </td>
                      <td
                        class="text-right text-no-wrap"
                        :class="{
                          true: item.status == 'Critical',
                          false: item.status != 'Critical',
                        }"
                      >
                        {{
                          item.result_value_post == null
                            ? "-"
                            : item.result_value_post
                        }}
                      </td>
                      <td class="text-right text-no-wrap">
                        <template v-if="checkIsJsonString(item.reference_text)">
                          <span
                            v-for="(reference, index) of JSON.parse(
                              item.reference_text
                            )"
                            :key="index"
                          >
                            {{ index > 0 ? "," : "" }}
                            {{ reference.reference_text }}
                          </span>
                        </template>
                        <template v-else>
                          <template v-if="Array.isArray(item.reference_text)">
                            <span
                              v-for="(reference, index) of item.reference_text"
                              :key="index"
                            >
                              {{ index > 0 ? "," : "" }}
                              {{ reference.reference_text }}
                            </span>
                          </template>
                          <template
                            v-else-if="
                              Array.isArray(preformatText(item.reference_text))
                            "
                          >
                            <span
                              class="d-block"
                              v-for="(reference, index) of preformatText(
                                item.reference_text
                              )"
                              :key="index"
                            >
                              {{ reference }}
                            </span>
                          </template>
                          <template v-else>
                            {{ item.reference_text }}
                          </template>
                        </template>
                      </td>
                      <td class="text-right text-no-wrap">
                        {{ item.item_unit == null ? "-" : item.item_unit }}
                      </td>
                      <td class="text-left text-no-wrap">
                        {{ item.status }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </card>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import Card from "@/components/v2/card/index.vue";

export default {
  components: {
    Card,
  },
  props: {
    result: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    listLabResult: null,
  }),
  created() {
    let list = [];
    let groupedByCategory = this.result.data.detail_diagnostic.reduce(
      (acc, curr) => {
        if (!acc[curr[`sub_category_name_${this.$i18n.locale}`]]) {
          acc[curr[`sub_category_name_${this.$i18n.locale}`]] = [];
        }
        acc[curr[`sub_category_name_${this.$i18n.locale}`]].push(curr);
        return acc;
      },
      {}
    );
    Object.keys(groupedByCategory).forEach(category => {
      list = [
        ...list,
        {
          type: "category",
          category_id: groupedByCategory[category][0].sub_category_name_id,
          category_en: groupedByCategory[category][0].sub_category_name_en,
        },
      ];
      let groupedByProductName = groupedByCategory[category].reduce(
        (acc, curr) => {
          if (!acc[curr[`product_name_${this.$i18n.locale}`]]) {
            acc[curr[`product_name_${this.$i18n.locale}`]] = [];
          }
          acc[curr[`product_name_${this.$i18n.locale}`]].push(curr);
          return acc;
        },
        {}
      );
      Object.keys(groupedByProductName).forEach(product => {
        list = [
          ...list,
          {
            type: "product",
            product: product,
          },
        ];
        list = [...list, ...groupedByProductName[product]];
      });
    });
    this.listLabResult = list;
  },
  methods: {
    checkIsJsonString(data) {
      try {
        JSON.parse(data);
        return true;
      } catch (error) {
        return false;
      }
    },
    preformatText(text) {
      if (text.includes("\n")) {
        return text.split("\n");
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}
.v-data-table table tr td,
.v-data-table table tr th,
table tr td,
table tr th {
  font-size: 12px !important;
}
.normal-label {
  text-transform: capitalize;
  color: $green;
}
.abnormal-label {
  color: $red_2;
  text-transform: capitalize;
}

// mobile
.mobile {
  display: none;
}
.mobile .table-inside tr td:first-child {
  width: 20%;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 200px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}
</style>
