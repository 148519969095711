<template>
  <v-card class="card">
    <v-card-text class="pa-3">
      <div class="d-flex justify-lg-space-between">
        <div class="d-flex align-center">
          <p
            class="desc text-capitalize"
            :class="{
              approved: data.status.toLowerCase() == 'approved',
              expired: data.status.toLowerCase() == 'expired',
            }"
          >
            {{ data.status.toLowerCase() }}
          </p>
        </div>
        <div class="d-flex align-center">
          <action-order
            :title="data.approved ? 'Stop Sharing' : 'Cancel Sharing'"
            icon="mdi-block-helper"
            class="pl-2 d-block"
            color="red"
            @click="unshare()"
            v-if="data.status.toLowerCase() != 'expired'"
          ></action-order>
        </div>
      </div>
      <h6>{{ data.recipient }}</h6>
      <p>
        Share from {{ formatDate(data.start_date) }} until
        {{ formatDate(data.end_date) }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import ActionOrder from "@/components/v3/action-order/index.vue";
import moment from "moment";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";

export default {
  components: {
    ActionOrder,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY");
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    async unshare() {
      try {
        const dataReady = {
          id: this.data.id,
          action: this.data.approved ? "unshare" : "cancel",
        };

        const res = await API.post(
          `${process.env.VUE_APP_API_TRUST}registration/update-sharing`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );

        if (res.statusCode === 200) {
          this.$emit("updateSharing", res.message);
        } else if (res.statusCode == 403) {
          Object.keys(res.errors).forEach(element => {
            this.toast(
              "error",
              typeof res.errors[element] == "object"
                ? res.errors[element][0]
                : res.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: res.message,
          });
        }
      } catch (error) {
        console.log("Error 403! (stop share)");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.card {
  font-size: 14px;
  h6 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
    margin: 0;
    line-height: 16px;
  }
  .desc {
    color: $yellow;
    font-weight: 500;
  }
  .approved {
    color: $main_2;
  }
  .expired {
    color: $red_2;
  }
}
</style>
