<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="465px"
    content-class="modal-schedule"
    @click:outside="onClickOutside"
  >
  <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="history"
        small
        color="#FFF"
        text
        class="btn-schedule-act"
        :class="classStyle"
        v-bind="attrs"
        v-on="on"
        v-model="dateFormatted"
        @click:clear="date = null"
      >
        <IconDoctor 
          color="white" 
        />
        <span class="tooltiptext" v-html="$t('page.profile.tooltip_schedule_consultant')"></span>
    </v-btn>
      
      <v-btn
        v-else
        small
        color="#FFF"
        text
        class="btn-schedule-act"
        :class="classStyle"
        v-bind="attrs"
        v-on="on"
        v-model="dateFormatted"
        @click:clear="date = null"
      >
          <!-- <template v-if="history">
            <IconDoctor color="#209fb4" style="margin: 0 5px 0 -5px;"/>   
            <span class="tooltiptext" v-html="$t('page.profile.tooltip_schedule_consultant')"></span>
          </template> -->
          <!-- <template v-else> -->
            {{ $t("page.profile.schedule_consultant") }}
          <!-- </template> -->
      </v-btn>
  </template>
    <div>
      <p class="text-head mt-4">{{ $t("page.profile.select_date") }}</p>
    </div>
    <v-date-picker
      width="465px"
      :locale="$i18n.locale"
      v-model="date"
      no-title
      @change="updDate()"
      @input="menu = false"
      :min="
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      "
      class="dialog-schedule"
    >
      <!-- <v-spacer></v-spacer> -->
    </v-date-picker>
    <div>
      <p class="text-doctor">{{ $t("page.profile.select_doctor") }}</p>
    </div>

    <div>
      <v-autocomplete
        :items="list_doctor"
        item-text="full_name"
        item-value="full_name"
        v-model="select_doctor"
        class="padding-doctor"
      ></v-autocomplete>
    </div>

    <div>
      <p class="text-head">{{ $t("page.profile.select_time") }}</p>
    </div>

    <div class="time-picker">
      <v-item-group active-class="item-time-active">
        <span v-for="(time, index) of testTime" :key="index">
          <v-item v-slot="{ toggle }">
            <div class="item-time" @click="toggle">
              {{ formatTime(time.start_at) }} - {{ formatTime(time.end_at) }}
            </div>
          </v-item>
        </span>
      </v-item-group>
    </div>

    <div class="footer-schedule">
      <v-btn text @click="$refs.dialog.save(date)" class="btn-schedule">
        {{ $t("page.profile.book_consultant") }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import moment from "moment";
import API from "@/service/api_service.js";
import IconDoctor from "@/components/elements/IconDoctor.vue";
//import Auth from "@/service/auth_service";
export default {
  components: { IconDoctor },
  data: () => ({
    date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    menu: false,
    dialog: false,
    modal: false,
    testTime: [
      {
        start_at: "2022-11-30 06:00:00",
        end_at: "2022-11-30 07:00:00",
        available: 1,
      },
      {
        start_at: "2022-11-30 07:00:00",
        end_at: "2022-11-30 08:00:00",
        available: 1,
      },
      {
        start_at: "2022-11-30 08:00:00",
        end_at: "2022-11-30 09:00:00",
        available: 1,
      },
      {
        start_at: "2022-11-30 09:00:00",
        end_at: "2022-11-30 10:00:00",
        available: 1,
      },
      {
        start_at: "2022-11-30 10:00:00",
        end_at: "2022-11-30 11:00:00",
        available: 1,
      },
      {
        start_at: "2022-11-30 11:00:00",
        end_at: "2022-11-30 12:00:00",
        available: 1,
      },
      {
        start_at: "2022-11-30 12:00:00",
        end_at: "2022-11-30 13:00:00",
        available: 1,
      },
      {
        start_at: "2022-11-30 13:00:00",
        end_at: "2022-11-30 14:00:00",
        available: 1,
      },
    ],
    active: false,
    list_doctor: "",
    select_doctor: "",
  }),
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    valueStart: {
      type: String,
      default: "",
    },
    classStyle: { type: [String, Number], default: "" },
    history: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.date);
    },
  },
  created() {
    ///api/v1.3/doctor/list
    //this.getListDoctor();
  },
  methods: {
    updDate() {
      this.$store.state.date = moment(this.dateFormatted)
        .format("YYYY-MM-DD")
        .toUpperCase();
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    changeTime(date) {
      if (date.available > 0) {
        this.$emit("changeTime", date);
        this.$emit("toggleModal");
      }
    },
    formatTime(date) {
      return date == null
        ? moment().locale(this.$i18n.locale).format("HH:mm")
        : moment(date).locale(this.$i18n.locale).format("HH:mm");
    },
    onClickOutside() {
      this.modal = false;
    },
    async getListDoctor() {
      try {
        await API.get(
          `${process.env.VUE_APP_API_TRUST}doctor/list`,
          {}
        ).then((res) => {
          this.list_doctor = res.results;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";
.modal-schedule {
  background: white !important;
  .time-picker {
    height: auto;
    padding: 8px 0 10px 10px;
    overflow: auto;
    font-size: 12px;
    .item-time {
      display: inline-block;
      padding: 8px 13px;
      border-radius: 6px;
      margin: 8px;
      cursor: pointer;
      background-color: #eefafc;
      color: #00a4b3;
      &:hover {
        background: #00a4b3;
        color: white;
      }
      &.active {
        background: #00a4b3;
        color: white;
      }
      &.dissabled {
        cursor: default;
        background: rgba(128, 128, 128, 0.412);
      }
    }
    .item-time-active {
      background: #00a4b3;
      color: white;
    }
  }
  .footer-schedule {
    text-align: end;
    .btn-schedule {
      margin: 0 18px 20px;
      border: 1px solid;
      background-color: #00a4b3;
      color: white !important;
      font-size: 12px;
      padding: 6px 14px;
      height: 35px;
    }
  }
  .text-head {
    color: #213b8f;
    font-size: 16px;
    font-weight: 600;
    margin: 0 20px;
    border-bottom: 1.5px solid;
    border-color: #213b8f;
  }
  .dialog-schedule {
    .v-date-picker-table {
      height: 220px;
    }
  }
}
.btn-schedule-act {
  background: #209fb4 ;
  // &:hover {
  //   background: #209fb4;
  //   color: white;
  // }
}

.btn-schedule-act.theme--light.v-btn:hover::before {
  opacity: 0.08;
}
.btn-schedule-act.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
.text-doctor {
  color: #213b8f;
  font-size: 16px;
  font-weight: 600;
  margin: 0 20px;
  margin-bottom: 0 !important;
}
.padding-doctor {
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-top: -5px !important;
  padding-top: 0 !important;
}
.padding-doctor.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #213b8f !important;
  border: 0.5px solid;
}
.padding-doctor .v-icon.v-icon {
  color: #213b8f !important;
}

.btn-style-medical-record {
  height: 30px !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    display: none !important;
    border:1px solid;
    border-color: #209fb4;
    color: #209fb4 !important;
    background: #fff;
}
.btn-style-your-history {
  display: none !important;
  box-shadow: unset !important;
  border: none !important;
  border-color: none;
  background: none;
  margin: -8px -10px 8px -10px;
}

.btn-schedule-act {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.btn-schedule-act .tooltiptext {
  visibility: hidden;
  background-color: #00a4b3;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  //top: 30px;
  right: 35px;
  //opacity: 75%;
  font-size: 10px;
}

.btn-schedule-act:hover .tooltiptext {
  visibility: visible;
}
</style>
