<template>
  <div class="card--order" v-if="data != null">
    <v-row>
      <v-col class="d-none d-lg-block">
        <img
          :src="
            data.plant_image_url != null && data.plant_image_url.length != 0
              ? `${url_his}${data.plant_image_url[0]}`
              : require('@/assets/branch_image3.jpg')
          "
          height="160"
          class="img-branch"
        />
      </v-col>
      <v-col cols="12" lg="9">
        <div class="pa-3 pa-md-5 py-lg-5 pr-lg-5 pl-lg-0">
          <v-row dense>
            <v-col cols="12" class="bottom-none top-mobile-none">
              <div class="show-btn-nav pb-3">
                <div class="order-number">
                  {{
                    data.from_homecare
                      ? data.registration_group
                      : data.order_number
                  }}
                </div>
                <span
                  :class="{
                    'status-order--green':
                      data.status_name == `SELESAI` ||
                      data.status_name == `DIDAFTARKAN`,
                    'status-order--yellow': data.status_name == `DALAM PROSES`,
                    'status-order--red':
                      data.status_name != `SELESAI` &&
                      data.status_name != `DIDAFTARKAN` &&
                      data.status_name != `DALAM PROSES`,
                  }"
                >
                  {{
                    $i18n.locale == "id"
                      ? data.status_name
                      : data[`status_name_${$i18n.locale}`]
                  }}
                </span>
                <!-- start use for subscribe-->
                <p class="mb-0 text-capitalize" v-if="data.used_subscription">
                  {{ $t("page.profile.use_subscription") }}
                </p>
                <!-- end use for subscribe-->
                <!-- start for corporate-->
                <p
                  class="mb-0 text-capitalize"
                  v-if="
                    data.from_officecare ||
                    data.from_booking_office ||
                    data.from_booking_company
                  "
                >
                  {{ $t("page.profile.test_corporate") }}
                </p>
                <!-- end for corporate-->
              </div>
              <v-row dense>
                <v-col cols="12" md="4"
                  ><div class="sub-title">
                    {{ $t("page.profile.your_appointment_on") }}
                  </div>
                  <div>
                    {{
                      `${formatDate(
                        data.complete
                          ? data.registration_date
                          : data.booking_date
                      )}${
                        data.from_homecare
                          ? ` ${$t("page.profile.at")} ${
                              data.estimated_service_at
                            }`
                          : ""
                      }`
                    }}
                  </div>
                </v-col>
                <!-- start homecare location -->
                <v-col cols="12" md="8" v-if="data.from_homecare">
                  <div class="sub-title">
                    {{ $t("location") }}
                  </div>
                  <div>
                    {{ `${data.sample_pickup_location.toUpperCase()}` }}
                  </div>
                </v-col>
                <!-- end homecare location -->

                <v-col cols="12" md="8" v-if="!data.from_homecare"
                  ><div class="sub-title">
                    {{ $t("page.profile.lab") }}
                  </div>
                  <div>
                    {{ data.plant }}
                  </div>
                </v-col>
                <!-- lab -->
                <!-- start package hide name patient -->
                <v-col
                  cols="12"
                  md="4"
                  v-if="
                    data.payor != null &&
                    data.payor == data.patient_id &&
                    !data.from_homecare &&
                    data.used_subscription
                  "
                  ><div class="sub-title">
                    {{ $t("page.profile.appoinment_for") }}
                  </div>
                  <span>{{ data.patient_name }}</span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  v-if="
                    data.payor != null &&
                    data.payor != data.patient_id &&
                    !data.from_homecare &&
                    data.used_subscription
                  "
                  ><div class="sub-title">
                    {{ $t("page.profile.payor") }}
                  </div>
                  <span>{{ data.payor_name }}</span>
                </v-col>
                <!-- end package hide name patient -->
                <v-col cols="12" :md="data.used_subscription ? '8' : '12'">
                  <div class="sub-title" v-if="!data.used_subscription">
                    {{ $t("page.profile.type_of_appointment") }}
                  </div>
                  <div class="sub-title" v-else>
                    {{ $t("page.profile.subscription_package") }}
                  </div>
                  <div>
                    <!-- start test biasa -->
                    <template
                      v-if="
                        data.type != 'BUY PACKAGE' && data.from_homecare != true
                      "
                    >
                      <span
                        v-for="(product, productIndex) of data.product_order"
                        :key="productIndex"
                        class="title-test"
                        >{{ product
                        }}{{
                          data.product_order.length - 1 == productIndex
                            ? "."
                            : ", "
                        }}</span
                      >
                    </template>
                    <!-- end test biasa -->
                    <!-- start package -->
                    <template
                      v-else-if="
                        data.type == 'BUY PACKAGE' && data.from_homecare != true
                      "
                    >
                      <span class="title-test">{{ data.package }}</span>
                    </template>
                    <!-- end package -->
                    <!-- start homecare -->
                    <template v-else>
                      <span>{{ $t("page.profile.home_care") }}</span>
                    </template>
                    <!-- end homecare -->
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <div class="action-box mt-3 mt-md-5">
        <div class="inner-display d-flex flex-column">
          <!-- start test or package -->
          <action-order
            v-if="lastOrder"
            :title="
              testDate == 0
                ? $t('today')
                : `${$t('due_in')} ${testDate} ${$t(
                    testDate > 1 ? 'days' : 'day'
                  )}`
            "
            icon="mdi-comment-alert-outline"
            class="py-1 d-block"
          ></action-order>
          <action-order
            v-if="
              (data.rating == null || data.rating == '') &&
              data.complete &&
              !share
            "
            :title="$t('give_rating')"
            icon="mdi-star-outline"
            class="py-1 d-block"
            :class="{ 'order-1': data.complete }"
            @click="giveRating()"
          ></action-order>
          <action-order
            v-if="data.status == 'paid' && data.registration_number != null"
            :title="$t('direction')"
            icon="mdi-map-marker"
            class="py-1 d-block"
            :class="{ 'order-4': data.complete }"
            @click="openMap()"
          ></action-order>
          <action-order
            v-if="
              data.status == 'paid' &&
              data.registration_number != null &&
              !data.complete
            "
            :title="$t('page.profile.booking_code')"
            icon="mdi-qrcode"
            class="py-1 d-block"
            @click="showQr()"
          ></action-order>
          <action-order
            v-if="data.complete == true && !data.from_homecare"
            :title="$t('view_result')"
            icon="mdi-eye"
            class="py-1 d-block"
            :class="{ 'order-2': data.complete }"
            @click="openDialogOrder()"
          ></action-order>
          <action-order
            v-if="
              data.used_subscription == false &&
              !data.from_homecare &&
              data.payor != data.patient_id
            "
            :title="$t('invoice')"
            icon="mdi-file-document"
            class="py-1 d-block"
            :class="{ 'order-3': data.complete }"
            @click="openDialogInvoice()"
          ></action-order>
          <action-order
            v-if="
              data.complete != true &&
              data.reschedule_allowed != 0 &&
              !lastOrder &&
              checkReschedule
            "
            :title="$t('page.profile.reschedule')"
            icon="mdi-timetable"
            class="py-1 d-block"
            @click="showRescheduleTest()"
          ></action-order>
          <!-- end test or package -->

          <!-- start homecare -->
          <action-order
            v-if="data.from_homecare == true && checkItem != data.order_number"
            :title="$t('page.profile.view_participant')"
            icon="mdi-account-multiple"
            class="py-1 d-block"
            :class="{ 'order-2': data.complete }"
            @click="showHomeCare()"
          ></action-order>
          <action-order
            v-if="data.from_homecare == true && checkItem == data.order_number"
            :title="$t('close')"
            icon="mdi-account-multiple-minus"
            class="py-1 d-block"
            :class="{ 'order-2': data.complete }"
            @click="closeHomeCare()"
          ></action-order>
          <!-- end homecare -->
          <!-- start order share -->
          <action-order
            v-if="
              data.complete &&
              !(
                data.from_officecare ||
                data.from_booking_office ||
                data.from_booking_company
              )
            "
            :title="data.shared ? $t('view_share_list') : $t('share')"
            icon="mdi-share-variant"
            class="py-1 d-block"
            @click="dialog_share = true"
            :class="{ 'order-5': data.complete }"
            :flag="data.shared"
          ></action-order>
          <!-- end order share -->
        </div>
      </div>
    </v-row>
    <!-- start homecare -->
    <v-row v-if="data.from_homecare == true && checkItem == data.order_number">
      <v-col cols="12" class="pt-0">
        <div class="table-responsive">
          <v-data-table
            :headers="headerTable"
            :items="orderHomecareDetail"
            hide-default-footer
            :items-per-page="-1"
            :mobile-breakpoint="0"
            class="elevation-1 table-homecare-profile"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td valign="top" class="pt-2">
                  {{ item.order_number }}
                </td>
                <td valign="top" class="pt-2">
                  {{ item.patient_id_number }}
                </td>
                <td valign="top" class="pt-2">
                  {{ item.patient }}
                </td>
                <td valign="top" class="pt-2">
                  {{ item.booking_code }}
                </td>
                <td valign="top" class="pt-2">
                  <span
                    v-for="(product, productIndex) of item.product_order"
                    :key="productIndex"
                  >
                    {{ product }}
                    {{
                      item.product_order.length - 1 == productIndex
                        ? "."
                        : " | "
                    }}
                  </span>
                </td>
                <td>
                  <template
                    v-if="
                      item.status == `paid` && item.used_subscription == false
                    "
                  >
                    <b
                      @click="
                        openDialogInvoice(
                          item.from_homecare,
                          item.order_number,
                          item.used_subscription
                        )
                      "
                      class="cursor-pointer"
                      >{{ $t("view_invoice") }}</b
                    >
                    |
                  </template>
                  <b
                    @click="showQrHomecare()"
                    v-if="data.complete != true"
                    class="cursor-pointer"
                    >{{ $t("view_qr") }}</b
                  >
                  <b
                    @click="openDialogOrderHomecare(item)"
                    v-if="data.complete == true"
                    class="cursor-pointer"
                    >{{ $t("view_result") }}</b
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <!-- end homecare -->

    <!-- start popup invoice -->
    <DialogInvoice
      :item="dataDialogInvoice"
      :tampilkan="dialog_invoice"
      :tableFilter="tableInvoice"
      @toggleDialog="is_show => toggleDialogInvoice(is_show)"
      v-if="dialog_invoice"
    />
    <DialogInvoiceHomecare
      :item="dataDialogInvoiceHomecare"
      :tampilkan="dialog_invoice_homecare"
      @toggleDialog="is_show => toggleDialogInvoice(is_show)"
      v-if="dialog_invoice_homecare"
    />
    <!-- end popup invoice -->

    <!-- start qr popup -->
    <!-- qrcode -->
    <v-dialog v-model="dialogQR" width="300" content-class="scrollQr">
      <card-qr
        :headQR="headQR"
        :backColor="backColor"
        :foreColor="foreColor"
        :qrCodeSet="qrCodeSet"
        :sizes="sizes"
        :urlTemp="urlTemp"
        :urlEnkripHc="urlEnkripHc"
        @closePopup="dialogQR = false"
      />
    </v-dialog>
    <!-- end qr popup -->

    <!-- start reschedule test -->
    <v-dialog
      v-model="dialog_reschedule_test"
      persistent
      max-width="500px"
      @click:outside="closeReschedule"
    >
      <dialog-reschedule-test
        :data="dataTmpReschedule"
        @closeReschedule="closeReschedule()"
        @updateData="updateOrderList()"
      />
    </v-dialog>
    <!-- end reschedule test -->

    <!-- start reschedule homecare -->
    <v-dialog
      v-model="dialog_reschedule_homecare"
      v-if="dialog_reschedule_homecare"
      max-width="450px"
      content-class="modal-schedule"
      @click:outside="closeReschedule"
    >
      <!-- <date-home-care
        :value="data.booking_date"
        :plant="data.plant_id"
        :plantHoliday="findPlant(data.plant_id).upcoming_holidays"
        reschedule
        @toggleModal="dialog_reschedule_homecare = !dialog_reschedule_homecare"
        @changeTime="time => changeTime(time)"
      >
      </date-home-care> -->
      <dialog-reschedule-homecare
        :data="dataTmpReschedule"
        @closeReschedule="closeReschedule()"
        @updateData="updateOrderList()"
      >
      </dialog-reschedule-homecare>
    </v-dialog>
    <!-- end reschedule homecare -->

    <!-- start dialog result -->
    <DialogResult
      v-if="dialog_order"
      :item="dataDialogOrder"
      :tampilkan="dialog_order"
      @toggleDialog="is_show => (dialog_order = is_show)"
    />
    <!-- end dialog result -->

    <!-- start dialog rating -->
    <v-dialog v-model="dialog_rating" max-width="500px">
      <dialog-rating
        :data="data"
        @closeEdit="dialog_rating = false"
        @updateOrderList="updateOrderList"
      ></dialog-rating>
    </v-dialog>
    <!-- end dialog rating -->

    <!-- start dialog share company or family -->
    <v-dialog v-model="dialog_share" max-width="400px">
      <dialog-share-order
        :data="data"
        @closeEdit="dialog_share = false"
        @updateOrderList="updateOrderList"
      >
      </dialog-share-order>
    </v-dialog>
    <!-- end dialog share company or family -->
  </div>
</template>

<script>
import ActionOrder from "@/components/v3/action-order/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import DialogInvoice from "@/components/v2/dialog/dialogInvoice.vue";
import DialogInvoiceHomecare from "@/components/v2/dialog/dialogInvoiceHomecare.vue";
import CardQr from "@/components/v3/cards/card-qr.vue";
import DialogRescheduleTest from "@/components/v2/dialog/dialogRescheduleTest.vue";
import DialogRescheduleHomecare from "@/components/v2/dialog/dialogRescheduleHomecare.vue";
// import DateHomeCare from "@/components/v3/date/dateHomeCare.vue";
import DialogResult from "@/components/v3/results/index.vue";
import DialogRating from "@/components/v2/dialog/dialogRating.vue";
import DialogShareOrder from "@/components/v2/dialog/dialogShareOrder.vue";
import moment from "moment-timezone";

export default {
  components: {
    ActionOrder,
    DialogInvoice,
    DialogInvoiceHomecare,
    CardQr,
    DialogRescheduleTest,
    DialogResult,
    DialogRating,
    DialogShareOrder,
    DialogRescheduleHomecare,
    // DateHomeCare,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    lastOrder: {
      type: Boolean,
      default: false,
    },
    share: {
      type: Boolean,
      default: false,
    },
    plant: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    url_his: "",
    urlTemp: "",

    // start sharing
    dialog_share: false,
    // end sharing

    // start give rating
    dialog_rating: false,
    // end give rating

    // start qr popup
    headQR: {
      plant: "",
      order_number: "",
      booking_date: "",
      estimated_service_at: "",
      product_order: {},
      patient: "",
      barcode: "",
    },
    backColor: "#000000",
    foreColor: "#FFFFFF",
    qrCodeSet: "",
    sizes: 150,
    urlEnkripHc: "",
    // end qr popup

    // start invoice
    dataDialogInvoiceHomecare: {
      order_number: null,
    },
    dataDialogInvoice: {
      order_number: null,
      subscription: false,
      list: [],
      data: {
        metadata: {},
      },
    },
    groupItemCategory: [],
    tableInvoice: [],
    dialog_invoice: false,
    dialog_invoice_homecare: false,
    // end invoice

    // start result
    dataDialogOrder: {},
    dialog_order: false,
    // end result

    // start homecare
    dateBook: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    checkItem: "",
    isActiveHome: false,
    orderHomecareDetail: [],
    headerTable: [],
    patientId: "",
    dialogQR: false,
    // end homecare

    // start reschedule
    dialog_reschedule_test: false,
    dialog_reschedule_homecare: false,
    dialog_reschedule_subscription: false,
    dataTmpReschedule: {},
    // end reschedule
  }),
  created() {
    this.url_his = process.env.VUE_APP_URL_HIS;
    this.urlTemp = process.env.VUE_APP_BASE_URL;
    this.patientId = this.dataProfile.person.patient.id;
    this.headerTable = [
      {
        text: this.$t("page.profile.order_no"),
        value: "order_number",
        sortable: false,
        width: "50px",
      },
      {
        text: this.$t("page.profile.patient_id"),
        value: "patient_id_number",
        sortable: false,
        width: "50px",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient",
        sortable: false,
        width: "150px",
      },
      {
        text: this.$t("page.profile.booking_code"),
        value: "booking_code",
        sortable: false,
        width: "140px",
      },
      {
        text: this.$t("test"),
        value: "product_order",
        sortable: false,
        width: "350px",
      },
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        width: "160px",
      },
    ];
  },
  computed: {
    dataProfile() {
      return this.$store.state.profileData;
    },
    testDate() {
      return moment(this.data.booking_date).diff(
        moment().add(-1, "days"),
        "days"
      );
    },
    checkReschedule() {
      let currentTime = moment();
      if (currentTime.isBefore(this.data.booking_date)) {
        return true;
      }
      // check timezone time
      const timezone = moment.tz.guess();
      if (this.findPlant(this.data.plant_id).timezone_area == timezone) {
        currentTime = moment();
      } else {
        const now = moment().format("HH:mm:ss");
        currentTime = moment(
          this.convertTime(
            now,
            moment.tz.guess(),
            this.findPlant(this.data.plant_id).timezone_area
          ),
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      let startTime = "";
      let endTime = "";
      const { opening_hours } = this.findPlant(this.data.plant_id);
      const indexByDayName = opening_hours.findIndex(
        item => item.day == currentTime.locale("ID").format("dddd")
      );
      if (indexByDayName > -1) {
        const close = opening_hours[indexByDayName].hours
          .split("-")[1]
          .replace(":00", "");
        startTime = moment()
          .hour(0) // mulai jam 00:00
          .minute(0)
          .second(0);
        endTime = moment().hour(close).minute(0).second(0);
      }
      // Check if the current time is between the start and end time
      const isWithinTimeRange = currentTime.isBetween(startTime, endTime);
      if (isWithinTimeRange && currentTime.isBefore(this.data.booking_date)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // start toast
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    // end toast

    // start convert time
    convertTime(timeString, sourceTimeZone, targetTimeZone) {
      // Parse the original time string in the source timezone
      const originalTime = moment.tz(timeString, "HH:mm:ss", sourceTimeZone);

      // Convert the time to the target timezone
      const convertedTime = originalTime.clone().tz(targetTimeZone);

      // Format the converted time as "HH:mm:ss"
      const formattedTime = convertedTime.format("YYYY-MM-DD HH:mm:ss");

      return formattedTime;
    },
    // end convert time

    // start function reschedule check timezone
    findPlant(id) {
      const indexPlant = this.plant.findIndex(res => res.id == id);
      if (indexPlant > -1) {
        return this.plant[indexPlant];
      }
      return null;
    },
    // end function reschedule check timezone

    // start rating
    giveRating() {
      this.dialog_rating = true;
    },
    // end rating
    openMap() {
      window.open(
        `https://www.google.com/maps/?q=${this.data.plant_longlat}`,
        "_blank"
      );
    },
    async updateOrderList() {
      this.$emit("updateOrderList");
    },
    formatDate(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    // start method invoice
    async openDialogInvoice() {
      if (this.data.from_homecare) {
        this.dataDialogInvoiceHomecare.order_number = this.data.order_number;
        this.dataDialogInvoice.subscription = this.data.used_subscription;
      } else {
        this.dataDialogInvoice.order_number = this.data.order_number;
        this.dataDialogInvoice.subscription = this.data.used_subscription;
      }
      this.getOrderDetailInvoice(
        this.data.from_homecare,
        this.data.used_subscription
      );
    },
    async getOrderDetailInvoice(homecare, subs) {
      try {
        if (!homecare) {
          let order_number = this.dataDialogInvoice.order_number;
          const res = await API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
              order_number +
              ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          this.dataDialogInvoice.data = res.results;
          this.dataDialogInvoice.list = res.results.data;

          //cari harga total karena diskon belum kehitung
          let grand_total =
            parseInt(this.dataDialogInvoice.data.metadata.amount_total) -
            parseInt(this.dataDialogInvoice.data.metadata.total_discount);
          this.dataDialogInvoice.data.metadata.grand_total = grand_total;

          let groupCategory = this.dataDialogInvoice.data.data.reduce(
            (r, a) => {
              r[a.sub_category] = [...(r[a.sub_category] || []), a];
              return r;
            },
            {}
          );

          this.groupItemCategory = Object.entries(groupCategory).map(arr => ({
            nameCategory: arr[0],
            dataCategory: arr[1],
          }));

          let filterItem = [];
          this.groupItemCategory.forEach(element => {
            filterItem = [
              ...filterItem,
              { text: element.nameCategory, type: "category" },
            ];
          });

          if (subs != true) {
            this.tableInvoice = filterItem;
          } else {
            this.tableInvoice = this.dataDialogInvoice.list;
          }
          this.dialog_invoice = true;
        } else {
          let order_number = this.dataDialogInvoiceHomecare.order_number;
          const resp = await API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail-homecare?order_number=` +
              order_number +
              ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          if (resp.statusCode == 200) {
            this.dataDialogInvoiceHomecare = {};
            const { results } = resp;
            let dataInvoice = {
              order_number: order_number,
              document_info: {
                invoice_number: results.invoice_number,
                invoice_date: results.invoice_date,
                plant_name: results.plant_name,
                medical_record_number: results.medical_record_number,
                practitioner_name: results.practitioner_name,
                payment_method: results.payment_method_name,
                gender: results[`gender_name_${this.$i18n.locale}`],
                currency: results.currency,
                total_payment: results.total_payment,
              },
              customer_info: {
                name: results.name,
                address: results.address,
                email: results.email_contact,
                dob: results.person_dob,
                age: results.age,
                district_city: `${results.subdistrict_name}, ${results.district_name}, ${results.city_name}`,
                postal_province: `${results.province_name} ${results.postal_code}, ${results.country_name}`,
                mobile_phone:
                  results.celluar_contact == null
                    ? "-"
                    : `${results.celluar_contact_code}${results.celluar_contact}`,
                home_contact:
                  results.home_contact == null
                    ? "-"
                    : `${results.home_contact_code}${results.home_contact}`,
              },
              amount_total: 0,
              total_discount: 0,
              grand_total: 0,
              price_discount: 0,
              tables: [],
            };

            results.participant.forEach(item => {
              dataInvoice.tables = [
                ...dataInvoice.tables,
                { type: "name", name: item.patient_name },
              ];
              item.participant_test.forEach(participant => {
                participant.product_data.forEach((category, indexCategory) => {
                  if (indexCategory == 0) {
                    dataInvoice.tables = [
                      ...dataInvoice.tables,
                      {
                        type: "category",
                        category_id: participant.category_name,
                        category_en: participant.category_name_en,
                      },
                    ];
                  }
                  dataInvoice.tables = [
                    ...dataInvoice.tables,
                    {
                      type: "sub_category",
                      sub_category_id: category.product_name,
                      sub_category_en: category.product_name_en,
                    },
                  ];
                  dataInvoice.tables = [
                    ...dataInvoice.tables,
                    {
                      type: "item",
                      product: category,
                    },
                  ];
                  dataInvoice.amount_total =
                    parseFloat(category.product_price) +
                    parseFloat(dataInvoice.amount_total);
                  dataInvoice.total_discount =
                    parseFloat(category.product_discount) +
                    parseFloat(dataInvoice.total_discount);
                  dataInvoice.price_discount =
                    parseFloat(
                      (category.product_discount / 100) * category.product_price
                    ) + parseFloat(dataInvoice.price_discount);
                });
              });
            });
            dataInvoice.grand_total =
              parseFloat(dataInvoice.amount_total) -
              parseFloat(dataInvoice.price_discount);
            this.dataDialogInvoiceHomecare = dataInvoice;
            this.dialog_invoice_homecare = true;
          }
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    toggleDialogInvoice(toggle) {
      this.dialog_invoice = toggle;
      this.dialog_invoice_homecare = toggle;
      // reset data invoice
      if (!toggle) {
        this.dataDialogInvoice = {
          order_number: null,
          subscription: false,
          list: [],
          data: {
            metadata: {},
          },
        };
        this.dataDialogInvoiceHomecare = {
          order_number: null,
        };
      }
    },
    // end method invoice

    // start homecare
    async showHomeCare() {
      try {
        await API.get(
          `${process.env.VUE_APP_API_TRUST}registration/get-order-detail?registration_number=${this.data.parent_registration_number}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          if (res.statusCode === 200) {
            if (this.data.payor == this.patientId) {
              this.orderHomecareDetail = res.results.orders;
            } else {
              this.orderHomecareDetail = res.results.orders.filter(
                dataFilter => {
                  return dataFilter.patient_id == this.patientId;
                }
              );
            }
          }
        });
      } catch (error) {
        console.log("Error 403!");
      }
      this.checkItem = this.data.order_number;
      this.isActiveHome = true;
    },
    showQr() {
      this.dialogQR = true;
      this.qrCodeSet = this.data.from_homecare
        ? this.data.registration_group
        : this.data.booking_code[0];
      if (typeof this.data.plant !== "undefined") {
        this.headQR.plant = this.data.plant.substr(13);
      } else {
        this.headQR.plant = this.data.plant;
      }
      this.headQR.order_number = this.data.order_number;
      this.headQR.booking_date = this.data.booking_date;
      this.headQR.estimated_service_at = this.data.estimated_service_at;
      this.headQR.product_order = this.data.product_order;
      this.headQR.patient = this.data.patient_name;
      this.headQR.barcode = this.qrCodeSet;

      const crypt = require("crypto");
      const key = "2707ac1992b6e77fa20a2594b2cb850f";
      const iv = "cca99e79b377a41d";
      const algorithm = "aes-256-cbc";
      const textEnkrip = JSON.stringify(this.data.uuid);
      var cipher = crypt.createCipheriv(algorithm, key, iv);
      let crypted = cipher.update(textEnkrip, "utf8", "hex");
      crypted += cipher.final("hex");
      this.urlEnkripHc = crypted;
    },
    closeHomeCare() {
      this.isActiveHome = false;
      this.checkItem = "";
    },
    showQrHomecare() {
      this.dialogQR = true;
      this.qrCodeSet = this.data.booking_code[0];
      if (typeof this.data.plant !== "undefined") {
        this.headQR.plant = this.data.plant.substr(13);
      } else {
        this.headQR.plant = this.data.plant;
      }

      this.headQR.order_number = this.data.order_number;
      this.headQR.booking_date = this.data.booking_date;
      this.headQR.estimated_service_at = this.data.estimated_service_at;
      this.headQR.product_order = this.data.product_order;
      this.headQR.patient = this.data.patient;
      this.headQR.barcode = this.qrCodeSet;

      const crypt = require("crypto");
      const key = "2707ac1992b6e77fa20a2594b2cb850f";
      const iv = "cca99e79b377a41d";
      const algorithm = "aes-256-cbc";
      const textEnkrip = JSON.stringify(this.data.uuid);
      var cipher = crypt.createCipheriv(algorithm, key, iv);
      let crypted = cipher.update(textEnkrip, "utf8", "hex");
      crypted += cipher.final("hex");
      this.urlEnkripHc = crypted;
    },
    async changeTime(time) {
      try {
        const dataReady = [
          {
            registration_group: this.data.registration_group,
            address: this.data.sample_pickup_location,
            date: time,
          },
        ];
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}registration/reschedule-homecare`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );
        if (resp.statusCode == 200) {
          this.toast("success", this.$t("success_reschedule"));
          this.closeReschedule();
          this.$emit("updateOrderList");
        } else if (resp.statusCode == 400) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: this.$t("error_reschedule"),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // end homecare

    // start reschedule
    // test
    showRescheduleTest() {
      if (this.data.from_homecare) {
        this.dialog_reschedule_homecare = true;
        this.dataTmpReschedule = this.data;
      } else {
        this.dialog_reschedule_test = true;
        this.dataTmpReschedule = this.data;
      }
    },
    closeReschedule() {
      this.dataTmpReschedule = {};
      this.dialog_reschedule_homecare = false;
      this.dialog_reschedule_test = false;
      this.dialog_reschedule_subscription = false;
    },
    // end reschedule

    // show result
    async openDialogOrder() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${this.data.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          this.dataDialogOrder.results = res.results;
          this.dataDialogOrder.order_number = this.data.order_number;
          this.dataDialogOrder.type_test = this.data.type;
          this.dialog_order = true;
        } else {
          this.$swal({
            icon: "error",
            text: res.message,
          });
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    // end show result

    // show result hc
    async openDialogOrderHomecare(order) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${order.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogOrder.results = res.results;
        this.dataDialogOrder.order_number = this.data.order_number;
        this.dataDialogOrder.type_test = this.data.type;
        this.dialog_order = true;
      } catch (error) {
        console.log("Error 403!");
      }
    },
    // end show result
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.card--order {
  position: relative;
  border-radius: 6px;
  background: #fff;
  margin-top: 20px;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  position: relative;
  color: $gray_5;

  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(1) {
    margin-top: 0;
  }
  .img-branch {
    border-radius: 6px 0 0 6px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .action-box {
    background-color: #00a4b3;
    color: white;
    text-align: center;
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px 0px 0px 5px;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    line-height: 16px;
  }
  .status-order {
    &--green {
      font-size: 12px;
      font-weight: 600;
      text-transform: capitalize;
      color: $green;
    }

    &--red {
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 600;
      color: $red_2;
    }

    &--yellow {
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 600;
      color: $yellow;
    }
  }

  .order-number {
    font-size: 16px;
    font-weight: bold;
    color: $main_1;
  }
  .sub-title {
    color: $dark_blue_3;
    font-weight: 700;
  }
}
</style>
