<template>
  <v-container fluid :class="{ 'mt-6': data.length <= 1 }">
    <v-sparkline
      :fill="fill"
      :gradient="selectedGradient"
      :line-width="width"
      :padding="padding"
      :smooth="radius || false"
      :value="data"
      auto-draw
    ></v-sparkline>

    <v-divider></v-divider>
  </v-container>
</template>

<script>
const gradients = [["#4E944F"]];

export default {
  data: () => ({
    fill: false,
    selectedGradient: gradients[0],
    gradients,
    padding: 8,
    radius: 1,
    value: [],
    width: 2,
  }),
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  // created(){
  //   this.data.sort((a, b) => {
  //     return new Date(a.date) - new Date(b.date);
  //   });
  //  console.log(this.data,'data ki')
  // }
};
</script>
