<template>
  <div>
    <v-card>
      <v-card-text>
        <h6 class="text-h6 mb-2">
          {{ data.shared ? "Shared List" : "Add Shared" }}
        </h6>
        <v-card v-if="!data.shared">
          <v-card-text class="pa-3">
            <v-form ref="formShare" lazy-validation>
              <v-select
                outlined
                :label="$t('field.company_name')"
                :items="listShare"
                item-value="sap_bp_id"
                item-text="name"
                v-model="payload.sap_bp_id"
                :rules="[
                  v =>
                    !!v || `${$t('field.company_name')} ${$t('is_required')}`,
                ]"
                dense
              >
                <template v-slot:item="data">
                  {{ data.item.name }}
                  <span
                    class="ml-3"
                    style="color: #00a4b3"
                    v-if="data.item.has_active_contract"
                    >({{ $t("active") }})</span
                  >
                </template>
              </v-select>
              <v-menu
                v-model="opendatepicker"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    :label="$t('field.share_date')"
                    outlined
                    append-icon="mdi-calendar-range"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[
                      v =>
                        !!v || `${$t('field.share_date')} ${$t('is_required')}`,
                    ]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :locale="$i18n.locale"
                  v-model="payload.share_date"
                  range
                  @change="opendatepicker = true"
                  :min="minDate"
                  color="#00a4b3"
                  header-color="#00a4b3"
                >
                </v-date-picker>
              </v-menu>
              <div class="d-flex justify-end">
                <v-btn text @click="closeEdit" small>
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="#00a4b3" text @click="saveEdit" small>
                  {{ $t("save") }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
        <template v-if="(detailShare && data.shared) || false">
          <card-share-detail
            v-for="(item, i) of detailShare"
            :key="i"
            :data="item"
            :class="{ 'mb-5': i + 1 < detailShare.length }"
            @updateSharing="msg => updateSharing(msg)"
          >
          </card-share-detail>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment";
import CardShareDetail from "@/components/v3/cards/card-share-detail.vue";

export default {
  components: {
    CardShareDetail,
  },
  data: () => ({
    payload: {
      sap_bp_id: "",
      order_number: "",
      share_date: [],
      medical_record_number: "",
    },
    listShare: [],
    opendatepicker: false,
    minDate: "",
    detailShare: null,
  }),
  props: {
    data: { type: Object, default: null },
  },
  computed: {
    dateFormatted() {
      if (this.payload.share_date.length > 1) {
        const sorted = [...this.payload.share_date].sort(
          (a, b) => moment(a) - moment(b)
        );
        return `${this.formatDate(sorted[0])} ${this.$t(
          "to_date"
        )} ${this.formatDate(sorted[1])}`;
      } else if (this.payload.share_date.length == 1) {
        return `${this.formatDate(this.payload.share_date[0])} ${this.$t(
          "to_date"
        )} ${this.formatDate(this.payload.share_date[0])}`;
      }
      return "";
    },
  },
  created() {
    this.getDetailShare();
    this.minDate = moment().format("YYYY-MM-DD");
    this.payload.order_number = this.data.order_number;
    this.getCompany();
  },
  methods: {
    updateSharing(msg) {
      this.toast("success", msg);
      this.$emit("updateOrderList");
    },
    async getDetailShare() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}registration/get-sharing-list?order_number=${this.data.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        const statusOrder = {
          APPROVED: 1,
          "WAITING FOR APPROVAL": 2,
          EXPIRED: 3,
        };

        if (res.statusCode == 200) {
          this.detailShare = res.results.sort((a, b) => {
            const statusA = a.status.toUpperCase();
            const statusB = b.status.toUpperCase();
            return statusOrder[statusA] - statusOrder[statusB];
          });
        }
      } catch (error) {
        console.log("Error 403! (get shared)");
      }
    },
    formatDate(date) {
      return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY");
    },
    closeEdit() {
      this.$emit("closeEdit");
    },
    async getCompany() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}registration/get-sharing-recipients?type=COM`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          this.listShare = res.results;
        }
      } catch (error) {
        console.log("Error 403! (Cant get list company)");
      }
    },
    async saveEdit() {
      if (this.$refs.formShare.validate() == true) {
        try {
          const sorted = [...this.payload.share_date].sort(
            (a, b) => moment(a) - moment(b)
          );
          let form = {
            order_number:
              typeof this.payload.order_number !== "undefined"
                ? this.payload.order_number
                : "",
            sap_bp_id:
              typeof this.payload.sap_bp_id !== "undefined"
                ? this.payload.sap_bp_id
                : "",
            start_date:
              typeof this.payload.share_date !== "undefined" ? sorted[0] : "",
            end_date:
              typeof this.payload.share_date !== "undefined"
                ? sorted.length == 1
                  ? sorted[0]
                  : sorted[1]
                : "",
          };
          // Begin api request
          const res = await API.post(
            `${process.env.VUE_APP_API_TRUST}registration/create-sharing`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [form]
          );

          if (res.statusCode === 200) {
            this.toast("success", res.message);
            this.$emit("updateOrderList");
          } else if (res.statusCode == 403) {
            Object.keys(res.errors).forEach(element => {
              this.toast(
                "error",
                typeof res.errors[element] == "object"
                  ? res.errors[element][0]
                  : res.errors[element]
              );
            });
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
          }
        } catch (error) {
          this.$swal({
            icon: "error",
            text: this.$t("error_try_again_latter"),
          });
        }
        this.closeEdit();
      }
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
  },
};
</script>

<style>
.header-shared {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
