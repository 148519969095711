<template>
  <!-- :class="{ 'd-none': !ready }"  -->
  <v-col class="last-med-record filterMedicalNew">
    <v-row>
      <div class="col col-12 pt-pb-0">
        <div class="v-card v-sheet theme--light">
          <custom-section class="section">
            <v-row dense class="rowPad display-dekstop">
              <div class="d-flex justify-space-between align-center mb-4">
                <span class="ungu s16 bold">
                  {{ $t("select") }} {{ $t("page.profile.medical_record") }}
                </span>
                <div>
                  <v-btn
                    valign="middle"
                    value="thirty_days_ago"
                    style="width: auto;display: none;"
                    @click="showDialogFilterMedicalRecord()"
                  >
                    <span class="font-weight-bold">
                      <v-icon color="#00a4b3"> mdi-filter </v-icon>
                    </span>
                  </v-btn>
                </div>
              </div>
              <!-- <v-divider style="border: 1px solid #00a4b3"></v-divider> -->
            </v-row>

            <div class="display-f display-dekstop">
              <v-data-table
                dense
                :headers="headers"
                :items="medicalResult"
                item-key="code"
                :items-per-page="-1"
                :footer-props="footerProps"
                mobile-breakpoint="0"
                hide-default-footer
                class="table-valign-top-mobile table-medical-info"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td valign="middle" class="td-stretch link" style="width: 150px">
                      <span
                        class="link-detail text-uppercase text-no-wrap crs-chart text-title-mr"
                        @click="showDialogChart(item.code)"
                        v-if="item.current_value != null && !isNaN(item.current_value) && item.current_value != '' "
                      >
                        {{ item[`name_${$i18n.locale}`] != "" ? item[`name_${$i18n.locale}`] : item.code }}
                      </span>  
                      <span
                        class="link-detail text-uppercase text-no-wrap text-title-mr-black"
                        v-else
                      >
                        {{ item[`name_${$i18n.locale}`] != "" ? item[`name_${$i18n.locale}`] : item.code }}
                      </span>  
                    </td>
                    <td valign="middle" class="text-uppercase">{{ item.current_item_unit != null ? item.current_item_unit : "-" }}</td>
                    <td valign="middle" class="text-right">
                      {{
                        item.previous_value != null &&
                        !isNaN(item.previous_value) && 
                        item.previous_value != ''
                          ? parseFloat(item.previous_value).toFixed(2)
                          : $t("no_data")
                      }}
                    </td>
                    <td valign="middle" class="text-right">
                      {{
                        item.current_value != null && !isNaN(item.current_value) && item.current_value != ''
                          ? parseFloat(item.current_value).toFixed(2)
                          : $t("no_data")
                      }}
                    </td>
                    <td valign="middle" class="text-right">
                      {{
                        item.previous_value == null ||
                        item.current_value == null ||
                        isNaN(item.previous_value) ||
                        isNaN(item.current_value) ||
                        item.previous_value == '' ||
                        item.current_value == '' 
                          ? "-"
                          : parseFloat(
                              parseFloat(item.current_value) -
                                parseFloat(
                                  item.previous_value != null
                                    ? item.previous_value
                                    : 0
                                ).toFixed(2)
                            ).toFixed(2)
                      }}
                    </td>
                    <td
                      valign="middle"
                      class="text-uppercase"
                      :class="{
                              'text-danger':
                                item.reference_min != null &&
                                item.reference_max != null
                                  ? parseFloat(item.current_value) >
                                      parseFloat(item.reference_max) ||
                                    parseFloat(item.current_value) <
                                      parseFloat(item.reference_min)
                                  : false,
                              'text-success':
                                item.reference_min != null &&
                                item.reference_max != null
                                  ? parseFloat(item.current_value) >=
                                      parseFloat(item.reference_min) &&
                                    parseFloat(item.current_value) <=
                                      parseFloat(item.reference_max)
                                  : false,
                            }"
                    >
                    {{
                        item.current_value == null || isNaN(item.current_value) || item.current_value == ''
                          ? "-"
                          : item.reference_min != null &&
                            item.reference_max != null
                          ? parseFloat(item.current_value) >= parseFloat(item.reference_min) &&
                          parseFloat(item.reference_max) >= parseFloat(item.current_value)
                            ? $t("good")
                            : $t("warning")
                          : "-"
                      }}
                    </td>
                    <td
                      :class="{'height-line': item.value.length == 1}"
                      valign="middle">
                      <span
                        class="link-detail text-uppercase crs-chart"
                        @click="showDialogChart(item.code)"
                        v-if="item.current_value != null && !isNaN(item.current_value) && item.current_value != '' "
                      >
                        <ChartMedicalRecord :data="getDataChart(item.value)" />
                      </span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <v-dialog
              v-model="dialogChart"
              persistent
              max-width="900px"
              @click:outside="onClickOutside"
              @keydown.esc="onClickOutside"
            >
              <DetailChartMedicalRecord 
                :data="itemKey" 
              />
            </v-dialog>

            <v-dialog
              v-model="dialogFilterList"
              persistent
              max-width="600px"
              @click:outside="closeFilterMedicalRecord()"
              @keydown.esc="closeFilterMedicalRecord()"
            >
              <dialog-filter-medical-record
                :data="dataTmpMedicalRecord"
                @closeReschedule="closeFilterMedicalRecord()"
                @addFilter="code => newFilter(code)"
              />
            </v-dialog>
          </custom-section>
        </div>
      </div>
    </v-row>
  </v-col>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import CustomSection from "@/components/v2/section/index.vue";
import DialogFilterMedicalRecord from "@/components/v3/popup/dialogFilterMedicalRecord.vue";
import ChartMedicalRecord from "@/views/v2/Patient/Chart/ChartMedicalRecord.vue";
import DetailChartMedicalRecord from "@/views/v2/Patient/Tabs/DetailMedicalInfoNew.vue";

export default {
  components: {
    CustomSection,
    DialogFilterMedicalRecord,
    ChartMedicalRecord,
    DetailChartMedicalRecord,
  },
  data: () => ({
    dialogFilterList: false,
    dataTmpMedicalRecord: [],
    dataChart: [],
    medicalResult: [],
    detailMedicalResult: [],
    dialog: false,
    items: [],
    headers: [],
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    perPage: 25,
    dialogChart: false,
    itemKey: "",
  }),
  // watch: {
  // },
  created() {
    this.getDataFilter();
    this.headers = [
      {
        text: this.$t("page.profile.test_name"),
        sortable: false,
        value: "name",
        class: "text-center",
        align: "center",
      },
      {
        text: this.$t("page.profile.unit"),
        value: "unit",
        sortable: false,
        class: "text-center",
        align: "center",
      },
      {
        text: this.$t("page.profile.previous_test"),
        value: "previous",
        sortable: false,
        class: "text-center text-no-wrap",
        bordered: true,
        align: "center",
      },
      {
        text: this.$t("page.profile.current_test"),
        value: "current",
        sortable: false,
        class: "text-center text-no-wrap",
        align: "center",
      },
      {
        text: this.$t("page.profile.diff"),
        value: "diff1",
        sortable: false,
        class: "text-center",
        bordered: true,
        align: "center",
      },
      {
        text: this.$t("page.profile.notes"),
        value: "notes",
        sortable: false,
        class: "text-center",
        align: "center",
      },
      {
        text: this.$t("page.profile.chart"),
        class: "text-center tb-wide0",
        value: "chart",
        sortable: false,
        align: "left",
      },
    ];
  },
  mounted() {
    this.$store.commit("setLoading", true);
    this.$store.commit("setLoading", false);
  },
  methods: {
    async getDataFilter() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}lab-results/get-product-item-list?medical_record_number=${this.$store.state.paramMedicalRecord}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          // sort selected filter
          const sorted = res.results.sort((a, b) => {
            // Compare by isActive property first
            if (a.default !== b.default) {
              return a.default ? -1 : 1;
            }
            // If isActive property is the same, compare by name property
            return a[`name_${this.$i18n.locale}`].localeCompare(
              b[`name_${this.$i18n.locale}`]
            );
          });
          this.dataTmpMedicalRecord = sorted;
          this.getResult();
        } else {
          this.ready = false;
        }
      } catch (error) {
        this.ready = false;
      }
    },
    async getResult() {
      try {
        const filter = this.getFilterActive(this.dataTmpMedicalRecord)
          .join(",")
          .replace(/#/g, "%23");
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}lab-results/get-product-item-result?medical_record_number=${this.$store.state.paramMedicalRecord}&filter_code=${filter}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        
        const _this = this;
        const sort =  res.results.sort(function (a, b) {
          if (a[`name_${_this.$i18n.locale}`] < b[`name_${_this.$i18n.locale}`]) {
            return -1;
          }
          if (a[`name_${_this.$i18n.locale}`] > b[`name_${_this.$i18n.locale}`]) {
            return 1;
          }
           return 0;
        });
        this.medicalResult = sort;
        // const sort = res.results.map(obj => {
        //   obj.value.sort((a, b) => {
        //     const dateA = new Date(a.date);
        //     const dateB = new Date(b.date);
        //     return dateA - dateB;
        //   })
        //   return obj;
        // });
        //this.medicalResult = sort;
        //res.results;
        //medicalInfo
      } catch (error) {
        this.ready = false;
      }
    },
    getFilterActive(dat) {
      return dat.filter(item => item.default == true).map(item => item.code);
    },
    newFilter(item) {
      this.dialogFilterList = false;
      // sort selected filter
      const sorted = item.sort((a, b) => {
        // Compare by isActive property first
        if (a.default !== b.default) {
          return a.default ? -1 : 1;
        }
        // If isActive property is the same, compare by name property
        return a[`name_${this.$i18n.locale}`].localeCompare(
          b[`name_${this.$i18n.locale}`]
        );
      });
      this.dataTmpMedicalRecord = sorted;
      this.getResult();
    },
    showDialogFilterMedicalRecord() {
      this.dialogFilterList = true;
    },
    closeFilterMedicalRecord() {
      this.dataTmpMedicalRecord = []
      this.dialogFilterList = false;
      //this.getDataFilter();
    },
    onClickOutside() {
      this.dialogFilterList = false;
      this.dialogChart = false;
    },
    getDataChart(data) {    
      if (data.length > 0) {
        //  data.sort((a, b) => {
        //   return new Date(a.date) - new Date(b.date);
        // });
        return data.map(item => parseFloat(item.clean_value));
      } else return [];
    },
    showDialogChart(dat) {
      this.dialogChart = true;
      this.itemKey = dat;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.display-dekstop {
  display: block;
}
.display-mobile {
  display: none;
}
.blue-color {
  color: #00a4b3 !important;
}

td {
  a {
    color: black;
    vertical-align: text-top;
  }
  width: 80px;
  text-transform: uppercase;
}
th {
  width: 100px;
  vertical-align: top;
}

.link-detail {
  vertical-align: super;
}

.header-padding {
  padding: 0 16px;
}
.background-ratio-left {
  border-left: 1px solid black;
}
.background-ratio-right {
  border-right: 1px solid black;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  color: black;
  &.text-danger {
    color: red;
  }
  &.text-success {
    color: green;
  }
  &.text-warning {
    color: orange;
  }
}
.link a {
  text-decoration: none !important;
  font-weight: 700;
  color: #00a4b3;
}
.crs-chart{
  cursor: pointer;
}
.text-title-mr{
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #00a4b3;
  font-weight: bold;
}
.text-title-mr-black{
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
}

.text-no-wrap{
  white-space: nowrap !important;
}

.height-line{
  height: 38px !important;
}
</style>
