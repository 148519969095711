<template>
  <div class="my-sub pa-2">
    <!-- <template v-if="listSub != null && listSub.length > 0">
      <h4 class="title-tab mb-1 d-none d-md-block">
        {{ $t("my_subscription") }}
      </h4>
      <v-expansion-panels v-model="panel">
        <v-expansion-panel v-for="(item, i) in listSub" :key="i">
          <v-expansion-panel-header class="ml-1">
            <h5 class="package-title text-capitalize">
              {{ item.name.toLowerCase() }} 
            </h5>
            <template v-slot:actions>
              <v-icon color="#00a6b6" dense> mdi-menu-down </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-row class="mb-3" dense>
                <v-col cols="12" sm="6" md="3" class="ml-1">
                  <p class="label-detail">{{ $t("subscription_name") }}</p>
                  <p class="value-detail text-capitalize">
                    {{ item.name.toLowerCase() }} 
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <p class="label-detail">{{ $t("cost") }}</p>
                  <p class="value-detail">
                    IDR {{ formatPrice(item.amount_total) }} 
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <p class="label-detail">{{ $t("date_purchase") }}</p>
                  <p class="value-detail">
                    {{ formatDate(item.date_purchased) }}
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="ml-1">
                  <p class="label-detail">{{ $t("expired_date") }}</p>
                  <p class="value-detail">{{ formatDate(item.expired_at) }}</p>
                </v-col>
              </v-row>
              <div class="package-detail-box pa-3">
                <h5 class="value-detail">{{ $t("subscription_use") }}</h5>
                <p class="label-detail mb-2">
                  {{ $t("desc_expired") }}
                  {{ formatDate(item.expired_at) }}
                </p>


                <div class="slider-box mb-3">
                  <div class="d-flex justify-space-between mb-2">
                    <h5 class="value-detail">{{ $t("order") }}</h5>
                    <p class="value-detail">
                      {{ item.used }} {{ $t("of") }} {{ item.total }}
                      {{ $t("order_used") }}
                    </p>
                  </div>
                  <div class="slider">
                    <div
                      :style="{
                        width:
                          item.used == 0
                            ? '0%'
                            : `${(item.used / item.total) * 100}%`,
                      }"
                      class="value"
                    ></div>
                  </div>
                </div>
                <p class="value-detail mb-2">{{ $t("subscription_details") }}:</p>
                <v-row
                  style="padding: 6px;"
                >
                  <v-col 
                    cols="6"  
                    v-for="(value, i) of item.products"
                    :key="i"
                    style="padding: 5px;"
                  >
                    <v-card>
                      <v-container>
                        <v-row>
                          <v-col cols="9">
                            <span>
                              {{ capitalize(value[`name_${$i18n.locale}`]) }}
                              ({{ value.total }}x)
                            </span>
                            <br>
                            <span>
                              {{ $t("remaining") }} : {{ value.total }}
                            </span>  
                          </v-col>
                          <v-col
                            cols="3"
                            class="d-flex justify-end mt-2"
                          >
                          <localized-link
                            style="text-decoration:none;"
                            :to="`/patient/use-subscription-test/${item.id}?plant=${item.plant_id}`"
                          >
                            <span
                              class="button-use-sm"
                            >
                              {{ $t("use") }}
                            </span>
                          </localized-link>  
                          </v-col>
                        </v-row>
                        <ul class="value-detail">
                              <li
                                v-for="(value, i) of item.products"
                                :key="i"
                                class="text-capitalize"
                              >
                                {{ value[`name_${$i18n.locale}`].toLowerCase() }}
                                {{ value.total }}x
                              </li>
                            </ul>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row> 
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <v-row v-else>
      <v-col cols="12" sm="3" md="3">
        <img
          :src="require('@/assets/images/banner/doesnt_subscribe.png')"
          alt="Image doesnt have package!"
          class="img-responsive"
        />
      </v-col>
      <v-col cols="12" sm="9" md="6" class="d-flex align-center">
        <div class="d-flex flex-column">
          <h4 class="title-not-sub mb-3">{{ $t("not_have_subsribe") }}</h4>
          <div class="desc-not-sub" v-html="$t('not_have_subsribe_desc')"></div>
        </div>
      </v-col>
    </v-row> -->

    <!-- <template v-if="listDeal != null && listDeal.length > 0">
      <h4 class="title-tab mb-1 d-none d-md-block mt-4">
        {{ $t("my_subscription") }}
      </h4>
      <v-expansion-panels v-model="panelDeal" class="table-stick-subscription">
        <v-expansion-panel v-for="(item, i) in listDeal" :key="i">
          <v-expansion-panel-header class="ml-1">
            <h5 class="package-title text-capitalize">
              {{ item.subscription_no }} 
            </h5>
            <template v-slot:actions>
              <v-icon color="#00a6b6" dense> mdi-menu-down </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
            v-for="(value, i) of item.details" :key="i"
            >
              <v-row class="mb-3" dense>
                <v-col cols="12" sm="6" md="3" class="ml-1">
                  <p class="label-detail">{{ $t("subscription_name") }}</p>
                  <p class="value-detail text-capitalize">
                    {{ value.deal_name.toLowerCase() }} 
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="ml-1">
                  <p class="label-detail">{{ $t("cost") }}</p>
                  <p class="value-detail">
                    IDR {{ formatPrice(item.total_amount) }} 
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <p class="label-detail">{{ $t("date_purchase") }}</p>
                  <p class="value-detail">
                    {{ formatDate(item.subscription_date) }}
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="ml-1">
                  <p class="label-detail">{{ $t("expired_date") }}</p>
                  <p class="value-detail">{{ formatDate(value.expiration_date) }}</p>
                </v-col>
              </v-row>
              <div
                class="package-detail-box pa-3 mb-4"
              >
                <h5 class="value-detail">{{ $t("subscription_use") }}</h5>
                <p class="label-detail mb-2">
                  {{ $t("desc_expired") }}
                  {{ formatDate(value.expiration_date) }}
                </p>

                <div class="slider-box mb-3">
                  <div class="d-flex justify-space-between mb-2">
                    <h5 class="value-detail">{{ $t("deal_use") }}</h5>
                    <p class="value-detail">
                      {{ value.qty_usage }} {{ $t("of") }} {{ value.qty_remaining }}
                      {{ $t("order_used") }}
                    </p>
                  </div>
                  <div class="slider">
                    <div
                      :style="{
                        width:
                          value.qty_usage == 0
                            ? '0%'
                            : `${(value.qty_usage / value.qty_remaining) * 100}%`,
                      }"
                      class="value"
                    ></div>
                  </div>
                </div>
                <p class="value-detail mb-2">{{ $t("subscription_details") }}:</p>
                <v-row style="padding: 6px;">
                  <v-col 
                    cols="6"
                    style="padding: 5px;"
                  >
                    <v-card>
                      <v-container>
                        <v-row>
                          <v-col cols="9">
                            <span>
                              {{ capitalize(value.package_name) }}
                              ({{  value.qty + value.qty_bonus }}x)
                            </span>
                            <br/>
                            <span>
                              {{ $t("remaining") }} : {{ value.qty_remaining }}
                            </span> 
                          </v-col>
                          <v-col
                            cols="3"
                            class="d-flex justify-end mt-2"
                          >
                          <localized-link
                            style="text-decoration:none;"
                            :to="`/patient/use-subscription-packages/${item.id}?plant=${item.plant_id}&packages=${value.package_id}`"
                          >
                            <span
                              class="button-use-sm"
                            >
                              {{ $t("use") }}
                            </span>
                          </localized-link>
                          
                          </v-col>
                        </v-row>

                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>   
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <v-row v-else>
      <v-col cols="12" sm="3" md="3">
        <img
          :src="require('@/assets/images/banner/doesnt_subscribe.png')"
          alt="Image doesnt have package!"
          class="img-responsive"
        />
      </v-col>
      <v-col cols="12" sm="9" md="6" class="d-flex align-center">
        <div class="d-flex flex-column">
          <h4 class="title-not-sub mb-3">{{ $t("not_have_deal") }}</h4>
          <div class="desc-not-sub" v-html="$t('not_have_deal_desc')"></div>
        </div>
      </v-col>
    </v-row> -->

    <template v-if="listDeal != null && listDeal.length > 0">
      <h4 class="title-tab mb-1 d-none d-md-block mt-4">
        {{ $t("my_subscription") }}
      </h4>
      <div class="table-stick-subscription">
        <v-expansion-panels v-model="panelDealPaket">
          <v-expansion-panel v-for="(item, i) in listDeal" :key="i">
            <v-expansion-panel-header class="ml-1">
              <!-- <h5 class="package-title text-capitalize">
              {{ item.subscription_no }} 
            </h5>
            <h5 class="package-title text-capitalize">
              IDR {{ formatPrice(item.total_amount) }} 
            </h5>
            <h5 class="package-title text-capitalize">
              {{ formatDate(item.subscription_date) }} 
            </h5>
            <h5 class="package-title text-capitalize">
              {{ test }} {{ countTest }}
            </h5> -->
              <v-row class="mb-1" dense>
                <v-col cols="12" sm="6" md="4" class="pad-title">
                  <p class="label-detail-blue ml-1 mt-2">
                    {{ $t("subscription_no") }}
                  </p>
                  <p class="package-title text-capitalize ml-1">
                    {{ item.subscription_no }}
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="pad-title">
                  <p class="label-detail-blue mt-2">
                    {{ $t("date_purchase") }}
                  </p>
                  <p class="package-title text-capitalize">
                    {{ formatDate(item.subscription_date) }}
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="pad-title">
                  <p class="label-detail-blue mt-2">{{ $t("cost") }}</p>
                  <p class="package-title text-capitalize">
                    IDR {{ formatPrice(item.total_amount) }}
                  </p>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="4" class="pad-title">
                <p class="label-detail-blue mt-2">{{ $t("book_detail") }}</p>
                <p class="package-title text-capitalize">{{ item.covered_package }}</p>
              </v-col> -->
              </v-row>

              <template v-slot:actions>
                <v-icon color="#00a6b6" dense class="mr-3">
                  mdi-menu-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-4"></v-divider>
              <!-- color="#00a4b3" -->
              <div v-for="(value, i) of item.details" :key="i">
                <!--
              <v-row class="mb-3" dense>
                <v-col cols="12" sm="6" md="3" class="ml-1">
                  <p class="label-detail">{{ $t("subscription_name") }}</p>
                  <p class="value-detail text-capitalize">
                    {{ value.deal_name.toLowerCase() }} 
                  </p>
                </v-col>
                 <v-col cols="12" sm="6" md="3" class="ml-1">
                  <p class="label-detail">{{ $t("cost") }}</p>
                  <p class="value-detail">
                    IDR {{ formatPrice(item.total_amount) }} 
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <p class="label-detail">{{ $t("date_purchase") }}</p>
                  <p class="value-detail">
                    {{ formatDate(item.subscription_date) }}
                  </p>
                </v-col> 
                <v-col cols="12" sm="6" md="3" class="ml-1">
                  <p class="label-detail">{{ $t("expired_date") }}</p>
                  <p class="value-detail">{{ formatDate(value.expiration_date) }}</p>
                </v-col> 
              </v-row> -->
                <div class="package-detail-box pa-3 mb-4">
                  <v-row dense>
                    <v-col cols="12" sm="9" md="10" xl="11">
                      <h5 class="value-detail">
                        {{ capitalize(value.package_name) }}
                      </h5>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="2"
                      xl="1"
                      class="d-none d-sm-block"
                    >
                      <localized-link
                        style="text-decoration: none"
                        :to="`/patient/use-subscription-packages/${item.id}?plant=${item.plant_id}&packages=${value.package_id}&sub=${value.id}`"
                      >
                        <div class="d-flex justify-end">
                          <c-button
                            rounded
                            shadow
                            bold
                            small
                            fluid
                            nonFluidSm
                            v-if="
                              value.qty_remaining > 0 &&
                              checkExpDate(value.expiration_date)
                            "
                          >
                            {{ $t("use") }}
                          </c-button>
                        </div>
                      </localized-link>
                    </v-col>
                  </v-row>

                  <!-- <p class="label-detail mb-2">
                  {{ $t("desc_expired") }}
                  {{ formatDate(value.expiration_date) }}
                </p> -->
                  <!-- slider -->

                  <div class="slider-box mb-3">
                    <div class="d-flex justify-space-between mb-2">
                      <template
                        v-if="
                          value.qty + value.qty_bonus - value.qty_remaining == 0
                        "
                      >
                        <p class="label-detail mb-2 mt-2 mt-sm-0">
                          {{ value.qty_remaining }} {{ $t("prod_available") }}
                        </p>
                      </template>
                      <template v-else-if="value.qty_remaining == 0">
                        <p class="label-detail mb-2">
                          {{ $t("product_has_been_used") }}
                        </p>
                      </template>
                      <template v-else>
                        <p class="label-detail mb-2">
                          {{ value.qty_remaining }} {{ $t("prod_available") }} |
                          {{
                            value.qty + value.qty_bonus - value.qty_remaining
                          }}
                          {{ $t("product_used") }}
                        </p>
                      </template>
                      <!-- <p class="label-detail mb-2" v-else-if="value.qty_remaining === 0">
                      {{ (value.qty + value.qty_bonus) - value.qty_remaining }} {{ $t("product_used") }}  {{ value.qty_remaining  }}
                    </p>
                    <p class="label-detail mb-2" v-else>
                      {{ $t("prod_available") }} {{ value.qty_remaining  }}
                    </p> -->
                      <!-- <h5 class="value-detail">{{ $t("available_package") }}</h5>
                    <p class="value-detail">
                      {{ (value.qty + value.qty_bonus) - value.qty_remaining }} {{ $t("of") }} {{ value.qty + value.qty_bonus }}
                      {{ $t("order_used") }}
                    </p> -->
                    </div>
                    <div class="slider">
                      <div
                        :style="{
                          width:
                            value.qty_remaining == 0
                              ? '0%'
                              : `${
                                  (value.qty_remaining /
                                    (value.qty + value.qty_bonus)) *
                                  100
                                }%`,
                        }"
                        class="value"
                      ></div>
                    </div>
                  </div>
                  <p
                    class="bundle-title"
                    v-if="value.deal_name != null || value.deal_code != null"
                  >
                    {{ $t("this_package") }} {{ $t("package_from_bundle") }}
                    <strong> {{ value.deal_name.toLowerCase() }}</strong
                    >, {{ $t("used_until") }}
                    {{ formatDate(value.expiration_date) }}
                  </p>
                  <p class="bundle-title" v-else>
                    <!-- <template v-if="value.qty_remaining != 0"> -->
                    {{ $t("this_package") }} {{ $t("used_until") }}
                    {{ formatDate(value.expiration_date) }}
                    <!-- </template>   -->
                  </p>
                  <localized-link
                    style="text-decoration: none"
                    :to="`/patient/use-subscription-packages/${item.id}?plant=${item.plant_id}&packages=${value.package_id}&sub=${value.id}`"
                  >
                    <div class="d-flex justify-end d-sm-none mt-3">
                      <c-button
                        rounded
                        shadow
                        bold
                        small
                        fluid
                        nonFluidSm
                        v-if="
                          value.qty_remaining > 0 &&
                          checkExpDate(value.expiration_date)
                        "
                      >
                        {{ $t("use") }}
                      </c-button>
                    </div>
                  </localized-link>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
    <v-row v-else>
      <v-col cols="12" sm="3" md="3">
        <img
          :src="require('@/assets/images/banner/doesnt_subscribe.png')"
          alt="Image doesnt have package!"
          class="img-responsive"
        />
      </v-col>
      <v-col cols="12" sm="9" md="6" class="d-flex align-center">
        <div class="d-flex flex-column">
          <h4 class="title-not-sub mb-3">{{ $t("not_have_deal") }}</h4>
          <div class="desc-not-sub" v-html="$t('not_have_deal_desc')"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import API from "../../../service/api_service";
import Auth from "../../../service/auth_service";
import CButton from "@/components/v2/button/index.vue";
export default {
  components: {
    CButton,
  },
  data: () => ({
    panel: 0,
    panelDeal: 0,
    panelDealPaket: 0,
    listSub: null,
    listDeal: null,
    paket: [
      {
        id: 260,
        plant_id: 12,
        subscription_no: "27A6.20230109.90005",
        subscription_date: "2023-01-09 18:22:59",
        total_amount: "5788000",
        details: [
          {
            qty: 4,
            products: [
              {
                qty: 1,
                name_en: "ALBUMIN",
                name_id: "ALBUMIN",
                product_id: 45,
              },
              {
                qty: 1,
                name_en: "ANA - SPECIAL MEDIUM TISSUE",
                name_id: "ANA - JARINGAN KHUSUS SEDANG",
                product_id: 300,
              },
              {
                qty: 1,
                name_en: "AUDIOMETRY",
                name_id: "AUDIOMETRI",
                product_id: 218,
              },
            ],
            qty_book: 0,
            deal_code: "DEAL070",
            deal_name: "DEAL EXPIRED 1",
            qty_bonus: 1,
            qty_usage: 0,
            package_id: 570,
            package_name: "PAKET DEAL ANGGA",
            qty_remaining: 0,
            expiration_date: "2023-03-20",
            t_subscription_id: 260,
          },
          {
            qty: 4,
            products: [
              {
                qty: 1,
                name_en: "ALBUMIN",
                name_id: "ALBUMIN",
                product_id: 45,
              },
              {
                qty: 1,
                name_en: "ANA - SPECIAL MEDIUM TISSUE",
                name_id: "ANA - JARINGAN KHUSUS SEDANG",
                product_id: 300,
              },
              {
                qty: 1,
                name_en: "AUDIOMETRY",
                name_id: "AUDIOMETRI",
                product_id: 218,
              },
            ],
            qty_book: 0,
            deal_code: "DEAL070",
            deal_name: "DEAL EXPIRED 1 2",
            qty_bonus: 1,
            qty_usage: 0,
            package_id: 570,
            package_name: "PAKET DEAL ANGGA 2",
            qty_remaining: 0,
            expiration_date: "2023-03-20",
            t_subscription_id: 260,
          },
        ],
      },
      {
        id: 261,
        plant_id: 12,
        subscription_no: "27A6.20230109.90005",
        subscription_date: "2023-01-09 18:22:59",
        total_amount: "5788000",
        details: [
          {
            qty: 4,
            products: [
              {
                qty: 1,
                name_en: "ALBUMIN",
                name_id: "ALBUMIN",
                product_id: 45,
              },
              {
                qty: 1,
                name_en: "ANA - SPECIAL MEDIUM TISSUE",
                name_id: "ANA - JARINGAN KHUSUS SEDANG",
                product_id: 300,
              },
              {
                qty: 1,
                name_en: "AUDIOMETRY",
                name_id: "AUDIOMETRI",
                product_id: 218,
              },
            ],
            qty_book: 0,
            deal_code: "DEAL070",
            deal_name: "DEAL EXPIRED 1",
            qty_bonus: 1,
            qty_usage: 0,
            package_id: 570,
            package_name: "PAKET TEST",
            qty_remaining: 0,
            expiration_date: "2023-03-20",
            t_subscription_id: 260,
          },
        ],
      },
    ],
    test: "",
  }),

  created() {
    //this.getMyPackage();
    this.getDeal();
  },
  // mounted(){
  //   this.test = this.paket.map(el => {
  //     el.covered_package = el.details.length > 1 ? `${el.details[0].package_name} (+${el.details.length - 1} ${ this.$t("package") })` : el.details[0].package_name;
  //     return el;
  //   });

  // },
  methods: {
    async getMyPackage() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}subscription/get-detail`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          this.listSub = resp.results;
        } else {
          this.$swal({
            icon: "error",
            text: this.$t("error_reschedule"),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDeal() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}subscription/get?type=package`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          this.listDeal = resp.results.map(el => {
            el.covered_package =
              el.details.length > 1
                ? `${el.details[0].package_name}(+${
                    el.details.length - 1
                  } ${this.$t("package")})`
                : el.details[0].package_name;
            return el;
          });
        } else {
          this.$swal({
            icon: "error",
            text: this.$t("error_404_title"),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },
    formatDate(date) {
      return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY");
    },
    // capitalize(str) {
    //   const lower = str.toLowerCase();
    //   return str.charAt(0).toUpperCase() + lower.slice(1);
    // },
    checkExpDate(date) {
      return new Date() <= new Date(date);
    },
    capitalize(str) {
      const lower = str.toLowerCase();
      return lower.replace(/\w\S*/g, function (str) {
        const word = str.slice(0, 1).toUpperCase() + str.substr(1);
        return word;
      });
    },
  },
};
</script>

<style lang="scss">
.my-sub {
  .v-expansion-panel-header {
    min-height: 40px;
    padding: 0;
  }
  .v-expansion-panel--active:not(:first-child) {
    margin-top: 0;
  }
  .border {
    border-bottom: 1px solid #cccc !important;
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
    border-bottom-color: rgba(204, 204, 204, 0.8) !important;
  }
}
.button-use-sm {
  // background-color: #00a4b3 !important;
  // border-radius: 4px;
  // min-width: 50px;
  // min-height: 25px;
  // color: white;
  // padding: 2px 12px 5px 12px;
  cursor: pointer;
  margin: 0 18px 20px;
  border: 1px solid;
  background-color: #00a4b3;
  color: white !important;
  font-size: 12px;
  padding: 6px 14px;
  height: 35px;
}
.button-use-sm:hover {
  background: #01adbc !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.my-sub {
  .pad-title {
    padding: 12px;
  }
  .bundle-title {
    font-size: 12px;
    color: grey;
    font-style: italic;
    margin-bottom: 0;
  }
  .title-tab {
    font-size: 16px;
    font-weight: bold;
    color: $dark_blue_2;
  }
  .package-title {
    font-size: 14px;
    font-weight: bold;
    color: $dark_blue_2;
    margin-bottom: 0;
  }
  .label-detail {
    font-size: 12px;
    color: $gray_8;
    margin-bottom: 0;
  }
  .label-detail-blue {
    font-size: 12px;
    color: $dark_blue_2;
    margin-bottom: 5px;
  }
  .value-detail {
    color: $gray_4;
    font-size: 14px;
    margin-bottom: 0;
  }
  .title-usage {
    font-size: 14px;
    font-weight: 600;
    color: $gray_4;
    margin-bottom: 0;
    color: $dark_blue_2;
  }
  .package-detail-box {
    border: 1px solid $gray_2;
    background: #fff;
    margin: 0 16px;
  }
  .slider {
    background: $gray_7;
    height: 12px;
    border-radius: 6px;
    overflow: hidden;
    .value {
      background: $main_1;
      height: 100%;
      border-radius: 6px;
    }
  }
  .title-not-sub {
    font-size: 26px;
    font-weight: bold;
    color: $dark_blue_2;
    line-height: 26px;
  }
  .desc-not-sub {
    color: $dark;
    font-size: 14px;
    margin-bottom: 0;
  }
  .img-responsive {
    width: 100%;
    height: auto;
  }
}
.table-stick-subscription {
  overflow-y: auto;
  max-height: 580px !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-subscription::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-subscription {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
