var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-layout"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card--cart"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{staticClass:"mt-0 uppercase-input",attrs:{"rules":[
                  v =>
                    !!v || `${_vm.$t('field.first_name')} ${_vm.$t('is_required')}`,
                ],"label":_vm.$t('field.first_name'),"required":""},model:{value:(_vm.payload.firstname),callback:function ($$v) {_vm.$set(_vm.payload, "firstname", $$v)},expression:"payload.firstname"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{staticClass:"mt-0 uppercase-input",attrs:{"rules":[
                  v => !!v || `${_vm.$t('field.last_name')} ${_vm.$t('is_required')}`,
                ],"label":_vm.$t('field.last_name'),"required":""},model:{value:(_vm.payload.lastname),callback:function ($$v) {_vm.$set(_vm.payload, "lastname", $$v)},expression:"payload.lastname"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-select',{staticClass:"mt-0",attrs:{"items":_vm.identityType,"item-text":_vm.$i18n.locale == 'en' ? `name` : `name_${_vm.$i18n.locale}`,"item-value":"id","readonly":"","rules":[
                  v =>
                    !!v ||
                    `${_vm.$t('field.identity_type')} ${_vm.$t('is_required')}`,
                ],"label":_vm.$t('field.identity_type'),"required":""},model:{value:(_vm.payload.identityType),callback:function ($$v) {_vm.$set(_vm.payload, "identityType", $$v)},expression:"payload.identityType"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-autocomplete',{staticClass:"mt-0",attrs:{"items":_vm.identityCountry,"item-text":"name","item-value":"id","rules":[
                  v =>
                    !!v ||
                    `${_vm.$t('field.identity_country_isu')} ${_vm.$t(
                      'is_required'
                    )}`,
                ],"label":_vm.$t('field.identity_country_isu'),"required":""},model:{value:(_vm.payload.identityCountry),callback:function ($$v) {_vm.$set(_vm.payload, "identityCountry", $$v)},expression:"payload.identityCountry"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"rules":[
                  v =>
                    !!v ||
                    `${_vm.$t('field.identity_number')} ${_vm.$t('is_required')}`,
                ],"label":_vm.$t('field.identity_number'),"required":""},model:{value:(_vm.payload.idcard),callback:function ($$v) {_vm.$set(_vm.payload, "idcard", $$v)},expression:"payload.idcard"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"inp-text",attrs:{"readonly":"","label":_vm.$t('field.birth_date'),"rules":[
                      v =>
                        !!v ||
                        `${_vm.$t('field.birth_date')} ${_vm.$t('is_required')}`,
                    ],"hint":"DD-MM-YYYY format"},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"header-color":"#00A4B3","max":new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.payload.birthDate),callback:function ($$v) {_vm.$set(_vm.payload, "birthDate", $$v)},expression:"payload.birthDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-autocomplete',{staticClass:"mt-0",attrs:{"items":_vm.gender,"item-text":_vm.$i18n.locale == 'en' ? `name` : `name_${_vm.$i18n.locale}`,"item-value":"id","rules":[
                  v => !!v || `${_vm.$t('field.gender')} ${_vm.$t('is_required')}`,
                ],"label":_vm.$t('field.gender'),"required":""},model:{value:(_vm.payload.selectGender),callback:function ($$v) {_vm.$set(_vm.payload, "selectGender", $$v)},expression:"payload.selectGender"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-autocomplete',{staticClass:"mt-0",attrs:{"items":_vm.relation,"item-text":"name","item-value":"id","rules":[
                  v =>
                    !!v ||
                    `${_vm.$t('page.profile.relation')} ${_vm.$t('is_required')}`,
                ],"label":_vm.$t('page.profile.relation'),"required":""},model:{value:(_vm.payload.selectRelation),callback:function ($$v) {_vm.$set(_vm.payload, "selectRelation", $$v)},expression:"payload.selectRelation"}})],1)],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{staticClass:"mr-4 button-register",attrs:{"disabled":!_vm.valid,"color":"white"},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),_c('v-btn',{staticStyle:{"border":"1px solid"},attrs:{"color":"button-cancel"},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }