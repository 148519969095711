<template>
  <v-form>
    <v-card class="filterYourHistory pa-3 pa-md-4">
      <div class="d-flex justify-lg-space-between">
        <h4 class="mb-4">
          {{ history ? $t("filer_history") : $t("filer_ongoing") }}
        </h4>
        <p @click="reset()" class="reset">{{ $t("reset") }}</p>
      </div>
      <v-row
        ><v-col cols="12" v-if="history && !family">
          <v-checkbox
            dense
            class="mt-0"
            hide-details
            v-model="payload.shared"
            label="Shared Company"
          ></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-menu
            v-model="opendatepicker"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Select Date"
                outlined
                append-icon="mdi-calendar-range"
                hide-details
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :locale="$i18n.locale"
              v-model="payload.filter_date"
              range
              @change="opendatepicker = false"
              :max="history ? minDate : null"
              color="#00a4b3"
              header-color="#00a4b3"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-select
            :items="branch"
            ref="branch"
            :label="$t('field.branch')"
            item-text="name"
            item-value="id"
            v-model="payload.branch"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            :items="listTypeTest"
            ref="type_test"
            label="Type Test"
            item-text="name"
            item-value="id"
            v-model="payload.type_test"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <!-- <div class="d-flex align-center justify-end pt-4">
        <c-button class="py-2" roundedSmall @click="saveFIlter()">{{
          $t("save")
        }}</c-button>
      </div> -->
    </v-card>
  </v-form>
</template>

<script>
// import CButton from "@/components/v2/button/index.vue";
import moment from "moment";
import API from "../../../service/api_service";
import Auth from "../../../service/auth_service";

export default {
  // components: {
  //   CButton,
  // },
  data: () => ({
    payload: {
      filter_date: [],
      branch: "",
      type_test: "",
      shared: false,
    },
    opendatepicker: false,
    minDate: "",
    branch: null,
    listTypeTest: [],
  }),
  props: {
    filterActive: {
      type: Object,
      default: () => ({
        filter_date: [],
        branch: "",
        type_test: "",
        shared: false,
      }),
    },
    history: {
      type: Boolean,
      default: false,
    },
    family: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateFormatted() {
      if (this.payload.filter_date.length > 1) {
        const sorted = [...this.payload.filter_date].sort(
          (a, b) => moment(a) - moment(b)
        );
        return `${this.formatDate(sorted[0])} ${this.$t(
          "to_date"
        )} ${this.formatDate(sorted[1])}`;
      } else if (this.payload.filter_date.length == 1) {
        return `${this.formatDate(this.payload.filter_date[0])} ${this.$t(
          "to_date"
        )} ${this.formatDate(this.payload.filter_date[0])}`;
      }
      return "";
    },
  },
  watch: {
    filterActive: {
      handler() {
        this.payload = this.filterActive;
      },
      deep: true,
    },
    payload: {
      handler(val) {
        this.$emit("changeFilterActive", val);
      },
      deep: true,
    },
  },
  created() {
    this.minDate = moment().format("YYYY-MM-DD");
    this.getBranch();
    this.payload = this.filterActive;
    this.listTypeTest = [
      { name: this.$t("all"), id: "" },
      { name: "Homecare", id: "homecare" },
      { name: "Package", id: "package" },
      { name: "Subscription", id: "subscription" },
      { name: "Product", id: "product" },
      { name: "Office Care", id: "officecare" },
    ];
  },
  methods: {
    formatDate(date) {
      return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY");
    },
    reset() {
      this.payload = {
        filter_date: [],
        branch: "",
        type_test: "",
        shared: false,
      };
    },
    async getBranch() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          this.branch = [{ name: this.$t("all"), id: "" }, ...resp.results];
        }
      } catch (error) {
        console.log("Error 404 Get Branch");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/color.scss";

.reset {
  color: $main_2;
}
</style>
