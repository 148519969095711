<template>
  <div>
    <apexchart
      height="360"
      ref="chart"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import ChartService from "@/service/chart_service.js";
export default {
  data: () => ({
    options: {
      chart: {
        type: "line",
        locales: ChartService,
      },
      colors: ["#00539C", "#1a7a4c"],
      markers: {
        size: 5,
      },
      stroke: {
        dashArray: 20,
      },
      xaxis: {
        type: "numeric",
      },
      yaxis: {
        type: "numeric",
      },
      tooltip: {
        custom: function ({ dataPointIndex, w }) {
          let view = ``;
          w.globals.initialSeries.forEach((item, index) => {
            item.data[dataPointIndex];
            view =
              view +
              `<div class="px-2" style="font-size:12px; ${
                index > 0 ? "border-left:1px solid #90a0b7" : ""
              }"><table><tr><tr><td class="pb-1 text-center" colspan="2"> <span style="transform: translateY(2px);display: inline-block;width: 12px;height: 12px;border-radius: 6px;${
                index == 0 ? "background: #00539c;" : "background: #1a7a4c;"
              }"></span> ${
                item.data[dataPointIndex].conduction
              }</td></tr><td class="pb-1">Frequency</td><td class="pb-1">: ${
                item.data[dataPointIndex].x
              } Hz</td></tr><tr><td class="pb-1">Result</td><td class="pb-1">: ${
                item.data[dataPointIndex].y
              }dB</td></tr><tr><td class="pb-1">Method</td><td class="pb-1">: ${
                item.data[dataPointIndex].method
              }</td></tr></table></div>`;
          });
          return `<div class="d-flex">${view}</div>`;
        },
      },
    },
    series: [],
  }),
  props: {
    data: {
      type: [Array, Object],
      default: null,
    },
  },
  created() {
    this.updateChartData(this.data);
  },
  methods: {
    updateChartData(data) {
      if (data != null) {
        Object.keys(data).forEach(key => {
          this.series = [...this.series, { name: key, data: data[key] }];
        });
      }
    },
  },
};
</script>
