<template>
  <!-- :class="{ 'd-none': !ready }"  -->
  <v-col class="last-med-record filterMedicalNew">
    <v-row>
      <div class="col col-12 pt-pb-0">
        <div class="v-card v-sheet theme--light">
          <custom-section class="section">
            <v-row dense class="rowPad display-dekstop">
              <div class="d-flex justify-space-between align-center mb-4">
                <span class="ungu s16 bold">
                  {{ $t("page.profile.medical_record") }}
                </span>
                <div>
                  <span
                    class="font-weight-bold"
                    @click="showDialogFilterMedicalRecord()"
                  >
                    <v-icon color="#00a4b3"> mdi-filter </v-icon>
                  </span>
                </div>
              </div>
              <!-- <v-divider style="border: 1px solid #00a4b3"></v-divider> -->
            </v-row>

            <div class="display-f display-dekstop">
              <v-data-table
                dense
                :headers="headers"
                :items="medicalResult"
                item-key="code"
                :items-per-page="-1"
                :footer-props="footerProps"
                mobile-breakpoint="0"
                hide-default-footer
                class="table-valign-top-mobile table-medical-info"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="td-stretch link" style="width: 150px">
                      <span
                        class="link-detail text-uppercase text-no-wrap crs-chart text-title-mr"
                        @click="showDialogChart(item.code)"
                      >
                        {{
                          item[`name_${$i18n.locale}`] != ""
                            ? item[`name_${$i18n.locale}`]
                            : item.code
                        }}
                      </span>
                    </td>
                    <td valign="middle" class="text-uppercase">
                      {{
                        item.current_item_unit != null
                          ? item.current_item_unit
                          : "-"
                      }}
                    </td>
                    <td
                      valign="middle"
                      class="text-right"
                      v-if="
                        item.previous_value != null &&
                        !isNaN(item.previous_value)
                      "
                    >
                      {{ parseFloat(item.previous_value).toFixed(2) }}
                    </td>
                    <td valign="middle" class="text-left" v-else>
                      {{ $t("no_data") }}
                    </td>
                    <td valign="middle" class="text-right">
                      {{
                        item.current_value != null && !isNaN(item.current_value)
                          ? parseFloat(item.current_value).toFixed(2)
                          : $t("no_data")
                      }}
                    </td>
                    <td valign="middle" class="text-right">
                      {{
                        item.previous_value == null ||
                        item.current_value == null ||
                        isNaN(item.previous_value) ||
                        isNaN(item.current_value)
                          ? "-"
                          : parseFloat(
                              parseFloat(item.current_value) -
                                parseFloat(
                                  item.previous_value != null
                                    ? item.previous_value
                                    : 0
                                ).toFixed(2)
                            ).toFixed(2)
                      }}
                    </td>
                    <td
                      valign="middle"
                      class="text-uppercase"
                      :class="{
                        'text-danger':
                          item.reference_min != null &&
                          item.reference_max != null
                            ? parseFloat(item.current_value) >
                                parseFloat(item.reference_max) ||
                              parseFloat(item.current_value) <
                                parseFloat(item.reference_min)
                            : false,
                        'text-success':
                          item.reference_min != null &&
                          item.reference_max != null
                            ? parseFloat(item.current_value) >=
                                parseFloat(item.reference_min) &&
                              parseFloat(item.current_value) <=
                                parseFloat(item.reference_max)
                            : false,
                      }"
                    >
                      {{
                        item.current_value == null
                          ? "-"
                          : item.reference_min != null &&
                            item.reference_max != null
                          ? parseFloat(item.current_value) >=
                              parseFloat(item.reference_min) &&
                            parseFloat(item.reference_max) >=
                              parseFloat(item.current_value)
                            ? $t("good")
                            : $t("warning")
                          : "-"
                      }}
                    </td>
                    <td :class="{ 'height-line': item.value.length == 1 }">
                      <span
                        class="link-detail text-uppercase crs-chart"
                        @click="showDialogChart(item.code)"
                      >
                        <ChartMedicalRecord :data="getDataChart(item.value)" />
                      </span>
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <!-- 
                <v-data-table
                  dense
                  :headers="headers"
                  :items="list"
                  item-key="name"
                  :items-per-page="perPage"
                  :footer-props="footerProps"
                  mobile-breakpoint="0"
                  hide-default-footer
                  class="table-valign-top-mobile table-medical-info"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="td-stretch pt-2 pt-sm-0 link" style="width: 150px">
                        <localized-link
                          :to="`/patient/medical-record/${item.key}`"
                          class="link-detail pt-2 text-uppercase text-no-wrap"
                        >
                          {{ item.name }}
                        </localized-link>
                      </td>
                      <td valign="top" class="pt-2 text-uppercase">{{ item.unit }}</td>
                      <td valign="top" class="text-right pt-2">
                        {{
                          item.previous_result != null &&
                          !checkObjectIsEmpty(item.current_result)
                            ? parseFloat(item.previous_result.value).toFixed(2)
                            : $t("no_data")
                        }}
                      </td>
                      <td valign="top" class="text-right pt-2">
                        {{
                          item.current_result != null &&
                          !checkObjectIsEmpty(item.current_result)
                            ? parseFloat(item.current_result.value).toFixed(2)
                            : $t("no_data")
                        }}
                      </td>
                      <td valign="top" class="text-right pt-2">
                        {{
                          checkObjectIsEmpty(item.current_result) ||
                          checkObjectIsEmpty(item.previous_result) ||
                          item.previous_result == null ||
                          item.current_result == null
                            ? "-"
                            : parseFloat(
                                parseFloat(item.current_result.value) -
                                  parseFloat(
                                    !checkObjectIsEmpty(item.previous_result)
                                      ? item.previous_result.value
                                      : 0
                                  ).toFixed(2)
                              )
                        }}
                      </td>
                      <td
                        valign="top"
                        class="pt-2 text-uppercase"
                        :class="{
                                'text-danger':
                                  !checkObjectIsEmpty(item.current_result) &&
                                  item.current_result.min != null &&
                                  item.current_result.max != null
                                    ? parseFloat(item.current_result.value) >
                                        parseFloat(item.current_result.max) ||
                                      parseFloat(item.current_result.value) <
                                        parseFloat(item.current_result.min)
                                    : false,
                                'text-success':
                                  !checkObjectIsEmpty(item.current_result) &&
                                  item.current_result.min != null &&
                                  item.current_result.max != null
                                    ? parseFloat(item.current_result.value) >=
                                        parseFloat(item.current_result.min) &&
                                      parseFloat(item.current_result.value) <=
                                        parseFloat(item.current_result.max)
                                    : false,
                              }"
                      >
                      {{
                          checkObjectIsEmpty(item.current_result) ||
                          item.current_result == null
                            ? "-"
                            : item.current_result.min != null &&
                              item.current_result.max != null
                            ? item.current_result.value >= item.current_result.min &&
                              item.current_result.max >= item.current_result.value
                              ? $t("good")
                              : $t("warning")
                            : "-"
                        }}
                      </td>
                      <td>
                      
                        <span
                          class="link-detail pt-2 text-uppercase crs-chart"
                          @click="showDialogChart(item.key)"
                        >
                          <ChartMedicalRecord :data="getDataChart(item.list_history)" />
                        </span>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
            --></div>

            <v-dialog
              v-model="dialogChart"
              persistent
              max-width="900px"
              @click:outside="onClickOutside"
              @keydown.esc="onClickOutside"
            >
              <DetailChartMedicalRecord :data="itemKey" />
            </v-dialog>

            <v-dialog
              v-model="dialogFilterList"
              persistent
              max-width="600px"
              @click:outside="closeFilterMedicalRecord()"
              @keydown.esc="closeFilterMedicalRecord()"
            >
              <dialog-filter-medical-record
                :data="dataTmpMedicalRecord"
                @closeReschedule="closeFilterMedicalRecord()"
                @addFilter="code => newFilter(code)"
              />
            </v-dialog>
          </custom-section>
        </div>
      </div>
    </v-row>
  </v-col>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import CustomSection from "@/components/v2/section/index.vue";
import DialogFilterMedicalRecord from "@/components/v3/popup/dialogFilterMedicalRecord.vue";
import ChartMedicalRecord from "@/views/v2/Patient/Chart/ChartMedicalRecord.vue";
import DetailChartMedicalRecord from "@/views/v2/Patient/Tabs/DetailMedicalInfoNew.vue";

export default {
  components: {
    CustomSection,
    DialogFilterMedicalRecord,
    ChartMedicalRecord,
    DetailChartMedicalRecord,
  },
  data: () => ({
    dialogFilterList: false,
    dataTmpMedicalRecord: [],
    dataChart: [],
    medicalResult: [],
    detailMedicalResult: [],
    dialog: false,
    items: [],
    headers: [],
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    perPage: 25,
    list: [
      {
        current_result: {
          date: "2022-12-08",
          value: "30",
          min: 60,
          max: 100,
        },
        previous_result: {
          date: "2022-08-31",
          value: "77",
          min: 60,
          max: 100,
        },
        unit: "bpm",
        list_history: [
          {
            date: "2022-08-31",
            value: "77",
            min: 60,
            max: 100,
          },
          {
            date: "2022-12-08",
            value: "30",
            min: 60,
            max: 100,
          },
        ],
        key: "heart_rate",
        name: "Heart Rate",
        note: "Good",
      },
      {
        current_result: {
          date: "2022-12-14",
          value: "36",
          min: 36.1,
          max: 37.2,
        },
        previous_result: {
          date: "2022-12-08",
          value: "18",
          min: 36.1,
          max: 37.2,
        },
        unit: "°C",
        list_history: [
          {
            date: "2022-08-31",
            value: "99",
            min: 36.1,
            max: 37.2,
          },
          {
            date: "2022-12-08",
            value: "18",
            min: 36.1,
            max: 37.2,
          },
          {
            date: "2022-12-14",
            value: "36",
            min: 36.1,
            max: 37.2,
          },
        ],
        key: "body_temperature",
        name: "Body Temperature",
        note: "Good",
      },
      {
        current_result: {
          date: "2022-12-14",
          value: "164",
          min: null,
          max: null,
        },
        previous_result: {
          date: "2022-12-08",
          value: "85",
          min: null,
          max: null,
        },
        unit: "cm",
        list_history: [
          {
            date: "2022-08-31",
            value: "200",
            min: null,
            max: null,
          },
          {
            date: "2022-12-08",
            value: "85",
            min: null,
            max: null,
          },
          {
            date: "2022-12-14",
            value: "164",
            min: null,
            max: null,
          },
        ],
        key: "body_height",
        name: "Body Height",
        note: "Good",
      },
      {
        current_result: {
          date: "2022-12-14",
          value: "44",
          min: 57.6,
          max: 57.6,
        },
        previous_result: {
          date: "2022-12-08",
          value: "25",
          min: -13.5,
          max: -13.5,
        },
        unit: "kg",
        list_history: [
          {
            date: "2022-08-31",
            value: "70",
            min: 90,
            max: 90,
          },
          {
            date: "2022-12-08",
            value: "25",
            min: -13.5,
            max: -13.5,
          },
          {
            date: "2022-12-14",
            value: "44",
            min: 57.6,
            max: 57.6,
          },
        ],
        key: "body_weight",
        name: "Body Weight",
        note: "Good",
      },
      {
        current_result: {
          date: "2022-12-14",
          value: "16",
          min: null,
          max: null,
          ideal: "kg/㎡",
        },
        previous_result: {
          date: "2022-12-08",
          value: "9",
          min: null,
          max: null,
          ideal: "kg/㎡",
        },
        unit: "kg/㎡",
        list_history: [
          {
            date: "2022-08-31",
            value: "18",
            min: null,
            max: null,
            ideal: "kg/㎡",
          },
          {
            date: "2022-12-08",
            value: "9",
            min: null,
            max: null,
            ideal: "kg/㎡",
          },
          {
            date: "2022-12-14",
            value: "16",
            min: null,
            max: null,
            ideal: "kg/㎡",
          },
        ],
        key: "body_mass_index",
        name: "Body Mass Index",
        note: "Good",
      },
      {
        current_result: {
          date: "2022-08-31",
          value: "111",
          min: 95,
          max: 135,
        },
        previous_result: null,
        unit: "mmHg",
        list_history: [
          {
            date: "2022-08-31",
            value: "111",
            min: 95,
            max: 135,
          },
        ],
        key: "sistole_pressure",
        name: "Sistole Pressure",
        note: "Good",
      },
      {
        current_result: {
          date: "2022-08-31",
          value: "222",
          min: 60,
          max: 80,
        },
        previous_result: null,
        unit: "mmHg",
        list_history: [
          {
            date: "2022-08-31",
            value: "222",
            min: 60,
            max: 80,
          },
        ],
        key: "diastole_pressure",
        name: "Diastole Pressure",
        note: "Good",
      },
    ],
    dialogChart: false,
    itemKey: "",
  }),
  watch: {
    medicalResult(id) {
      this.getDetailResult(id[0]);
    },
    // page: function () {
    //   this.getDataFilter();
    // },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataFilter();
      }
    },
  },
  created() {
    this.getDataFilter();
    this.headers = [
      {
        text: this.$t("page.profile.test_name"),
        sortable: false,
        value: "name",
        class: "text-center",
        align: "center",
      },
      {
        text: this.$t("page.profile.unit"),
        value: "unit",
        sortable: false,
        class: "text-center",
        align: "center",
      },
      {
        text: this.$t("page.profile.previous_test"),
        value: "previous",
        sortable: false,
        class: "text-center text-no-wrap",
        bordered: true,
        align: "center",
      },
      {
        text: this.$t("page.profile.current_test"),
        value: "current",
        sortable: false,
        class: "text-center text-no-wrap",
        align: "center",
      },
      {
        text: this.$t("page.profile.diff"),
        value: "diff1",
        sortable: false,
        class: "text-center",
        bordered: true,
        align: "center",
      },
      {
        text: this.$t("page.profile.notes"),
        value: "notes",
        sortable: false,
        class: "text-center",
        align: "center",
      },
      {
        text: this.$t("page.profile.chart"),
        class: "text-center tb-wide0",
        value: "chart",
        sortable: false,
        align: "left",
      },
    ];
  },
  mounted() {
    this.$store.commit("setLoading", true);

    this.$store.commit("setLoading", false);
  },
  methods: {
    allDefaultsFalse(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].default) {
          return false;
        }
      }
      return true;
    },
    setDefaultsTrue(arr) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].default = true;
      }
    },
    async getDataFilter() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}lab-results/get-product-item-list`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          // sort selected filter
          if (this.allDefaultsFalse(res.results)) {
            this.setDefaultsTrue(res.results);
            this.newFilter(res.results);
          } else {
            const sorted = res.results.sort((a, b) => {
              // Compare by isActive property first
              if (a.default !== b.default) {
                return a.default ? -1 : 1;
              }
              // If isActive property is the same, compare by name property
              return a[`name_${this.$i18n.locale}`].localeCompare(
                b[`name_${this.$i18n.locale}`]
              );
            });
            this.dataTmpMedicalRecord = sorted;
            this.getResult();
          }
        } else {
          this.ready = false;
        }
      } catch (error) {
        this.ready = false;
      }
    },
    async getResult() {
      try {
        const filter = this.getFilterActive(this.dataTmpMedicalRecord)
          .join(",")
          .replace(/#/g, "%23");
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}lab-results/get-product-item-result?filter_code=${filter}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          res.results.map(obj => {
            obj.value.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateA - dateB;
            });
            return obj;
          });

          const _this = this;
          const sort = res.results.sort(function (a, b) {
            //console.log(_this.$i18n.locale,'this.$i18n.locale')
            // console.log(b[`name_${this.$i18n.locale}`],'b[`name_${this.$i18n.locale}`]')
            if (
              a[`name_${_this.$i18n.locale}`] < b[`name_${_this.$i18n.locale}`]
            ) {
              return -1;
            }
            if (
              a[`name_${_this.$i18n.locale}`] > b[`name_${_this.$i18n.locale}`]
            ) {
              return 1;
            }
            return 0;
          });
          this.medicalResult = sort;
        }
        //medicalInfo
      } catch (error) {
        this.ready = false;
      }
    },
    async getDetailResult(item) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}lab-results/get-product-item-result?code=${item.code}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.detailMedicalResult =
          res.results.length == 0
            ? []
            : res.results[0].value.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              });
      } catch (error) {
        this.ready = false;
      }
    },
    newFilter(item) {
      this.dialogFilterList = false;
      // sort selected filter
      const sorted = item.sort((a, b) => {
        // Compare by isActive property first
        if (a.default !== b.default) {
          return a.default ? -1 : 1;
        }
        // If isActive property is the same, compare by name property
        return a[`name_${this.$i18n.locale}`].localeCompare(
          b[`name_${this.$i18n.locale}`]
        );
      });
      this.dataTmpMedicalRecord = sorted;
      this.getResult();
    },
    getFilterActive(dat) {
      return dat.filter(item => item.default == true).map(item => item.code);
    },
    onClickItem(item) {
      this.getDetailResult(item);
    },
    showDialogFilterMedicalRecord() {
      this.dialogFilterList = true;
    },
    closeFilterMedicalRecord() {
      this.dataTmpMedicalRecord = [];
      this.dialogFilterList = false;
      this.getDataFilter();
    },
    onClickOutside() {
      this.dialogFilterList = false;
      this.dialogChart = false;
    },
    getDataChart(data) {
      if (data.length > 0) {
        //  data.sort((a, b) => {
        //   return new Date(a.date) - new Date(b.date);
        // });
        return data.map(item => parseFloat(item.clean_value));
      } else return [];
    },
    pageNext(dat) {
      this.page = dat;
      this.getDataFilter();
    },
    showDialogChart(dat) {
      this.dialogChart = true;
      this.itemKey = dat;
    },
    checkObjectIsEmpty(obj) {
      return (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.display-dekstop {
  display: block;
}
.display-mobile {
  display: none;
}
.blue-color {
  color: #00a4b3 !important;
}

td {
  a {
    color: black;
    vertical-align: text-top;
  }
  width: 80px;
  text-transform: uppercase;
}
th {
  width: 100px;
  vertical-align: top;
}

.link-detail {
  vertical-align: super;
}

.header-padding {
  padding: 0 16px;
}
.background-ratio-left {
  border-left: 1px solid black;
}
.background-ratio-right {
  border-right: 1px solid black;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  color: black;
  &.text-danger {
    color: red;
  }
  &.text-success {
    color: green;
  }
  &.text-warning {
    color: orange;
  }
}
.link a {
  text-decoration: none !important;
  font-weight: 700;
  color: #00a4b3;
}
.crs-chart {
  cursor: pointer;
}
.text-title-mr {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #00a4b3;
  font-weight: bold;
}
.text-no-wrap {
  white-space: nowrap !important;
}
.height-line {
  height: 38px !important;
}
</style>
