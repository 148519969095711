<template>
  <span :class="{ shareds: flag }">
    <v-tooltip left class="grey-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          @click="$emit('click')"
          v-bind="attrs"
          v-on="on"
          :style="{ color: color, fontSize: '16px' }"
        >
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ title }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    icon: { type: String, default: "" },
    color: { type: String, default: "white" },
    flag: { flag: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.shareds {
  display: inline-block;
  position: relative;
  &:before {
    content: "!";
    position: absolute;
    width: 2px;
    color: yellow;
    top: 0;
    right: 0;
    transform: translate(2px, -4px);
    font-size: 15px;
    font-weight: 600;
  }
}
</style>
