<template>
  <div>
    <apexchart
      :height="height"
      ref="chart"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import localStorageService from "@/service/localStorageService";
import ChartService from "@/service/chart_service.js";
import moment from "moment";
export default {
  data: () => ({
    options: {
      chart: {
        id: "vuechart",
        type: "line",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          autoSelected: "pan",
        },
        locales: ChartService,
        defaultLocale: localStorageService.getItem("locale"),
        // events: {
        //   beforeZoom: function(ctx) {
        //     // we need to clear the range as we only need it on the iniital load.
        //     ctx.w.config.xaxis.range = undefined
        //   }
        // }
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
        // min: 1,
        // max: 4,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return parseFloat(value).toFixed(2);
          },
        },
      },
      annotations: {},
      colors: ["#00a6b6"],
      tooltip: {
        x: {
          show: true,
        },
        y: {
          formatter: function (value) {
            return value;
          },
        },
        marker: {
          show: false,
        },
      },
      // markers: {
      //   size: 1,
      //   // strokeColors: '#fff',
      //   // strokeWidth: 1,
      //   // strokeDashArray: 0,
      //   // fillOpacity: 1,
      //   // shape: "circle",
      //   // radius: 1,
      // },
    },
   
    series: [
      {
        data: [],
      },
    ],
  }),
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
      default: "",
    },
    activeRange: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "360",
    },
  },
  watch: {
    data: function (newVal) {
      this.updateChartData(newVal);
    },
    activeRange: function () {
      this.updateChartData(this.data);
    },
  },
  created() {
    this.updateChartData(this.data);
    // console.log(this.options,'dddddddddddd')
  },
  methods: {
    // `https://cdn.jsdelivr.net/npm/apexcharts/dist/locales/${this.$i18n.locale}.json`

    updateChartData(data) {
      this.series = [{ name: this.$t("result"), data: [] }];
      // zoom
      const this_ = this;
      let xaxis = {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: function (value) {
            return moment(value)
              .locale(this_.$i18n.locale)
              .format("DD MMM yyyy");
          },
        },
      };
      xaxis.max = new Date(
        moment().subtract(1, "months").endOf("month").format("MM-DD-YYYY")
          ).getTime();
      xaxis.min = new Date(
        moment().subtract(3, "months").endOf("month").format("MM-DD-YYYY")
      ).getTime();
      // tooltip
      const tooltip = {
        x: {
          show: true,
          formatter: function (value) {
            return moment(value)
              .locale(this_.$i18n.locale)
              .format("DD MMM yyyy");
          },
        },
        y: {
          formatter: function (value) {
            return parseFloat(value).toFixed(1);
          },
        },
        marker: {
          show: false,
        },
      };
      // switch (this.activeRange) {
      //   case "this_month":
      //     xaxis.max = new Date(moment().format("MM-DD-YYYY")).getTime();
      //     xaxis.min = new Date(
      //       moment().startOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     break;
      //   case "last_month":
      //     xaxis.max = new Date(
      //       moment().subtract(1, "months").endOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     xaxis.min = new Date(
      //       moment().subtract(1, "months").startOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     break;
      //   case "last_3_month":
      //     xaxis.max = new Date(
      //       moment().subtract(1, "months").endOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     xaxis.min = new Date(
      //       moment().subtract(3, "months").startOf("month").format("MM-DD-YYYY")
      //     ).getTime();
      //     break;
      //   case "this_year":
      //     xaxis.max = new Date(
      //       moment().endOf("year").format("MM-DD-YYYY")
      //     ).getTime();
      //     xaxis.min = new Date(
      //       moment().startOf("year").format("MM-DD-YYYY")
      //     ).getTime();
      //     break;
      //   default:
      //     break;
      // }
      if (data.length > 0) {
        this.series[0].data = data.map(res => {
          // if (this.activeRange == "life_time") {
          //   res.x = moment(res.date)
          //     .locale(this.$i18n.locale)
          //     .format("MMM YYYY");
          // } else if (this.activeRange == "this_year") {
          //   res.x = moment(res.date).locale(this.$i18n.locale).format("MMM");
          // } else {
          //   res.x = moment(res.date)
          //     .locale(this.$i18n.locale)
          //     .format("DD MMM yyyy");
          // }
          res.x = new Date(res.date).getTime();
          res.y = parseFloat(res.value);
          return res;
        });

        if (this.$refs.chart != undefined) {
          this.$refs.chart.updateOptions({
            annotations: {},
          });
        }
        if (typeof data[0].max == "number" && typeof data[0].min == "number") {
          let min = parseFloat(data[0].min);
          let max = parseFloat(data[0].max);
          if (min == max) {
            const annotations = {
              yaxis: [
                {
                  y: min,
                  // y2: min + 1,
                  // fillColor: "#ff0000",
                  borderColor: "red",
                  opacity: 1,
                },
              ],
            };
            if (this.$refs.chart != undefined) {
              this.$refs.chart.updateOptions({
                annotations: annotations,
                xaxis: xaxis,
                tooltip,
              });
            } else {
              this.options.annotations = annotations;
              this.options.xaxis = xaxis;
              this.options.tooltip = tooltip;
            }
          } else {
            const annotations = {
              yaxis: [
                {
                  y: min,
                  y2: max,
                  fillColor: "#00a6b6",
                  opacity: 0.2,
                },
                {
                  y: max,
                  borderColor: "red",
                  opacity: 1,
                },
                {
                  y: min,
                  borderColor: "red",
                  opacity: 1,
                },
              ],
            };
            if (this.$refs.chart != undefined) {
              this.$refs.chart.updateOptions({
                annotations: annotations,
                xaxis: xaxis,
                tooltip,
              });
            } else {
              this.options.annotations = annotations;
              this.options.xaxis = xaxis;
              this.options.tooltip = tooltip;
            }
          }
        } else {
          const annotations = {
            yaxis: [
              {
                y: null,
                borderColor: "#000",
                fillColor: "#00a6b6",
                opacity: 0.2,
              },
            ],
          };
          if (this.$refs.chart != undefined) {
            this.$refs.chart.updateOptions({
              annotations: annotations,
              xaxis: xaxis,
              tooltip,
            });
          } else {
            this.options.annotations = annotations;
            this.options.xaxis = xaxis;
            this.options.tooltip = tooltip;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
