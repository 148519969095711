<template>
  <v-card-text class="px-3 px-md-5">
    <v-row>
      <v-col cols="12">
        <h3 class="black--text">{{ $t("R.ris_result") }}</h3>
        <!-- RIS list -->
        <template v-if="resultXray != null">
          <div v-for="(item, index) of resultXray" :key="index">
            <v-card color="basil" flat>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-center">{{ $t("R.observed") }}</th>
                            <th class="text-center">{{ $t("R.result") }}</th>
                            <th class="text-center">
                              {{ $t("R.description") }}
                            </th>
                            <th class="text-center">
                              {{ $t("R.conclusion") }}
                            </th>
                            <th class="text-center">
                              {{ $t("R.approval_by") }}
                            </th>
                          </tr>
                          <tr>
                            <td class="text-center text-no-wrap">
                              {{ item.product_name }}
                            </td>
                            <td class="text-center text-no-wrap">
                              {{ item.radiologist_result }}
                            </td>
                            <td class="text-center text-no-wrap">
                              {{ item.description }}
                            </td>
                            <td class="text-center text-no-wrap">
                              {{ item.comment == null ? "-" : item.comment }}
                            </td>
                            <td class="text-center text-no-wrap">
                              {{ item.approval_by_name }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>

              <zoom-on-hover
                v-if="item.file[activeImage[index]] != undefined"
                :img-normal="item.file[activeImage[index]].file_link"
                :scale="5"
                class="my-5"
              ></zoom-on-hover>
              <div class="list-image mb-5">
                <img
                  :class="{ active: activeImage[index] == fileIndex }"
                  :src="image.file_link"
                  v-for="(image, fileIndex) of item.file"
                  :key="fileIndex"
                  class="mr-5"
                  @click="changeActiveImage(index, fileIndex)"
                  :alt="
                    $i18n.locale == 'id'
                      ? image.product_name
                      : image[`product_name_${$i18n.locale}`]
                  "
                />
              </div>
            </v-card>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import Card from "@/components/v2/card/index.vue";

export default {
  components: {
    Card,
  },
  props: {
    result: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    resultXray: null,
    activeImage: {},
  }),
  created() {
    this.resultXray = this.result.data.detail_radiology_xray;
    for (let index = 0; index < this.resultXray.length; index++) {
      this.activeImage[index] = 0;
    }
  },
  methods: {
    changeActiveImage(index, value) {
      const active = this.activeImage;
      this.activeImage = {};
      this.activeImage = active;
      this.activeImage[index] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}
.v-data-table table tr td,
.v-data-table table tr th,
table tr td,
table tr th {
  font-size: 12px !important;
}
.normal-label {
  text-transform: capitalize;
  color: $green;
}
.abnormal-label {
  color: $red_2;
  text-transform: capitalize;
}

// mobile
.mobile {
  display: none;
}
.mobile .table-inside tr td:first-child {
  width: 20%;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 200px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}

// radiology
.list-image {
  display: flex;
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    border: 4px solid $gray_8;
    &.active {
      border: 4px solid $main_2;
    }
  }
}
</style>
