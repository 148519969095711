<template>
  <v-card-text class="px-3 px-md-5">
    <v-row>
      <v-col cols="12">
        <h3 class="black--text">{{ $t("R.support_investigation") }}</h3>
        <!-- RIS list -->
        <template v-if="supportiveData != null">
          <div v-for="(item, index) of supportiveData" :key="index">
            <v-card color="basil" flat>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-no-wrap text-center">{{ $t("R.observed") }}</th>
                            <th class="text-no-wrap text-center">{{ $t("R.result") }}</th>
                            <th class="text-no-wrap text-center">
                              {{ $t("R.description") }}
                            </th>
                            <th class="text-no-wrap text-center">
                              {{ $t("R.conclusion") }}
                            </th>
                            <th class="text-no-wrap text-center">
                              {{ $t("R.approval_by") }}
                            </th>
                          </tr>
                          <tr>
                            <td class="text-no-wrap text-center">
                              {{ item.product_name }}
                            </td>
                            <td class="text-no-wrap text-center">
                              {{ item.radiologist_result }}
                            </td>
                            <td class="text-no-wrap text-center">
                              {{ item.description }}
                            </td>
                            <td class="text-no-wrap text-center">
                              {{ item.comment == null ? "-" : item.comment }}
                            </td>
                            <td class="text-no-wrap text-center">
                              {{ item.approval_by_name }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-card>
            <div v-for="(file, index) of item.file" :key="index">
              <h4>{{ file.product_name }}</h4>
              <iframe
                :src="file.file_link"
                height="500"
                width="100%"
                :title="file.product_name"
              ></iframe>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import Card from "@/components/v2/card/index.vue";

export default {
  components: {
    Card,
  },
  props: {
    result: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    supportiveData: null,
    activeImage: {},
  }),
  created() {
    this.supportiveData = this.result.data.detail_supportive;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}
.v-data-table table tr td,
.v-data-table table tr th,
table tr td,
table tr th {
  font-size: 12px !important;
}
.normal-label {
  text-transform: capitalize;
  color: $green;
}
.abnormal-label {
  color: $red_2;
  text-transform: capitalize;
}

// mobile
.mobile {
  display: none;
}
.mobile .table-inside tr td:first-child {
  width: 20%;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 200px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}
</style>
