<template>
  <div class="dialog-popup">
    <p class="text-head mt-4">{{ $t("page.profile.select_date") }}</p>
    <v-spacer></v-spacer>
    <v-date-picker
      width="465px"
      v-model="dateBook"
      no-title
      color="#00a4b3"
      :allowed-dates="allowedDates"
      @update:picker-date="pickerUpdate($event)"
      class="dialog-schedule"
      :min="minDate"
      :max="maxDate"
    >
    </v-date-picker>
    <v-spacer></v-spacer>
    <p class="text-head">{{ $t("page.profile.select_time") }}</p>
    <v-spacer></v-spacer>
    <template v-if="listTimeAvailable.length > 0">
      <v-item-group
        active-class="item-time-active"
        class="time-picker"
        v-model="timeDate"
      >
        <span v-for="(time, index) of listTimeAvailable" :key="index">
          <v-item v-slot="{ toggle }">
            <div class="item-time dissabled" v-if="!checkTime(time)">
              {{ formatTime(time.time) }}
            </div>
            <div class="item-time" @click="toggle" v-else>
              {{ formatTime(time.time) }}
            </div>
          </v-item>
        </span>
      </v-item-group>
    </template>
    <p v-else class="mx-5 text-desc my-2">{{ $t("schedule_not_available") }}</p>
    <v-spacer></v-spacer>
    <div class="footer-schedule">
      <!-- <v-btn text @click="closeModal()" class="btn-schedule">
        {{ $t("cancel") }}
      </v-btn> -->
      <v-btn
        text
        @click="changeTime()"
        class="btn-schedule"
        :class="{
          disabled: timeDate == null || timeDate == undefined || timeDate < 0,
        }"
        :disabled="timeDate == null || timeDate == undefined || timeDate < 0"
      >
        {{ $t("select") }} {{ $t("field.booking_date") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
export default {
  props: {
    plant: { type: [String, Number], default: "" },
    value: { type: String, default: "" },
    holiday: { type: Array, default: () => [] },
    enableDays: { type: Array, default: () => [] },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    modal: false,
    dateBook: "",
    listTimeAvailable: [],
    timeDate: "",
    lists: [],
    payload: {
      selected_time: "",
    },
    pickerValue: "",
    holidayThisMonth: [],
    availableDates: [],
  }),
  computed: {
    // start reschedule data
    getDataReschedule() {
      return this.$store.state.rescheduleData;
    },
    // end reschedule data

    dateVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("changeTime", val);
      },
    },
  },
  watch: {
    enableDays: function () {
      this.pickerUpdate(this.pickerValue);
    },
    dateBook(val) {
      !val || this.getTime();
    },
  },
  mounted() {
    this.dateBook =
      this.dateVal == "" || this.dateVal == undefined || this.dateVal == null
        ? this.minDate
        : moment(this.dateVal).format("YYYY-MM-DD");
    this.activeDate = moment().add(1, "days").format("YYYY-MM-DD");
  },
  methods: {
    checkIsHoliday(param) {
      const date = moment(param);
      return this.holiday.some(holiday => date.isSame(holiday));
    },
    // start calculate reschedule min
    calculateMinReschedule(date, min) {
      const initialDate = moment(date);
      let excMin = 0;
      while (min > excMin) {
        initialDate.subtract(1, "days");
        if (!this.checkIsHoliday(initialDate) && initialDate.day() != 0)
          excMin++;
      }
      return initialDate.format("YYYY-MM-DD");
    },
    // end calculate reschedule min
    // start calculate reschedule max
    calculateMaxReschedule(date, max) {
      const initialDate = moment(date);
      let excMax = 0;
      while (max >= excMax) {
        initialDate.add(1, "days");
        if (!this.checkIsHoliday(initialDate) && initialDate.day() != 0)
          excMax++;
      }
      return initialDate.format("YYYY-MM-DD");
    },
    // end calculate reschedule max

    // start time check
    checkTime(time) {
      if (time.available) {
        return moment(this.dateBook).format("DD-MM-YYYY") ==
          moment().format("DD-MM-YYYY")
          ? moment(time.time, "HH:mm:ss").format("HH:mm:ss") >
              moment().format("HH:mm:ss")
          : true;
      } else {
        return false;
      }
    },
    // end time check

    // start available date
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    async pickerUpdate(val) {
      this.pickerValue = val;
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;
      for (let i = day; i <= totalDay; i++) {
        let date = moment(val, "YYYY-MM")
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (this.enableDays.includes(-1)) {
          availableDates.push(date);
        } else {
          if (this.enableDays.includes(moment(date).day()))
            availableDates.push(date);
        }
      }
      this.holidayThisMonth = await this.holiday.filter(
        item => moment(item.date).format("YYYY-MM") == val
      );
      for (const holiday of this.holidayThisMonth) {
        const index = availableDates.indexOf(holiday.date);
        if (index !== -1) {
          availableDates.splice(index, 1);
        }
      }
      const filtered = [...availableDates];
      availableDates.forEach(async date => {
        const res = await API.get(
          `${
            process.env.VUE_APP_API_TRUST
          }homecare/available-schedule?date=${this.formatDateYYYYMMDD(
            date
          )}&plant_id=${this.plant}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.results.available_time.length == 0) {
          const index = filtered.indexOf(date);
          filtered.splice(index, 1);
        }
      });
      this.availableDates = filtered;
    },
    // end available date

    closeModal() {
      this.$emit("toggleModal");
    },
    changeTime() {
      if (typeof this.timeDate && this.timeDate > -1)
        this.$emit(
          "changeTime",
          `${this.dateBook} ${this.listTimeAvailable[this.timeDate].time}`
        );
      else this.$emit("changeTime", "");
    },
    getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        // if ((currentDate.getDay() != 0) & (currentDate.getDay() < 6))
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    },
    async getTime() {
      try {
        const res = await API.get(
          `${
            process.env.VUE_APP_API_TRUST
          }homecare/available-schedule?date=${this.formatDateYYYYMMDD(
            this.dateBook
          )}&plant_id=${this.plant}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          const startTime = moment(res.results.start_at, "HH:mm:ss");
          const endTime = moment(res.results.end_at, "HH:mm:ss");
          const timeList = [];
          let currentTime = startTime.clone();
          this.timeDate = undefined;
          while (currentTime.isSameOrBefore(endTime)) {
            timeList.push(currentTime.format("HH:mm:ss"));
            currentTime.add(1, "hour");
          }
          this.listTimeAvailable = timeList.map(item => {
            return {
              time: item,
              available: res.results.available_time.includes(item),
            };
          });
          // get active time
          if (this.dateVal != "" || this.dateVal != null) {
            const tmpDate = moment(this.dateVal).format("YYYY-MM-DD");
            if (moment(this.dateBook).isSame(tmpDate)) {
              const tmpTime = moment(this.dateVal).format("HH:mm:ss");
              const indexTime = this.listTimeAvailable.findIndex(
                item => item.available && item.time == tmpTime
              );
              if (indexTime > -1) {
                this.timeDate = indexTime;
              }
            }
          }
          // end active time
        } else {
          this.listTimeAvailable = [];
        }
      } catch (error) {
        console.log("Error 403! (Available Schedule)", error);
      }
    },
    formatDateDDMMM(date) {
      return date == null
        ? moment().locale(this.$i18n.locale).format("DD MMM")
        : moment(date).locale(this.$i18n.locale).format("DD MMM");
    },
    formatDateYY(date) {
      return date == null
        ? moment().locale(this.$i18n.locale).format("YYYY")
        : moment(date).locale(this.$i18n.locale).format("YYYY");
    },
    formatDateYYYYMMDD(date) {
      return date == null
        ? moment().locale(this.$i18n.locale).format("YYYY-MM-DD")
        : moment(date).locale(this.$i18n.locale).format("YYYY-MM-DD");
    },
    formatTime(date) {
      return date.substr(0, 5);
    },
  },
};
</script>

<style lang="scss">
.modal-schedule {
  background: white !important;
  .time-picker {
    height: auto;
    padding: 8px 0 10px 10px;
    overflow: auto;
    font-size: 12px;
    .item-time {
      display: inline-block;
      padding: 8px 13px;
      border-radius: 6px;
      margin: 8px;
      cursor: pointer;
      background-color: #eefafc;
      color: #00a4b3;
      &:hover {
        background: #00a4b3;
        color: white;
      }
      &.active {
        background: #00a4b3;
        color: white;
      }
      &.dissabled {
        cursor: default;
        background: rgba(128, 128, 128, 0.412);
        color: #00a4b3 !important;
      }
    }
    .item-time-active {
      background: #00a4b3;
      color: white;
    }
  }
  .footer-schedule {
    text-align: end;
    .btn-schedule {
      margin: 0 18px 20px;
      border: 1px solid;
      background-color: #00a4b3;
      color: white !important;
      font-size: 12px;
      padding: 6px 14px;
      height: 35px;
      &.disabled {
        background-color: rgba(128, 128, 128, 0.412);
      }
    }
  }
  .text-head {
    color: #213b8f;
    font-size: 16px;
    font-weight: 600;
    margin: 0 20px;
    border-bottom: 1.5px solid;
    border-color: #213b8f;
  }
  .text-desc {
    font-size: 14px;
  }
}
.btn-schedule-act {
  background: #00a4b3 !important;
}
.text-doctor {
  color: #213b8f;
  font-size: 16px;
  font-weight: 600;
  margin: 0 20px;
  margin-bottom: 0 !important;
}
.padding-doctor {
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-top: -5px !important;
  padding-top: 0 !important;
}
.padding-doctor.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #213b8f !important;
  border: 0.5px solid;
}
.padding-doctor .v-icon.v-icon {
  color: #213b8f !important;
}
</style>
