<template>
  <v-col :class="{ 'd-none': !ready }" class="last-med-record">
    <v-row>
      <div class="col col-12 pt-pb-0">
        <div
          class="v-card v-sheet theme--light"
        >
          <custom-section class="section">
            <v-row dense class="rowPad display-dekstop">
              <div class="d-flex justify-space-between align-center">
                <span class="ungu s16 bold"
                  >{{ $t("page.profile.last_medical_record") }} ||
                  {{ order_number }}</span
                >
                <div>
                  <v-menu offset-y v-if="dat.length > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        color="#FFF"
                        text
                        class="btn-download-report ml-3 mr-3"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left dark> mdi-printer </v-icon>
                        {{ $t("page.profile.print_report") }}
                      </v-btn>
                      
                      <date-schedule :classStyle="medicalRecord" :history="false"></date-schedule>
                      
                    </template>
                    <div class="popup">
                      <v-row style="width: 260px">
                        <v-col cols="12" class="pa-2 mt5 mb10">
                          <v-btn
                            small
                            text
                            @click="openReport(order_number, 'simple', mcu)"
                          >
                            <v-icon left dark> mdi-chart-areaspline </v-icon>
                            <div class="title-grid">
                              <span class="title-grid-h">{{ $t("simple") }}</span>
                              <span class="title-grid-c">{{
                                $t("page.profile.desc_simple")
                              }}</span>
                            </div>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" class="pa-2 mb5">
                          <v-btn
                            small
                            text
                            @click="openReport(order_number, 'advance', mcu)"
                          >
                            <v-icon left dark color="black">
                              mdi-chart-bar-stacked
                            </v-icon>
                            <div class="title-grid">
                              <span class="title-grid-h">{{
                                $t("advance")
                              }}</span>
                              <span class="title-grid-c">{{
                                $t("page.profile.desc_advance")
                              }}</span>
                            </div>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-menu>
                </div>
              </div>
            </v-row>

            <v-row dense class="rowPad display-mobile">
              <div class="d-flex justify-space-between align-center">
                <span class="ungu s16 bold"
                  >{{ $t("page.profile.last_medical_record") }} 
                  <br>
                  {{ order_number }}</span
                >
                <div>
                  <v-menu offset-y v-if="dat.length > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="background-transparant"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left dark color="#209fb4"> mdi-dots-horizontal </v-icon>
                      </v-btn>
                      
                      <date-schedule :classStyle="medicalRecord" :history="false"></date-schedule>
                      
                    </template>
                    <div class="popup">
                      <v-row style="width: 120px">
                        <v-col cols="12" class="pa-2 mt5 mb10">
                          <v-btn
                            small
                            text
                            @click="openReport(order_number, 'simple', mcu)"
                          >
                            <v-icon left dark> mdi-chart-areaspline </v-icon>
                            <div class="title-grid">
                              <span class="title-grid-h">{{ $t("simple") }}</span>
                            </div>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" class="pa-2 mb5">
                          <v-btn
                            small
                            text
                            @click="openReport(order_number, 'advance', mcu)"
                          >
                            <v-icon left dark color="black">
                              mdi-chart-bar-stacked
                            </v-icon>
                            <div class="title-grid">
                              <span class="title-grid-h">{{
                                $t("advance")
                              }}</span>
                            </div>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-menu>
                </div>
              </div>
            </v-row>

            <div class="display-f display-dekstop">
              
              <v-col>
                <v-row>
                  <v-col md="4" cols="12">
                    <v-row>
                      <div class="box">
                        <span class="s12 biru bold">{{
                          $t("page.profile.filter_res")
                        }}</span>
                        <div
                          id="crs"
                          :class="{ active: actived == 'all' }"
                          @click="filterResult('all')"
                        >
                          <div :class="{ boxArrow: actived == 'all' }"></div>
                          <div :class="{ arrow: actived == 'all' }"></div>
                          <div class="s12 sumHijau bold">
                            {{ total_res }}
                          </div>
                          <span class="s12">
                            {{ $t("page.profile.total_res") }}
                          </span>
                        </div>
                        <div
                          id="crs"
                          :class="{ active: actived == 'review' }"
                          @click="filterResult('review')"
                        >
                          <div :class="{ boxArrow: actived == 'review' }"></div>
                          <div :class="{ arrow: actived == 'review' }"></div>
                          <span class="s12 sumMerah bold">
                            {{ review_res }}
                          </span>
                          <span class="s12">
                            {{ $t("page.profile.review_res") }}
                          </span>
                        </div>
                        <div
                          id="crs"
                          :class="{ active: actived == 'normal' }"
                          style="padding-bottom: 10px"
                          @click="filterResult('normal')"
                        >
                          <div :class="{ boxArrow: actived == 'normal' }"></div>
                          <div :class="{ arrow: actived == 'normal' }"></div>
                          <span class="s12 sumHijau bold">
                            {{ expected_res }}
                          </span>
                          <span class="s12">
                            {{ $t("page.profile.expected_res") }}
                          </span>
                        </div>
                        <v-divider></v-divider>
                      </div>
                    </v-row>

                    <v-row>
                      <div v-if="dat.length <= 0">
                        <p class="no-record">{{ $t("no_data") }}</p>
                      </div>
                      <v-virtual-scroll
                        :items="dat"
                        :item-height="50"
                        height="350"
                        class="br"
                      >
                        <template v-slot:default="{ item }">
                          <v-list-item
                            @click="
                              onClickItem(item.productObj.product_name_id)
                            "
                            style="padding-left: 0px; padding-bottom: 10px"
                          >
                            <v-list-item-content>
                              <v-list-item-title class="bold s12">
                                <div style="width: 150px" class="ellipse-text text-uppercase">
                                  {{ item.productName }}
                                </div>
                                <template
                                  v-if="
                                    (item.productObj.reference_max == null || item.productObj.reference_max == -1) &&
                                    (item.productObj.reference_min == null || item.productObj.reference_min == -1)
                                  "
                                >
                                  <div class="res-bt reff-value">
                                    <slider-result
                                      :data="{
                                        type: 'C',
                                        all: item.productObj,
                                      }"
                                    />
                                    <span>{{ $t("page.profile.reff_value") }} </span>
                                  </div>
                                  <!-- <div v-if="!item.productObj.result_in_scale" class="bold s12 d-flex flex-column align-end upper hijau">
                                    {{ item.productObj.result_value }}
                                  </div> -->
                                </template>
                                <template v-else>
                                  <div class="slider-bt">
                                    <slider-result
                                      :data="{
                                        type: 'B',
                                        all: item.productObj,
                                      }"
                                    />
                                  </div>
                                </template>
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content
                              v-if="item.productObj.result_in_scale == true"
                            >
                            <!-- v-if="item.productObj.result_category != null" -->
                              <template
                                v-if="
                                  item.productObj.reference_max != null &&
                                  item.productObj.reference_min != null
                                "
                              >
                                <v-list-item-title
                                  class="bold s12 d-flex flex-column align-end upper"
                                  :class="{
                                    hijau:
                                      item.productObj.status == '',
                                      // typeof item.productObj
                                      //   .reference_operation == 'string'
                                      //   ? item.productObj.reference_min == -1 ||
                                      //     item.productObj.reference_min == null
                                      //     ? cekReferalOperation(
                                      //         item.productObj.reference_max,
                                      //         item.productObj.result_value,
                                      //         item.productObj
                                      //           .reference_operation
                                      //       )
                                      //     : cekReferalOperation(
                                      //         item.productObj.reference_min,
                                      //         item.productObj.result_value,
                                      //         item.productObj
                                      //           .reference_operation
                                      //       )
                                      //   : item.productObj.result_category.toLowerCase() ==
                                      //     'normal',
                                    merah:
                                      item.productObj.status != '',
                                      // typeof item.productObj
                                      //   .reference_operation == 'string'
                                      //   ? item.productObj.reference_min == -1 ||
                                      //     item.productObj.reference_min == null
                                      //     ? !cekReferalOperation(
                                      //         item.productObj.reference_max,
                                      //         item.productObj.result_value,
                                      //         item.productObj
                                      //           .reference_operation
                                      //       )
                                      //     : !cekReferalOperation(
                                      //         item.productObj.reference_min,
                                      //         item.productObj.result_value,
                                      //         item.productObj
                                      //           .reference_operation
                                      //       )
                                      //   : item.productObj.result_category.toLowerCase() !=
                                      //     'normal',
                                  }"
                                >
                                  <div>
                                    {{ item.productObj.result_category }}
                                  </div>
                                </v-list-item-title>
                              </template>
                              <template v-else>
                                <v-list-item-title
                                  class="bold s12 d-flex flex-column align-end upper"
                                  :class="{
                                    hijau:
                                    item.productObj.status == '',  
                                    // typeof item.productObj
                                      //   .reference_operation == 'string'
                                      //   ? item.productObj.reference_min == -1 ||
                                      //     item.productObj.reference_min == null
                                      //     ? cekReferalOperation(
                                      //         item.productObj.reference_max,
                                      //         item.productObj.result_value,
                                      //         item.productObj
                                      //           .reference_operation
                                      //       )
                                      //     : cekReferalOperation(
                                      //         item.productObj.reference_min,
                                      //         item.productObj.result_value,
                                      //         item.productObj
                                      //           .reference_operation
                                      //       )
                                      //   : item.productObj.result_category.toLowerCase() ==
                                      //     'normal',
                                    merah:
                                      item.productObj.status != '',
                                      // typeof item.productObj
                                      //   .reference_operation == 'string'
                                      //   ? item.productObj.reference_min == -1 ||
                                      //     item.productObj.reference_min == null
                                      //     ? !cekReferalOperation(
                                      //         item.productObj.reference_max,
                                      //         item.productObj.result_value,
                                      //         item.productObj
                                      //           .reference_operation
                                      //       )
                                      //     : !cekReferalOperation(
                                      //         item.productObj.reference_min,
                                      //         item.productObj.result_value,
                                      //         item.productObj
                                      //           .reference_operation
                                      //       )
                                      //   : item.productObj.result_category.toLowerCase() !=
                                      //     'normal',
                                  }"
                                >
                                  <div>
                                    {{ item.productObj.result_category }}
                                  </div>
                                  <div class="none-bt">
                                    {{ $t("page.profile.none_seen") }}
                                  </div>
                                </v-list-item-title>
                              </template>
                            </v-list-item-content>
                            <v-list-item-content
                              v-else
                            >
                              <template>
                                <v-list-item-title
                                  class="bold s12 d-flex flex-column align-end upper"
                                  :class="{hijau:item.productObj.status == '',merah:item.productObj.status != '',}"
                                >
                                  <div>
                                    <!-- {{ item.productObj.result_value }} -->
                                    <template v-if="item.productObj.status != 'OUT OF RANGE' && item.productObj.status != 'Oot Of Range'">
                                      {{ item.productObj.status }} 
                                    </template>
                                    <template v-else>
                                      WARNING
                                    </template>
                                  </div>
                                </v-list-item-title>
                              </template>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </template>
                      </v-virtual-scroll>
                    </v-row>
                  </v-col>
                  <v-col md="8" cols="12" v-if="dat.length <= 0">
                    <template>
                      <p class="no-record">{{ $t("no_data") }}</p>
                    </template>
                  </v-col>
                  <v-col md="8" cols="12" v-if="dat.length > 0">
                    <v-row>
                      <div class="box3 pl0">
                        <span class="ungu" style="font-size: 20px">
                          {{ $t("field.title_test_result_lead") }}
                          {{ content.productName }}
                          {{ $t("field.title_test_result_tail") }}</span
                        >
                      </div>
                    </v-row>
                    <v-row>
                      <v-col md="6" cols="12">
                        <v-row>
                          <div class="box2 pl0">
                            <span class="s14 ungu bold">
                              {{ content.productName }}
                            </span>
                            <div
                            style="margin: 40px 0;text-align: center;font-size: 14px;color:grey;"
                            v-if="
                                (content.reference_max == -1 || content.reference_max == null) &&
                                (content.reference_min == -1 || content.reference_min == null )
                              "
                            >
                              <slider-result
                                :data="{
                                  type: 'C',
                                  all: content,
                                }"
                              />
                              <span>{{ $t("page.profile.reff_value") }} </span>
                            </div>
                            <div
                              style="margin: 40px 0"
                              v-else
                            >
                              <slider-result
                                :data="{
                                  type: 'A',
                                  all: content,
                                }"
                              />
                              
                            </div>
                            <div
                              class="box4 d-flex justify-space-between align-center bb s12"
                            >
                              <div
                                class="d-flex flex-column bold s12"
                                style="width: 55%"
                              >
                                <span class="ellipse-text">
                                  {{ content.productName }}
                                </span>
                              </div>
                              <div
                                class="d-flex flex-column align-end bold upper"
                                :class="{hijau:content.ref_sts == '',merah:content.ref_sts != '',} "
                              >
                                <span>
                                  {{ content.productStatusCategory }} 
                                </span>
                              </div>
                            </div>
                            <div
                              class="box4 d-flex justify-space-between align-center bb s12"
                            >
                              <div class="d-flex flex-column bold">
                                <span>
                                  {{ $t("field.current_result") }}
                                </span>
                              </div>
                              <div
                                class="d-flex flex-column align-end hijau bold"
                                :class="{hijau:content.ref_sts == '',merah:content.ref_sts != '',} "
                              >
                                <span>
                                  {{ content.status }}
                                  {{ content.productCurrent }} 
                                  {{ content.productUnit }}
                                </span>
                              </div>
                            </div>
                            <div
                              class="box4 d-flex justify-space-between align-center s12"
                            >
                              <div class="d-flex flex-column">
                                <span>
                                  {{ $t("field.desired_range") }}
                                </span>
                              </div>
                              <div class="d-flex flex-column align-end">
                                <span>
                                  {{ content.productRange }}
                                  {{ content.productUnit }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </v-row>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-row>
                          <div class="box3 pl0" style="padding-top: 0">
                            <div>
                              <span class="s14 ungu bold">
                                {{ $t("field.what_is") }}
                                {{ content.productName }}?</span
                              >
                            </div>
                            <div class="s12" style="padding-top: 10px">
                              {{ content.productDesc }}
                            </div>
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" style="padding-top: 6%" class="pl0">
                        <div style="display: grid">
                          <span class="s14 ungu bold">{{
                            $t("field.performing_lab")
                          }}</span>
                          <span class="s12">{{ plant_name }}</span>
                          <span class="s12">{{ plant_address }}</span>
                        </div>
                        <div style="display: grid; padding-top: 10px">
                          <span class="s14 ungu bold">
                            {{ $t("field.lab_chief") }}</span
                          >
                          <span class="s12">{{ doctor_name }}</span>
                          <span class="s12">{{ doctor_info }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
             
             
            </div>

            <div class="display-f display-mobile">
              <v-col cols="12">
                <v-row>
                  <div class="box">
                    <span class="s12 biru bold">
                      {{ $t("page.profile.filter_res") }}
                    </span>
                    <div
                      id="crs"
                      :class="{ active: actived == 'all' }"
                      @click="filterResult('all')"
                    >
                      <div :class="{ boxArrow: actived == 'all' }"></div>
                      <div :class="{ arrow: actived == 'all' }"></div>
                      <div class="s12 sumHijau bold">
                        {{ total_res }}
                      </div>
                      <span class="s12">
                        {{ $t("page.profile.total_res") }}
                      </span>
                    </div>
                    <div
                      id="crs"
                      :class="{ active: actived == 'review' }"
                      @click="filterResult('review')"
                    >
                      <div :class="{ boxArrow: actived == 'review' }"></div>
                      <div :class="{ arrow: actived == 'review' }"></div>
                      <span class="s12 sumMerah bold">
                        {{ review_res }}
                      </span>
                      <span class="s12">
                        {{ $t("page.profile.review_res") }}
                      </span>
                    </div>
                    <div
                      id="crs"
                      :class="{ active: actived == 'normal' }"
                      style="padding-bottom: 10px"
                      @click="filterResult('normal')"
                    >
                      <div :class="{ boxArrow: actived == 'normal' }"></div>
                      <div :class="{ arrow: actived == 'normal' }"></div>
                      <span class="s12 sumHijau bold">
                        {{ expected_res }}
                      </span>
                      <span class="s12">
                        {{ $t("page.profile.expected_res") }}
                      </span>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </v-row>
                <div
                  v-if="dat.length <= 0"
                  style="padding-top: 20px; padding-bottom: 20px"
                >
                  <p class="no-record">{{ $t("no_data") }}</p>
                </div>
                <v-row style="padding-top: 0; margin-top: 0">
                  <v-expansion-panels
                    v-model="contentItem"
                    :class="{ 'my-list': dat.length > 0 }"
                  >
                    <v-expansion-panel v-for="(item, i) in dat" :key="i">
                      <v-expansion-panel-header>
                        <div style="width: 150px" class="ellipse-text bold s12">
                          {{ item.productName }}
                        </div>
                        <!-- slider -->
                        <template
                          v-if="
                            (item.productObj.reference_max == null || item.productObj.reference_max == -1) &&
                            (item.productObj.reference_min == null || item.productObj.reference_min == -1)
                          "
                        >
                          
                          <div class="res-bt reff-value">
                            <slider-result
                              :data="{
                                type: 'C',
                                all: item.productObj,
                              }"
                            />
                            <span class="display-dekstop">{{ $t("page.profile.reff_value") }} </span> 
                          </div>
                        </template>
                        <template v-else>
                          <div
                              class="slider-bt"
                              style="padding-top: 30px; width: 100%"
                            >
                                <!-- all: item.productObj, -->
                            <slider-result
                              :data="{
                                type: 'B',
                                all: item.productObj,
                              }"
                            />
                          </div>
                        </template>
                        <!-- status -->
                        <div
                          v-if="item.productObj.result_category != null"
                          class="status"
                        >
                          <template
                            v-if="
                              item.productObj.reference_max != null &&
                              item.productObj.reference_min != null
                            "
                          >
                            <div
                              class="bold s12 d-flex flex-column align-end upper"
                              :class="{
                                hijau:
                                  item.productObj.status == '',
                                  // typeof item.productObj.reference_operation ==
                                  // 'string'
                                  //   ? item.productObj.reference_min == -1 ||
                                  //     item.productObj.reference_min == null
                                  //     ? cekReferalOperation(
                                  //         item.productObj.reference_max,
                                  //         item.productObj.result_value,
                                  //         item.productObj.reference_operation
                                  //       )
                                  //     : cekReferalOperation(
                                  //         item.productObj.reference_min,
                                  //         item.productObj.result_value,
                                  //         item.productObj.reference_operation
                                  //       )
                                  //   : item.productObj.result_category.toLowerCase() ==
                                  //     'normal',
                                merah:
                                  item.productObj.status != '',
                                  // typeof item.productObj.reference_operation ==
                                  // 'string'
                                  //   ? item.productObj.reference_min == -1 ||
                                  //     item.productObj.reference_min == null
                                  //     ? !cekReferalOperation(
                                  //         item.productObj.reference_max,
                                  //         item.productObj.result_value,
                                  //         item.productObj.reference_operation
                                  //       )
                                  //     : !cekReferalOperation(
                                  //         item.productObj.reference_min,
                                  //         item.productObj.result_value,
                                  //         item.productObj.reference_operation
                                  //       )
                                  //   : item.productObj.result_category.toLowerCase() !=
                                  //     'normal',
                              }"
                            >
                              <div>
                                {{ item.productObj.result_category }}
                              </div>
                            </div>
                            <!-- <div
                              class="bold s12 d-flex flex-column align-end upper merah"
                              v-else
                            >
                              {{ item.productObj.result_category }}
                            </div> -->
                          </template>
                          <template v-else>
                            <v-list-item-title
                              class="bold s12 d-flex flex-column align-end upper"
                              :class="{
                                hijau:
                                  item.productObj.status == '',
                                  // typeof item.productObj.reference_operation ==
                                  // 'string'
                                  //   ? item.productObj.reference_min == -1 ||
                                  //     item.productObj.reference_min == null
                                  //     ? cekReferalOperation(
                                  //         item.productObj.reference_max,
                                  //         item.productObj.result_value,
                                  //         item.productObj.reference_operation
                                  //       )
                                  //     : cekReferalOperation(
                                  //         item.productObj.reference_min,
                                  //         item.productObj.result_value,
                                  //         item.productObj.reference_operation
                                  //       )
                                  //   : item.productObj.result_category.toLowerCase() ==
                                  //     'normal',
                                merah:
                                  item.productObj.status != '',
                                  // typeof item.productObj.reference_operation ==
                                  // 'string'
                                  //   ? item.productObj.reference_min == -1 ||
                                  //     item.productObj.reference_min == null
                                  //     ? !cekReferalOperation(
                                  //         item.productObj.reference_max,
                                  //         item.productObj.result_value,
                                  //         item.productObj.reference_operation
                                  //       )
                                  //     : !cekReferalOperation(
                                  //         item.productObj.reference_min,
                                  //         item.productObj.result_value,
                                  //         item.productObj.reference_operation
                                  //       )
                                  //   : item.productObj.result_category.toLowerCase() !=
                                  //     'normal',
                              }"
                            >
                              <div>
                                {{ item.productObj.result_category }}
                              </div>
                              <div class="none-bt">
                                {{ $t("page.profile.none_seen") }}
                              </div>
                            </v-list-item-title>
                            <!-- <v-list-item-title
                              class="bold s12 d-flex flex-column align-end upper merah"
                              v-else
                            >
                              <div>
                                {{ item.productObj.result_category }}
                              </div>
                              <div class="none-bt">
                                {{ $t("page.profile.none_seen") }}
                              </div>
                            </v-list-item-title> -->
                          </template>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-col>
                          <v-row>
                            <div class="box2 pl0">
                              <div
                                style="margin: 40px 0;text-align: center;font-size: 14px;color:grey;"
                                v-if="
                                  (content.reference_max == null || content.reference_max == -1) &&
                                  (content.reference_min == null || content.reference_min == -1) 
                                "
                              >
                                  <slider-result
                                    :data="{
                                      type: 'C',
                                      all: content,
                                    }"
                                  />
                                <span>{{ $t("page.profile.reff_value") }} </span>
                              </div>
                              <div
                                style="margin: 40px 0"
                                v-else
                              >
                                <slider-result
                                  :data="{
                                    type: 'A',
                                    all: content,
                                  }"
                                />
                              </div>
                              <div
                                class="box4 d-flex justify-space-between align-center bb s12"
                              >
                                <div
                                  class="d-flex flex-column bold s12"
                                  style="width: 55%"
                                >
                                  <span class="ellipse-text">
                                    {{ content.productName }}
                                  </span>
                                </div>
                                <div
                                  class="d-flex flex-column align-end bold upper"
                                  :class="{hijau:content.ref_sts == '',merah:content.ref_sts != '',} "
                                >
                                  <span>
                                    {{ content.productStatusCategory }} 
                                  </span>
                                </div>
                              </div>
                              <div
                                class="box4 d-flex justify-space-between align-center bb s12"
                              >
                                <div class="d-flex flex-column bold">
                                  <span>
                                    {{ $t("field.current_result") }}
                                  </span>
                                </div>
                                <div
                                  class="d-flex flex-column align-end hijau bold"
                                >
                                  <span>
                                    {{ content.productCurrent }}
                                    {{ content.productUnit }}
                                  </span>
                                </div>
                              </div>
                              <div
                                class="box4 d-flex justify-space-between align-center s12"
                              >
                                <div class="d-flex flex-column">
                                  <span>
                                    {{ $t("field.desired_range") }}
                                  </span>
                                </div>
                                <div class="d-flex flex-column align-end">
                                  <span>
                                    {{ content.productRange }}
                                    {{ content.productUnit }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <div>
                              <span class="s14 ungu bold">
                                {{ $t("field.what_is") }}
                                {{ content.productName }}?</span
                              >
                            </div>
                            <div class="s12" style="padding-top: 10px">
                              {{ content.productDesc }}
                            </div>
                          </v-row>
                        </v-col>
                        <!-- <div class="s12" style="padding-top: 10px">
                          {{ content.productDesc }}
                        </div> -->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-col style="padding: 0; border-top: 1px solid #ccc">
                    <v-row style="padding: 10px">
                      <v-col cols="12" style="padding-top: 6%" class="pl0">
                        <div style="display: grid">
                          <span class="s14 ungu bold">{{
                            $t("field.performing_lab")
                          }}</span>
                          <span class="s12">{{ plant_name }}</span>
                          <span class="s12">{{ plant_address }}</span>
                        </div>
                        <div style="display: grid; padding-top: 10px">
                          <span class="s14 ungu bold">
                            {{ $t("field.lab_chief") }}</span
                          >
                          <span class="s12">{{ doctor_name }}</span>
                          <span class="s12">{{ doctor_info }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </custom-section>
        </div>
      </div>
    </v-row>
  </v-col>
</template>

<script>
// import moment from "moment";
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import CustomSection from "@/components/v2/section/index.vue";
import SliderResult from "@/components/general/SliderResult.vue";
import DateSchedule from "@/components/v3/date/dateSchedule.vue";

export default {
  components: {
    // moment,
    // Auth,
    // API,
    SliderResult,
    CustomSection,
    DateSchedule,
  },
  data: () => ({
    medicalRecord: "btn-style-medical-record",
    listProductItem: [],
    listProductItemTmp: [],
    ready: false,
    content: {
      productName: "",
      productStatusCategory: "",
      productDesc: "",
      productCurrent: "",
      productUnit: "",
      productRange: "",
      reference_min: 0,
      reference_max: 0,
      result_value: 0,
      ref_sts: "",
    },
    contentMin: {
      productName: "",
      productStatusCategory: "",
      productDesc: "",
      productCurrent: "",
      productUnit: "",
      productRange: "",
      reference_min: 1232,
      reference_max: 12,
      result_value: 10,
      ref_sts: "",
    },
    contentMax: {
      productName: "",
      productStatusCategory: "",
      productDesc: "",
      productCurrent: "",
      productUnit: "",
      productRange: "",
      reference_min: 50,
      reference_max: null,
      result_value: 65,
      ref_sts: "",
    },
    contentNull: {
      productName: "",
      productStatusCategory: "",
      productDesc: "",
      productCurrent: "",
      productUnit: "",
      productRange: "",
      reference_min: null,
      reference_max: null,
      result_value: 227,
      ref_sts: "",
    },
    doctor_info: "",
    doctor_name: "",
    plant_address: "",
    plant_name: "",
    order_number: "",
    mcu: "",
    dat: [],
    total_res: 0,
    review_res: 0,
    expected_res: 0,
    actived: "all",
    contentItem: [],
  }),
  watch: {
    contentItem(id) {
      if (typeof id !== "undefined") {
        this.content.productCurrent = this.listProductItem[id].result_value;
        this.content.productUnit = this.listProductItem[id].item_unit;
        this.content.productRange = this.listProductItem[id].reference_text;
        if (this.$i18n.locale == "en") {
          this.content.productDesc =
            this.listProductItem[id].product_description_en != null
              ? this.listProductItem[id].product_description_en
              : "-";
          this.content.productName = this.listProductItem[id].product_name_en
            ? this.listProductItem[id].product_name_en
            : "-";
        } else {
          this.content.productDesc =
            this.listProductItem[id].product_description_id != null
              ? this.listProductItem[id].product_description_id
              : "-";
          this.content.productName = this.listProductItem[id].product_name_id
            ? this.listProductItem[id].product_name_id
            : "-";
        }
        this.content.reference_max = this.listProductItem[id].reference_max;
        this.content.reference_min = this.listProductItem[id].reference_min;
        this.content.result_value = this.listProductItem[id].result_value;
        this.content.productStatusCategory =
          this.listProductItem[id].result_category != null
            ? this.listProductItem[id].result_category
            : this.listProductItem[id].status;
            // : "-";
        this.content.ref_sts = this.listProductItem[id].status;
      }
    },
  },
  mounted() {
    this.$store.commit("setLoading", true);
    this.getData();

    this.$store.commit("setLoading", false);
  },
  methods: {
    async getData() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}lab-results/get-advance-chart-data`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode === 200 && res.results.lab_result !== undefined) {
          this.order_number = res.results.order_number;
          this.mcu = res.results.mcu;
          this.listProductItem = res.results.lab_result;
          this.listProductItemTmp = res.results.lab_result;
          this.content.productCurrent = this.listProductItem[0].result_value;
          this.content.productUnit = this.listProductItem[0].item_unit;
          this.content.productRange = this.listProductItem[0].reference_text;
          if (this.$i18n.locale == "en") {
            this.content.productDesc =
              this.listProductItem[0].product_description_en != null
                ? this.listProductItem[0].product_description_en
                : "-";
            this.content.productName = this.listProductItem[0].product_name_en
              ? this.listProductItem[0].product_name_en
              : "-";
          } else {
            this.content.productDesc =
              this.listProductItem[0].product_description_id != null
                ? this.listProductItem[0].product_description_id
                : "-";
            this.content.productName = this.listProductItem[0].product_name_id
              ? this.listProductItem[0].product_name_id
              : "-";
          }
          this.content.reference_max = this.listProductItem[0].reference_max;
          this.content.reference_min = this.listProductItem[0].reference_min;
          this.content.result_value = this.listProductItem[0].result_value;
          this.content.productStatusCategory =
            this.listProductItem[0].result_category != null
              ? this.listProductItem[0].result_category
              : this.listProductItem[0].status;
              // : "-";
          this.content.ref_sts = this.listProductItem[0].status;
          this.doctor_info =
            res.results.doctor_info != null ? res.results.doctor_info : "-";
          this.doctor_name =
            res.results.doctor_name != null ? res.results.doctor_name : "-";
          this.plant_address =
            res.results.plant_address != null ? res.results.plant_address : "-";
          this.plant_name =
            res.results.plant_name != null ? res.results.plant_name : "-";
          this.dat = this.datList();

          const resultExpected = this.listProductItem.filter(res => {
            return res.status == ''
            // if (typeof res.reference_operation == "string") {
            //   if (res.reference_min == -1 || res.reference_min == null) {
            //     return this.cekReferalOperation(
            //       res.reference_max,
            //       res.result_value,
            //       res.reference_operation
            //     );
            //   } else {
            //     return this.cekReferalOperation(
            //       res.reference_min,
            //       res.result_value,
            //       res.reference_operation
            //     );
            //   }
            // } else {
            //   if (typeof res.result_category == "string") {
            //     return res.result_category == "NORMAL";
            //   }
            //   return false;
            // }
          });
          // console.log(resultExpected)
          const resultReview = this.listProductItem.filter(
            res => {
              return res.status != ''
              // typeof res.reference_operation == "string"
              //   ? res.reference_min == -1 || res.reference_min == null
              //     ? !this.cekReferalOperation(
              //         res.reference_max,
              //         res.result_value,
              //         res.reference_operation
              //       )
              //     : !this.cekReferalOperation(
              //         res.reference_min,
              //         res.result_value,
              //         res.reference_operation
              //       )
              //   : res.result_category != "NORMAL"

            // typeof res.reference_operation == "string"
            //   ? res.reference_min == -1 || res.reference_min == null
            //     ? !this.cekReferalOperation(
            //         res.reference_max,
            //         res.result_value,
            //         res.reference_operation
            //       )
            //     : !this.cekReferalOperation(
            //         res.reference_min,
            //         res.result_value,
            //         res.reference_operation
            //       )
            //   : typeof res.result_category == "string" ? res.result_category != "NORMAL" : false
          });

          this.total_res = this.listProductItem.length;
          this.expected_res = resultExpected.length;
          this.review_res = resultReview.length;
          this.ready = true;
        } else {
          this.ready = false;
        }
      } catch (error) {
        this.ready = false;
      }
    },
    onClickItem(par) {
      const item = this.listProductItem.find(
        element => element.product_name_id == par
      );
      this.content.productCurrent = item.result_value;
      this.content.productUnit = item.item_unit;
      this.content.productRange = item.reference_text;
      if (this.$i18n.locale == "en") {
        this.content.productName =
          item.product_name_en != null ? item.product_name_en : "-";
        this.content.productDesc =
          item.product_description_en != null
            ? item.product_description_en
            : "-";
      } else {
        this.content.productName =
          item.product_name_id != null ? item.product_name_id : "-";
        this.content.productDesc =
          item.product_description_id != null
            ? item.product_description_id
            : "-";
      }
      this.content.reference_max = item.reference_max;
      this.content.reference_min = item.reference_min;
      this.content.result_value = item.result_value;
      this.content.productStatusCategory =
        item.result_category != null ? item.result_category : item.status;
        //"-";
      this.content.ref_sts = item.status;  
    },
    filterResult(par) {
      this.actived = par;
      if (par == "normal") {
        this.listProductItem = this.listProductItemTmp;
        const resultEx = this.listProductItem.filter(
          res =>
            // typeof res.reference_operation == "string"
            //   ? res.reference_min == -1 || res.reference_min == null
            //     ? this.cekReferalOperation(
            //         res.reference_max,
            //         res.result_value,
            //         res.reference_operation
            //       )
            //     : this.cekReferalOperation(
            //         res.reference_min,
            //         res.result_value,
            //         res.reference_operation
            //       )
            //   : res.result_category == "NORMAL"
          //res.result_category == "NORMAL"
            res.status == ""
        );
        this.listProductItem = resultEx;
        this.content.productStatusCategory = this.listProductItem[0].result_category != null
            ? this.listProductItem[0].result_category
            : this.listProductItem[0].status;
        this.content.ref_sts = this.listProductItem[0].status;
      }
      if (par == "review") {
        this.listProductItem = this.listProductItemTmp;
        const resultRe = this.listProductItem.filter(
          res =>
            // typeof res.reference_operation == "string"
            //   ? res.reference_min == -1 || res.reference_min == null
            //     ? !this.cekReferalOperation(
            //         res.reference_max,
            //         res.result_value,
            //         res.reference_operation
            //       )
            //     : !this.cekReferalOperation(
            //         res.reference_min,
            //         res.result_value,
            //         res.reference_operation
            //       )
            //   : res.result_category != "NORMAL"
          res.status != ""
        );
        this.listProductItem = resultRe;
        this.content.productStatusCategory = this.listProductItem[0].result_category != null
            ? this.listProductItem[0].result_category
            : this.listProductItem[0].status;
        this.content.ref_sts = this.listProductItem[0].status;    
      }
      if (par == "all") {
        this.listProductItem = this.listProductItemTmp;
        this.content.productStatusCategory = this.listProductItem[0].result_category != null
            ? this.listProductItem[0].result_category
            : this.listProductItem[0].status;
        this.content.ref_sts = this.listProductItem[0].status;
      }
      if (this.listProductItem.length > 0) {
        this.dat = this.datList();
        this.content.productCurrent = this.listProductItem[0].result_value;
        this.content.productUnit = this.listProductItem[0].item_unit;
        this.content.productRange = this.listProductItem[0].reference_text;
        if (this.$i18n.locale == "en") {
          this.content.productDesc =
            this.listProductItem[0].product_description_en != null
              ? this.listProductItem[0].product_description_en
              : "-";
          this.content.productName = this.listProductItem[0].product_name_en
            ? this.listProductItem[0].product_name_en
            : "-";
        } else {
          this.content.productDesc =
            this.listProductItem[0].product_description_id != null
              ? this.listProductItem[0].product_description_id
              : "-";
          this.content.productName = this.listProductItem[0].product_name_id
            ? this.listProductItem[0].product_name_id
            : "-";
        }
        this.content.reference_max = this.listProductItem[0].reference_max;
        this.content.reference_min = this.listProductItem[0].reference_min;
        this.content.result_value = this.listProductItem[0].result_value;
      } else {
        this.dat = [];
      }
    },
    datList() {
      const item = this.listProductItem.length;
      return Array.from({ length: item }, (k, v) => {
        const productObj = this.listProductItem[v];
        let prodName = "";
        if (this.$i18n.locale == "en") {
          prodName =
            productObj.product_name_en != null
              ? productObj.product_name_en
              : "-";
        } else {
          prodName =
            productObj.product_name_id != null
              ? productObj.product_name_id
              : "-";
        }

        return {
          productObj: productObj,
          productName: prodName,
        };
      });
    },
    openReport(item, type, mcu) {
      let report = "";
      if (mcu == true) {
        report = "get-mcureport";
      } else {
        report = "get-labreport";
      }
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/${report}?order_number=${item}&type=${type}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    cekReferalOperation(v1, v2, condition) {
      var cond = false;
      switch (condition) {
        case ">=":
          cond = v1 >= v2;
          break;
        case "<=":
          cond = v1 <= v2;
          break;
        case ">":
          cond = v1 > v2;
          break;
        case "<":
          cond = v1 < v2;
          break;
        case "==":
          cond = v1 == v2;
          break;
        case "!=":
          cond = v1 != v2;
          break;
        default:
          cond = false;
      }
      return cond;
    },
  },
  //     computed: {
  //       dat () {
  //         const item = this.listProductItem.length;
  //          return Array.from({ length: item }, (k,v) => {
  //           const productObj = this.listProductItem[v];
  //           return {
  //             productObj: productObj,
  //           }
  //         })
  //       },
  //   },
};
</script>
<style lang="scss">
// .v-sheet.v-card:not(.v-sheet--outlined){
//   box-shadow: 0px 6px 18px rgb(0 0 0 / 10%);
// }
@media screen and (max-width: 767px) {
  .last-med-record {
    padding: 20px !important;
  }
}

.last-med-record .v-expansion-panel-header {
  padding: 0;
  &__icon {
    display: none !important;
  }
}
.v-expansion-panel-content {
  &__wrap {
    padding: 0;
  }
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-top: 1px solid #cccc !important ;
  border-bottom: 1px solid #cccc !important ;
}
.v-expansion-panel--active > .v-expansion-panel-header--active > .slider-bt {
  display: none;
}
.v-expansion-panel--active > .v-expansion-panel-header--active > .status {
  display: none;
}
.v-expansion-panel--active > .v-expansion-panel-header--active > .res-bt {
  display: none;
}
.my-list {
  height: calc(52vh - 10px);
  overflow-y: auto;
}
</style>

<style lang="scss" scoped>
//css tampilan baru
.popup {
  padding: 8px;
  background: white;
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 16px;
  a {
    text-decoration: none;
    color: black;
  }
}
.title-grid {
  display: grid;
}
.title-grid-h {
  text-align: left;
}
.title-grid-c {
  text-align: left;
  font-size: 8px;
}
.mt5 {
  margin-top: 5px !important;
}
.mb10 {
  margin-bottom: -10px;
}
.mb5 {
  margin-bottom: 5px !important;
}
.ungu {
  color: #233f78;
}
.biru {
  color: #00a4b3;
}
.hijau {
  color: #719e51;
}
.merah {
  color: #f60707;
}
.s14 {
  font-size: 14px;
}
.s16 {
  font-size: 16px;
}
.s12 {
  font-size: 12px;
}
.s10 {
  font-size: 10px;
}
.bold {
  font-weight: 600;
}
.rowPad {
  display: block;
  border-bottom: 1px solid #cccc;
  padding-bottom: 10px;
}
.box {
  border-right: 1px solid #cccc;
  padding-top: 10px;
  width: 100%;
  margin-top: 3px;
  padding-bottom: 10px;
}
.box2 {
  border-right: 1px solid #cccc;
  padding: 0 15px 15px 15px;
  width: 100%;
  margin-top: 3px;
}
.box3 {
  padding: 15px;
  width: 100%;
}
.box4 {
  padding: 10px 10px 10px 0;
  width: 100%;
  display: flex;
}
.bt {
  border-top: 1px solid #cccc;
}
.bb {
  border-bottom: 1px solid #cccc;
}
.br {
  border-right: 1px solid #cccc;
}
.bl {
  border-left: 1px solid #cccc;
  // padding-top: 50px;
  // margin-left: -1px;
}
.flexSlide {
  display: flex;
  width: 200px;
  position: relative;
}

.flexSlide > div {
  flex: 0 0 50%;
  border: 1px solid black;
  box-sizing: border-box;
}

.slide-2 {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}
.ellipse-text {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lower {
  text-transform: lowercase;
}
.capital {
  text-transform: capitalize;
}
.upper {
  text-transform: uppercase;
}
.slider-bt {
  padding-top: 10px;
  width: 95%;
  position: absolute;
}
.res-bt {
  width: 95%;
  position: absolute;
}
.reff-value{
  text-align: center;
  font-size: 12px;
  color: grey;
}
.none-bt {
  padding-top: 15px;
  position: absolute;
}
.sumHijau {
  border: 1.5px solid #719e51;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding-top: 2px;
  padding-right: 2px;
}
.sumMerah {
  border: 1.5px solid #f60707;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding-top: 2px;
  padding-right: 2px;
}
#crs {
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
  &.active {
    font-weight: 700;
  }
}

.boxArrow {
  width: 7px;
  height: 15px;
  background: #00a4b3;
  position: absolute;
  margin-left: -20px;
  margin-top: 5px;
}
.arrow {
  border-top: solid 7px white;
  border-left: solid 10px #00a4b3;
  border-bottom: solid 8px white;
  margin-left: -15px;
  margin-top: 5px;
  position: absolute;
}
.display-f {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .pt-pb-0 {
    padding: 30px 0 0 0;
  }
  .display-f {
    display: block !important;
  }
  .box {
    border-right: none;
  }
  .box2 {
    border-right: none;
  }
  .br {
    border-right: none;
  }
  .pl0 {
    padding-left: 0 !important;
  }
  .display-dekstop {
    display: none !important;
  }
  .display-mobile {
    display: block !important;
  }
  .mdi-dots-horizontal::before{
    font-size: 42px;
  }
  .background-transparant{
    background-color: unset !important;
    box-shadow: unset !important;
  }
  .background-transparant::before{
    background-color: unset !important;
  }
}
@media only screen and (max-width: 767px) {
  .res-bt{
    width: 100% !important;
    padding-top: 40px !important;
  }
  .my-list{
    height: unset !important;
  }
}
.display-dekstop {
  display: block;
}
.display-mobile {
  display: none;
}
</style>
