<template>
  <v-card-text class="px-3 px-md-5">
    <v-row>
      <v-col cols="12">
        <h3 class="black--text">{{ $t("R.result") }}</h3>
        <table>
          <tr>
            <td class="pr-3 text-uppercase">{{ $t("R.final_result") }}</td>
            <td>: {{ result.data.result.finale_result_name }}</td>
          </tr>
          <tr>
            <td class="pr-3 text-uppercase">
              {{ $t("R.final_health_status") }}
            </td>
            <td>: {{ result.data.result.final_health_name }}</td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12">
        <h3 class="black--text">{{ $t("R.result_details") }}</h3>
        <table>
          <tr v-for="(detail, index) of result.data.result.detail" :key="index">
            <td>
              {{
                $i18n.locale == "id"
                  ? detail.description
                  : detail[`description_${$i18n.locale}`]
              }}
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12">
        <h3 class="black--text">{{ $t("R.recommendation") }}</h3>
        <table>
          <tr
            v-for="(recomendation, index) of result.data.result.recomendation"
            :key="index"
          >
            <td>
              {{ recomendation.text }}
            </td>
          </tr>
        </table>
      </v-col>
      <v-col
        cols="12"
        v-if="result.data.doctor_examination.detail.alergies.length"
      >
        <h3 class="black--text">{{ $t("R.medical_history") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table class="super-dense" mobile-breakpoint="0">
                <template v-slot:default>
                  <tbody class="no-pad tbl">
                    <tr class="grey-color head-vir2">
                      <th class="text-center tb-wide">
                        {{ $t("R.current_complaint") }}
                      </th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.allergy") }}
                      </th>
                    </tr>
                    <tr>
                      <td>
                        {{
                          result.data.doctor_examination.detail.anamnesis
                            .description
                        }}
                      </td>
                      <td class="text-center">
                        <span
                          v-for="(alergy, index) of result.data
                            .doctor_examination.detail.alergies"
                          :key="index"
                        >
                          {{ alergy.description }}
                          {{
                            index !=
                            result.data.doctor_examination.detail.alergies
                              .length -
                              1
                              ? ","
                              : ""
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
      <v-col
        cols="12"
        v-if="
          result.data.doctor_examination.detail.test_diseases_history.length > 0
        "
      >
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table class="super-dense" mobile-breakpoint="0">
                <template v-slot:default>
                  <tbody class="no-pad tbl">
                    <tr class="grey-color head-vir2">
                      <th class="text-center tb-wide">
                        {{ $t("R.diseases") }}
                      </th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.start_date") }}
                      </th>
                      <th class="text-center tb-wide2">{{ $t("R.status") }}</th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.description") }}
                      </th>
                    </tr>
                    <template
                      v-if="
                        result.data.doctor_examination.detail
                          .test_diseases_history.length > 0
                      "
                    >
                      <tr
                        v-for="(diseases, index) of result.data
                          .doctor_examination.detail.test_diseases_history"
                        :key="index"
                      >
                        <td>
                          {{ diseases.name }}
                        </td>
                        <td class="text-center">
                          {{ formatDate(diseases.test_date) }}
                        </td>
                        <td class="text-center">
                          {{ diseases.status }}
                        </td>
                        <td class="text-center">
                          {{ diseases.description }}
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="4" class="text-center">
                        {{ $t("no_data") }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
      <v-col
        cols="12"
        v-if="
          result.data.doctor_examination.detail.test_exposure_history.length > 0
        "
      >
        <h3 class="black--text">{{ $t("R.history_exposure") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table class="super-dense" mobile-breakpoint="0">
                <template v-slot:default>
                  <tbody class="no-pad tbl">
                    <tr class="grey-color head-vir2">
                      <th class="text-center tb-wide">{{ $t("R.company") }}</th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.job_function") }}
                      </th>
                      <th class="text-center tb-wide2">{{ $t("R.period") }}</th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.exposure") }}
                      </th>
                    </tr>
                    <template
                      v-if="
                        result.data.doctor_examination.detail
                          .test_exposure_history.length > 0
                      "
                    >
                      <tr
                        v-for="(exposure, index) of result.data
                          .doctor_examination.detail.test_exposure_history"
                        :key="index"
                      >
                        <td class="text-no-wrap">
                          {{ exposure.company }}
                        </td>
                        <td class="text-no-wrap text-center">
                          {{ exposure.job_function }}
                        </td>
                        <td class="text-no-wrap text-center">
                          {{ exposure.period }}
                        </td>
                        <td class="text-no-wrap text-center">
                          {{ exposure.object }}
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="4" class="text-center">
                        {{ $t("no_data") }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
      <v-col
        cols="12"
        v-if="result.data.doctor_examination.detail.test_exposure.length > 0"
      >
        <h3 class="black--text">{{ $t("R.current_exposure") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table class="super-dense" mobile-breakpoint="0">
                <template v-slot:default>
                  <tbody class="no-pad tbl">
                    <tr class="grey-color head-vir2">
                      <th class="text-center tb-wide">
                        {{ $t("R.exposure") }}
                      </th>
                      <th class="text-center tb-wide2">{{ $t("R.status") }}</th>
                    </tr>
                    <template
                      v-if="
                        result.data.doctor_examination.detail.test_exposure
                          .length > 0
                      "
                    >
                      <tr
                        v-for="(exposure, index) of result.data
                          .doctor_examination.detail.test_exposure"
                        :key="index"
                      >
                        <td class="text-center">
                          {{ exposure.object }}
                        </td>
                        <td class="text-center">
                          {{ exposure.description }}
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="5" class="text-center">
                        {{ $t("R.no_data") }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
      <v-col
        cols="12"
        v-if="result.data.doctor_examination.detail.habits.length > 0"
      >
        <h3 class="black--text">{{ $t("R.habit") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table class="super-dense" mobile-breakpoint="0">
                <template v-slot:default>
                  <tbody class="no-pad tbl">
                    <tr class="grey-color head-vir2">
                      <th class="text-center tb-wide">{{ $t("R.name") }}</th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.start_date") }}
                      </th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.frequency") }}
                      </th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.frequency_period") }}
                      </th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.description") }}
                      </th>
                    </tr>
                    <template
                      v-if="
                        result.data.doctor_examination.detail.habits.length > 0
                      "
                    >
                      <tr
                        v-for="(habits, index) of result.data.doctor_examination
                          .detail.habits"
                        :key="index"
                      >
                        <td>
                          {{ habits.mcu_habits_name }}
                        </td>
                        <td class="text-center">
                          {{ formatDate(habits.start_date) }}
                        </td>
                        <td class="text-center">
                          {{ habits.frequency }}
                        </td>
                        <td class="text-center">
                          {{ habits.frequency_period }}
                        </td>
                        <td class="text-center">
                          {{ habits.description }}
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="5" class="text-center">
                        {{ $t("R.no_data") }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
      <v-col cols="12" v-if="listPhysical != null">
        <h3 class="black--text">{{ $t("R.physical_examination") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <v-simple-table class="super-dense no-hover-table">
              <template v-slot:default>
                <thead class="no-pad">
                  <tr class="grey-color head-vir2">
                    <th class="text-center tb-wide" style="width: 30%">
                      {{ $t("R.organ") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 20%">
                      {{ $t("R.result") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 50%">
                      {{ $t("R.description") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="listPhysical != null">
                    <!-- CHEST -->
                    <template v-if="listPhysical['CHEST'] != undefined">
                      <tr class="grey-color">
                        <td colspan="3" class="category-blue">
                          {{ $t("R.chest") }}
                        </td>
                      </tr>
                      <tr
                        v-for="item of listPhysical['CHEST']"
                        :key="item.code"
                      >
                        <td>
                          <span class="pl-3">{{
                            item.mcu_physical_detail_name
                          }}</span>
                        </td>
                        <td
                          :class="{
                            'abnormal-label': item.status == 'abnormal',
                            'normal-label': item.status == 'normal',
                          }"
                        >
                          {{ item.status }}
                        </td>
                        <td>
                          {{
                            item.description == null ? "-" : item.description
                          }}
                        </td>
                      </tr>
                    </template>
                    <!-- MOTORIC AND PHYSIOLOGY -->
                    <template
                      v-if="listPhysical['MOTORIC AND PHYSIOLOGY'] != undefined"
                    >
                      <tr class="grey-color">
                        <td colspan="3" class="category-blue text-uppercase">
                          {{ $t("R.motoric_physiology") }}
                        </td>
                      </tr>
                      <tr
                        v-for="item of listPhysical['MOTORIC AND PHYSIOLOGY']"
                        :key="item.code"
                      >
                        <td>
                          <span class="pl-3">{{
                            item.mcu_physical_detail_name
                          }}</span>
                        </td>
                        <td
                          :class="{
                            'abnormal-label': item.status == 'abnormal',
                            'normal-label': item.status == 'normal',
                          }"
                        >
                          {{ item.status }}
                        </td>
                        <td>
                          {{
                            item.description == null ? "-" : item.description
                          }}
                        </td>
                      </tr>
                    </template>

                    <!-- BRAIN -->
                    <template v-if="listPhysical['OTAK'] != undefined">
                      <tr class="grey-color">
                        <td colspan="3" class="category-blue text-uppercase">
                          OTAK
                        </td>
                      </tr>
                      <tr v-for="item of listPhysical['OTAK']" :key="item.code">
                        <td>
                          <span class="pl-3">{{
                            item.mcu_physical_detail_name
                          }}</span>
                        </td>
                        <td
                          :class="{
                            'abnormal-label': item.status == 'abnormal',
                            'normal-label': item.status == 'normal',
                          }"
                        >
                          {{ item.status }}
                        </td>
                        <td>
                          {{
                            item.description == null ? "-" : item.description
                          }}
                        </td>
                      </tr>
                    </template>
                    <!-- HEAD -->
                    <template v-if="listPhysical['HEAD'] != undefined">
                      <tr class="grey-color">
                        <td colspan="3" class="category-blue text-uppercase">
                          {{ $t("R.head") }}
                        </td>
                      </tr>
                      <tr v-for="item of listPhysical['HEAD']" :key="item.code">
                        <td>
                          <span class="pl-3">{{
                            item.mcu_physical_detail_name
                          }}</span>
                        </td>
                        <td
                          :class="{
                            'abnormal-label': item.status == 'abnormal',
                            'normal-label': item.status == 'normal',
                          }"
                        >
                          {{ item.status }}
                        </td>
                        <td>
                          {{
                            item.description == null ? "-" : item.description
                          }}
                        </td>
                      </tr>
                    </template>
                    <!-- SKIN AND NAIL -->
                    <template v-if="listPhysical['SKIN AND NAIL'] != undefined">
                      <tr class="grey-color">
                        <td colspan="3" class="category-blue text-uppercase">
                          {{ $t("R.skin_nails") }}
                        </td>
                      </tr>
                      <tr
                        v-for="item of listPhysical['SKIN AND NAIL']"
                        :key="item.code"
                      >
                        <td>
                          <span class="pl-3">{{
                            item.mcu_physical_detail_name
                          }}</span>
                        </td>
                        <td
                          :class="{
                            'abnormal-label': item.status == 'abnormal',
                            'normal-label': item.status == 'normal',
                          }"
                        >
                          {{ item.status }}
                        </td>
                        <td>
                          {{
                            item.description == null ? "-" : item.description
                          }}
                        </td>
                      </tr>
                    </template>
                  </template>
                  <tr v-else>
                    <td colspan="3">
                      {{ $t("R.no_data") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </card>
      </v-col>
      <v-col
        cols="12"
        v-if="result.data.doctor_examination.detail.visus.length > 0"
      >
        <h3 class="black--text">{{ $t("R.visus_observation") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table class="super-dense" mobile-breakpoint="0">
                <template v-slot:default>
                  <tbody class="no-pad tbl">
                    <tr class="grey-color head-vir2">
                      <th class="text-center tb-wide">{{ $t("R.name") }}</th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.right") }} (OD)
                      </th>
                      <th class="text-center tb-wide2">
                        {{ $t("R.left") }} (OS)
                      </th>
                    </tr>
                    <template
                      v-if="
                        result.data.doctor_examination.detail.visus.length > 0
                      "
                    >
                      <tr
                        v-for="(visus, index) of result.data.doctor_examination
                          .detail.visus"
                        :key="index"
                      >
                        <td class="text-no-wrap">
                          {{ visus[`name_${$i18n.locale}`] }}
                        </td>
                        <template v-if="visus.value.right != undefined">
                          <td class="text-no-wrap text-center">
                            {{ visus.value.right }}
                          </td>
                          <td class="text-no-wrap text-center">
                            {{ visus.value.left }}
                          </td>
                        </template>
                        <td v-else colspan="2" class="text-no-wrap text-center">
                          {{ visus.value }}
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="3">
                        {{ $t("R.no_data") }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import Card from "@/components/v2/card/index.vue";
import moment from "moment";

export default {
  components: {
    Card,
  },
  props: {
    result: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    listPhysical: null,
  }),
  created() {
    const checkPhysic =
      this.result.data.doctor_examination.detail.physical.filter(item => {
        return item.id != null;
      });
    if (checkPhysic.length > 0) {
      let groupedPhysical =
        this.result.data.doctor_examination.detail.physical.reduce(
          (acc, curr) => {
            if (!acc[curr.mcu_physical_object]) {
              acc[curr.mcu_physical_object] = [];
            }
            acc[curr.mcu_physical_object].push(curr);
            return acc;
          },
          {}
        );
      this.listPhysical = groupedPhysical;
    }
  },
  methods: {
    formatDate(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}
.v-data-table table tr td,
.v-data-table table tr th,
table tr td,
table tr th {
  font-size: 12px !important;
}
.normal-label {
  text-transform: capitalize;
  color: $green;
}
.abnormal-label {
  color: $red_2;
  text-transform: capitalize;
}
</style>
