<template>
  <v-form ref="formRating" lazy-validation>
    <v-card>
      <v-card-title>{{ $t("give_rating") }}</v-card-title>
      <v-divider class="mt-1"></v-divider>
      <v-card-text style="padding-bottom: 3px !important">
        <p class="text-center">
          {{ $t("page.profile.how_was_the_service") }}<br />{{
            $t("page.profile.value_rating")
          }}
        </p>
        <div class="text-center mt-3 mb-4">
          <v-rating
            v-model="payload.rating"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$ratingFull"
            hover
            large
          ></v-rating>
        </div>
        <v-textarea
          ref="comment"
          v-model="payload.comment"
          :rules="[
            () =>
              !!payload.comment ||
              `${$t('field.comment')} ${$t('is_required')}`,
          ]"
          solo
          name="comment"
          :label="$t('page.profile.write_your_review_here')"
          row-height="5"
        ></v-textarea>
      </v-card-text>
      <v-divider class="mt-1"></v-divider>
      <v-card-actions class="justify-end">
        <v-btn text @click="closeEdit"> {{ $t("cancel") }} </v-btn>
        <v-btn color="#00a4b3" text @click="saveEdit">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
export default {
  data: () => ({
    payload: {
      rating: 5,
      comment: "",
    },
  }),
  props: {
    data: { type: Object, default: null },
  },
  created() {
    this.payload.order_number = this.data.order_number;
  },
  methods: {
    closeEdit() {
      this.$emit("closeEdit");
    },
    async saveEdit() {
      if (this.$refs.formRating.validate() == true) {
        try {
          let form = {
            order_number:
              typeof this.payload.order_number !== "undefined"
                ? this.payload.order_number
                : "",
            rating:
              typeof this.payload.rating !== "undefined"
                ? this.payload.rating
                : "",
            comment:
              typeof this.payload.comment !== "undefined"
                ? this.payload.comment.toString().toUpperCase()
                : "",
          };
          // Begin api request
          const res = await API.post(
            `${process.env.VUE_APP_API_TRUST}invoices/save-rating`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [form]
          );

          if (res.statusCode === 200) {
            this.toast("success", res.message);
            this.$emit("updateOrderList");
          } else if (res.statusCode == 403) {
            Object.keys(res.errors).forEach(element => {
              this.toast(
                "error",
                typeof res.errors[element] == "object"
                  ? res.errors[element][0]
                  : res.errors[element]
              );
            });
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
          }
        } catch (error) {
          this.$swal({
            icon: "error",
            text: this.$t("error_try_again_latter"),
          });
        }
        this.closeEdit();
      }
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
  },
};
</script>

<style></style>
