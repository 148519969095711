var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"last-med-record filterMedicalNew"},[_c('v-row',[_c('div',{staticClass:"col col-12 pt-pb-0"},[_c('div',{staticClass:"v-card v-sheet theme--light"},[_c('custom-section',{staticClass:"section"},[_c('v-row',{staticClass:"rowPad display-dekstop",attrs:{"dense":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-4"},[_c('span',{staticClass:"ungu s16 bold"},[_vm._v(" "+_vm._s(_vm.$t("page.profile.medical_record"))+" ")]),_c('div',[_c('span',{staticClass:"font-weight-bold",on:{"click":function($event){return _vm.showDialogFilterMedicalRecord()}}},[_c('v-icon',{attrs:{"color":"#00a4b3"}},[_vm._v(" mdi-filter ")])],1)])])]),_c('div',{staticClass:"display-f display-dekstop"},[_c('v-data-table',{staticClass:"table-valign-top-mobile table-medical-info",attrs:{"dense":"","headers":_vm.headers,"items":_vm.medicalResult,"item-key":"code","items-per-page":-1,"footer-props":_vm.footerProps,"mobile-breakpoint":"0","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticClass:"td-stretch link",staticStyle:{"width":"150px"}},[_c('span',{staticClass:"link-detail text-uppercase text-no-wrap crs-chart text-title-mr",on:{"click":function($event){return _vm.showDialogChart(item.code)}}},[_vm._v(" "+_vm._s(item[`name_${_vm.$i18n.locale}`] != "" ? item[`name_${_vm.$i18n.locale}`] : item.code)+" ")])]),_c('td',{staticClass:"text-uppercase",attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(item.current_item_unit != null ? item.current_item_unit : "-")+" ")]),(
                      item.previous_value != null &&
                      !isNaN(item.previous_value)
                    )?_c('td',{staticClass:"text-right",attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(parseFloat(item.previous_value).toFixed(2))+" ")]):_c('td',{staticClass:"text-left",attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(_vm.$t("no_data"))+" ")]),_c('td',{staticClass:"text-right",attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(item.current_value != null && !isNaN(item.current_value) ? parseFloat(item.current_value).toFixed(2) : _vm.$t("no_data"))+" ")]),_c('td',{staticClass:"text-right",attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(item.previous_value == null || item.current_value == null || isNaN(item.previous_value) || isNaN(item.current_value) ? "-" : parseFloat( parseFloat(item.current_value) - parseFloat( item.previous_value != null ? item.previous_value : 0 ).toFixed(2) ).toFixed(2))+" ")]),_c('td',{staticClass:"text-uppercase",class:{
                      'text-danger':
                        item.reference_min != null &&
                        item.reference_max != null
                          ? parseFloat(item.current_value) >
                              parseFloat(item.reference_max) ||
                            parseFloat(item.current_value) <
                              parseFloat(item.reference_min)
                          : false,
                      'text-success':
                        item.reference_min != null &&
                        item.reference_max != null
                          ? parseFloat(item.current_value) >=
                              parseFloat(item.reference_min) &&
                            parseFloat(item.current_value) <=
                              parseFloat(item.reference_max)
                          : false,
                    },attrs:{"valign":"middle"}},[_vm._v(" "+_vm._s(item.current_value == null ? "-" : item.reference_min != null && item.reference_max != null ? parseFloat(item.current_value) >= parseFloat(item.reference_min) && parseFloat(item.reference_max) >= parseFloat(item.current_value) ? _vm.$t("good") : _vm.$t("warning") : "-")+" ")]),_c('td',{class:{ 'height-line': item.value.length == 1 }},[_c('span',{staticClass:"link-detail text-uppercase crs-chart",on:{"click":function($event){return _vm.showDialogChart(item.code)}}},[_c('ChartMedicalRecord',{attrs:{"data":_vm.getDataChart(item.value)}})],1)])])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},on:{"click:outside":_vm.onClickOutside,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onClickOutside.apply(null, arguments)}},model:{value:(_vm.dialogChart),callback:function ($$v) {_vm.dialogChart=$$v},expression:"dialogChart"}},[_c('DetailChartMedicalRecord',{attrs:{"data":_vm.itemKey}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},on:{"click:outside":function($event){return _vm.closeFilterMedicalRecord()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeFilterMedicalRecord()}},model:{value:(_vm.dialogFilterList),callback:function ($$v) {_vm.dialogFilterList=$$v},expression:"dialogFilterList"}},[_c('dialog-filter-medical-record',{attrs:{"data":_vm.dataTmpMedicalRecord},on:{"closeReschedule":function($event){return _vm.closeFilterMedicalRecord()},"addFilter":code => _vm.newFilter(code)}})],1)],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }