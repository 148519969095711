<template>
  <div >
    <v-card>
      <CustomSection class="pa-0">
        <v-container>
          <v-row>
            <heading-card class="title" :title="nameData" />
          </v-row>
        </v-container>  
        <v-container>
          <v-row>
            <v-col>
              <ApexMedicalChart
                :data="graphData"
                v-if="graphData != null"
                :activeRange="dateFilterDefaultValue"
              />
            </v-col>
          </v-row>
        </v-container>
      </CustomSection>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import ApexMedicalChart from "@/views/v2/Patient/Chart/ApexMedicalChart.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";

export default {
  components: {
    CustomSection,
    ApexMedicalChart,
    HeadingCard,
  },
  props: {
    graphData: {
      type: Array,
      default: () => [],
    },
    nameData:{
      type: String,
      default: "",
    }
  },
  data: () => ({
    dateFilterDefaultValue: "this_year",
  }),
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.row-button {
  float: right;
  margin-right: 15px;
  margin-top: 10px;
}

.back-button {
  float: left;
  box-shadow: none;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 44px;
}
.title {
  margin-top: 15px;
  margin-left: 15px;
}
.description {
  margin-left: 15px;
}

.icon {
  font-size: 20px;
}
</style>
