<template>
  <v-card style="overflow: hidden">
    <v-container>
      <v-row class="head-bc">
        <v-col cols="8" class="title-head-bc title-lab">
          <img
            src="/iconVirtuWhite.png"
            alt="Icon Virtu"
            style="max-width: 75px"
          />
        </v-col>
        <v-col
          cols="4"
          class="d-flex align-center justify-end"
          style="padding-bottom: 0px; margin-top: 30px"
        >
          <v-btn
            icon
            x-small
            @click="$emit('closePopup')"
            class="btn-cancel-qr"
          >
            X
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="head-bc" style="margin-top: 10px !important">
        <v-col
          cols="12"
          class="title-head-bc2 pb-4"
          style="margin-top: -10px; margin-bottom: -10px"
        >
          {{ headQR.plant }}
        </v-col>
      </v-row>
    </v-container>
    <v-card-text class="padding-card">
      <v-row style="padding-left: 5px">
        <v-col
          cols="12"
          class="title-bc-rgb"
          style="padding-top: 15px; padding-bottom: 0"
        >
          {{ headQR.order_number }}
        </v-col>
        <v-col cols="12" class="title-bc-plant">
          {{ headQR.patient }}
        </v-col>

        <v-col cols="6" class="title-head-bc2">
          <div class="title-bc-rgb">
            {{ $t("page.profile.date") }}
          </div>
          <div class="title-bc">
            {{ formatDate(headQR.booking_date) }}
          </div>
        </v-col>
        <v-col cols="6" class="title-head-bc2" style="text-align: end">
          <div class="title-bc-rgb">
            {{ $t("page.profile.estimed_time") }}
          </div>
          <div class="title-bc" style="text-align: end">
            {{ headQR.estimated_service_at }}
          </div>
        </v-col>
        <v-col cols="12" class="title-head-bc2 pb-6" style="margin-top: -5px">
          <div class="title-bc-rgb">
            {{ $t("page.profile.type_of_appointment") }}
          </div>
          <div
            class="title-bc"
            v-for="(test, index) of headQR.product_order"
            :key="index"
          >
            <div
              class="overflow-elipse"
              style="text-align: left !important; margin-bottom: -7px"
            >
              {{ test }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text style="position:relative;br-bottom-barcode">
      <div class="strip">
        <div class="strip-item"></div>
        <div class="strip-item"></div>
        <div class="strip-item"></div>
        <div class="strip-item"></div>
        <div class="strip-item"></div>
        <div class="strip-item"></div>
        <div class="strip-item"></div>
        <div class="strip-item"></div>
      </div>
      <v-col
        cols="12"
        class="d-flex align-center justify-center"
        style="padding: 35px 50px 0px 50px"
      >
        <!-- edit barcode disini -->
        <qrcode-vue
          :value="qrCodeSet"
          :size="sizes"
          level="H"
          :background="foreColor"
          :foreground="backColor"
        >
        </qrcode-vue>
      </v-col>
      <v-col
        cols="12"
        class="d-flex align-center justify-center"
        style="font-size: 18px; padding: 0; color: #000000; font-weight: 500"
      >
        {{ headQR.barcode }}
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-center title-bc">
        {{ $t("have_nice") }}
      </v-col>
      <!-- <v-col
        cols="12"
        class="icon pb-2"
        style="text-align: end; margin-top: -40px"
      >
        <v-btn
          style="background-color: white; box-shadow: none"
          x-small
          @click="copyTextFun(urlTemp + 'barcode?dat=' + urlEnkripHc)"
        >
          <v-icon> mdi-share-variant </v-icon>
        </v-btn>
      </v-col> -->
    </v-card-text>
  </v-card>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import moment from "moment";

export default {
  components: {
    QrcodeVue,
  },
  props: {
    headQR: {
      type: Object,
      default: null,
    },
    backColor: {
      type: String,
      default: "",
    },
    foreColor: {
      type: String,
      default: "",
    },
    qrCodeSet: {
      type: String,
      default: "",
    },
    sizes: {
      type: [Number, String],
      default: "",
    },
    urlTemp: {
      type: String,
      default: "",
    },
    urlEnkripHc: {
      type: String,
      default: "",
    },
  },
  methods: {
    formatDate(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    copyTextFun(data) {
      navigator.clipboard.writeText(data);
      this.toast("success", this.$t("text_copied"));
    },
    // start service
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    // end service
  },
};
</script>

<style lang="scss">
// barcode
.head-bc {
  background: #00a4b3;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  padding: 0 15px;
}

.ic-home {
  padding-bottom: 0px;
  height: 20px;
}

.padding-card {
  padding: 20px 24px !important;
}

.title-head-bc {
  padding-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  margin-bottom: -10px;
}

.title-head-bc2 {
  padding-bottom: 0px;
  font-style: normal;
  font-size: 14px;
  color: #ffffff;
  padding-top: 10px;
}

.title-lab {
  height: 40px;
  margin-top: -15px;
}

.title-lab-family {
  height: 40px;
  margin-top: -25px;
}

.title-bc {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
}

.title-bc-plant {
  font-size: 14px;
  color: #000000;
  margin-top: -12px;
  margin-bottom: -15px;
  font-weight: 500;
}

.title-bc-rgb {
  color: #00a4b3;
  margin-bottom: -5px;
  font-size: 14px !important;
}

.ic-close {
  position: absolute;
  top: -8px;
  right: -30px;
}

.buletL {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: aquamarine;
  box-shadow: inset 0 0 3px grey;
}

.buletR {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(50%, -50%);
  background: white;
  box-shadow: aquamarine;
  box-shadow: inset 0 0 3px grey;
}

.strip {
  position: absolute;
  height: 10px;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-around;
  transform: translateY(-50%);
  //padding: 0 30px;
}

.strip-item {
  border-radius: 10px;
  width: 30px;
  background: #00a4b3;
  box-shadow: inset 0 0 3px grey;
}

.br-top-barcode {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.br-bottom-barcode {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.btn-cancel-qr {
  width: 25px !important;
  height: 25px !important;
  border-radius: 100% !important;
  min-width: 25px !important;
  background: white;
  margin-right: -20px;
  margin-top: -35px;
}
</style>

<!-- no scoped -->
<style lang="scss">
.table-homecare-profile thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}

.table-homecare-profile thead tr th {
  height: 38px !important;
}

.table-homecare-profile tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
}

.table-homecare-profile tbody tr:hover {
  background-color: transparent !important;
}
</style>
