var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-text',[_c('h6',{staticClass:"text-h6 mb-2"},[_vm._v(" "+_vm._s(_vm.data.shared ? "Shared List" : "Add Shared")+" ")]),(!_vm.data.shared)?_c('v-card',[_c('v-card-text',{staticClass:"pa-3"},[_c('v-form',{ref:"formShare",attrs:{"lazy-validation":""}},[_c('v-select',{attrs:{"outlined":"","label":_vm.$t('field.company_name'),"items":_vm.listShare,"item-value":"sap_bp_id","item-text":"name","rules":[
                v =>
                  !!v || `${_vm.$t('field.company_name')} ${_vm.$t('is_required')}`,
              ],"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "),(data.item.has_active_contract)?_c('span',{staticClass:"ml-3",staticStyle:{"color":"#00a4b3"}},[_vm._v("("+_vm._s(_vm.$t("active"))+")")]):_vm._e()]}}],null,false,927281549),model:{value:(_vm.payload.sap_bp_id),callback:function ($$v) {_vm.$set(_vm.payload, "sap_bp_id", $$v)},expression:"payload.sap_bp_id"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('field.share_date'),"outlined":"","append-icon":"mdi-calendar-range","dense":"","readonly":"","rules":[
                    v =>
                      !!v || `${_vm.$t('field.share_date')} ${_vm.$t('is_required')}`,
                  ]},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1358082744),model:{value:(_vm.opendatepicker),callback:function ($$v) {_vm.opendatepicker=$$v},expression:"opendatepicker"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"range":"","min":_vm.minDate,"color":"#00a4b3","header-color":"#00a4b3"},on:{"change":function($event){_vm.opendatepicker = true}},model:{value:(_vm.payload.share_date),callback:function ($$v) {_vm.$set(_vm.payload, "share_date", $$v)},expression:"payload.share_date"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":_vm.closeEdit}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"#00a4b3","text":"","small":""},on:{"click":_vm.saveEdit}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)],1):_vm._e(),((_vm.detailShare && _vm.data.shared) || false)?_vm._l((_vm.detailShare),function(item,i){return _c('card-share-detail',{key:i,class:{ 'mb-5': i + 1 < _vm.detailShare.length },attrs:{"data":item},on:{"updateSharing":msg => _vm.updateSharing(msg)}})}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }