<template>
  <div class="detail-medical-info">
    <v-card>
      <CustomSection class="pa-0">
        <v-container>
          <v-row>
            <heading-card class="title" :title="dataName" />
          </v-row>
          <v-row>
            <p class="description subtitle-1">
              {{ $t("the") }} {{ dataName }}
              {{ $t("detail_the") }}
            </p>
          </v-row>
          <!--
          <v-row>
            <v-col>
              <localized-link
                :to="`/patient/profile`"
                class="link-detail pt-2 text-uppercase"
              >
                <v-btn class="back-button">
                  <v-icon class="icon"> mdi-arrow-left </v-icon>
                </v-btn>
              </localized-link>
            </v-col>
            <v-col>
              <v-btn-toggle
                class="float-right mr-5"
                v-model="filterMedicalRecord"
                dense
              >
                <v-btn valign="middle" value="thirty_days_ago">
                  <span class="font-weight-bold">30D</span>
                </v-btn>
                <v-btn valign="middle" value="ninety_days_ago">
                  <span class="font-weight-bold">90D</span>
                </v-btn>
                <v-btn valign="middle" value="one_year_ago_date">
                  <span class="font-weight-bold">1Y</span>
                </v-btn>
                <v-btn valign="middle" value="life_time_date">
                  <span class="font-weight-bold">MAX</span>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          -->
        </v-container>
        <v-container>
          <v-row>
            <v-col>
              <ApexMedicalChartNew
                :data="dataChart"
                :dataAll="dataAll"
                v-if="dataChart != null"
              />

              <!-- <ChartMedical :data="dataChart" /> -->
            </v-col>
          </v-row>
        </v-container>
      </CustomSection>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
// import ChartDetailMedicalInfo from "@/components/general/ChartDetailMedicalInfo.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
// import ChartMedical from "@/views/v2/Patient/Chart/ChartMedical.vue";
import ApexMedicalChartNew from "@/views/v2/Patient/Chart/ApexMedicalChartNew.vue";

import API from "../../../../service/api_service";
import Auth from "../../../../service/auth_service";

export default {
  components: {
    CustomSection,
    // ChartMedical,
    // ChartDetailMedicalInfo,
    HeadingCard,
    ApexMedicalChartNew,
  },
  props: {
    data: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => ({
    filterMedicalRecord: "life_time_date",
    dataFilter: [],
    dataChart: [],
    dataAll: [
      {
        max: 0,
        min: 0,
      },
    ],
    dataName: "",
  }),
  watch: {
    data() {
      // this.$store.commit("setHelper", {
      //   ...this.$store.state.helper,
      //   filter_time: this.filterMedicalRecord,
      // });
      this.getData();
    },
  },
  created() {
    // this.getDataFilter()
    // this.filterMedicalRecord = this.$store.state.helper.filter_time;
    this.getData();
  },
  methods: {
    async getData() {
      const resp = await API.get(
        `${
          process.env.VUE_APP_API_TRUST
        }lab-results/get-product-item-result?medical_record_number=${
          this.$store.state.paramMedicalRecord
        }&code=${encodeURIComponent(this.data)}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.results.length > 0) {
        this.dataChart = resp.results[0].value.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });

        this.dataAll[0].max = Number(resp.results[0].reference_max);
        this.dataAll[0].min = Number(resp.results[0].reference_min);
        this.dataName =
          this.$i18n.locale == "id"
            ? resp.results[0].name_id
            : resp.results[0].name_en;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.row-button {
  float: right;
  margin-right: 15px;
  margin-top: 10px;
}

.back-button {
  float: left;
  box-shadow: none;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 44px;
}
.title {
  margin-top: 15px;
  margin-left: 15px;
}
.description {
  margin-left: 15px;
}

.icon {
  font-size: 20px;
}
</style>
