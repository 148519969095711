<template>
  <v-card-text class="px-3 px-md-5">
    <v-row>
      <v-col cols="12">
        <h3 class="black--text">{{ $t("R.spirometry") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <v-simple-table class="super-dense no-hover-table">
              <template v-slot:default>
                <thead class="no-pad">
                  <tr class="grey-color head-vir2">
                    <th class="text-center tb-wide" style="width: 40%">
                      {{ $t("R.test") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 15%">UOM</th>
                    <th class="text-center tb-wide" style="width: 15%">
                      {{ $t("R.predicted") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 15%">
                      {{ $t("R.observed") }}
                    </th>
                    <th class="text-center tb-wide" sPtyle="width: 15%">
                      {{ $t("R.predicted") }} (%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="listSpirometry != null">
                    <tr v-for="(item, index) of listSpirometry" :key="index">
                      <td>
                        <span class="pl-3">{{
                          item.mcu_spirometry_object
                        }}</span>
                      </td>
                      <td>
                        {{ item.mcu_spirometry_uom }}
                      </td>
                      <td>
                        {{ item.predicted }}
                      </td>
                      <td>
                        {{ item.observed }}
                      </td>
                      <td>
                        {{ item.percentage }}
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="3">
                      {{ $t("no_data") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="font-black">{{ $t("R.notes") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody class="no-pad desktop">
                    <tr class="hover-apri" v-if="result.data.status">
                      <td class="text-capitalize">
                        {{ `${result.data.status}` }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="no-pad mobile" v-if="result.data.status">
                    <tr class="hover-apri">
                      <td class="text-capitalize">
                        {{ `${result.data.status}` }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="hover-apri">
                      <td class="text-center">{{ $t("no_data") }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
    </v-row>
    <p class="mb-0 check-email-warning">* {{$t('R.check_email')}}</p>
  </v-card-text>
</template>

<script>
import Card from "@/components/v2/card/index.vue";

export default {
  components: {
    Card,
  },
  props: {
    result: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    listSpirometry: null,
  }),
  created() {
    this.listSpirometry = this.result.data.detail_spirometry;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}
.v-data-table table tr td,
.v-data-table table tr th,
table tr td,
table tr th {
  font-size: 12px !important;
}
.normal-label {
  text-transform: capitalize;
  color: $green;
}
.abnormal-label {
  color: $red_2;
  text-transform: capitalize;
}

// mobile
.mobile {
  display: none;
}
.mobile .table-inside tr td:first-child {
  width: 20%;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 200px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}

// spirometry
.check-email-warning{
  font-size: 12px;
  color: $gray_8;
}
</style>
