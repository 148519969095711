<template>
  <v-form>
    <v-card class="filterMedical pa-3 pa-md-4">
      <div class="d-flex justify-space-between filterMedical__box-header">
        <h4 class="filterMedical__title">
          {{ $t("select") }}
          {{ $t("page.profile.medical_record") }}
        </h4>
        <v-btn
          icon
          x-small
          @click="btnCancel()"
          class="filterMedical__btn-cancel"
        >
          <svg
            width="11"
            height="12"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.27312 10.948L5.24775 7.97676L8.21902 10.9514C8.58362 11.3164 9.17532 11.3167 9.54034 10.9521L10.2014 10.2918C10.5664 9.92725 10.5667 9.33555 10.2021 8.97054L7.23083 5.99591L10.2055 3.02463C10.5705 2.66003 10.5708 2.06833 10.2062 1.70332L9.54592 1.04229C9.18131 0.677278 8.58961 0.676945 8.2246 1.04155L5.24998 4.01282L2.2787 1.0382C1.9141 0.673187 1.3224 0.672854 0.957385 1.03745L0.296357 1.69774C-0.0686542 2.06234 -0.0689873 2.65404 0.295614 3.01905L3.26689 5.99368L0.292266 8.96496C-0.0727453 9.32956 -0.0730784 9.92126 0.291522 10.2863L0.951807 10.9473C1.31641 11.3123 1.90811 11.3126 2.27312 10.948Z"
              fill="#00A4B3"
            />
          </svg>
        </v-btn>
      </div>
      <p class="filterMedical__sub-title">
        {{ selectedFilter }}
        {{ $t("page.profile.filter_selected") }}
      </p>
      <v-text-field
        solo
        label="Type Here..."
        hide-details
        class="mb-3"
        prepend-inner-icon="mdi-magnify"
        v-model="searchField"
        dense
      ></v-text-field>
      <div class="mx-2 mb-2 d-flex align-center select_all-box">
        <span class="mr-3" @click="filterAll(true)">
          <v-icon color="#00a6b6" class="mr-1">mdi-check-all</v-icon
          >{{ $t("select_all") }}</span
        >
        <span @click="filterAll(false)">
          <v-icon color="#00a6b6" class="mr-1">mdi-close-box-outline</v-icon
          >{{ $t("unselect_all") }}</span
        >
      </div>
      <v-divider style="border: 1px solid #00a4b3" class="mb-1"></v-divider>
      <div class="mr-4 ml-4 whiteback userGroupHeight mb-5">
        <v-row>
          <v-col
            class="filterMedical__item"
            cols="12"
            sm="6"
            md="4"
            v-for="(category, index) in listFilter"
            :key="filter[index].code"
          >
            <v-checkbox
              light
              v-model="category.default"
              :disabled="selectedFilter <= 1 && category.default"
              color="#00a6b6"
            >
              <template v-slot:label>
                <div
                  v-if="category.name_id != '' && category.name_en != ''"
                  class="font-weight-medium text-overflow"
                >
                  <!-- {{ category[`name_${$i18n.locale}`] }} -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ category[`name_${$i18n.locale}`] }}
                      </span>
                    </template>
                    <span class="font-weight-medium">{{
                      category[`name_${$i18n.locale}`]
                    }}</span>
                  </v-tooltip>
                </div>
                <div v-else class="font-weight-medium text-overflow">
                  <!-- {{ category.code }} -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ category.code }}
                      </span>
                    </template>
                    <span class="font-weight-medium">{{ category.code }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex align-center justify-space-between pt-4">
        <custom-pagination
          @changePage="val => (page = val)"
          :totalPage="countFilterPage"
          :activePage="page"
        />
        <c-button class="py-2" roundedSmall @click="saveFIlter()">{{
          $t("save")
        }}</c-button>
      </div>
    </v-card>
  </v-form>
</template>

<script>
import CButton from "@/components/v2/button/index.vue";
import CustomPagination from "@/components/v3/pagination.vue";

export default {
  components: {
    CButton,
    CustomPagination,
  },
  data: () => ({
    selected: [],
    filter: [],
    isChecked: false,
    page: 1,
    perPage: "24",
    nameCode: [],
    nameSearch: [],
    searchField: "",
  }),
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    data() {
      this.page = 1;
      this.filter = JSON.parse(JSON.stringify(this.data));
    },
    searchField() {
      if (this.searchField != undefined && this.searchField != "") {
        this.page = 1;
      }
    },
  },
  created() {
    this.filter = JSON.parse(JSON.stringify(this.data));
  },
  computed: {
    selectedFilter() {
      return this.filter.filter(item => item.default == true).length;
    },
    listFilterSearch() {
      return this.filter.filter(item => {
        if (this.searchField != undefined && this.searchField != "") {
          if (item[`name_${this.$i18n.locale}`] != "") {
            return (
              item[`name_${this.$i18n.locale}`].includes(
                this.searchField.toUpperCase()
              ) ||
              item[`name_${this.$i18n.locale}`].includes(
                this.searchField.toLowerCase()
              )
            );
          }
          return true;
        }
        return true;
      });
    },
    listFilter() {
      const offset =
        this.page == 1 ? 0 : this.page * this.perPage - this.perPage;
      return this.listFilterSearch.slice(offset, this.perPage * this.page);
    },
    countFilterPage() {
      return Math.ceil(this.listFilterSearch.length / this.perPage);
    },
  },
  methods: {
    saveFIlter() {
      this.$emit("addFilter", this.filter);
    },
    btnCancel() {
      this.filter = JSON.parse(JSON.stringify(this.data));
      this.$emit("closeReschedule");
    },
    filterAll(selected) {
      this.filter.map((res, index) => {
        if (!selected && index == 0) {
          res.default = true;
        } else {
          res.default = selected;
        }
        return res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select_all-box {
  font-size: 14px;
  cursor: pointer;
  color: #00a6b6;
  font-weight: 500;
}
.filterMedical {
  &__box-header {
    position: relative;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #233f78;
  }
  &__sub-title {
    font-size: 12px;
    margin-bottom: 12px;
  }
  &__item {
    height: 35px;
    padding: 0;
  }
  &__btn-cancel {
    border-radius: 100% !important;
    background: white;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
    width: max-content;
  }
}
</style>
<style lang="scss">
.text-overflow {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
