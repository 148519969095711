<template>
  <div class="main-layout">
    <!-- <v-container class="mt-14 containt"> -->
    <v-form v-model="valid" lazy-validation ref="form">
      <v-row>
        <v-col cols="12">
          <div class="card--cart">
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field
                  class="mt-0 uppercase-input"
                  v-model="payload.firstname"
                  :rules="[
                    v =>
                      !!v || `${$t('field.first_name')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.first_name')"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="6">
                <v-text-field
                  class="mt-0 uppercase-input"
                  v-model="payload.lastname"
                  :rules="[
                    v => !!v || `${$t('field.last_name')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.last_name')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-select
                  class="mt-0"
                  v-model="payload.identityType"
                  :items="identityType"
                  :item-text="$i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`"
                  item-value="id"
                  readonly
                  :rules="[
                    v =>
                      !!v ||
                      `${$t('field.identity_type')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.identity_type')"
                  required
                ></v-select>
              </v-col>
              <v-col cols="6" md="6">
                <v-autocomplete
                  class="mt-0"
                  v-model="payload.identityCountry"
                  :items="identityCountry"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    v =>
                      !!v ||
                      `${$t('field.identity_country_isu')} ${$t(
                        'is_required'
                      )}`,
                  ]"
                  :label="$t('field.identity_country_isu')"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  class="mt-0"
                  v-model="payload.idcard"
                  :rules="[
                    v =>
                      !!v ||
                      `${$t('field.identity_number')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.identity_number')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="inp-text"
                      v-model="dateFormatted"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :label="$t('field.birth_date')"
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.birth_date')} ${$t('is_required')}`,
                      ]"
                      hint="DD-MM-YYYY format"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    header-color="#00A4B3"
                    v-model="payload.birthDate"
                    @input="menu = false"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                  ></v-date-picker>
                </v-menu>
                <!--
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="payload.birthDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mt-0"
                        v-model="dateFormatted"
                        :label="$t('field.birth_date')"
                        :rules="[
                          v =>
                            !!v ||
                            `${$t('field.birth_date')} ${$t('is_required')}`,
                        ]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="DD-MM-YYYY format"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="payload.birthDate"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(payload.birthDate)"
                      >
                        {{ $t("ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  -->
              </v-col>
              <v-col cols="6" md="6">
                <v-autocomplete
                  class="mt-0"
                  v-model="payload.selectGender"
                  :items="gender"
                  :item-text="$i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`"
                  item-value="id"
                  :rules="[
                    v => !!v || `${$t('field.gender')} ${$t('is_required')}`,
                  ]"
                  :label="$t('field.gender')"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="6">
                <v-autocomplete
                  class="mt-0"
                  v-model="payload.selectRelation"
                  :items="relation"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    v =>
                      !!v ||
                      `${$t('page.profile.relation')} ${$t('is_required')}`,
                  ]"
                  :label="$t('page.profile.relation')"
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="6" offset-md="3">
          <div class="d-flex align-center justify-center">
            <v-btn
              :disabled="!valid"
              class="mr-4 button-register"
              @click="validate"
              color="white"
            >
              {{ $t("save") }}
              <!-- 
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-left:8px"
                >
                 
                 <path
                    d="M5.56238 11.1299C4.907 11.1299 4.37571 11.6612 4.37571 12.3166C4.37571 12.972 4.907 13.5033 5.56238 13.5033V11.1299ZM21.714 13.1557C22.1774 12.6923 22.1774 11.9409 21.714 11.4775L14.1621 3.9256C13.6987 3.46218 12.9473 3.46218 12.4839 3.9256C12.0205 4.38903 12.0205 5.14038 12.4839 5.6038L19.1967 12.3166L12.4839 19.0294C12.0205 19.4928 12.0205 20.2442 12.4839 20.7076C12.9473 21.171 13.6987 21.171 14.1621 20.7076L21.714 13.1557ZM5.56238 13.5033H20.8749V11.1299H5.56238V13.5033Z"
                    fill="white"
                  />
                </svg>
                -->
            </v-btn>
            <v-btn
              color="button-cancel"
              v-on:click="closeDialog"
              style="border: 1px solid"
            >
              {{ $t("cancel") }}
            </v-btn>
          </div>
        </v-col>
        <!-- <v-row> -->
        <!-- <v-col>
            
          </v-col> -->
        <!-- </v-row> -->
      </v-row>
    </v-form>
    <!-- </v-container> -->
    <!--
    <address-pop-up
      v-if="modalAddressIsOpen"
      @modalAddressClose="closeModalAddress"
      @setAddress="setAddress"
    />
    -->
  </div>
</template>

<script>
import validator from "validator";
import API from "@/service/api_service";
//import AddressPopUp from "../components/general/AddressPopUp.vue";
import Auth from "@/service/auth_service";
export default {
  //components: { AddressPopUp },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t("menu.register")}`,
    };
  },
  name: "Register",
  data: () => ({
    modalAddressIsOpen: false,
    valid: true,
    show_password: false,
    show_confirm_password: false,
    modal: false,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    bloodType: [
      { value: "-", text: "UNKNOWN" },
      { value: "A+", text: "A+" },
      { value: "A-", text: "A-" },
      { value: "AB+", text: "AB+" },
      { value: "AB-", text: "AB-" },
      { value: "B+", text: "B+" },
      { value: "B-", text: "B-" },
      { value: "O+", text: "O+" },
      { value: "O-", text: "O-" },
    ],
    gender: [],
    ethnicity: [],
    country: [],
    province: [],
    city: [],
    district: [],
    subdistrict: [],
    birthPlace: [],
    identityType: [],
    identityCountry: [],
    religion: [],
    martialStatus: [],
    relation: [],
    payload: {
      idcard: "",
      firstname: "",
      lastname: "",
      birthDate: "",
      birthPlace: "",
      identityType: 128,
      identityCountry: 102,
    },
    menu: false,
    dialog: true,
  }),
  computed: {
    dateFormatted() {
      return this.formatDate(this.payload.birthDate);
    },
  },
  mounted() {
    // identity Type
    API.get(
      `${process.env.VUE_APP_API_TRUST}identity-type/get?show_default=true`,
      {}
    ).then(res => {
      if (res.statusCode == 200) {
        this.identityType = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // gender
    API.get(`${process.env.VUE_APP_API_TRUST}gender/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.gender = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // religion
    API.get(`${process.env.VUE_APP_API_TRUST}religion/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.religion = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // martial status
    API.get(`${process.env.VUE_APP_API_TRUST}marital-status/get`, {}).then(
      res => {
        if (res.statusCode == 200) {
          this.martialStatus = res.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      }
    );
    // place
    API.get(`${process.env.VUE_APP_API_TRUST}city/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.birthPlace = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // sub district
    API.get(`${process.env.VUE_APP_API_TRUST}subdistrict/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.subdistrict = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // district
    API.get(`${process.env.VUE_APP_API_TRUST}district/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.district = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // city
    API.get(`${process.env.VUE_APP_API_TRUST}city/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.city = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // province
    API.get(
      `${process.env.VUE_APP_API_TRUST}province/get?country_code=ID`,
      {}
    ).then(res => {
      if (res.statusCode == 200) {
        this.province = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // identity country
    API.get(`${process.env.VUE_APP_API_TRUST}country/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.identityCountry = res.results.sort((a, b) =>
          this.sortByName(a, b)
        );
      }
    });

    // country
    API.get(`${process.env.VUE_APP_API_TRUST}country/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.country = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // ethnic
    API.get(`${process.env.VUE_APP_API_TRUST}ethnicity/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.ethnicity = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    API.get(`${process.env.VUE_APP_API_TRUST}patient-related/get-rel-type`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(res => {
      if (res.statusCode == 200) {
        this.relation = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
  },
  methods: {
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        const {
          firstname,
          lastname,
          idcard,
          birthDate,
          selectGender,
          identityType,
          identityCountry,
          selectRelation,
        } = this.payload;
        const dataReady = {
          first_name: firstname,
          last_name: lastname,
          identity_number: idcard,
          identity_type: identityType,
          identity_country_issuer: identityCountry,
          date_of_birth: birthDate,
          gender: selectGender,
          client_unique_id: "tm617a34c4a3b86",
          relation: selectRelation,
        };
        API.post(
          `${process.env.VUE_APP_API_TRUST}register/subaccount`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        )
          .then(res => {
            if (res.statusCode === 200) {
              this.$swal({
                text: "Add Sub Account success!",
                confirmButtonColor: "#1A428A",
                confirmButtonText: "Oke",
              }).then(result => {
                if (result.isConfirmed) {
                  //   this.payload = {
                  //     idcard: "",
                  //     firstname: "",
                  //     lastname: "",
                  //     birthDate: "",
                  //     birthPlace: "",
                  //     identityType: 128,
                  //     identityCountry: 102,
                  //     gender: "",
                  //     relation: "",
                  //   };
                  window.location.href = "/patient/profile";
                }
              });
            } else if (res.statusCode == 400) {
              this.toast("error", res.message);
              // Object.keys(res.errors).forEach(element => {
              //   this.toast(
              //     "error",
              //     typeof res.errors[element] == "object"
              //       ? res.errors[element][0]
              //       : res.errors[element]
              //   );
              // });
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
                //text: this.$t("error_try_again_latter"),
              });
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              text: "Error Registration!",
            });
          });
        // this.$router.push({ path: "email-verification" });
      }
    },
    passwordConfirmRules(value) {
      if (value !== this.payload.password) {
        return `${this.$t("register.confirm_password")} ${this.$t(
          "must_valid"
        )}`;
      } else if (value == "") {
        return `${this.$t("register.confirm_password")} ${this.$t(
          "is_required"
        )}`;
      } else {
        return true;
      }
    },
    emailRules(value) {
      if (value == "") {
        return `${this.$t("register.email")} ${this.$t("is_required")}`;
      } else if (!validator.isEmail(value)) {
        return `${this.$t("register.email")} ${this.$t("must_valid")}`;
      } else {
        return true;
      }
    },
    // changeCountry() {
    //   // provinsi
    //   API.get(
    //     `${process.env.VUE_APP_API_TRUST}province/get?country_code=${this.payload.selectCountry}`,
    //     {}
    //   ).then(res => {
    //     if (res.statusCode == 200) {
    //       this.province = res.results.sort((a, b) => this.sortByName(a, b));
    //     }
    //   });
    // },
    // changeProvince() {
    //   // city
    //   API.get(
    //     `${process.env.VUE_APP_API_TRUST}city/get?province_id_sni=${this.payload.selectProvince}`,
    //     {}
    //   ).then(res => {
    //     if (res.statusCode == 200) {
    //       this.city = res.results.sort((a, b) => this.sortByName(a, b));
    //     }
    //   });
    // },
    // changeCity() {
    //   // district
    //   API.get(
    //     `${process.env.VUE_APP_API_TRUST}district/get?city_id=${this.payload.selectCity}`,
    //     {}
    //   ).then(res => {
    //     if (res.statusCode == 200) {
    //       this.district = res.results.sort((a, b) => this.sortByName(a, b));
    //     }
    //   });
    // },
    // changeDistrict() {
    //   // sub district
    //   API.get(
    //     `${process.env.VUE_APP_API_TRUST}subdistrict/get?district_id=${this.payload.selectDistrict}`,
    //     {}
    //   ).then(res => {
    //     if (res.statusCode == 200) {
    //       this.subdistrict = res.results.sort((a, b) => this.sortByName(a, b));
    //     }
    //   });
    changeSubDistrict() {
      // sub district detail
      this.payload.selectCity = "";
      this.payload.selectProvince = "";
      this.payload.selectDistrict = "";
      this.payload.subDistrictDetail = "";
      API.get(
        `${process.env.VUE_APP_API_TRUST}subdistrict/get?query=${this.payload.selectSubdistrict}&show_all_parents=true`,
        {}
      ).then(res => {
        if (res.statusCode == 200) {
          this.payload.selectCity = res.results[0].city_id;
          this.payload.selectProvince = res.results[0].province_id_sni;
          this.payload.selectDistrict = res.results[0].district_id;
          this.payload.subDistrictDetail = res.results[0];

          //   this.subdistrict = res.results.sort((a, b) => this.sortByName(a, b));
        }
      });
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    formatDate(date) {
      if (!date) return null;
      const MMM = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "Aug",
        "09": "Sept",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      };
      const [year, month, day] = date.split("-");
      return `${day} ${MMM[month]} ${year}`;
    },
    // uppercase() {
    //   this.payload.firstname = this.payload.firstname.toUpperCase();
    //   this.payload.lastname = this.payload.lastname.toUpperCase();
    //   this.payload.birthPlace = this.payload.birthPlace.toUpperCase();
    //   this.payload.address = this.payload.address.toUpperCase();
    //   this.payload.email = this.payload.email.toUpperCase();
    // },
    openModalAddress() {
      this.modalAddressIsOpen = true;
    },
    closeModalAddress() {
      this.modalAddressIsOpen = false;
    },
    setAddress(param) {
      this.payload.addressInput = param.name;
      this.payload.selectSubdistrict = param.idsubdistrict;
    },
  },
};
</script>
<style>
.uppercase-input input,
.v-input {
  text-transform: uppercase;
}
</style>
<style lang="scss" scoped>
@import "../../../assets/css/custom.scss";
.button-register {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 130px !important;
}
.button-cancel {
  background-color: white !important;
  border-radius: 5px;
  color: #00a4b3 !important;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 130px !important;
}
.theme--light.v-btn.v-btn--disabled {
  color: white !important;
  background: rgb(24, 24, 24);
}
.containt {
  padding-bottom: 110px;
}
.card--cart {
  //padding: 32px 15px;
  border-radius: 16px;
  background: #fff;
  //margin-top: 30px;
}
.blue-font {
  color: #00a4b3;
  font-weight: 700;
  font-size: 20px;
}

a {
  text-decoration: none;
}
</style>
