<template>
  <v-col cols="12" class="vital-signs">
    <div class="card-vital">
      <v-row>
        <v-col cols="12" class="pt-0">
          <div class="table-responsive">
            <div
              class="d-flex justify-space-between align-center"
              style="height: 25px"
            >
              <p class="ungu">
                {{ $t("page.profile.vital_signs") }}
              </p>
              <div>
                <p class="biru">
                  {{ $t("page.profile.last_update") }} : {{ dateNow }}
                </p>
              </div>
            </div>
            <v-divider style="border: 1px solid #00a4b3"></v-divider>

            <v-data-table
              :headers="headerTableVital"
              :items="itemVital"
              hide-default-footer
              :mobile-breakpoint="0"
              class="mt-4"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td valign="top" class="text-center">
                    <span v-if="item.blood_sistole_pressure != '' && item.blood_sistole_pressure != '-'" class="click" @click="openPopUp(item,'blood_sistole_pressure_history',headerTableVital,text_sistole)">
                      <!-- {{ item.blood_sistole_pressure }} -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ item.blood_sistole_pressure }}
                            </span>
                          </template>
                        <span class="font-weight-medium ">{{ text_sistole }}</span>
                      </v-tooltip>
                    </span>
                    <span v-else>
                      {{ item.blood_sistole_pressure }} 
                    </span>
                    /
                    <span v-if="item.blood_diastole_pressure != '' && item.blood_diastole_pressure != '-'" class="click" @click="openPopUp(item,'blood_diastole_pressure_history',headerTableVital,text_diastole)">
                      <!-- {{ item.blood_diastole_pressure }} -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ item.blood_diastole_pressure }}
                            </span>
                          </template>
                        <span class="font-weight-medium ">{{ text_diastole }}</span>
                      </v-tooltip>
                    </span>
                    <span v-else>
                      {{ item.blood_diastole_pressure }}
                    </span>
                   
                  </td>
                  <td valign="top" class="text-center">
                    <span>
                      {{ item.heart_rate }}
                    </span>
                  </td>
                  <td valign="top" class="text-center">
                    <span>
                      {{ item.body_temperature }}
                    </span>
                  </td>
                  <td valign="top" class="text-center">
                    <span>
                      {{ item.respiratory_rate }}
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>

        <v-col cols="12" class="pt-0">
          <div class="table-responsive">
            <p class="ungu mb-2">
              {{ $t("page.profile.anthropometry") }}
            </p>
            <v-divider style="border: 1px solid #00a4b3"></v-divider>

            <v-data-table
              :headers="headerTableAnthA"
              :items="itemAnthA"
              hide-default-footer
              :mobile-breakpoint="0"
              class="mt-4 mb-4"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td valign="top" class="text-center">
                    {{ item.body_height }}
                  </td>
                  <td valign="top" class="text-center">
                    <span v-if="item.body_weight != '' && item.body_weight != '-'" class="click" @click="openPopUp(item,'body_weight_history',headerTableAnthA,text_weight)">
                      {{ item.body_weight }}
                    </span>
                    <span v-else>
                      {{ item.body_weight }}
                    </span>
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.body_mass_index }}
                  </td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table
              :headers="headerTableAnthB"
              :items="itemAnthB"
              hide-default-footer
              :mobile-breakpoint="0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td valign="top" class="text-center">
                    {{ item.belly_circumference }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.chest_circumference }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.head_circumference }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="dialogPopUp"
      persistent
      max-width="900px"
      @click:outside="onClickOutside"
      @keydown.esc="onClickOutside"
    >
      <DetailChartMedicalRecordPopUp :graphData="graphData" :nameData="headerName"/>
    </v-dialog>
    
    
  </v-col>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import moment from "moment";
//import ApexMedicalChartPopUp from "@/views/v2/Patient/Chart/ApexMedicalChartPopUp.vue";
import DetailChartMedicalRecordPopUp from "@/views/v2/Patient/Tabs/DetailMedicalInfoPopUp.vue";

export default {
  components: {
  //  ApexMedicalChartPopUp,
    DetailChartMedicalRecordPopUp
  },
  data: () => ({
    dateNow: moment().format("DD-MMM-YYYY").toUpperCase(),
    headerTableVital: [],
    itemVital: [
      {
        blood_sistole_pressure: "-",
        blood_sistole_pressure_history: [],
        blood_diastole_pressure: "-",
        blood_diastole_pressure_history: [],
        heart_rate: "-",
        heart_rate_history: [],
        temperature: "-",
        temperature_history: [],
        respiratory_rate: "-",
        respiratory_rate_history: [],
      },
    ],
    headerTableAnthA: [],
    itemAnthA: [
      {
        body_height: "-",
        body_weight: "-",
        body_weight_history: [],
        body_mass_index: "-",
      },
    ],
    headerTableAnthB: [],
    itemAnthB: [
      {
        belly_circumference: "-",
        chest_circumference: "-",
        head_circumference: "-",
      },
    ],
    dialogMedicalRecord: false,
    graphData: [],
    dateFilterDefaultValue: "this_year",
    dialogPopUp: false,
    dialog: false,
    headerName: "",
    text_sistole: "",
    text_diastole:"",
    text_weight: "",
  }),
  created() {
    this.getData();
    this.text_sistole = this.$i18n.locale == 'en' ? `Sistole Pressure` : `Tekanan Sistole`;
    this.text_diastole = this.$i18n.locale == 'en' ? `Diastole Pressure` : `Tekanan Diastole`;
    this.text_weight = this.$i18n.locale == 'en' ? `Body Weight` : `Berat Badan`;
  },
  methods: {
    async getData() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-chart`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200 && res.results.length > 0) {
          const vital = res.results[0];
          this.headerTableVital = [
            {
              text: `${this.$t("R.blood_pressure")} (${
                vital.sistole_pressure.unit
              })`,
              value: "blood_pressure",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.heart_rate")} (${vital.heart_rate.unit})`,
              value: "heart_rate",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.temperature")} (${
                vital.body_temperature.unit
              })`,
              value: "body_temperature",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.heart_rate")} (${
                vital.respiratory_rate.unit
              })`,
              value: "respiratory_rate",
              sortable: false,
              align: "center",
            },
          ];

          this.itemVital = [
            {
              // blood_pressure:
              //   Object.keys(vital.sistole_pressure.current_result).length == 0
              //     ? "-"
              //     : `${parseFloat(
              //         vital.sistole_pressure.current_result.value
              //       ).toFixed(2)} / ${parseFloat(
              //         vital.diastole_pressure.current_result.value
              //       ).toFixed(2)}`,
              blood_sistole_pressure:
                Object.keys(vital.sistole_pressure.current_result).length == 0
                  ? "-"
                  : `${parseFloat(
                      vital.sistole_pressure.current_result.value
                    ).toFixed(2)}`,
              blood_sistole_pressure_history:
                vital.sistole_pressure.list_history.length > 0
                  ?
                    vital.sistole_pressure.list_history.sort(
                      (a, b) => {
                        return new Date(a.date) - new Date(b.date);
                      }
                    )
                  : [],
              blood_diastole_pressure:
                Object.keys(vital.diastole_pressure.current_result).length == 0
                  ? "-"
                  : `${parseFloat(
                      vital.diastole_pressure.current_result.value
                    ).toFixed(2)}`,    
              blood_diastole_pressure_history:
                vital.diastole_pressure.list_history.length > 0
                  ?
                    vital.diastole_pressure.list_history.sort(
                      (a, b) => {
                        return new Date(a.date) - new Date(b.date);
                      }
                    )
                  : [],    
              heart_rate:
                Object.keys(vital.heart_rate.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.heart_rate.current_result.value).toFixed(
                      2
                    ),
              heart_rate_history:
                vital.heart_rate.list_history.length > 0
                  ?
                    vital.heart_rate.list_history.sort(
                      (a, b) => {
                        return new Date(a.date) - new Date(b.date);
                      }
                    )
                  : [],      
              body_temperature:
                Object.keys(vital.body_temperature.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.body_temperature.current_result.value
                    ).toFixed(2),
              body_temperature_history:
                vital.body_temperature.list_history.length > 0
                  ?
                    vital.body_temperature.list_history.sort(
                      (a, b) => {
                        return new Date(a.date) - new Date(b.date);
                      }
                    )
                  : [],      
              respiratory_rate:
                Object.keys(vital.respiratory_rate.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.respiratory_rate.current_result.value
                    ).toFixed(2), 
              respiratory_rate_history:
                vital.respiratory_rate.list_history.length > 0
                  ?
                    vital.respiratory_rate.list_history.sort(
                      (a, b) => {
                        return new Date(a.date) - new Date(b.date);
                      }
                    )
                  : [],                      
            },
          ];
          this.headerTableAnthA = [
            {
              text: `${this.$t("R.height")} (${vital.body_height.unit})`,
              value: "body_height",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.weight")} (${vital.body_weight.unit})`,
              value: "body_weight",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.body_mass_index")} (${
                vital.body_mass_index.unit
              })`,
              value: "body_mass_index",
              sortable: false,
              align: "center",
              width: "40%",
            },
          ];
          this.itemAnthA = [
            {
              body_height:
                Object.keys(vital.body_height.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.body_height.current_result.value).toFixed(
                      2
                    ),
              body_weight:
                Object.keys(vital.body_weight.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.body_weight.current_result.value).toFixed(
                      2
                    ),
              body_weight_history:
                vital.body_weight.list_history.length > 0
                  ?
                    vital.body_weight.list_history.sort(
                      (a, b) => {
                        return new Date(a.date) - new Date(b.date);
                      }
                    )
                  : [],       
              body_mass_index:
                Object.keys(vital.body_mass_index.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.body_mass_index.current_result.value
                    ).toFixed(2),
            },
          ];
          this.headerTableAnthB = [
            {
              text: `${this.$t("R.abdomen_circumference")} (${
                vital.belly_circumference.unit
              })`,
              value: "belly_circumference",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.chest_circumference")} (${
                vital.chest_circumference.unit
              })`,
              value: "chest_circumference",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.head_circumference")} (${
                vital.head_circumference.unit
              })`,
              value: "head_circumference",
              sortable: false,
              align: "center",
              width: "40%",
            },
          ];
          this.itemAnthB = [
            {
              belly_circumference:
                Object.keys(vital.belly_circumference.current_result).length ==
                0
                  ? "-"
                  : parseFloat(
                      vital.belly_circumference.current_result.value
                    ).toFixed(2),
              chest_circumference:
                Object.keys(vital.chest_circumference.current_result).length ==
                0
                  ? "-"
                  : parseFloat(
                      vital.chest_circumference.current_result.value
                    ).toFixed(2),
              head_circumference:
                Object.keys(vital.head_circumference.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.head_circumference.current_result.value
                    ).toFixed(2),
            },
          ];
        }
      } catch (error) {
        this.ready = false;
      }
    },
    openPopUp(data,obj,header,name) {
      // const textName = header.filter(dat => {
      //   return dat.value == obj;
      // });
      this.headerName = name;
      this.graphData = data[obj];
      this.dialogPopUp = true;
    },
    toggleDialogMedicalRecord(toggle) {
      this.dialogMedicalRecord = toggle;
    },
    onClickOutside() {
      this.dialogPopUp = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";

.card-vital {
  position: relative;
  background: white;
  padding: 30px 20px;
}

.card-vital .v-data-table {
  border: 1px solid #e3e3e38a;
  td {
    font-size: 12px;
    height: 35px !important;
    vertical-align: middle;
  }
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  background: #e3e3e38a ;
  height: 35px;
}

.ungu {
  color: #233f78;
  font-size: 16px;
  font-weight: 700;
}
.biru {
  color: #00a4b3;
  font-size: 14px;
  font-weight: 700;
}
.click{
  cursor: pointer;
  color: #00a4b3;
}
</style>
