<template>
  
    <div v-if="type == 'A'">
      <div class="d-flex align-center box-result">
        <!-- <span class="result tresult--left" v-if="resultPercent >= 50">{{result}}</span> -->
        <!--
        <IconSliderMarker
          class="slider-marker"
          :style="{
            left: resultPercent +'%',
          }"
        />
        left: `${resultPercentAll < 0 ? 0 : resultPercentAll}%`,
        -->
        <div
          class="slider-marker"
          :class="resultPercentAll < 0 || resultPercentAll > 100 ? 'redSlider' : 'greenSlider'"
          :style="{
            left: resultPercentAll + '%',
          }"
        >
          {{ resultAll }}
        </div>
        <div
          class="arrow"
          :class="resultPercentAll < 0 || resultPercentAll > 100 ? 'redArrow' : 'greenArrow'"
          :style="{
            left: resultPercentAll + '%',
          }"
        ></div>
        <!--
        <span class="result result--right" v-if="resultPercent < 50">{{
          result
        }}</span>
        -->
      </div>

      <template v-if="(maximumValueAll != null &&  maximumValueAll != -1 ) && (minimumValueAll != null && minimumValueAll != -1 )">
        <div class="slider">
          <div
            class="grey"
            :style="{
              width: sliderWidthAll + 20 + '%',
              marginLeft: sliderLeftAll - 10 + '%',
            }"
          ></div>
        </div>
        <div class="labeling">
          <span class="limit" :style="{ left: 15 + '%' }">{{
            minimumValueAll
          }}</span>

          <span class="limit" :style="{ left: 85 + '%' }">{{
            maximumValueAll
          }}</span>
        </div>
      </template>

      <template v-if="minimumValueAll == null || minimumValueAll == -1">
        <div class="slider">
          <div
            class="grey"
            :style="{
              width: sliderWidthAll + '%',
            }"
          ></div>
        </div>
        <div class="labeling">
          <span class="limit" :style="{ left: 50 + '%' }">{{
            maximumValueAll
          }}</span>
        </div>
      </template>

      <template v-if="maximumValueAll == null || maximumValueAll == -1">
        <div class="slider">
          <div
            class="grey"
            :style="{
              marginLeft: sliderLeftAll + 25 + '%',
            }"
          ></div>
        </div>
        <div class="labeling">
          <span class="limit" :style="{ left: 50 + '%' }">{{
            minimumValueAll
          }}</span>
        </div>
      </template>
    </div>  

    <div v-else-if="type == 'B'">
      <div class="d-flex align-center box-result-b">
        <div
          class="slider-marker-b"
          :style="{
            left: resultPercentAll + '%',
          }"
        ></div>
      </div>
      <template v-if="(maximumValueAll != null &&  maximumValueAll != -1 ) && (minimumValueAll != null && minimumValueAll != -1 )">
        <div class="slider-b">
          <div
            class="grey"
            :style="{
              width: sliderWidthAll + 20 + '%',
              marginLeft: sliderLeftAll - 10 + '%',
            }"
          ></div>
        </div>
        <div class="labeling">
          <span
            class="limit"
            :style="{
              left: 15 + '%',
            }"
          ></span>
          <span class="limit" :style="{ left: 85 + '%' }"></span>
        </div>
      </template>
      <template v-if="minimumValueAll == null || minimumValueAll == -1">
        <div class="slider-b">
          <div
            class="grey"
            :style="{
              width: sliderWidthAll + '%',
            }"
          ></div>
        </div>
        <div class="labeling">
          <span
            class="limit"
            :style="{
              left: 15 + '%',
            }"
          ></span>
          <span class="limit" :style="{ left: 85 + '%' }"></span>
        </div>
      </template>

      <template v-if="maximumValueAll == null || maximumValueAll == -1">
        <div class="slider-b">
          <div
            class="grey"
            :style="{
              width: sliderWidthAll + '%',
              marginLeft: sliderLeftAll + 25 + '%',
            }"
          ></div>
        </div>
        <div class="labeling">
          <span
            class="limit"
            :style="{
              left: 15 + '%',
            }"
          ></span>
          <span class="limit" :style="{ left: 85 + '%' }"></span>
        </div>
      </template>
    </div>

    <div v-else-if="type == 'C'" style="padding-bottom: 5px;">
      <template >
        <div class="slider-b">
          <div
            class="grey"
            style="width: 100%;"
          ></div>
        </div>
        <div class="labeling">
          <span
            class="limit"
            style="left: 100%"
          ></span>
          <span class="limit" style="left: 100%"></span>
        </div>
      </template>
    </div>
  
</template>

<script>
//import IconSliderMarker from "../elements/IconSliderMarker.vue";
export default {
  components: {
    //IconSliderMarker
  },
  props: {
    data: { 
      type: Object, 
      default: () => {} 
    },
  },
  data: () => ({
    sliderWidth: 0,
    sliderLeft: 0,
    //sliderRight:0,
    resultPercent: 0,
    result: 0,
    minimumValue: 0,
    maximumValue: 0,
    totalValue: 0,
    totalPercent: 0,
    type: "",
    sliderWidthAll: 0,
    sliderLeftAll: 0,
    resultPercentAll: 0,
    resultAll: 0,
    minimumValueAll: 0,
    maximumValueAll: 0,
    totalValueAll: 0,
    totalPercentAll: 0,
    all: {},
    category: ""
  }),
  created() {
    this.all = this.data.all;
    this.type = this.data.type;
    this.category = this.data.category;
    this.slide(this.all);
    //kodingan Lama
    // this.result = this.data.result;
    // this.minimumValue = this.data.min;
    // this.maximumValue = this.data.max;

    //   this.totalValue = (
    //     parseFloat(this.maximumValue) - parseFloat(this.minimumValue)
    //   );
    //   this.totalPercent = 100;
    //   this.resultPercent = ((this.result - this.minimumValue) / this.totalValue * 100);
    //   if(this.resultPercent < 0 ){
    //     this.resultPercent = -18;

    //   }
    //   if(this.resultPercent > 100 ){
    //     this.resultPercent = 115;
    //   }
    //  this.sliderWidth = 50;
    //  this.sliderLeft = 25;
    //this.sliderWidth = this.data.Width;
    //this.sliderLeft = this.data.Left;
    //this.sliderRight = this.data.Right;
  },
  watch: {
    data() {
      this.all = this.data.all;
      this.type = this.data.type;
      this.category = this.data.category;
      this.slide(this.all);
    },
  },
  methods: {
    formatNumber(number) {
      if (number % 1 == 0) {
        return number;
      } else {
        return Math.round(number * 100) / 100;
      }
    },
    slide(par) {
      this.all = par;
      if(this.category == "newMedicalRecord"){
        this.resultAll = parseFloat(this.all.current_value);
      }else{
        this.resultAll = parseFloat(this.all.result_value);
      }
      this.minimumValueAll = this.all.reference_min;
      this.maximumValueAll = this.all.reference_max;

      if (this.minimumValueAll == null || this.minimumValueAll == -1) {
        this.totalPercentAll = 100;
        this.resultPercentTmp =
          ((this.resultAll - 0) / parseFloat(this.maximumValueAll)) * 100;
        this.resultPercentAll = this.resultPercentTmp - 54;
        if (this.resultPercentTmp == 100) {
          if (this.type == "B") {
            this.resultPercentAll = this.resultPercentTmp - 50;
          }
        }
        if (this.resultPercentTmp <= 32.5) {
          this.resultPercentAll = -21.5;
        }
        if (this.resultPercentTmp >= 165) {
          if (this.type == "B") {
            this.resultPercentAll = 118;
          } else {
            this.resultPercentAll = 111;
          }
        }
      }

      if (this.maximumValueAll == null || this.maximumValueAll == -1) {
        this.totalPercentAll = 100;
        this.resultPercentTmp =
          ((this.resultAll - this.minimumValueAll) /
            parseFloat(this.minimumValueAll)) *
          100;
        this.resultPercentAll = this.resultPercentTmp + 45;

        if (this.resultPercentTmp == 0) {
          if (this.type == "B") {
            this.resultPercentAll = this.resultPercentTmp + 47;
          }
          if (this.type == "A") {
            this.resultPercentAll = this.resultPercentTmp + 50;
          }
        }

        if (this.resultPercentTmp >= 66) {
          this.resultPercentAll = 111;
          if (this.type == "B") {
            this.resultPercentAll = 118;
          }
        }

        if (this.resultPercentTmp <= -66) {
          this.resultPercentAll = -21;
        }
      }

      if ((this.minimumValueAll != null && this.minimumValueAll != -1) && (this.maximumValueAll != null && this.maximumValueAll != -1)) {
        this.totalValueAll =
          parseFloat(this.maximumValueAll) - parseFloat(this.minimumValueAll);
        this.totalPercentAll = 100;
        this.resultPercentAll =
          ((this.resultAll - this.minimumValueAll) / this.totalValueAll) * 100;
          // console.log(this.all.product_name_id,'product')
          // console.log(this.totalValueAll,'result')
          // console.log(this.resultPercentAll.toFixed(0),'percent')
        // if (this.resultPercentAll == 100) {
        //   this.resultPercentAll = 100;
        // }

      

        // if (this.resultPercentAll == 0) {
        //   if (this.type == "B") {
        //     this.resultPercentAll = -3;
        //   } else {
        //     this.resultPercentAll = 0;
        //   }
        // }

        // if (this.resultPercentAll < 0) {
        //   this.resultPercentAll = -21;
        // }

        // if (this.resultPercentAll > -49 || this.resultPercentAll < -1000) {
        //   if (this.type == "B") {
        //     this.resultPercentAll = -21;
        //   } else {
        //     this.resultPercentAll = -18;
        //   }
        // }

        // if (this.resultPercentAll > -20 || this.resultPercentAll < -30) {
        //   if (this.type == "B") {
        //     this.resultPercentAll = -12;
        //   } else {
        //     this.resultPercentAll = -10;
        //   }
        // }

        // if (this.resultPercentAll < -9) {
        //   this.resultPercentAll = -5;
        // }

        if (this.resultPercentAll.toFixed(0) == 0) {
          if (this.type == "B") {
            this.resultPercentAll = -3;
          } else {
            this.resultPercentAll = 0;
          }
        }else if(this.resultPercentAll.toFixed(0) < 0 && this.resultPercentAll.toFixed(0) >= -5){
          if (this.type == "B") {
            this.resultPercentAll = -4;
          } else {
            this.resultPercentAll = -1;
          }
        }else if(this.resultPercentAll.toFixed(0) <= -6 && this.resultPercentAll.toFixed(0) >= -10){
          if (this.type == "B") {
            this.resultPercentAll = -5;
          } else {
            this.resultPercentAll = -2;
          }
        }else if(this.resultPercentAll.toFixed(0) <= -11 && this.resultPercentAll.toFixed(0) >= -19){
          if (this.type == "B") {
            this.resultPercentAll = -6;
          } else {
            this.resultPercentAll = -3;
          }
        }else if(this.resultPercentAll.toFixed(0) <= -20 && this.resultPercentAll.toFixed(0) >= -25){
          if (this.type == "B") {
            this.resultPercentAll = -9;
          } else {
            this.resultPercentAll = -7;
          }
        }else if(this.resultPercentAll.toFixed(0) <= -26 && this.resultPercentAll.toFixed(0) >= -30){
          if (this.type == "B") {
            this.resultPercentAll = -12;
          } else {
            this.resultPercentAll = -9;
          }
        }else if(this.resultPercentAll.toFixed(0) <= -31 && this.resultPercentAll.toFixed(0) >= -34){
          if (this.type == "B") {
            this.resultPercentAll = -14;
          } else {
            this.resultPercentAll = -11;
          }
        }else if(this.resultPercentAll.toFixed(0) <= -35 && this.resultPercentAll.toFixed(0) >= -40){
          if (this.type == "B") {
            this.resultPercentAll = -16;
          } else {
            this.resultPercentAll = -13;
          }
        }else if(this.resultPercentAll.toFixed(0) <= -41 && this.resultPercentAll.toFixed(0) >= -44){
          if (this.type == "B") {
            this.resultPercentAll = -19;
          } else {
            this.resultPercentAll = -16;
          }
        }else if(this.resultPercentAll.toFixed(0) <= -45 && this.resultPercentAll.toFixed(0) >= -1000){
          if (this.type == "B") {
            this.resultPercentAll = -21;
          } else {
            this.resultPercentAll = -18;
          }
        }else if (this.resultPercentAll.toFixed(0) == 100) {
          this.resultPercentAll = 100;
        }else if (this.resultPercentAll.toFixed(0) >= 101 && this.resultPercentAll.toFixed(0) <= 109) {
          if (this.type == "B") {
            this.resultPercentAll = 103;
          } else {
            this.resultPercentAll = 103;
          }
        }else if (this.resultPercentAll.toFixed(0) >= 110 && this.resultPercentAll.toFixed(0) <= 119) {
          if (this.type == "B") {
            this.resultPercentAll = 105;
          } else {
            this.resultPercentAll = 105;
          }
        }else if (this.resultPercentAll.toFixed(0) >= 120 && this.resultPercentAll.toFixed(0) <= 129) {
          if (this.type == "B") {
            this.resultPercentAll = 109;
          } else {
            this.resultPercentAll = 107;
          }
        }else if (this.resultPercentAll.toFixed(0) >= 130 && this.resultPercentAll.toFixed(0) <= 139) {
          if (this.type == "B") {
            this.resultPercentAll = 110;
          } else {
            this.resultPercentAll = 109;
          }
        }else if (this.resultPercentAll.toFixed(0) >= 140 && this.resultPercentAll.toFixed(0) <= 149) {
          if (this.type == "B") {
            this.resultPercentAll = 113;
          } else {
            this.resultPercentAll = 112;
          }
        }else if (this.resultPercentAll.toFixed(0) >= 150 && this.resultPercentAll.toFixed(0) <= 159) {
          if (this.type == "B") {
            this.resultPercentAll = 116;
          } else {
            this.resultPercentAll = 115;
          }
        }else if (this.resultPercentAll.toFixed(0) >= 160) {
          if (this.type == "B") {
            this.resultPercentAll = 119;
          } else {
            this.resultPercentAll = 118;
          }
        }
      }


      this.sliderWidthAll = 50;
      this.sliderLeftAll = 25;
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  //background: #f35f5f;
  background: #f60707;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  .green {
    background: #68ee76;
    height: 100%;
  }
  .grey {
    background: #d9d9d9;
    height: 100%;
  }
}
.labeling {
  position: relative;
  .limit {
    position: absolute;
    font-size: 10px;
    font-weight: 700;
    transform: translateX(-50%);
  }
}
.box-result {
  position: relative;
  margin-bottom: 2px;
  width: 70%;
  margin-left: 15%;
}
.result {
  font-size: 10px;
  font-weight: 700;
  padding: 0 4px;
  position: absolute;
  &--left {
    left: 20%;
  }
  &--right {
    right: 20%;
  }
}
.slider-marker {
  position: absolute;
  transform: translate(-50%, -8px);
  background: #719e51;
  color: white;
  font-size: 12px;
  bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 3px;
  width: 40px;
  height: 24px;
  text-align: center;
}
.arrow {
  position: absolute;
  transform: translateX(-50%);
  bottom: -6px;
  background: #719e51;
  color: white;
  font-size: 12px;
  width: 40px;
  text-align: center;
  border-top: solid 11px #719e51;
  border-left: solid 20px white;
  border-right: solid 20px white;
  height: 16px;
}
.redSlider{
  background:#f60707 !important;
}
.redArrow{
  background:#f60707 !important;
  border-top: solid 11px #f60707;
}
.greenSlider{
  background:#719e51 !important;
}
.greenArrow{
  background:#719e51 !important;
  border-top: solid 11px #719e51;
}
.box-result-b {
  position: relative;
  width: 70%;
  margin-left: 15%;
}
.slider-marker-b {
  position: absolute;
  color: white;
  font-size: 12px;
  padding: 2px;
  width: 2%;
  text-align: center;
  border: 1.5px solid #1f3c87;
  border-radius: 100%;
  z-index: 1;
  margin-bottom: -4px;
}
.slider-b {
  background: #f60707;
  height: 4px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  .green {
    background: #68ee76;
    height: 100%;
  }
  .grey {
    background: #d9d9d9;
    height: 100%;
  }
}
.v-application {
  .grey {
    background: #d9d9d9 !important;
  }
}
.mrgL {
  margin-left: 13px !important;
}
</style>
