<template>
  <v-data-table
    :headers="headers"
    :items="myFamily"
    dense
    mobile-breakpoint="0"
    class="header-stretch table-relation-approve"
    :hide-default-footer="true"
  >
    <template v-slot:item="row">
      <tr class="nowrap-mobile">
        <td>
          <v-icon
            small
            @click="approveItem(row.item)"
            v-if="row.item.status == 'PENDING' && !row.item.requestor"
            style="color: #00a4b3"
          >
            mdi-check
          </v-icon>
          <!-- <v-icon
            small
            @click="rejectItem(row.item)"
            v-if="row.item.status == 'PENDING'"
          >
            mdi-cancel
          </v-icon> -->
          <v-icon small @click="deleteItem(row.item)" style="color: #00a4b3">
            mdi-delete
          </v-icon>
        </td>
        <td>
          <a
            v-if="row.item.status.toUpperCase() == 'APPROVED'"
            target="blank"
            :href="`/patient/family/${row.item.medical_record_number}`"
            >{{ row.item.medical_record_number }}</a
          >
          <template v-else>
            {{ row.item.medical_record_number }}
          </template>
        </td>
        <td>{{ row.item.related_patient_name }}</td>
        <td>{{ row.item.related_patient_nik }}</td>
        <td>{{ formatDate(row.item.related_date_of_birth) }}</td>
        <td>{{ row.item.relation_name }}</td>
        <td>{{ row.item.status }}</td>
      </tr>
    </template>
    <template v-slot:top>
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("confirmation_dialog") }}
          </v-card-title>
          <v-card-text>
            {{ $t("page.profile.u_sure_want_delete") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDelete">{{ $t("cancel") }}</v-btn>
            <v-btn color="#00a4b3" text @click="deleteItemConfirm">{{
              $t("oke")
            }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogReject" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("confirmation_dialog") }}
          </v-card-title>
          <v-card-text>
            {{ $t("page.profile.u_sure_want_reject") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeReject">{{ $t("cancel") }}</v-btn>
            <v-btn color="#00a4b3" text @click="makeRejectConfirm">{{
              $t("oke")
            }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogApprove" max-width="350">
        <v-card class="pa-3">
          <div class="d-flex justify-center mb-3">
            <v-icon x-large color="yellow"> mdi-information-outline </v-icon>
          </div>
          <h4 class="text-center mb-4">{{ $t("page.profile.are_u_sure_make_person_family") }}</h4>
          <p class="text-center">
            {{ $t("tnc.tnc_family") }}
          </p>
          <div class="d-flex justify-center">
            <c-button class="mx-2 py-2" rounded @click="makeApproveConfirm">{{
              $t("continue")
            }}</c-button>
            <c-button class="mx-2 py-2" rounded outline @click="closeApprove">{{
              $t("cancel")
            }}</c-button>
          </div>
        </v-card>
      </v-dialog>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import CButton from "@/components/v2/button/index.vue";

export default {
  components:{
    CButton
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogReject: false,
    dialogApprove: false,
    formHasError: false,
    headers: [],
    myFamily: [],

    editedIndex: -1,
    name: null,
    id: null,
    birthdate: null,
    relationship: null,
    status: null,
    errorMessages: "",
    editedItem: {
      id: "",
      fullname: "",
      birthdate: "",
      status: "",
      relationship: "",
    },
    defaultItem: {
      id: "",
      fullname: "",
      birthdate: "",
      status: "",
      relationship: "",
    },
  }),

  computed: {
    form() {
      return {
        id: this.id,
        fullname: this.fullname,
        birthdate: this.birthdate,
        status: this.status,
        relationship: this.relationship,
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogApprove(val) {
      val || this.closeApprove();
    },

    dialogReject(val) {
      val || this.closeReject();
    },
    name() {
      this.errorMessages = "";
    },
    familyListApproval() {
      this.myFamily = this.familyListApproval;
    },
  },
  props: {
    familyListApproval: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_id"),
        sortable: false,
        value: "medical_record_number",
        align: "center",
      },
      {
        text: this.$t("page.profile.full_name"),
        value: "related_person_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.id_card_number"),
        value: "related_patient_nik",
        sortable: false,
        align: "center",
      },

      {
        text: this.$t("page.profile.birth_date"),
        value: "birthdate",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.relationship"),
        value: "relation_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
    ];
    this.myFamily = this.familyListApproval;
  },
  methods: {
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    save() {
      if (this.$refs.form.validate() == true) {
        if (this.editedIndex > -1) {
          Object.assign(this.myFamily[this.editedIndex], this.editedItem);
        } else {
          const indexrelation = this.relationItem.findIndex(
            e => e.id == this.editedItem.relation
          );
          this.editedItem.relation_name = this.relationItem[indexrelation].name;
          API.post(
            `${process.env.VUE_APP_API_TRUST}patient-related/add`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            { ...this.editedItem }
          ).then(res => {
            if (res.statusCode == 200) {
              this.found = false;
              this.close();
              this.toast("success", res.message);
            }
          });
        }
      }
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    approveItem(item) {
      this.editedIndex = this.myFamily.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    rejectItem(item) {
      this.editedIndex = this.myFamily.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    deleteItem(item) {
      this.editedIndex = this.myFamily.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      API.del(
        `${process.env.VUE_APP_API_TRUST}patient-related/delete`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        this.editedItem
      ).then(res => {
        if (res.statusCode == 200) {
          this.myFamily.splice(this.editedIndex, 1);
          this.closeDelete();
          this.toast("success", res.message);
        }
      });
    },
    makeApproveConfirm() {
      API.post(
        `${process.env.VUE_APP_API_TRUST}patient-related/confirm`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        {
          ...this.editedItem,
          approve: true,
        }
      ).then(res => {
        if (res.statusCode == 200) {
          this.myFamily.splice(this.editedIndex, 1);
          this.closeApprove();
          this.toast("success", res.message);
        }
      });
    },
    makeRejectConfirm() {
      API.post(
        `${process.env.VUE_APP_API_TRUST}patient-related/confirm`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        {
          ...this.editedItem,
          approve: false,
        }
      ).then(res => {
        if (res.statusCode == 200) {
          this.myFamily.splice(this.editedIndex, 1);
          this.closeReject();
          this.toast("success", res.message);
        }
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$emit("getFamily");
      this.$emit("getFamilyApproval");
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$emit("getFamily");
      this.$emit("getFamilyApproval");
    },
  },
};
</script>
<style>
.table-relation-approve thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-relation-approve thead tr th {
  height: 38px !important;
}
.table-relation-approve tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
}
.table-relation-approve tbody tr:hover {
  background-color: transparent !important;
}
@media screen and (max-width: 767px) {
  .nowrap-mobile {
    white-space: nowrap;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.v-input__control {
  width: 700px;
}

.v-toolbar__title {
  font-size: 16px;
}

.v-btn {
  display: flex;
  justify-content: flex-end;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
}
</style>
