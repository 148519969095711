<template>
  <v-row no-gutters v-if="data != null">
    <!-- desktop -->
    <v-col
      cols="6"
      v-for="(item, index) of data.data"
      :key="`d-${index}`"
      class="d-md-block d-none px-1"
    >
      <v-row no-gutters class="list-data">
        <v-col class="text-title" v-if="item.title != ''">
          {{ item.title }}
        </v-col>
        <v-col class="text-value" :class="{ 'text-left': item.title == '' }">
          {{ item.value }}
        </v-col>
      </v-row>
    </v-col>
    <!-- mobile -->
    <v-col
      cols="12"
      v-for="(item, index) of data.dataMobile"
      :key="`m-${index}`"
      class="d-block d-md-none"
    >
      <v-row no-gutters class="list-data">
        <v-col class="text-title" v-if="item.title != ''">
          {{ item.title }}
        </v-col>
        <v-col class="text-value" :class="{ 'text-left': item.title == '' }">
          {{ item.value }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <div class="text-center" v-else>
    {{ $t("empty_data") }}
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.list-data {
  border-bottom: 1px solid #ebeff2;
  padding: 12px 8px 0 8px;
  height: 100%;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
  line-height: 18px;
}
.text-title {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}
</style>
