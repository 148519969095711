<template>
  <CustomSection class="head-family">
    <!-- <template v-if="listContent != null">
      <card-profile-patient :data="listContent[0]" />
    </template> -->
    <template v-if="dataList != null">
      <card-profile-patient :data="dataList[0]" />
    </template>
  </CustomSection>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
// import API from "../../../../service/api_service";
// import Auth from "../../../../service/auth_service";
// import moment from "moment";
import CardProfilePatient from "@/components/v2/card/card_profile_patient.vue";

export default {
  components: {
    CustomSection,
    CardProfilePatient,
  },
  data: () => ({
    // dateNow: moment().format("DD MMM YYYY, dddd"),
    // monthNow: moment().format("MMMM YYYY"),
    // listContent: null,
    dataList: null,
  }),
  watch: {
    // filterMedicalRecord() {
    //   this.$store.commit("setHelper", {
    //     ...this.$store.state.helper,
    //     filter_time: this.filterMedicalRecord,
    //   });
    //   this.getProfile();
    // },
    listData(){
      this.dataList = this.listData;
    }
  },
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
  },
  /*
  created() {
    this.filterMedicalRecord = this.$store.state.helper.filter_time;
    this.getProfile();
    console.log(this.listContent,'content')
  },
  
  methods: {
    async getProfile() {
      try {
        const profileMedic = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-by-medical-record?show_by=${this.filterMedicalRecord}&medical_record_number=${this.$store.state.paramOrderNumber}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        const { customer_info } = profileMedic.results;
        console.log(customer_info);
        this.listContent = [
          {
            id: 1,
            // sort desktop
            data: [
              {
                title: "",
                value: `${customer_info.patientTitle} ${customer_info.patientName}`,
              },
              {
                title: this.$t("field.id_card"),
                value:
                  customer_info.id_card_number == null
                    ? "-"
                    : customer_info.id_card_number,
              },
              {
                title: "",
                value:
                  customer_info.address == null ? "-" : customer_info.address,
              },
              {
                title: this.$t("page.profile.medical_record"),
                value:
                  customer_info.medicalRecordNumber == null
                    ? "-"
                    : customer_info.medicalRecordNumber,
              },
              {
                title: "",
                value: customer_info.city + ", " + customer_info.district,
              },
              {
                title: this.$t("field.gender"),
                value:
                  customer_info.patientGender == null
                    ? "-"
                    : customer_info.patientGender,
              },
              {
                title: "",
                value:
                  customer_info.province +
                  " " +
                  customer_info.postal_code +
                  ", " +
                  customer_info.country,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  customer_info.maritalStatus == null
                    ? "-"
                    : customer_info.maritalStatus,
              },
              {
                title: this.$t("field.email"),
                value: customer_info.email == null ? "-" : customer_info.email,
              },
              {
                title: this.$t("field.religion"),
                value:
                  customer_info.religionName == null
                    ? "-"
                    : customer_info.religionName,
              },
              {
                title: this.$t("field.mobile_phone"),
                value:
                  customer_info.mobile_phone == null
                    ? "-"
                    : customer_info.mobile_phone,
              },
              {
                title: this.$t("field.ethnic"),
                value:
                  customer_info.ethnic == null ? "-" : customer_info.ethnic,
              },
              {
                title: this.$t("field.home_phone"),
                value: customer_info.phone == null ? "-" : customer_info.phone,
              },
              {
                title: this.$t("field.currency"),
                value:
                  customer_info.currency == null
                    ? "IDR"
                    : customer_info.currency,
              },
            ],
            // sort mobile
            dataMobile: [
              {
                title: "",
                value: `${customer_info.patientTitle} ${customer_info.patientName}`,
              },
              {
                title: "",
                value:
                  customer_info.address == null ? "-" : customer_info.address,
              },
              {
                title: "",
                value: customer_info.city + ", " + customer_info.district,
              },
              {
                title: "",
                value:
                  customer_info.province +
                  " " +
                  customer_info.postal_code +
                  ", " +
                  customer_info.country,
              },
              {
                title: this.$t("field.email"),
                value: customer_info.email == null ? "-" : customer_info.email,
              },
              {
                title: this.$t("field.mobile_phone"),
                value:
                  customer_info.mobile_phone == null
                    ? "-"
                    : customer_info.mobile_phone,
              },
              {
                title: this.$t("field.home_phone"),
                value: customer_info.phone == null ? "-" : customer_info.phone,
              },
              {
                title: this.$t("field.id_card"),
                value:
                  customer_info.id_card_number == null
                    ? "-"
                    : customer_info.id_card_number,
              },
              {
                title: this.$t("page.profile.medical_record"),
                value:
                  customer_info.medicalRecordNumber == null
                    ? "-"
                    : customer_info.medicalRecordNumber,
              },
              {
                title: this.$t("field.gender"),
                value:
                  customer_info.patientGender == null
                    ? "-"
                    : customer_info.patientGender,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  customer_info.maritalStatus == null
                    ? "-"
                    : customer_info.maritalStatus,
              },
              {
                title: this.$t("field.religion"),
                value:
                  customer_info.religionName == null
                    ? "-"
                    : customer_info.religionName,
              },
              {
                title: this.$t("field.ethnic"),
                value:
                  customer_info.ethnic == null ? "-" : customer_info.ethnic,
              },
              {
                title: this.$t("field.currency"),
                value:
                  customer_info.currency == null
                    ? "IDR"
                    : customer_info.currency,
              },
            ],
          },
        ];
      } catch (error) {
        console.log(error);
      }
    },
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(par) {
      if (!par) return null;
      return moment(par).format("DD-MMM-YYYY").toUpperCase();
    },
    formatHour(par) {
      if (!par) return null;
      return moment(par).format("H:mm").toUpperCase();
    },
  },
  */
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.center-empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-switch {
  color: $main_2;
  margin-right: 8px;
}

.h4 {
  color: $main_2;
}
.pointer {
  cursor: pointer;
}
.card-chart {
  position: relative;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}

.v-tabs {
  width: 720px;
}

.center-chart {
  background: transparent;
  height: 80%;
  width: 80%;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 72px;
    margin-bottom: 0;
  }
}
.label-doughnut {
  align-self: center;
}
.doughnut-title {
  padding: 4px;
}
.chart-title {
  padding: 4px 12px;
}

.v-input__control {
  height: 30px;
}
.v-input__slot {
  height: 30px;
}

.v-select__selections {
  width: 30px;
  height: 30px;
}

.sticky-table-footer {
  td {
    font-weight: bold;
  }
}

.card-chart .v-data-table {
  td {
    font-size: 12px;
  }
}

.table-patient {
  th {
    .text-gray {
      color: $main_2;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  td {
    .text-gray {
      color: $gray_table;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .text-black {
      color: $black_table;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

// card recomended
.card-popular {
  margin-bottom: 16px;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.btn-detail {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  background-color: #ffffff !important;
  color: #1f3c87;
  font-size: 13px;
}

// START CSS ONGOING TEST & HISTORY
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}

a {
  text-decoration: none;
}

.v-application p {
  margin-bottom: 9px;
}

.text-field {
  max-width: 70%;
  margin-top: 6px;
  margin-left: 15px;
}

.card-top {
  height: 60px;
}

// stylefilterTab
.header {
  margin-bottom: 11px;
  margin-top: 2px;
}

.tagline {
  font-size: 20px;
  font-weight: bold;
  color: #1f3c87;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
}
.v-label {
  font-size: 12px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 130px;
  padding: 0 16px;
}

// endstylefilterTab
.card--order {
  border-radius: 6px;
  background: #fff;
  margin-top: 20px;
  border: 1px solid #e0e0e0;
  font-size: 12px;
}
.img-branch {
  border-radius: 6px 0 0 6px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.order-number {
  font-size: 16px;
  font-weight: bold;
  color: #00a4b3;
  padding-top: 8px !important;
}
.subtitle-order {
  color: #1f3c87;
  font-weight: 700;
}
.button-actions {
  background-color: #00a4b3 !important;
  border-radius: 4px;
  color: white !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  //   text-transform: capitalize;
  // height: 30px !important;
  // min-width: 105px !important;
}
.button-qr {
  border-radius: 4px;
  color: #33344f !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  //   text-transform: capitalize;
  // height: 30px !important;
  // min-width: 105px !important;
}
.paid {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: #04b300 !important;
}
.failure {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  color: #ff0000 !important;
}
.unpaid {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  color: #ff0000 !important;
}
.btn-cancel {
  height: 30px !important;
  min-width: 64px !important;
}
.iconsSet {
  cursor: pointer;
}
.goneText {
  display: none;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header--active::before {
  opacity: 0 !important;
}
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: transparent !important;
}
.section-expand {
  padding-bottom: 8px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
}
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}
.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #6dc6d6;
  font-weight: bold;
}

.head-vir2:hover {
  background: #6dc6d6 !important;
}

.head-vir2 th {
  color: #000 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.href-link {
  color: #1f3c87;
  font-weight: 600;
}
.uppercase-input input,
.v-input {
  text-transform: capitalize !important;
}
.button-rating {
  cursor: pointer;
  background-color: #012257;
  color: white;
  height: 25px;
  width: 85px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px 0px 0px 5px;
}
.button-rating-sm {
  cursor: pointer;
  background-color: #012257;
  color: white;
  height: 25px;
  width: 85px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}
.button-rating-done {
  background-color: #012257;
  color: white;
  height: 25px;
  width: 85px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px 0px 0px 5px;
}
.button-rating-done-sm {
  background-color: #012257;
  color: white;
  height: 25px;
  width: 85px;
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}
.button-irisan {
  background-color: #012257;
  padding: 0px 12px 12px 12px;
  color: white;
  height: 25px;
  width: 85px;
}
.button-transparent {
  background-color: white;
  padding: 18px 25px 0px 12px;
  color: white;
  height: 25px;
  width: 85px;
  border-radius: 0px 14px 0px 0px;
}
.border-5 {
  padding-top: 20px;
}
.border-b5 {
  margin-bottom: -5px !important;
}
.border-b3 {
  margin-bottom: -3px !important;
  padding-left: 25px !important;
}
@media screen and (max-width: 912px) {
  .border-side {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .border-top {
    padding-top: 0px !important;
  }
  .order-number {
    padding-top: 0px !important;
  }
  .show-btn-nav {
    display: none !important;
  }
  .hide-btn-nav {
    display: block !important;
  }
  .button-action-set {
    padding-bottom: 16px !important;
  }
  .top-mobile-none {
    padding-top: 0px !important;
  }
  .bottom-mobile-none {
    padding-bottom: 0px !important;
  }
}
@media screen and (min-width: 913px) {
  .border-5 {
    padding-top: 0px;
  }
  .border-side {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .show-btn-nav {
    display: block;
  }
  .hide-btn-nav {
    display: none;
  }
  .button-action-set {
    // padding-top: 15px !important;
    padding-bottom: 11px !important;
    padding-right: 11px !important;
  }
  .delete-padding-left {
    padding-left: 0px !important;
  }
  .delete-padding-top {
    padding-top: 0px !important;
  }
}
@media screen and (min-width: 1026px) {
  .border-side {
    padding-left: 0px !important;
  }
  .border-5 {
    padding-top: 15px;
  }
  .delete-padding-top {
    padding-top: 12px !important;
  }
}
.top-40 {
  margin-top: 40px !important;
}
.set-image-bottom {
  padding-bottom: 12px !important;
}
// .set-image-bottom-active {
//   padding-bottom: 0px !important;
// }

.card-cart {
  width: auto;
  height: 60px;
}

@media only screen and (max-width: 600px) {
  .card-cart {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .text-field {
    margin-left: 10px !important;
    max-width: 75%;
  }

  .bell-mobile {
    margin-left: -24px;
  }

  .user-card {
    max-width: 56% !important;
    margin-top: -41px;
    margin-left: 153px;
  }

  .notification {
    height: 55px;
    border-radius: 3px;
    margin-left: -97px;
    margin-top: 4px;
  }

  .cart-right {
    z-index: 1;
    margin-left: -101px;
  }
}

.cart-right {
  margin-left: 17px;
}
.align-end {
  text-align: end;
}
.bottom-none {
  padding-bottom: 0px !important;
}
.top-none {
  padding-top: 0px !important;
}
.column-queue {
  margin-top: -28px;
}
.head-family {
  padding: 0 !important;
}
.v-data-footer {
  padding: 0 !important;
}
//barcode
.head-bc {
  background: #00a4b3;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  padding: 0 15px;
}
.title-head-bc {
  padding-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  margin-bottom: -10px;
}
.title-head-bc2 {
  padding-bottom: 0px;
  font-style: normal;
  font-size: 14px;
  color: #ffffff;
  padding-top: 0;
}
.title-bc {
  color: #000000;
  font-weight: 600;
}
.title-bc-plant {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}
.title-bc-rgb {
  color: rgba(0, 0, 0, 0.64);
}
.ic-close {
  position: absolute;
  top: 0;
  right: 0;
}
.buletL {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: aquamarine;
  box-shadow: inset 0 0 3px grey;
}
.buletR {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(50%, -50%);
  background: white;
  box-shadow: aquamarine;
  box-shadow: inset 0 0 3px grey;
}
.strip {
  position: absolute;
  height: 20px;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-around;
  transform: translateY(-50%);
  padding: 0 50px;
}
.strip-item {
  border-radius: 10px;
  width: 30px;
  background: white;
  box-shadow: inset 0 0 3px grey;
}
</style>
