<template>
  <div class="payment-info">
    <!-- <template v-if="!loadActive">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </template>
    <template v-else> -->
    <v-data-table
      :options.sync="options"
      :headers="headers"
      :items="paymentList"
      dense
      mobile-breakpoint="0"
      class="table-payment-info"
      hide-default-footer
      :items-per-page="-1"
      :loading="myloadingvariable"
    >
      <template v-slot:[`header.invoice_number`]="{ header }">
        <span
          tabindex="0"
          style="background: #ddd !important; width: 50px !important"
          >{{ header.text }}</span
        >
      </template>
      <template v-slot:top>
        <v-row
          class="d-flex flex align-center justify-space-between px-0 py-3 pa-md-2"
        >
          <v-col cols="10" md="10">
            <v-toolbar-title class="title"
              >{{ $t("page.profile.payment_info") }}
            </v-toolbar-title>
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="2"
            md="2"
            v-if="itemLength >= 1"
          >
            <div>
              <v-select
                class="footerHide"
                v-model="perPage"
                item-text="text"
                :items="itemShowing"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <tr class="tr-stretch sticky-popup-payment">
          <template v-if="item.status == `paid`">
            <td class="sticky-popup-pay-dt text-center">
              <a
                @click="
                  openDialogInvoice(
                    item.homecare,
                    item.order_number,
                    item.subscription
                  )
                "
                >{{ item.invoice_number }}</a
              >
            </td>
          </template>
          <template v-else>
            <td class="sticky-popup-pay-dt" style="text-align: center">
              {{ item.invoice_number }}
            </td>
          </template>
          <template v-if="item.payment_date != null">
            <td>{{ formatDate(item.payment_date) }}</td>
          </template>
          <template v-else>
            <td style="text-align: center">-</td>
          </template>
          <td class="text-center">
            <template v-if="item.status != `paid`">
              <span class="mb-2">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="openInstructionsInfo(item)"
                      v-bind="attrs"
                      v-on="on"
                      style="color: orange; font-size: 16px"
                      v-if="item.midtrans_token !== null"
                    >
                      mdi-comment-alert-outline
                    </v-icon>
                    <v-icon
                      small
                      @click="openInstructionsInfoQris(item)"
                      v-bind="attrs"
                      v-on="on"
                      style="color: orange; font-size: 16px"
                      v-else
                    >
                      mdi-comment-alert-outline
                    </v-icon>
                  </template>
                  <span>{{
                    $t("page.my_order.view_payment_instructions")
                  }}</span>
                </v-tooltip>
              </span>
            </template>
            <span
              :class="
                item.status == 'paid'
                  ? 'text-capitalize status--paid'
                  : 'text-capitalize status--unpaid'
              "
            >
              {{ item.status }}
            </span>
          </td>
          <td class="widthSpace">{{ item.plant }}</td>
          <!-- <td>{{ item.patient_name }}</td> -->
          <td class="widthSpace">{{ item.payor }}</td>
          <!-- <td>
            <ul>
              <li v-for="(isi, index) of item.list_product" :key="index">
                {{ isi }}
              </li>
            </ul>
          </td> -->
          <td>
            IDR {{ formatPrice(item.amount_total - item.discount_total) }}
          </td>
        </tr>
      </template>
      <template v-slot:[`body.append`]>
        <tr class="tr-stretch sticky-popup-payment">
          <td colspan="7" class="sticky-popup-pay-dt text-center">
            <div class="d-flex justify-start justify-md-end">
              <table>
                <tr>
                  <td class="pr-3">{{ $t("page.profile.total_per_page") }}</td>
                  <td class="px-3">
                    :
                    {{
                      `IDR ${formatPrice(
                        total.amount_total - total.discount_total
                      )}`
                    }}
                  </td>
                  <td class="px-3">{{ $t("page.profile.total_payment") }}</td>
                  <td>: {{ `IDR ${formatPrice(itemsTotalAllPayment)}` }}</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:footer v-if="itemLength > 1">
        <v-row
          class="d-flex flex align-center justify-space-between footer-payment"
        >
          <v-col cols="12" md="6">
            <p class="footer-showing">
              showing {{ itemShowStart }} to {{ itemShowStop }} of
              {{ itemsTotal }}entries
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex justify-end">
              <custom-pagination
                @changePage="val => (page = val)"
                :totalPage="itemLength"
                :activePage="page"
              />
            </div>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <!-- </template> -->
    <!-- start modal popup order detail (dialog_order mcu)-->
    <DialogInvoice
      :item="dataDialogInvoice"
      :tableFilter="tableInvoice"
      :tableFilterDeal="tableInvoiceDeal"
      :tampilkan="dialog_invoice"
      :prodName="prodName"
      @toggleDialog="is_show => toggleDialogInvoice(is_show)"
      v-if="dialog_invoice"
    />

    <DialogInvoiceHomecare
      :item="dataDialogInvoiceHomecare"
      :tampilkan="dialog_invoice_homecare"
      @toggleDialog="is_show => toggleDialogInvoice(is_show)"
      v-if="dialog_invoice_homecare"
    />

    <v-dialog v-model="dialogQr" width="500" >
      <v-card>
        <v-container>
          <v-row style="min-height: 50px;">
            <v-col cols="9" style="padding-bottom: 0px"></v-col>
            <v-col
              cols="3"
              class="d-flex align-center justify-end"
              style="padding-bottom: 0px"
            >
              <v-btn @click="dialogQr = false" class="btn-cancel-qr-pay">
                <IconCancel />
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-card-text style="position: relative">
          <v-col
            cols="12"
            class="d-flex align-center justify-center"
            style="padding-top: 5px; padding-bottom: 22px"
          >
            <img :src=urlQrcode style="max-width: 450px;">
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import API from "../../../../service/api_service";
import Auth from "../../../../service/auth_service";
import DialogInvoice from "@/components/v2/dialog/dialogInvoice.vue";
import DialogInvoiceHomecare from "@/components/v2/dialog/dialogInvoiceHomecare.vue";
import CustomPagination from "@/components/v3/pagination.vue";
import IconCancel from "@/components/elements/IconCancel.vue";

export default {
  components: {
    DialogInvoice,
    DialogInvoiceHomecare,
    CustomPagination,
    IconCancel
  },
  data: () => ({
    dataDialogInvoice: {
      order_number: null,
      subscription: false,
      list: [],
      data: {
        metadata: {},
      },
    },
    dataDialogInvoiceHomecare: {
      order_number: null,
    },
    dialog_invoice: false,
    dialog_invoice_homecare: false,
    headers: [],
    paymentList: [],
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    footerTest: [25, 50, 70, -1],
    perPage: 25,
    groupItemCategory: [],
    tableInvoice: [],
    tableInvoiceDeal: [],
    subscription: false,
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    myloadingvariable: true,
    itemsTotalAllPayment: 0,
    //defaultShowing: 25,
    linkPaymentSuccess: "payment-success",
    linkPaymentPending: "payment-pending",
    prodName: "",
    urlQrcode: "",
    dialogQr: false
  }),
  watch: {
    page: function () {
      this.getPaymentList();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getPaymentList();
      }
    },
  },
  computed: {
    total() {
      const sums = {
        amount_total: 0,
        discount_total: 0,
      };
      if (this.paymentList.length > 0) {
        this.paymentList.forEach(item => {
          if (item.status == "paid") {
            sums.amount_total += parseFloat(item.amount_total);
            sums.discount_total += parseFloat(item.discount_total);
          }
        });
      }
      return sums;
    },
  },
  created() {
    this.getPaymentList();
    this.headers = [
      {
        text: this.$t("page.profile.invoice_number"),
        value: "invoice_number",
        sortable: false,
        align: "center",
        class: "sticky-popup-pay-hd",
      },
      {
        text: this.$t("page.profile.invoice_date"),
        value: "invoice_date",
        sortable: false,
        align: "center",
        width: "140px",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
        width: "160px",
      },
      {
        text: this.$t("page.profile.branch_location"),
        value: "plant",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.name"),
        value: "patient_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.amount_paid"),
        value: "amount_paid",
        sortable: false,
        align: "center",
        width: "160px",
      },
    ];
  },
  methods: {
    showing(id) {
      console.log(id, "footerTest");
    },
    toggleDialogInvoice(toggle) {
      this.dialog_invoice = toggle;
      this.dialog_invoice_homecare = toggle;
    },
    // async openDialogInvoice(item,subs) {
    //   this.dataDialogInvoice.order_number = item;
    //   this.dataDialogInvoice.subscription = subs;
    //   this.getOrderDetailInvoice(subs);
    //   this.dialog_invoice = true;
    // },
    async openDialogInvoice(homecare, item, subs) {
      if (homecare) {
        this.dataDialogInvoiceHomecare.order_number = item;
        this.dataDialogInvoice.subscription = subs;
      } else {
        this.dataDialogInvoice.order_number = item;
        this.dataDialogInvoice.subscription = subs;
      }
      this.getOrderDetailInvoiceMCU(homecare, subs);
    },
    async getOrderDetailInvoiceMCU(homecare, subs) {
      try {
        if (!homecare) {
          let order_number = this.dataDialogInvoice.order_number;
          const res = await API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
              order_number +
              ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          this.dataDialogInvoice.data = res.results;
          this.dataDialogInvoice.list = res.results.data;
          if (res.results.package_name != undefined) {
            this.prodName = res.results.package_name;
          }

          //cari harga total karena diskon belum kehitung
          let grand_total =
            parseInt(this.dataDialogInvoice.data.metadata.amount_total) -
            parseInt(this.dataDialogInvoice.data.metadata.total_discount);
          this.dataDialogInvoice.data.metadata.grand_total = grand_total;

          let groupCategory = this.dataDialogInvoice.data.data.reduce(
            (r, a) => {
              r[a.sub_category] = [...(r[a.sub_category] || []), a];
              return r;
            },
            {}
          );

          this.groupItemCategory = Object.entries(groupCategory).map(arr => ({
            nameCategory: arr[0],
            dataCategory: arr[1],
          }));

          let filterItem = [];
          this.groupItemCategory.forEach(element => {
            filterItem = [
              ...filterItem,
              { text: element.nameCategory, type: "category" },
            ];
            let groupSubcategory = element.dataCategory.reduce((r, a) => {
              r[
                a.product_name
                // this.$i18n.locale == "id"
                //   ? a.product_name
                //   : a[`product_name_${this.$i18n.locale}`]
              ] = [
                ...(r[
                  a.product_name
                  // this.$i18n.locale == "id"
                  //   ? a.product_name
                  //   : a[`product_name_${this.$i18n.locale}`]
                ] || []),
                a,
              ];
              return r;
            }, {});
            this.groupItemSubategory = Object.entries(groupSubcategory).map(
              arr => ({
                nameSubcategory: arr[0],
                dataSubcategory: arr[1],
              })
            );

            this.groupItemSubategory.forEach(element => {
              filterItem = [
                ...filterItem,
                { text: element.nameSubcategory, type: "subcategory" },
              ];
              element.dataSubcategory.forEach(element => {
                filterItem = [...filterItem, { ...element, type: "item" }];
              });
            });
          });

          // this.tableInvoice = filterItem;
          if (subs != true) {
            this.tableInvoice = filterItem;
          } else {
            let groupCategory =
              this.dataDialogInvoice.data.data[0].products.reduce((r, a) => {
                r[a[`sub_category_${this.$i18n.locale}`]] = [
                  ...(r[a[`sub_category_${this.$i18n.locale}`]] || []),
                  a,
                ];
                return r;
              }, {});

            this.groupItemCategory = Object.entries(groupCategory).map(arr => ({
              nameCategory: arr[0],
              dataCategory: arr[1],
            }));

            let filterItem = [];
            this.groupItemCategory.forEach(element => {
              filterItem = [
                ...filterItem,
                { text: element.nameCategory, type: "category" },
              ];
              let groupSubcategory = element.dataCategory.reduce((r, a) => {
                r[
                  a[`name_${this.$i18n.locale}`]
                  // this.$i18n.locale == "id"
                  //   ? a.product_name
                  //   : a[`product_name_${this.$i18n.locale}`]
                ] = [
                  ...(r[
                    a[`name_${this.$i18n.locale}`]
                    // this.$i18n.locale == "id"
                    //   ? a.product_name
                    //   : a[`product_name_${this.$i18n.locale}`]
                  ] || []),
                  a,
                ];
                return r;
              }, {});
              this.groupItemSubategory = Object.entries(groupSubcategory).map(
                arr => ({
                  nameSubcategory: arr[0],
                  dataSubcategory: arr[1],
                })
              );

              this.groupItemSubategory.forEach(element => {
                filterItem = [
                  ...filterItem,
                  { text: element.nameSubcategory, type: "subcategory" },
                ];
                element.dataSubcategory.forEach(element => {
                  filterItem = [...filterItem, { ...element, type: "item" }];
                });
              });
            });

            this.tableInvoice = this.dataDialogInvoice.list;
            this.tableInvoiceDeal = filterItem;
          }
          this.dialog_invoice = true;
        } else {
          let order_number = this.dataDialogInvoiceHomecare.order_number;
          const resp = await API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail-homecare?order_number=` +
              order_number +
              ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          if (resp.statusCode == 200) {
            this.dataDialogInvoiceHomecare = {};
            const { results } = resp;
            let dataInvoice = {
              order_number: order_number,
              document_info: {
                invoice_number: results.invoice_number,
                invoice_date: results.invoice_date,
                plant_name: results.plant_name,
                medical_record_number: results.medical_record_number,
                practitioner_name: results.practitioner_name,
                payment_method: results.payment_method_name,
                gender: results[`gender_name_${this.$i18n.locale}`],
                currency: results.currency,
                total_payment: results.total_payment,
              },
              customer_info: {
                name: results.name,
                address: results.address,
                email: results.email_contact,
                dob: results.person_dob,
                age: results.age,
                district_city: `${results.subdistrict_name}, ${results.district_name}, ${results.city_name}`,
                postal_province: `${results.province_name} ${results.postal_code}, ${results.country_name}`,
                mobile_phone:
                  results.celluar_contact == null
                    ? "-"
                    : `${results.celluar_contact_code}${results.celluar_contact}`,
                home_contact:
                  results.home_contact == null
                    ? "-"
                    : `${results.home_contact_code}${results.home_contact}`,
              },
              amount_total: 0,
              total_discount: 0,
              grand_total: 0,
              price_discount: 0,
              tables: [],
            };

            results.participant.forEach(item => {
              dataInvoice.tables = [
                ...dataInvoice.tables,
                { type: "name", name: item.patient_name },
              ];
              item.participant_test.forEach(participant => {
                participant.product_data.forEach((category, indexCategory) => {
                  if (indexCategory == 0) {
                    dataInvoice.tables = [
                      ...dataInvoice.tables,
                      {
                        type: "category",
                        category_id: participant.category_name,
                        category_en: participant.category_name_en,
                      },
                    ];
                  }
                  dataInvoice.tables = [
                    ...dataInvoice.tables,
                    {
                      type: "sub_category",
                      sub_category_id: category.product_name,
                      sub_category_en: category.product_name_en,
                    },
                  ];
                  dataInvoice.tables = [
                    ...dataInvoice.tables,
                    {
                      type: "item",
                      product: category,
                    },
                  ];
                  dataInvoice.amount_total =
                    parseFloat(category.product_price) +
                    parseFloat(dataInvoice.amount_total);
                  dataInvoice.total_discount =
                    parseFloat(category.product_discount) +
                    parseFloat(dataInvoice.total_discount);
                  dataInvoice.price_discount =
                    parseFloat(
                      (category.product_discount / 100) * category.product_price
                    ) + parseFloat(dataInvoice.price_discount);
                });
              });
            });
            dataInvoice.grand_total =
              parseFloat(dataInvoice.amount_total) -
              parseFloat(dataInvoice.price_discount);
            this.dataDialogInvoiceHomecare = dataInvoice;
            this.dialog_invoice_homecare = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getOrderDetailInvoiceOld(subs) {
      let order_number = this.dataDialogInvoice.order_number;
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
            order_number +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogInvoice.data = res.results;
        this.dataDialogInvoice.list = res.results.data;

        //cari harga total karena diskon belum kehitung
        let grand_total =
          parseInt(this.dataDialogInvoice.data.metadata.amount_total) -
          parseInt(this.dataDialogInvoice.data.metadata.total_discount);
        this.dataDialogInvoice.data.metadata.grand_total = grand_total;

        let groupCategory = this.dataDialogInvoice.data.data.reduce((r, a) => {
          r[a.sub_category] = [...(r[a.sub_category] || []), a];
          return r;
        }, {});

        this.groupItemCategory = Object.entries(groupCategory).map(arr => ({
          nameCategory: arr[0],
          dataCategory: arr[1],
        }));

        let filterItem = [];
        this.groupItemCategory.forEach(element => {
          filterItem = [
            ...filterItem,
            { text: element.nameCategory, type: "category" },
          ];
          let groupSubcategory = element.dataCategory.reduce((r, a) => {
            r[a.product_name] = [...(r[a.product_name] || []), a];
            return r;
          }, {});
          this.groupItemSubategory = Object.entries(groupSubcategory).map(
            arr => ({
              nameSubcategory: arr[0],
              dataSubcategory: arr[1],
            })
          );

          this.groupItemSubategory.forEach(element => {
            filterItem = [
              ...filterItem,
              { text: element.nameSubcategory, type: "subcategory" },
            ];
            element.dataSubcategory.forEach(element => {
              filterItem = [...filterItem, { ...element, type: "item" }];
            });
          });
        });
        if (subs != true) {
          this.tableInvoice = filterItem;
        } else {
          this.tableInvoice = this.dataDialogInvoice.list;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPaymentList() {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;
        // endpagination showing start of entries

        let resp = await API.get(
          //`${process.env.VUE_APP_API_TRUST}registration/get-referral-fees`,
          //?offset=${startPage}&per_page=${showPage}get-paid-payments
          `${process.env.VUE_APP_API_TRUST}invoices/get-all-payments?offset=${startPage}&per_page=${showPage}&status=paid,unpaid,pending`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        const warning = resp.results.data.some(item => item.status == "unpaid");
        let menu = this.$store.state.listMenu;
        menu[3].warning = warning;
        this.$store.commit("setListMenu", menu);

        this.paymentList = resp.results.data;
        this.itemsTotalAllPayment = resp.results.total_paid;
        this.itemsTotal = resp.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = resp.total;
        }
        if (this.itemShowStop > resp.total) {
          this.itemShowStop = resp.total;
        }
        this.myloadingvariable = false;
        // let respx = await API.get(
        //   `${process.env.VUE_APP_API_TRUST}registration/get-referral-fees`,
        //   {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${Auth.getToken()}`,
        //   }
        // );
        // console.log(respx.results,'referal')
      } catch (error) {
        console.log(error);
      }
    },
    async getPaymentListOld() {
      try {
        //v1.4/invoices/get-paid-payments
        //registration/get-referral-fees
        // get-referral-fees
        let resp = await API.get(
          //`${process.env.VUE_APP_API_TRUST}registration/get-referral-fees`,
          //?offset=${startPage}&per_page=${showPage}
          `${process.env.VUE_APP_API_TRUST}invoices/get-paid-payments`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        this.paymentList = resp.results;
        // let respx = await API.get(
        //   `${process.env.VUE_APP_API_TRUST}registration/get-referral-fees`,
        //   {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${Auth.getToken()}`,
        //   }
        // );
        // console.log(respx.results,'referal')
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },
    sum(key) {
      return this.paymentList.length > 0
        ? this.paymentList.reduce(
            (a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
            0
          )
        : 0;
    },
    openInstructionsInfo(history) {
      if (!history.homecare && history.subscription) {
        this.linkPaymentSuccess = "payment-success-subscribe";
        this.linkPaymentPending = "payment-pending-subscribe";
      }

      if (this.$store.state.midtransIsLoaded) {
        const vuethis = this;
        window.snap.pay(history.midtrans_token, {
          onSuccess: function (result) {
            vuethis.midtransStatus = result.statusCode;
            vuethis.$router.push(
              `/${vuethis.$i18n.locale}/${vuethis.linkPaymentSuccess}${result.finish_redirect_url}&home_care=${history.homecare}`
            );
          },
          onPending: function (result) {
            vuethis.midtransStatus = result.statusCode;
            //console.log(result,'xxxxxxxxxxx')
            API.get(
              `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${history.invoice_number}`,
              {
                Authorization: `Bearer ${Auth.getToken()}`,
              }
            ).then(res => {
              if (res.status_code == 200) {
                vuethis.$router.push(
                  vuethis.$router.push(
                    `/${vuethis.$i18n.locale}/${vuethis.linkPaymentSuccess}${result.finish_redirect_url}&home_care=${history.homecare}`
                  )
                );
              } else {
                vuethis.getPaymentList();
                //vuethis
                // .$swal({
                //   text: vuethis.$t("the_order_has_been_saved"),
                //   showCancelButton: false,
                //   confirmButtonColor: "#f7685b",
                //   confirmButtonText: vuethis.$t("close"),
                // });
              }
            });
          },
          onClose: function () {
            vuethis.getPaymentList();
          },
        });
      }
    },
   async openInstructionsInfoQris(dat) {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-qr-code?invoice_number=${dat.invoice_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
          if(resp.statusCode == 200){
            this.dialogQr = true;
            this.statusQr = true;
            this.urlQrcode = resp.results.url;
            //this.urlQrcode = "https://api.sandbox.midtrans.com/v2/gopay/de6b7ca7-3717-4d47-9eae-be46c2d24d05/qr-code";

          }else if(resp.statusCode == 400){
            // mdi-timetable
            this.$swal({
              icon: "error",
              text: resp.message,
              showCancelButton: false,
              confirmButtonColor: "#f7685b",
              confirmButtonText: this.$t("close"),
            });
          }
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
<style>
.table-payment-info thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-payment-info thead tr:last-child th {
  background: #ddd !important;
  height: 38px !important;
}
.table-payment-info tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
}
.table-payment-info tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.center-empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-switch {
  color: $main_2;
  margin-right: 8px;
}
.td {
  font-size: 12px;
}
.h4 {
  color: $main_2;
}
.list-data {
  border-bottom: 1px solid $gray_2;
  padding: 8px;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.card-chart {
  position: relative;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}

.v-tabs {
  width: 720px;
}

.center-chart {
  background: transparent;
  height: 80%;
  width: 80%;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 72px;
    margin-bottom: 0;
  }
}

.title {
  font-style: normal;
  font-size: 16px !important;
  line-height: 22px;
  color: black;
  /* identical to box height */
  color: #233f78;
  font-weight: 700;
  letter-spacing: 0.01em;
}

.label-doughnut {
  align-self: center;
}
.doughnut-title {
  padding: 4px;
}
.chart-title {
  padding: 4px 12px;
}

.v-input__control {
  height: 30px;
}
.v-input__slot {
  height: 30px;
}

.v-select__selections {
  width: 30px;
  height: 30px;
}

.v-select {
  height: 30px;
  background: #ffb946;
  color: #000;
}

.sticky-table-footer {
  td {
    font-weight: bold;
  }
}

.card-chart .v-data-table {
  td {
    font-size: 12px;
  }
}

.table-patient {
  th {
    .text-gray {
      color: $main_2;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  td {
    .text-gray {
      color: $gray_table;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .text-black {
      color: $black_table;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.theme--light.v-data-table .footerHide {
  border: unset !important;
}
</style>

<style lang="scss">
.sticky-popup-pay-dt {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  background: #fff;
  z-index: 2;
}
.sticky-popup-pay-dt2 {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 160px;
  background: #fff;
  z-index: 2;
}
.sticky-popup-pay-dt3 {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  background: #fff;
  z-index: 2;
}
.sticky-popup-pay-hd {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  background: #ddd !important;
  z-index: 2;
}
.sticky-popup-payment:hover {
  .sticky-popup-pay-dt {
    background: #eee !important;
  }
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr:first-child:hover
    td:first-child {
    background: #eee !important;
  }
}
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}

.status {
  &--paid {
    color: #00a4b3;
  }
  &--unpaid {
    color: orange;
  }
}
.widthSpace {
  white-space: nowrap;
}
.btn-cancel-qr-pay {
  width: 30px !important;
  height: 30px !important;
  border-radius: 100% !important;
  min-width: 25px !important;
  background: white;
  margin-right: -10px;
  margin-top: -30px;
  padding: unset !important;
}
</style>
