<template>
  <div class="card--order">
    <v-row dense>
      <v-col cols="12">
        <card rounded shadow class="your-history-box">
          <template v-slot:header>
            <div class="card__header">
              <div class="d-flex justify-space-between align-center">
                <v-row dense>
                  <v-col cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <heading-card
                        class="heading-card--dark-blue"
                        :title="$t(`tab.your_history`)"
                      />
                      <div class="d-flex justify-end">
                        <v-icon
                          color="#00a4b3"
                          @click="dialogFilterHistory = true"
                          >mdi-filter</v-icon
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="pa-3 pa-md-5">
              <template v-if="!loadingHistory">
                <template v-if="historyPackage.length > 0">
                  <card-order-list
                    v-for="(order, index) of historyPackage"
                    :data="order"
                    :key="index"
                    share
                  ></card-order-list>
                </template>
                <v-row v-else>
                  <v-col>
                    <img
                      src="@/assets/images/banner/coming_soon.png"
                      height="160"
                      class="mx-auto d-flex mb-3"
                    />

                    <p class="mb-0 font-weight-bold text-center">
                      {{ $t("no_data") }}
                    </p>
                  </v-col>
                </v-row>
              </template>
              <icon-loading
                v-else
                class="icon-loading-pagination mx-auto active"
              />
              <!-- pagination history view new  d-none-->
              <v-row
                dense
                class="justify-space-between"
                v-if="itemLengthMedical > 1"
              >
                <v-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-center justify-sm-start align-center"
                  style="padding-right: 0px"
                >
                  <v-subheader>{{ $t("per_page") }} : </v-subheader>
                  <div>
                    <v-select
                      class="footerHide"
                      v-model="perPageMedical"
                      :items="itemShowingMedical"
                    ></v-select>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-center justify-sm-end align-center"
                >
                  <custom-pagination
                    @changePage="val => (pageMedical = val)"
                    :totalPage="itemLengthMedical"
                    :activePage="pageMedical"
                  />
                </v-col>
              </v-row>
            </div>
          </template>
        </card>
      </v-col>
    </v-row>

    <!-- start filter your history order -->
    <v-dialog v-model="dialogFilterHistory" width="400">
      <dialogFilterYourHistory
        :filterActive="filterActiveHistory"
        history
        family
        @changeFilterActive="param => (filterActiveHistory = param)"
      />
    </v-dialog>
    <!-- end filter your history order -->
  </div>
</template>

<script>
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import Card from "@/components/v2/card/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import CardOrderList from "@/components/v3/cards/card-order-list.vue";
import dialogFilterYourHistory from "@/components/v3/popup/dialogFilterYourHistory.vue";
import moment from "moment";
import IconLoading from "@/components/elements/IconLoading.vue";
import CustomPagination from "@/components/v3/pagination.vue";

export default {
  components: {
    Card,
    HeadingCard,
    CardOrderList,
    dialogFilterYourHistory,
    IconLoading,
    CustomPagination,
    //  IconCancel,
  },
  data: () => ({
    url_his: "",
    tableInvoice: [],
    urlTemp: "",
    pageMedical: 1,
    itemsTotalMedical: 0,
    itemLengthMedical: 1,
    perPageMedical: 5,
    itemShowingMedical: [5, 10, 15],
    historyPackage: [],
    dialogFilterHistory: false,
    filterActiveHistory: {
      filter_date: [],
      branch: "",
      type_test: "",
      shared: false,
    },
    loadingHistory: false,
    pagination_history: {
      per_page: 5,
      active: 1,
    },
  }),
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  watch: {
    // pagination watch getHistory New
    pageMedical: function () {
      this.getHistoryTest();
    },
    perPageMedical: function () {
      if (this.pageMedical != 1) {
        this.pageMedical = 1;
      } else {
        this.getHistoryTest();
      }
    },
    // pagination watch
    pagination_history: {
      handler(newVal, oldVal) {
        if (newVal.per_page != oldVal.per_page) {
          this.pagination_history.active = 0;
        } else {
          this.getHistoryTest();
        }
      },
      deep: true,
    },

    filterActiveHistory: {
      handler() {
        if (this.pageMedical == 1) {
          this.getHistoryTest();
        } else {
          this.pageMedical = 1;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.getHistoryTest();
  },
  created() {
    this.urlTemp = process.env.VUE_APP_BASE_URL;
    this.url_his = process.env.VUE_APP_URL_HIS;
  },
  props: ["data"],
  methods: {
    async getHistoryTest() {
      this.loadingHistory = true;
      let startPage = (this.pageMedical - 1) * this.perPageMedical;
      let showPage = this.perPageMedical;
      const { shared, filter_date, branch, type_test } =
        this.filterActiveHistory;
      const sortedDate = [...filter_date].sort((a, b) => {
        return moment(a) - moment(b);
      });
      await API.get(
        `${
          process.env.VUE_APP_API_TRUST
        }registration/get-order-list?approved=true&offset=${startPage}&per_page=${showPage}&medical_record_number=${
          this.$store.state.paramMedicalRecord
        }&shared=${shared ? true : ""}&start_date=${
          sortedDate.length > 0 ? sortedDate[0] : ""
        }&end_date=${
          sortedDate.length > 0
            ? sortedDate.length > 1
              ? sortedDate[1]
              : sortedDate[0]
            : ""
        }${
          type_test == null || type_test == "" ? "" : `&type=${type_test}`
        }&plant_id=${branch == null ? "" : branch}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        if (res.statusCode === 200) {
          const testProduct = res.results.filter(dataProduct => {
            return dataProduct.status != "failure";
          });
          this.historyPackage = testProduct;
          this.itemsTotalMedical = res.total;
          this.itemLengthMedical = Math.ceil(this.itemsTotalMedical / showPage);
        }
        this.loadingHistory = false;
      });
    },
  },
};
</script>
