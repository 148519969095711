<template>
  <v-card-text class="px-3 px-md-5">
    <v-row>
      <v-col cols="12">
        <h3 class="black--text">{{ $t("R.ris_result") }}</h3>
        <!-- RIS list -->
        <template v-if="risPatology != null">
          <div v-for="(item, index) of risPatology" :key="index">
            <v-card color="basil" flat>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-no-wrap text-center">
                              {{ $t("R.observed") }}
                            </th>
                            <th class="text-no-wrap text-center">
                              {{ $t("R.result") }}
                            </th>
                            <th class="text-no-wrap text-center">
                              {{ $t("R.description") }}
                            </th>
                            <th class="text-no-wrap text-center">
                              {{ $t("R.conclusion") }}
                            </th>
                            <th class="text-no-wrap text-center">
                              {{ $t("R.approval_by") }}
                            </th>
                          </tr>

                          <tr>
                            <td class="text-no-wrap text-center">
                              {{ item.product_name }}
                            </td>
                            <td class="text-no-wrap text-center">
                              {{ item.radiologist_result }}
                            </td>
                            <td class="text-no-wrap text-center">
                              {{ item.description }}
                            </td>
                            <td class="text-no-wrap text-center">
                              {{ item.comment == null ? "-" : item.comment }}
                            </td>
                            <td class="text-no-wrap text-center">
                              {{ item.approval_by_name }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>

              <zoom-on-hover
                v-if="item.file[activeImage[index]] != undefined"
                :img-normal="item.file[activeImage[index]].file_link"
                :scale="5"
                class="my-5"
              ></zoom-on-hover>
              <div class="list-image mb-5">
                <img
                  :class="{ active: activeImage[index] == fileIndex }"
                  :src="image.file_link"
                  v-for="(image, fileIndex) of item.file"
                  :key="fileIndex"
                  class="mr-5"
                  @click="changeActiveImage(index, fileIndex)"
                  :alt="
                    $i18n.locale == 'id'
                      ? image.product_name
                      : image[`product_name_${$i18n.locale}`]
                  "
                />
              </div>
            </v-card>
          </div>
        </template>
        <h3 class="black--text">{{ $t("R.lis_result") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <v-simple-table class="super-dense no-hover-table">
              <template v-slot:default>
                <thead class="no-pad">
                  <tr class="grey-color head-vir2">
                    <th class="text-center tb-wide" style="width: 30%">
                      {{ $t("R.examination_code") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 30%">
                      {{ $t("R.examination_name") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 40%">
                      {{ $t("R.result") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="listPatology != null">
                    <tr v-for="(item, index) of listPatology" :key="index">
                      <td
                        v-if="item.type == 'category'"
                        colspan="6"
                        class="category-blue"
                      >
                        {{ item.category_id }} | {{ item.category_en }}
                      </td>
                      <td
                        v-else-if="item.type == 'product'"
                        colspan="6"
                        class="grey-color bold"
                      >
                        {{ item.product }}
                      </td>
                      <template v-else>
                        <td
                          class="text-left td-custom text-no-wrap"
                          :class="{
                            true: item.status == 'Critical',
                            false: item.status !== 'Critical',
                          }"
                        >
                          <span class="pl-3">
                            {{ item.product_code }}
                          </span>
                        </td>
                        <td class="text-left text-no-wrap">
                          {{
                            $i18n.locale == "id"
                              ? item[`product_item_name`]
                              : item[`product_item_name_${$i18n.locale}`]
                          }}
                        </td>
                        <td
                          class="text-left text-no-wrap"
                          :class="{
                            true: item.status == 'Critical',
                            false: item.status != 'Critical',
                          }"
                        >
                          {{ item.result_value_post }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="3">
                      {{ $t("no_data") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </card>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import Card from "@/components/v2/card/index.vue";

export default {
  components: {
    Card,
  },
  props: {
    result: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    listPatology: null,
    risPatology: null,
    activeImage: {},
  }),
  created() {
    this.risPatology = this.result.data.ris;
    for (let index = 0; index < this.risPatology.length; index++) {
      this.activeImage[index] = 0;
    }
    let lis = [];
    let groupedByCategory = this.result.data.lis.reduce((acc, curr) => {
      if (!acc[curr[`sub_category_name_${this.$i18n.locale}`]]) {
        acc[curr[`sub_category_name_${this.$i18n.locale}`]] = [];
      }
      acc[curr[`sub_category_name_${this.$i18n.locale}`]].push(curr);
      return acc;
    }, {});
    Object.keys(groupedByCategory).forEach(category => {
      lis = [
        ...lis,
        {
          type: "category",
          category_id: groupedByCategory[category][0].sub_category_name_id,
          category_en: groupedByCategory[category][0].sub_category_name_en,
        },
      ];
      let groupedByProductName = groupedByCategory[category].reduce(
        (acc, curr) => {
          if (!acc[curr[`product_name_${this.$i18n.locale}`]]) {
            acc[curr[`product_name_${this.$i18n.locale}`]] = [];
          }
          acc[curr[`product_name_${this.$i18n.locale}`]].push(curr);
          return acc;
        },
        {}
      );
      Object.keys(groupedByProductName).forEach(product => {
        lis = [
          ...lis,
          {
            type: "product",
            product: product,
          },
        ];
        lis = [...lis, ...groupedByProductName[product]];
      });
    });
    this.listPatology = lis;
  },
  methods: {
    changeActiveImage(index, value) {
      const active = this.activeImage;
      this.activeImage = {};
      this.activeImage = active;
      this.activeImage[index] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}
.v-data-table table tr td,
.v-data-table table tr th,
table tr td,
table tr th {
  font-size: 12px !important;
}
.normal-label {
  text-transform: capitalize;
  color: $green;
}
.abnormal-label {
  color: $red_2;
  text-transform: capitalize;
}

// mobile
.mobile {
  display: none;
}
.mobile .table-inside tr td:first-child {
  width: 20%;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 200px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}

// radiology
.list-image {
  display: flex;
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    border: 4px solid $gray_8;
    &.active {
      border: 4px solid $main_2;
    }
  }
}
</style>
