var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type == 'A')?_c('div',[_c('div',{staticClass:"d-flex align-center box-result"},[_c('div',{staticClass:"slider-marker",class:_vm.resultPercentAll < 0 || _vm.resultPercentAll > 100 ? 'redSlider' : 'greenSlider',style:({
        left: _vm.resultPercentAll + '%',
      })},[_vm._v(" "+_vm._s(_vm.resultAll)+" ")]),_c('div',{staticClass:"arrow",class:_vm.resultPercentAll < 0 || _vm.resultPercentAll > 100 ? 'redArrow' : 'greenArrow',style:({
        left: _vm.resultPercentAll + '%',
      })})]),((_vm.maximumValueAll != null &&  _vm.maximumValueAll != -1 ) && (_vm.minimumValueAll != null && _vm.minimumValueAll != -1 ))?[_c('div',{staticClass:"slider"},[_c('div',{staticClass:"grey",style:({
          width: _vm.sliderWidthAll + 20 + '%',
          marginLeft: _vm.sliderLeftAll - 10 + '%',
        })})]),_c('div',{staticClass:"labeling"},[_c('span',{staticClass:"limit",style:({ left: 15 + '%' })},[_vm._v(_vm._s(_vm.minimumValueAll))]),_c('span',{staticClass:"limit",style:({ left: 85 + '%' })},[_vm._v(_vm._s(_vm.maximumValueAll))])])]:_vm._e(),(_vm.minimumValueAll == null || _vm.minimumValueAll == -1)?[_c('div',{staticClass:"slider"},[_c('div',{staticClass:"grey",style:({
          width: _vm.sliderWidthAll + '%',
        })})]),_c('div',{staticClass:"labeling"},[_c('span',{staticClass:"limit",style:({ left: 50 + '%' })},[_vm._v(_vm._s(_vm.maximumValueAll))])])]:_vm._e(),(_vm.maximumValueAll == null || _vm.maximumValueAll == -1)?[_c('div',{staticClass:"slider"},[_c('div',{staticClass:"grey",style:({
          marginLeft: _vm.sliderLeftAll + 25 + '%',
        })})]),_c('div',{staticClass:"labeling"},[_c('span',{staticClass:"limit",style:({ left: 50 + '%' })},[_vm._v(_vm._s(_vm.minimumValueAll))])])]:_vm._e()],2):(_vm.type == 'B')?_c('div',[_c('div',{staticClass:"d-flex align-center box-result-b"},[_c('div',{staticClass:"slider-marker-b",style:({
        left: _vm.resultPercentAll + '%',
      })})]),((_vm.maximumValueAll != null &&  _vm.maximumValueAll != -1 ) && (_vm.minimumValueAll != null && _vm.minimumValueAll != -1 ))?[_c('div',{staticClass:"slider-b"},[_c('div',{staticClass:"grey",style:({
          width: _vm.sliderWidthAll + 20 + '%',
          marginLeft: _vm.sliderLeftAll - 10 + '%',
        })})]),_c('div',{staticClass:"labeling"},[_c('span',{staticClass:"limit",style:({
          left: 15 + '%',
        })}),_c('span',{staticClass:"limit",style:({ left: 85 + '%' })})])]:_vm._e(),(_vm.minimumValueAll == null || _vm.minimumValueAll == -1)?[_c('div',{staticClass:"slider-b"},[_c('div',{staticClass:"grey",style:({
          width: _vm.sliderWidthAll + '%',
        })})]),_c('div',{staticClass:"labeling"},[_c('span',{staticClass:"limit",style:({
          left: 15 + '%',
        })}),_c('span',{staticClass:"limit",style:({ left: 85 + '%' })})])]:_vm._e(),(_vm.maximumValueAll == null || _vm.maximumValueAll == -1)?[_c('div',{staticClass:"slider-b"},[_c('div',{staticClass:"grey",style:({
          width: _vm.sliderWidthAll + '%',
          marginLeft: _vm.sliderLeftAll + 25 + '%',
        })})]),_c('div',{staticClass:"labeling"},[_c('span',{staticClass:"limit",style:({
          left: 15 + '%',
        })}),_c('span',{staticClass:"limit",style:({ left: 85 + '%' })})])]:_vm._e()],2):(_vm.type == 'C')?_c('div',{staticStyle:{"padding-bottom":"5px"}},[[_vm._m(0),_vm._m(1)]],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-b"},[_c('div',{staticClass:"grey",staticStyle:{"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"labeling"},[_c('span',{staticClass:"limit",staticStyle:{"left":"100%"}}),_c('span',{staticClass:"limit",staticStyle:{"left":"100%"}})])
}]

export { render, staticRenderFns }