<template>
  <v-col cols="12" class="vital-signs">
    <div class="card-vital">
      <v-row>
        <v-col cols="12" class="pt-0">
          <div class="table-responsive">
            <div
              class="d-flex justify-space-between align-center"
              style="height: 25px"
            >
              <p class="ungu">
                {{ $t("page.profile.vital_signs") }}
              </p>
              <div>
                <p class="biru">
                  {{ $t("page.profile.last_update") }} : {{ dateNow }}
                </p>
              </div>
            </div>
            <v-divider style="border: 1px solid #00a4b3"></v-divider>

            <!-- <v-data-table
              :headers="headerTableVital"
              :items="itemVital"
              hide-default-footer
              :mobile-breakpoint="0"
              class="mt-4"
            > -->
            <v-data-table
              :headers="dataHeaderVital"
              :items="dataItemHeaderVital"
              hide-default-footer
              :mobile-breakpoint="0"
              class="mt-4"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td valign="top" class="text-center">
                    {{ item.blood_pressure }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.heart_rate }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.body_temperature }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.respiratory_rate }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>

        <v-col cols="12" class="pt-0">
          <div class="table-responsive">
            <p class="ungu mb-2">
              {{ $t("page.profile.anthropometry") }}
            </p>
            <v-divider style="border: 1px solid #00a4b3"></v-divider>

            <!-- <v-data-table
              :headers="headerTableAnthA"
              :items="itemAnthA"
              hide-default-footer
              :mobile-breakpoint="0"
              class="mt-4 mb-4"
            > -->
            <v-data-table
              :headers="dataTableAnthA"
              :items="dataItemTableAnthA"
              hide-default-footer
              :mobile-breakpoint="0"
              class="mt-4 mb-4"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td valign="top" class="text-center">
                    {{ item.body_height }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.body_weight }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.body_mass_index }}
                  </td>
                </tr>
              </template>
            </v-data-table>

            <!-- <v-data-table
              :headers="headerTableAnthB"
              :items="itemAnthB"
              hide-default-footer
              :mobile-breakpoint="0"
            > -->
            <v-data-table
              :headers="dataTableAnthB"
              :items="dataItemTableAnthB"
              hide-default-footer
              :mobile-breakpoint="0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td valign="top" class="text-center">
                    {{ item.belly_circumference }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.chest_circumference }}
                  </td>
                  <td valign="top" class="text-center">
                    {{ item.head_circumference }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
// import Auth from "@/service/auth_service";
// import API from "@/service/api_service";
import moment from "moment";

export default {
  data: () => ({
    dateNow: moment().format("DD-MMM-YYYY").toUpperCase(),
    headerTableVital: [],
    itemVital: [
      {
        blood_pressure: "-",
        heart_rate: "-",
        temperature: "-",
        respiratory_rate: "-",
      },
    ],
    headerTableAnthA: [],
    itemAnthA: [
      {
        body_height: "-",
        body_weight: "-",
        body_mass_index: "-",
      },
    ],
    headerTableAnthB: [],
    itemAnthB: [
      {
        belly_circumference: "-",
        chest_circumference: "-",
        head_circumference: "-",
      },
    ],
    filterMedicalRecord: "thirty_days_ago",
    dataHeaderVital: [],
    dataItemHeaderVital: [],
    dataTableAnthA: [],
    dataItemTableAnthA: [],
    dataTableAnthB: [],
    dataItemTableAnthB: [],
  }),
  watch: {
    listHeaderTableVital(){
      this.dataHeaderVital = this.listHeaderTableVital;
    },
    listItemVital(){
      this.dataItemHeaderVital = this.listItemVital;
    },
    listHeaderTableAnthA(){
      this.dataTableAnthA = this.listHeaderTableAnthA;
    },
    listItemAnthA(){
      this.dataItemTableAnthA = this.listItemAnthA;
    },
    listHeaderTableAnthB(){
      this.dataTableAnthB = this.listHeaderTableAnthB;
    },
    listItemAnthB(){
      this.dataItemTableAnthB = this.listItemAnthB;
    }
  },
  props: {
    listHeaderTableVital: {
      type: Array,
      default: () => [],
    },
    listItemVital: {
      type: Array,
      default: () => [],
    },
    listHeaderTableAnthA: {
      type: Array,
      default: () => [],
    },
    listItemAnthA: {
      type: Array,
      default: () => [],
    },
    listHeaderTableAnthB: {
      type: Array,
      default: () => [],
    },
    listItemAnthB: {
      type: Array,
      default: () => [],
    },
  },
 /*
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/vitality-sign-by-medical-record?show_by=${this.filterMedicalRecord}&medical_record_number=${this.$store.state.paramOrderNumber}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        console.log(res,'res')
        if (res.statusCode == 200 && res.results.vitality_sign.length > 0) {
          const vital = res.results.vitality_sign[0];
          this.headerTableVital = [
            {
              text: `${this.$t("R.blood_pressure")} (${
                vital.sistole_pressure.unit
              })`,
              value: "blood",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.heart_rate")} (${vital.heart_rate.unit})`,
              value: "heart_rate",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.temperature")} (${
                vital.body_temperature.unit
              })`,
              value: "body_temperature",
              sortable: false,
              align: "center",
            },
            {
              text: `${this.$t("R.heart_rate")} (${
                vital.respiratory_rate.unit
              })`,
              value: "respiratory_rate",
              sortable: false,
              align: "center",
            },
          ];
          this.itemVital = [
            {
              blood_pressure:
                Object.keys(vital.sistole_pressure.current_result).length == 0
                  ? "-"
                  : `${parseFloat(
                      vital.sistole_pressure.current_result.value
                    ).toFixed(2)} / ${parseFloat(
                      vital.diastole_pressure.current_result.value
                    ).toFixed(2)}`,
              heart_rate:
                Object.keys(vital.heart_rate.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.heart_rate.current_result.value).toFixed(
                      2
                    ),
              body_temperature:
                Object.keys(vital.body_temperature.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.body_temperature.current_result.value
                    ).toFixed(2),
              respiratory_rate:
                Object.keys(vital.respiratory_rate.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.respiratory_rate.current_result.value
                    ).toFixed(2),
            },
          ];
          this.headerTableAnthA = [
            {
              text: `${this.$t("R.height")} (${vital.body_height.unit})`,
              value: "body_height",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.weight")} (${vital.body_weight.unit})`,
              value: "body_weight",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.body_mass_index")} (${
                vital.body_mass_index.unit
              })`,
              value: "body_mass_index",
              sortable: false,
              align: "center",
              width: "40%",
            },
          ];
          this.itemAnthA = [
            {
              body_height:
                Object.keys(vital.body_height.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.body_height.current_result.value).toFixed(
                      2
                    ),
              body_weight:
                Object.keys(vital.body_weight.current_result).length == 0
                  ? "-"
                  : parseFloat(vital.body_weight.current_result.value).toFixed(
                      2
                    ),
              body_mass_index:
                Object.keys(vital.body_mass_index.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.body_mass_index.current_result.value
                    ).toFixed(2),
            },
          ];
          this.headerTableAnthB = [
            {
              text: `${this.$t("R.abdomen_circumference")} (${
                vital.belly_circumference.unit
              })`,
              value: "belly_circumference",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.chest_circumference")} (${
                vital.chest_circumference.unit
              })`,
              value: "chest_circumference",
              sortable: false,
              align: "center",
              width: "30%",
            },
            {
              text: `${this.$t("R.head_circumference")} (${
                vital.head_circumference.unit
              })`,
              value: "head_circumference",
              sortable: false,
              align: "center",
              width: "40%",
            },
          ];
          this.itemAnthB = [
            {
              belly_circumference:
                Object.keys(vital.belly_circumference.current_result).length ==
                0
                  ? "-"
                  : parseFloat(
                      vital.belly_circumference.current_result.value
                    ).toFixed(2),
              chest_circumference:
                Object.keys(vital.chest_circumference.current_result).length ==
                0
                  ? "-"
                  : parseFloat(
                      vital.chest_circumference.current_result.value
                    ).toFixed(2),
              head_circumference:
                Object.keys(vital.head_circumference.current_result).length == 0
                  ? "-"
                  : parseFloat(
                      vital.head_circumference.current_result.value
                    ).toFixed(2),
            },
          ];
        }
      } catch (error) {
        this.ready = false;
      }
    },
  },
  */
};
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";

.card-vital {
  position: relative;
  background: white;
  padding: 30px 20px;
}

.card-vital .v-data-table {
  border: 1px solid #e3e3e38a;
  td {
    font-size: 12px;
    height: 35px !important;
    vertical-align: middle;
  }
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  background: #e3e3e38a ;
  height: 35px;
}

.ungu {
  color: #233f78;
  font-size: 16px;
  font-weight: 700;
}
.biru {
  color: #00a4b3;
  font-size: 14px;
  font-weight: 700;
}
</style>
