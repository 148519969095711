<template>
  <h4>{{ title }}</h4>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
//section title records top
h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;
}
</style>
