<!-- start modal popup order detail (dialog_order)-->
<template>
  <v-row justify="center">
    <v-dialog
      v-model="is_show"
      content-class="dialogorderdetail"
      width="1000px"
    >
      <v-card class="pt-5">
        <v-card-title class="px-3 px-md-5">
          <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
            <span class="text-h5">{{ $t("R.lab_result") }}</span>
            <br />
            <!-- dissable sementara untuk advance -->
            <!-- <v-menu
              offset-y
              v-if="
                item.results.inspection_result.some(item => item.code === '01')
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="#FFF"
                  text
                  class="btn-download-report"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left dark> mdi-download </v-icon>
                  {{ $t("download") }} PDF
                </v-btn>
              </template>
              <div class="popup">
                <v-row>
                  <v-col cols="12" class="pa-2 mt5 mb10">
                    <v-btn
                      v-if="item.type_test == 'LAB'"
                      small
                      text
                      @click="
                        downloadLab(
                          item.list.data[0].document_information
                            .number_of_report,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("R.simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("R.download_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'BUY PRODUCT'"
                      small
                      text
                      @click="
                        downloadLab(
                          item.list.data[0].document_information
                            .number_of_report,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("R.simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("R.download_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'MCU'"
                      small
                      text
                      @click="
                        downloadMcu(
                          item.list.data[0].document_information
                            .number_of_report,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("R.simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("R.download_simple")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'BUY PACKAGE'"
                      color="#fff"
                      small
                      text
                      class="btn-download-report"
                      @click="
                        downloadMcu(
                          item.list.data[0].document_information
                            .number_of_report,
                          'simple'
                        )
                      "
                    >
                      <v-icon left dark> mdi-file-document </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("R.simple") }}</span>
                        <span class="title-grid-c">{{
                          $t("R.download_simple")
                        }}</span>
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pa-2 mb5">
                    <v-btn
                      v-if="item.type_test == 'LAB'"
                      small
                      text
                      @click="
                        downloadLab(
                          item.list.data[0].document_information
                            .number_of_report,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("R.advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("R.download_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'BUY PRODUCT'"
                      small
                      text
                      @click="
                        downloadLab(
                          item.list.data[0].document_information
                            .number_of_report,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("R.advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("R.download_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'MCU'"
                      small
                      text
                      @click="
                        downloadMcu(
                          item.list.data[0].document_information
                            .number_of_report,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("R.advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("R.download_advance")
                        }}</span>
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item.type_test == 'BUY PACKAGE'"
                      color="#fff"
                      small
                      text
                      class="btn-download-report"
                      @click="
                        downloadMcu(
                          item.list.data[0].document_information
                            .number_of_report,
                          'advance'
                        )
                      "
                    >
                      <v-icon left dark color="black">
                        mdi-chart-bar-stacked
                      </v-icon>
                      <div class="title-grid">
                        <span class="title-grid-h">{{ $t("R.advance") }}</span>
                        <span class="title-grid-c">{{
                          $t("R.download_advance")
                        }}</span>
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-menu> -->
            <v-btn
              small
              color="#FFF"
              @click="
                downloadResultBundle(
                  item.results.document_info.number_of_report,
                  'simple'
                )
              "
              text
              class="btn-download-report"
              v-if="item.results.document_info.is_package"
            >
              <v-icon left dark> mdi-download </v-icon>
              {{ $t("R.download_lab") }}
            </v-btn>
          </v-col>
          <v-col cols="6" md="6" no-gutters class="ma-0 pa-0">
            <img
              src="/iconVirtu.png"
              alt="Icon Virtu"
              class="float-right dialogvirtulogo"
            />
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3 px-md-5">
          <v-row>
            <v-col cols="12">
              <h3 class="black--text">
                {{ $t("R.customer_info") }}
              </h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense no-hover-table">
                      <template v-slot:default>
                        <tbody class="no-pad desktop">
                          <tr>
                            <td>
                              <b>{{
                                item.results.customers_information.patient_name
                              }}</b>
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("R.cell_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.results.customers_information
                                          .phone_cellular !== null
                                          ? item.results.customers_information
                                              .phone_cellular
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${item.results.customers_information.address.toUpperCase()}`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("R.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.results.customers_information
                                          .phone_number !== null
                                          ? item.results.customers_information
                                              .phone_number
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${
                                  item.results.customers_information.city ==
                                  null
                                    ? "-"
                                    : `${item.results.customers_information.city}, `
                                }${
                                  item.results.customers_information.district ==
                                  null
                                    ? "-"
                                    : item.results.customers_information
                                        .district
                                }`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("R.email") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.results.customers_information
                                          .email !== null
                                          ? item.results.customers_information.email.toUpperCase()
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${
                                  item.results.customers_information.province ==
                                  null
                                    ? "-"
                                    : `${item.results.customers_information.province}`
                                }${
                                  item.results.customers_information
                                    .postal_code == null
                                    ? "-"
                                    : `${item.results.customers_information.postal_code}, `
                                }${
                                  item.results.customers_information.country ==
                                  null
                                    ? "-"
                                    : item.results.customers_information.country
                                }`
                              }}
                            </td>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("R.birth_date") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${formatedDate(
                                        item.results.customers_information,
                                        "DD MMM YYYY"
                                      ).toUpperCase()} | ${item.results.customers_information[
                                        $i18n.locale == "id"
                                          ? `patient_age_id`
                                          : `patient_age`
                                      ].toUpperCase()}`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                        <tbody class="no-pad mobile" v-if="item != null">
                          <tr>
                            <td>
                              <b>{{
                                item.results.customers_information.patient_name
                              }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${item.results.customers_information.address.toUpperCase()}`
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${
                                  item.results.customers_information.city ==
                                  null
                                    ? "-"
                                    : `${item.results.customers_information.city}, `
                                }${
                                  item.results.customers_information.district ==
                                  null
                                    ? "-"
                                    : item.results.customers_information
                                        .district
                                }`
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                `${
                                  item.results.customers_information.province ==
                                  null
                                    ? "-"
                                    : `${item.results.customers_information.province}`
                                }${
                                  item.results.customers_information
                                    .postal_code == null
                                    ? "-"
                                    : `${item.results.customers_information.postal_code}, `
                                }${
                                  item.results.customers_information.country ==
                                  null
                                    ? "-"
                                    : item.results.customers_information.country
                                }`
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("R.cell_phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.results.customers_information
                                          .phone_cellular !== null
                                          ? item.results.customers_information
                                              .phone_cellular
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("R.phone") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.results.customers_information
                                          .phone_number !== null
                                          ? item.results.customers_information
                                              .phone_number
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("R.email") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${
                                        item.results.customers_information
                                          .email !== null
                                          ? item.results.customers_information.email.toUpperCase()
                                          : "-"
                                      }`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-simple-table class="table-inside">
                                <tr>
                                  <td>{{ $t("R.birth_date") }}</td>
                                  <td>
                                    :
                                    {{
                                      `${item.results.customers_information.dob.toUpperCase()} | ${item.results.customers_information[
                                        $i18n.locale == "id"
                                          ? `patient_age_id`
                                          : `patient_age`
                                      ].toUpperCase()}`
                                    }}
                                  </td>
                                </tr>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td class="text-center">{{ $t("R.no_data") }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
          <!-- vitality -->
          <v-row>
            <v-col cols="12">
              <h3 class="black--text">{{ $t("R.vital_signs") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide">
                              {{ $t("R.blood_pressure") }} (mmHg)
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("R.heart_rate") }} (bpm)
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("R.temperature") }} (&#8451;)
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("R.breath") }} ({{ $t("R.per_minutes") }})
                            </th>
                          </tr>
                          <tr v-if="item.results.anamnesa">
                            <td class="text-center">
                              {{
                                `${item.results.anamnesa.sistole_pressure}/${item.results.anamnesa.diastole_pressure}`
                              }}
                            </td>
                            <td class="text-center">
                              {{ item.results.anamnesa.heart_rate }}
                            </td>
                            <td class="text-center">
                              {{ item.results.anamnesa.body_temperature }}
                            </td>
                            <td class="text-center">
                              {{ item.results.anamnesa.respiratory_rate }}
                            </td>
                          </tr>
                          <tr v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("R.no_data") }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
            <v-col cols="12">
              <h3 class="black--text">{{ $t("R.anthropometry") }}</h3>
              <card rounded shadow>
                <template v-slot:body>
                  <template>
                    <v-simple-table class="super-dense" mobile-breakpoint="0">
                      <template v-slot:default>
                        <tbody class="no-pad tbl">
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide">
                              {{ $t("R.height") }} (cm)
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("R.weight") }} (kg)
                            </th>
                            <th class="text-center tb-wide">
                              IMT (kg/&#13217;)
                            </th>
                          </tr>
                          <tr v-if="item.results.anamnesa">
                            <td class="text-center">
                              {{ item.results.anamnesa.body_height }}
                            </td>
                            <td class="text-center">
                              {{ item.results.anamnesa.body_weight }}
                            </td>
                            <td class="text-center">
                              {{ item.results.anamnesa.body_mass_index }}
                            </td>
                          </tr>
                          <tr v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr class="grey-color head-vir2">
                            <th class="text-center tb-wide">
                              {{ $t("R.abdomen_circumference") }} (cm)
                            </th>
                            <th class="text-center tb-wide2">
                              {{ $t("R.chest_circumference") }} (cm)
                            </th>
                            <th class="text-center tb-wide">
                              {{ $t("R.head_circumference") }} (cm)
                            </th>
                          </tr>
                          <tr v-if="item.results.anamnesa">
                            <td class="text-center">
                              {{ item.results.anamnesa.belly_circumference }}
                            </td>
                            <td class="text-center">
                              {{ item.results.anamnesa.chest_circumference }}
                            </td>
                            <td class="text-center">
                              {{ item.results.anamnesa.head_circumference }}
                            </td>
                          </tr>
                          <tr v-else>
                            <td colspan="4" class="text-center">
                              {{ $t("R.no_data") }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- tabs -->
        <v-tabs
          v-model="tab"
          background-color="#209fb4"
          center-active
          dark
          class="results-list"
        >
          <v-tab
            v-for="(category_results, index) in item.results.inspection_result"
            :key="index"
          >
            {{
              category_results.available
                ? category_results[`name_${$i18n.locale}`]
                : $t("R.result_not_available")
            }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(result, index) in dataTabs"
            :key="index"
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <v-alert
              color="cyan"
              elevation="2"
              class="mx-5 mt-5"
              colored-border
              dense
              v-if="result.data == undefined"
            >
              {{ $t("R.notif_provide") }}
            </v-alert>
            <template v-else>
              <div
                class="d-flex justify-end px-5 pt-5 pb-5 pb-md-0"
                v-if="result.donwload_link != undefined"
              >
                <v-menu
                  left
                  offset-y
                  v-if="
                    result.code == '01' &&
                    result.donwload_link.basic.link_download != undefined &&
                    result.donwload_link.advance.link_download != undefined
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      color="#FFF"
                      text
                      class="btn-download-report"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left dark> mdi-download </v-icon>
                      {{ $t("R.download_result") }}
                    </v-btn>
                  </template>
                  <div class="popup">
                    <v-row>
                      <v-col cols="12" class="pa-2 mt5 mb10">
                        <v-btn
                          small
                          text
                          @click="
                            downloadLink(
                              result.donwload_link.basic.link_download
                            )
                          "
                        >
                          <v-icon left dark> mdi-file-document </v-icon>
                          <div class="title-grid">
                            <span class="title-grid-h">{{
                              $t("R.simple")
                            }}</span>
                            <span class="title-grid-c">{{
                              $t("R.download_simple")
                            }}</span>
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pa-2 mb5">
                        <v-btn
                          small
                          text
                          @click="
                            downloadLink(
                              result.donwload_link.advance.link_download
                            )
                          "
                        >
                          <v-icon left dark color="black">
                            mdi-chart-bar-stacked
                          </v-icon>
                          <div class="title-grid">
                            <span class="title-grid-h">{{
                              $t("R.advance")
                            }}</span>
                            <span class="title-grid-c">{{
                              $t("R.download_advance")
                            }}</span>
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-menu>
                <v-btn
                  small
                  color="#FFF"
                  @click="downloadLink(result.donwload_link.link_download)"
                  text
                  class="btn-download-report"
                  v-else
                >
                  <v-icon left dark> mdi-download </v-icon>
                  {{ $t("R.download_result") }}
                </v-btn>
              </div>
              <v-card color="basil" flat>
                <v-card-text class="px-3 px-md-5">
                  <v-row>
                    <v-col cols="12">
                      <h3 class="black--text">
                        {{ $t("R.document_info") }}
                      </h3>
                      <card rounded shadow>
                        <template v-slot:body>
                          <template>
                            <v-simple-table
                              class="super-dense"
                              mobile-breakpoint="0"
                            >
                              <template v-slot:default>
                                <tbody class="no-pad tbl">
                                  <tr class="grey-color head-vir2">
                                    <th
                                      class="text-no-wrap text-center tb-wide"
                                    >
                                      {{ $t("R.lab_report_number") }}
                                    </th>
                                    <th
                                      class="text-no-wrap text-center tb-wide2"
                                    >
                                      {{ $t("R.examination_date") }}
                                    </th>
                                    <th
                                      class="text-no-wrap text-center tb-wide"
                                    >
                                      {{ $t("R.branch_code") }}
                                    </th>
                                    <th
                                      class="text-no-wrap text-center tb-wide2"
                                    >
                                      {{ $t("R.branch_name") }}
                                    </th>
                                  </tr>
                                  <tr v-if="item != null">
                                    <td class="text-no-wrap text-center">
                                      {{
                                        item.results.document_info
                                          .number_of_report
                                      }}
                                    </td>
                                    <td class="text-no-wrap text-center">
                                      {{
                                        formatDateTime(
                                          item.results.document_info
                                            .datetime_observation
                                        )
                                      }}
                                    </td>
                                    <td class="text-no-wrap text-center">
                                      {{
                                        item.results.document_info.plant_code
                                      }}
                                    </td>
                                    <td class="text-no-wrap text-center">
                                      {{ item.results.document_info.plant }}
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="4" class="text-center">
                                      {{ $t("R.no_data") }}
                                    </td>
                                  </tr>
                                  <tr class="grey-color head-vir2">
                                    <th class="text-no-wrap text-center">
                                      {{ $t("R.medical_record_number") }}
                                    </th>
                                    <th
                                      class="text-no-wrap text-center tb-wide"
                                    >
                                      {{ $t("R.main_doctor") }}
                                    </th>
                                    <th class="text-no-wrap text-center">
                                      {{ $t("R.gender") }}
                                    </th>
                                    <th class="text-no-wrap text-center">
                                      {{ $t("R.lab_doctor") }}
                                    </th>
                                  </tr>
                                  <tr v-if="item != null">
                                    <td class="text-no-wrap text-center">
                                      {{
                                        item.results.document_info
                                          .medical_record_number
                                      }}
                                    </td>
                                    <td
                                      class="text-no-wrap text-center tb-wide2"
                                    >
                                      {{
                                        item.results.document_info
                                          .primary_doctor == undefined
                                          ? "-"
                                          : item.results.document_info
                                              .primary_doctor
                                      }}
                                    </td>
                                    <td class="text-no-wrap text-center">
                                      {{
                                        item.results.document_info[
                                          $i18n.locale == "id"
                                            ? `gender_name_id`
                                            : `gender`
                                        ]
                                      }}
                                    </td>
                                    <td
                                      class="text-no-wrap text-center tb-wide2"
                                    >
                                      {{
                                        result.data.practitioner_name ==
                                        undefined
                                          ? result.data.doctor_examination !=
                                            undefined
                                            ? result.data.doctor_examination
                                                .practitioner_name
                                            : "-"
                                          : result.data.practitioner_name
                                      }}
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="4" class="text-center">
                                      {{ $t("R.no_data") }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </template>
                        </template>
                      </card>
                    </v-col>
                  </v-row>
                  <v-row> </v-row>
                </v-card-text>
              </v-card>
              <audiometry
                v-if="result.code == '05'"
                :result="result"
              ></audiometry>
              <doctor-exam
                v-if="result.code == '09'"
                :result="result"
              ></doctor-exam>
              <lab-result
                v-if="result.code == '01'"
                :result="result"
              ></lab-result>
              <patology-anatomy
                v-if="result.code == '10'"
                :result="result"
              ></patology-anatomy>
              <spirometry
                v-if="result.code == '06'"
                :result="result"
              ></spirometry>
              <supportive-invest
                v-if="result.code == '08'"
                :result="result"
              ></supportive-invest>
              <usg v-if="result.code == '12'" :result="result"></usg>
              <xray v-if="result.code == '07'" :result="result"></xray>
              <mikro v-if="result.code == '11'" :result="result"></mikro>
            </template>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="toggleDialog()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Card from "@/components/v2/card/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment";
import Audiometry from "@/components/v3/results/audiometry.vue";
import DoctorExam from "@/components/v3/results/doctor_examination.vue";
import LabResult from "@/components/v3/results/lab_result.vue";
import PatologyAnatomy from "@/components/v3/results/patology_anatomy.vue";
import Spirometry from "@/components/v3/results/spirometry.vue";
import SupportiveInvest from "@/components/v3/results/supportive_investigation.vue";
import Usg from "@/components/v3/results/usg.vue";
import Xray from "@/components/v3/results/xray.vue";
import Mikro from "@/components/v3/results/mikro.vue";

export default {
  components: {
    Card,
    Audiometry,
    DoctorExam,
    LabResult,
    PatologyAnatomy,
    Spirometry,
    SupportiveInvest,
    Usg,
    Xray,
    Mikro,
  },
  props: {
    tampilkan: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      is_show: this.tampilkan,
      tab: null,
      dataTabs: [],
    };
  },
  watch: {
    tampilkan: function (new_val, old_val) {
      if (old_val !== "") {
        this.is_show = new_val;
      }
    },
    is_show: function (new_val, old_val) {
      if (old_val !== "") {
        if (!new_val) {
          this.$emit("toggleDialog", this.is_show);
        }
      }
    },
  },
  created() {
    this.item.results.inspection_result.forEach(test => {
      this.getDetailResult(test.code, this.item.order_number);
    });
    this.item.results.inspection_result.sort((a, b) => {
      return a.code - b.code;
    });
  },
  methods: {
    async getDetailResult(code, order_number) {
      try {
        const detail = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/detail?code=${code}&order_number=${order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (detail.statusCode == 200) {
          this.dataTabs = [
            ...this.dataTabs,
            {
              data: detail.results.result_inspection,
              code: code,
              donwload_link: detail.results.donwload_link,
            },
          ];
          if (
            this.dataTabs.length == this.item.results.inspection_result.length
          ) {
            this.dataTabs.sort((a, b) => {
              return a.code - b.code;
            });
          }
        } else {
          this.dataTabs = [
            ...this.dataTabs,
            {
              code: code,
            },
          ];
          if (
            this.dataTabs.length == this.item.results.inspection_result.length
          ) {
            this.dataTabs.sort((a, b) => {
              return a.code - b.code;
            });
          }
          this.$swal({
            icon: "error",
            text: detail.message,
          });
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    formatedDate(date, format) {
      return moment(date).locale(this.$i18n.locale).format(format);
    },
    formatDate(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatDateTime(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY HH:mm:ss")
        .toUpperCase();
    },
    toggleDialog() {
      this.is_show = false;
      this.$emit("toggleDialog", this.is_show);
    },
    downloadLab(param, type) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}&type=${type}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
    downloadLink(link) {
      window.open(link, "_blank");
    },
    downloadResultBundle(param, type) {
      API.get(
        `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}&type=${type}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
        this.download = res;
        window.open(this.download.linkDownload, "_blank");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}

.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #cecece;
  font-weight: bold;
}

// .head-vir2:hover {
// background: #6dc6d6 !important;
// }

.head-vir2 th {
  color: #000 !important;
}

.bold {
  font-weight: bold;
}

.v-data-table table tr td {
  font-size: 12px !important;
}

.mobile {
  display: none;
}

.table-inside {
  width: 100%;
  padding: 0;
}

.table-inside tr td:first-child {
  width: 20%;
}

.mobile tr {
  display: inline-table;
  width: 100%;
}

.mobile .table-inside tr td:first-child {
  width: 20%;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 200px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}
</style>
<style lang="scss">
.results-list .v-item-group {
  height: 40px;
  .v-tab {
    font-size: 12px;
  }
}
@media (max-width: 450px) {
  .dialogorderdetail.v-dialog {
    margin: 5px !important;
  }
  .dialogorderdetail .v-card__text {
    padding: 0 10px 20px !important;
  }
}
@media (min-width: 600px) {
  .table-sticky .v-data-table__wrapper {
    overflow: unset;
  }
  .table-sticky thead {
    position: sticky;
    inset-block-start: 0;
    top: 0;
  }
}
@media (max-width: 599px) {
  .table-sticky .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    height: 400px;
  }
  .table-sticky thead tr {
    border-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.category-blue {
  color: #fff !important;
  background: #233e8d !important;
}
.true {
  color: red;
  font-weight: 600;
}
.btn-download-report {
  background: #209fb4;
  &--dropdown {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}
.popup {
  //width: min-content;
  padding: 8px;
  background: white;
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 16px;
  a {
    text-decoration: none;
    color: black;
  }
}
.title-grid {
  display: grid;
}
.title-grid-h {
  text-align: left;
}
.title-grid-c {
  text-align: left;
  font-size: 8px;
}
.mt5 {
  margin-top: 5px !important;
}
.mb10 {
  margin-bottom: -10px;
}
.mb5 {
  margin-bottom: 5px !important;
}
</style>
