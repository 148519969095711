<template>
  <v-data-table
    :headers="headers"
    :items="myFamily"
    dense
    mobile-breakpoint="0"
    class="header-stretch table-relationship"
    :hide-default-footer="true"
  >
    <template v-slot:item="row">
      <tr class="tr-stretch">
        <!-- target="blank":href="`/patient/family/${row.item.medical_record_number}`" -->
        <td>
          <!-- <v-icon small @click="editItem(item)"> mdi-pencil </v-icon> -->
          <v-icon
            small
            @click="deleteItem(row.item)"
            v-if="row.item.relation !== '-'"
            style="color: #00a4b3"
          >
            mdi-delete
          </v-icon>
          <!-- <v-icon small @click="makeApprove(item)"> mdi-check </v-icon> -->
        </td>
        <td>
          <a
            v-if="row.item.status.toUpperCase() == 'APPROVED'"
            target="blank"
            @click="
              addMenu(
                row.item.related_person_name,
                row.item.medical_record_number
              )
            "
            >{{ row.item.medical_record_number }}
          </a>
          <template v-else>
            {{ row.item.medical_record_number }}
          </template>
        </td>
        <td>{{ row.item.related_person_name }}</td>
        <td>{{ formatDate(row.item.related_date_of_birth) }}</td>
        <td>{{ row.item.relation_name }}</td>
        <td>{{ row.item.status }}</td>
      </tr>
    </template>

    <template v-slot:top>
      <!--
     <v-btn
        text
        @click="addMenu"
      >
        Add Tab
      </v-btn>
      -->
      <div class="d-flex justify-space-between px-0 py-3 pa-md-2">
        <v-toolbar-title class="title">
          {{ $t("page.profile.relationship_info") }}
        </v-toolbar-title>
        <!-- <v-spacer></v-spacer> -->
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn color="#00A4B3" dark v-bind="attrs" v-on="on">
              {{ $t("add") }}
            </v-btn> -->
            <v-icon color="#00A4B3" dark v-bind="attrs" v-on="on">
              mdi-account-plus
            </v-icon>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      v-model="searchById"
                      :label="$t('page.profile.search_by_id_card')"
                      append-outer-icon="mdi-magnify"
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <template v-if="found && searchResult != null">
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="editedItem.related_person_name"
                          :label="$t('field.name')"
                          readonly
                          :rules="[
                            v =>
                              !!v || `${$t('field.name')} ${$t('is_required')}`,
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="relationItem"
                          v-model="editedItem.relation"
                          :item-text="`name_${$i18n.locale}`"
                          item-value="id"
                          :label="$t('page.profile.relation')"
                          :rules="[
                            v =>
                              !!v ||
                              `${$t('page.profile.relation')} ${$t(
                                'is_required'
                              )}`,
                          ]"
                        ></v-select>
                      </v-col>
                      <!-- <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="editedItem.description"
                          :label="$t('desc')"
                        ></v-text-field>
                      </v-col> -->
                    </template>
                    <template v-if="!found && searchResult == 'notfound'">
                      <v-col cols="12">
                        <p class="mb-0">
                          {{ $t("page.profile.account_not_found") }}
                        </p>
                      </v-col>
                    </template>
                    <template v-if="!found && searchResult == 'already'">
                      <v-col cols="12">
                        <p class="mb-0">
                          {{
                            $t(
                              "page.profile.already_have_relationship_with_you"
                            )
                          }}
                        </p>
                      </v-col>
                    </template>
                    <!-- <v-col cols="12">
                      <p class="mb-0">
                        {{ $t("page.profile.id_card_not_register_yet") }}
                        {{ $t("page.profile.create_new_account") }}
                        <a @click="notForMe = !notForMe">
                          {{ $t("here") }}
                        </a>
                      </p>
                    </v-col> -->
                  </v-row>
                </v-form>
                <div v-show="notForMe" @close="dialog = false">
                  <RegisterNew v-on:close-dialog="close" />
                </div>
              </v-container>
            </v-card-text>

            <v-card-actions v-show="!notForMe">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="!valid"
                v-if="found"
              >
                {{ $t("save") }}
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="searchFamily"
                :disabled="searchById == ''"
                v-if="!found"
              >
                {{ $t("search") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="dialogEdit" max-width="500px">
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>Edit Education Info</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
                <v-text-field
                  ref="id"
                  v-model="editedItem.id"
                  :rules="[() => !!id || 'This field is required']"
                  :error-messages="errorMessages"
                  label="Patient ID"
                  placeholder="Patient ID"
                ></v-text-field>
                <v-text-field
                  ref="fullname"
                  v-model="editedItem.fullname"
                  :rules="[() => !!fullname || 'This field is required']"
                  label="Full Name"
                  placeholder="Full Name"
                ></v-text-field>
                <v-text-field
                  ref="birthdate"
                  v-model="editedItem.birthdate"
                  :rules="[() => !!birthdate || 'This field is required']"
                  label="Birth Date"
                  placeholder="Birth Date"
                ></v-text-field>
                <v-autocomplete
                  ref="relationship"
                  v-model="editedItem.relationship"
                  :rules="[() => !!relationship || 'This field is required']"
                  :items="relationships"
                  label="Relationship"
                  placeholder="Select..."
                ></v-autocomplete>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="closeEdit"> Cancel </v-btn>
                <v-btn color="#00a4b3" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog> -->

        <!-- delete family -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-center">{{
              $t("page.profile.u_sure_delete_this_person")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">{{
                $t("cancel")
              }}</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                $t("ok")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogApprove" max-width="315">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("confirmation_dialog") }}
            </v-card-title>
            <v-card-text>
              {{ $t("page.profile.are_u_sure_make_person_family") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeApprove">{{ $t("cancel") }}</v-btn>
              <v-btn color="#00a4b3" text @click="makeApproveConfirm">{{
                $t("oke")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirmation" persistent max-width="350">
          <v-card class="pa-3">
            <div class="d-flex justify-center mb-3">
              <v-icon x-large color="yellow"> mdi-information-outline </v-icon>
            </div>
            <h4 class="text-center mb-4">{{ $t("page.profile.are_u_sure_make_person_family") }}</h4>
            <p class="text-center">
              {{ $t("tnc.tnc_family") }}
            </p>
            <div class="d-flex justify-center">
              <c-button class="mx-2 py-2" rounded @click="confirmAddFamily()">{{
                $t("continue")
              }}</c-button>
              <c-button
                class="mx-2 py-2"
                rounded
                outline
                @click="
                  () => {
                    dialogConfirmation = false;
                  }
                "
                >{{ $t("cancel") }}</c-button
              >
            </div>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import RegisterNew from "@/components/v2/register/index.vue";
import CButton from "@/components/v2/button/index.vue";

export default {
  components: {
    RegisterNew,
    CButton,
  },
  data: () => ({
    found: false,
    searchResult: null,
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    dialogApprove: false,
    dialogConfirmation: false,
    formHasError: false,
    valid: true,
    relationItem: [],
    headers: [],
    myFamily: [],
    myFamilyApproval: [],
    editedIndex: -1,
    searchById: "",
    id: null,
    fullname: null,
    birthdate: null,
    relationship: null,
    errorMessages: "",
    editedItem: {
      related_person_name: "",
      relation_name: "",
      relation: "",
      person_id: "",
      description: "-",
    },
    defaultItem: {
      id: "",
      fullname: "",
      birthdate: "",
      relationship: "",
    },
    notForMe: false,
    listMenu: [],
    listContent: [],
  }),

  computed: {
    form() {
      return {
        id: this.name,
        fullname: this.fullname,
        birthdate: this.birthdate,
        relationship: this.relationship,
      };
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("page.profile.new_family")
        : this.$t("page.profile.edit_family");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogApprove(val) {
      val || this.closeApprove();
    },
    name() {
      this.errorMessages = "";
    },
    length(val) {
      this.tab = val - 1;
    },
    familyList() {
      this.myFamily = this.familyList;
    },
    approvalFamily() {
      this.myFamilyApproval = this.approvalFamily;
    },
  },
  props: {
    familyList: {
      type: Array,
      default: () => [],
    },
    approvalFamily: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_id"),
        align: "center",
        sortable: false,
        value: "medical_record_number",
      },
      {
        text: this.$t("page.profile.full_name"),
        value: "related_person_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.birth_date"),
        value: "birthdate",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.relationship"),
        value: "relation_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
    ];
    this.myFamily = this.familyList;
    this.myFamilyApproval = this.approvalFamily;
    this.listMenu = [
      {
        id: 1,
        title: this.$t("tab.summary"),
      },
      {
        id: 2,
        title: this.$t("tab.personal_info"),
      },
      {
        id: 3,
        title: this.$t("tab.family_member_info"),
      },
      {
        id: 4,
        title: this.$t("tab.payment_info"),
      },
      {
        id: 5,
        title: this.$t("tab.ongoing_test"),
      },
      {
        id: 6,
        title: this.$t("tab.your_history"),
      },
    ];
  },
  methods: {
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    async getRelationList() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/get-rel-type?person_id=${this.searchResult.id}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.relationItem = res.results;
      } catch (error) {
        console.log(error);
      }
    },

    searchFamily() {
      if (this.searchById != "") {
        API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/search-id?id_number=${this.searchById}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          if (res.statusCode == 200) {
            this.found = res.results != null;
            this.searchResult = res.results;
            if (res.results != null) {
              const indexFamily = this.myFamily.findIndex(
                item => item.person_id == res.results.id
              );

              const indexFamilyApp = this.myFamilyApproval.findIndex(
                item => item.person_id == res.results.id
              );

              if (indexFamily > -1 || indexFamilyApp > -1) {
                this.found = false;
                this.searchResult = "already";
              } else {
                this.editedItem.person_id = res.results.id;
                this.editedItem.related_person_name = res.results.name;
                this.getRelationList();
              }
            } else {
              this.found = false;
              this.searchResult = "notfound";
            }
          } else {
            this.found = false;
            this.searchResult = "notfound";
          }
        });
      }
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    editItem(item) {
      this.editedIndex = this.myFamily.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.myFamily.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    makeApprove(item) {
      this.editedIndex = this.myFamily.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    deleteItemConfirm() {
      API.del(
        `${process.env.VUE_APP_API_TRUST}patient-related/delete`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        this.editedItem
      ).then(res => {
        if (res.statusCode == 200) {
          this.myFamily.splice(this.editedIndex, 1);
          this.closeDelete();
          this.toast("success", res.message);
        }
      });
    },

    makeApproveConfirm() {},

    close() {
      this.searchById = "";
      this.errorMessages = [];
      this.searchResult = null;
      this.found = false;
      this.$emit("getFamily");
      this.$emit("getApprovalFamily");
      this.formHasErrors = false;
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.notForMe = false;
    },

    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    confirmAddFamily() {
      API.post(
        `${process.env.VUE_APP_API_TRUST}patient-related/add?lang=${this.$i18n.locale}`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        { ...this.editedItem }
      )
        .then(res => {
          this.dialogConfirmation = false;
          if (res.statusCode == 200) {
            this.found = false;
            this.close();
            this.toast("success", res.message);
          } else {
            this.toast("error", res.message);
          }
        })
        .catch(() => {
          this.dialogConfirmation = false;
          this.close();
        });
    },
    save() {
      if (this.$refs.form.validate() == true) {
        if (this.editedIndex > -1) {
          Object.assign(this.myFamily[this.editedIndex], this.editedItem);
        } else {
          const indexrelation = this.relationItem.findIndex(
            e => e.id == this.editedItem.relation
          );
          this.editedItem.relation_name = this.relationItem[indexrelation].name;
          this.dialogConfirmation = true;
        }
      }
    },
    addMenu(personalName, paramsId) {
      this.listMenu = this.$store.state.listMenu;
      this.listContent = this.$store.state.listContent;
      const getIndex = this.listMenu.findIndex(
        data => data.title == personalName
      );
      if (getIndex < 1) {
        var count = this.listMenu.length + 1;
        var countContent = this.listContent.length + 1;

        this.listMenu.push({
          id: count,
          title: personalName,
          family: true,
          mr: paramsId,
        });
        this.listContent.push({ id: countContent });
        this.$store.commit("setActiveTab", count);
        this.$store.state.paramOrderNumber = paramsId;
        this.$store.state.paramMedicalRecord = paramsId;
      }
    },
  },
};
</script>
<style>
.table-relationship thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-relationship thead tr th {
  height: 38px !important;
}
.table-relationship tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
}
.table-relationship tbody tr:hover {
  background-color: transparent !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.v-input__control {
  width: 700px;
}

.v-toolbar__title {
  font-size: 16px;
}

.v-btn {
  display: flex;
  justify-content: flex-end;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
}

.title {
  font-style: normal;
  font-size: 16px !important;
  line-height: 22px;
  color: black;
  /* identical to box height */
  color: #233f78;
  font-weight: 700;
  letter-spacing: 0.01em;
}
</style>
