<template>
  <v-card-text class="px-3 px-md-5">
    <v-row>
      <v-col cols="12">
        <h3 class="black--text">Audiometri / Audiometry</h3>
        <card rounded shadow>
          <template v-slot:body>
            <v-simple-table class="super-dense no-hover-table">
              <template v-slot:default>
                <thead class="no-pad">
                  <tr class="grey-color head-vir2">
                    <th
                      class="text-center tb-wide"
                      style="width: 20%"
                      rowspan="2"
                    >
                      {{ $t("R.frequency") }}
                    </th>
                    <th
                      class="text-center tb-wide"
                      style="width: 40%"
                      colspan="2"
                    >
                      Bone
                    </th>
                    <th
                      class="text-center tb-wide"
                      style="width: 40%"
                      colspan="2"
                    >
                      Air
                    </th>
                  </tr>
                  <tr class="grey-color head-vir2">
                    <th class="text-center tb-wide" style="width: 20%">
                      {{ $t("R.left") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 20%">
                      {{ $t("R.right") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 20%">
                      {{ $t("R.left") }}
                    </th>
                    <th class="text-center tb-wide" style="width: 20%">
                      {{ $t("R.right") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="listAudiometry != null">
                    <tr v-for="(item, index) of listAudiometry" :key="index">
                      <td class="text-center">
                        <span class="pl-3">{{ index }} Hz</span>
                      </td>
                      <template v-if="item.length > 1">
                        <td class="text-center">
                          {{
                            item[item[0].conduction == "Bone" ? 0 : 1]
                              .left_result
                          }}
                          dB
                        </td>
                        <td class="text-center">
                          {{
                            item[item[0].conduction == "Bone" ? 0 : 1]
                              .right_result
                          }}
                          dB
                        </td>
                        <td class="text-center">
                          {{
                            item[item[0].conduction == "Bone" ? 1 : 0]
                              .left_result
                          }}
                          dB
                        </td>
                        <td class="text-center">
                          {{
                            item[item[0].conduction == "Bone" ? 1 : 0]
                              .right_result
                          }}
                          dB
                        </td>
                      </template>
                      <template v-else>
                        <template v-if="item[0].conduction == 'Bone'">
                          <td class="text-center">
                            {{ item[0].left_result }} dB
                          </td>
                          <td class="text-center">
                            {{ item[0].right_result }} dB
                          </td>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                        </template>
                        <template v-else>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center">
                            {{ item[0].left_result }} dB
                          </td>
                          <td class="text-center">
                            {{ item[0].right_result }} dB
                          </td>
                        </template>
                      </template>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="5">
                      {{ $t("R.no_data") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </card>
      </v-col>
      <v-col cols="12" md="6">
        <h3 class="font-black">{{ $t("R.left") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <apex-audiometry-chart :data="listAudiometryLeft" />
          </template>
        </card>
      </v-col>
      <v-col cols="12" md="6">
        <h3 class="font-black">{{ $t("R.right") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <apex-audiometry-chart :data="listAudiometryRight" />
          </template>
        </card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="font-black">{{ $t("R.status") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody class="no-pad desktop">
                    <tr class="hover-apri" v-if="result.data.status">
                      <td class="text-capitalize">
                        {{ `${result.data.status}` }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="no-pad mobile" v-if="result.data.status">
                    <tr class="hover-apri">
                      <td class="text-capitalize">
                        {{ `${result.data.status}` }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="hover-apri">
                      <td class="text-center">{{ $t("R.no_data") }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
      <v-col cols="12">
        <h3 class="font-black">{{ $t("R.description") }}</h3>
        <card rounded shadow>
          <template v-slot:body>
            <template>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody class="no-pad desktop">
                    <tr class="hover-apri" v-if="result.data.description">
                      <td class="text-capitalize">
                        {{ `${result.data.description}` }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="no-pad mobile" v-if="result.data.description">
                    <tr class="hover-apri">
                      <td class="text-capitalize">
                        {{ `${result.data.description}` }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="hover-apri">
                      <td class="text-center">{{ $t("R.no_data") }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </template>
        </card>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import Card from "@/components/v2/card/index.vue";
import ApexAudiometryChart from "@/components/v3/chart/ApexAudiometryChart.vue";

export default {
  components: {
    Card,
    ApexAudiometryChart,
  },
  props: {
    result: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    listAudiometry: null,
    listAudiometryLeft: null,
    listAudiometryRight: null,
  }),
  created() {
    let groupedAudio = this.result.data.detail_audiometry.reduce(
      (acc, curr) => {
        if (!acc[curr.frequency]) {
          acc[curr.frequency] = [];
        }
        acc[curr.frequency].push(curr);
        return acc;
      },
      {}
    );
    let left = [];
    let right = [];
    this.result.data.detail_audiometry.forEach(res => {
      left = [
        ...left,
        {
          x: res.frequency,
          y: res.left_result,
          conduction: res.conduction,
          method: res.method,
        },
      ];
      right = [
        ...right,
        {
          x: res.frequency,
          y: res.right_result,
          conduction: res.conduction,
          method: res.method,
        },
      ];
    });
    let groupedAudioChartLeft = left.reduce((acc, curr) => {
      if (!acc[curr.conduction]) {
        acc[curr.conduction] = [];
      }
      acc[curr.conduction].push(curr);
      return acc;
    }, {});
    let groupedAudioChartRight = right.reduce((acc, curr) => {
      if (!acc[curr.conduction]) {
        acc[curr.conduction] = [];
      }
      acc[curr.conduction].push(curr);
      return acc;
    }, {});
    this.listAudiometryLeft = groupedAudioChartLeft;
    this.listAudiometryRight = groupedAudioChartRight;
    this.listAudiometry = groupedAudio;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.grey-color {
  background: #eeeeee;
}
.v-data-table table tr td,
.v-data-table table tr th,
table tr td,
table tr th {
  font-size: 12px !important;
}
.normal-label {
  text-transform: capitalize;
  color: $green;
}
.abnormal-label {
  color: $red_2;
  text-transform: capitalize;
}

// mobile
.mobile {
  display: none;
}
.mobile .table-inside tr td:first-child {
  width: 20%;
}
@media (min-width: 539px) {
  .v-menu__content {
    max-width: 43%;
  }
}

@media (min-width: 750px) {
  .v-menu__content {
    max-width: 30%;
  }
}
@media (min-width: 810px) {
  .v-menu__content {
    max-width: 33%;
  }
}
@media (min-width: 1000px) {
  .v-menu__content {
    max-width: 25%;
  }
}
@media (min-width: 1030px) {
  .v-menu__content {
    max-width: 20%;
  }
}
@media (max-width: 450px) {
  .v-menu__content {
    max-width: 65%;
  }
  .dialogvirtu.v-dialog {
    margin: 5px !important;
  }
  .tb-wide {
    min-width: 150px;
  }
  .tb-wide2 {
    min-width: 200px;
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  .dialogvirtulogo {
    width: 150px;
  }
}
</style>
