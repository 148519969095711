var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list)?_c('div',[_c('v-data-table',{staticClass:"table-valign-top-mobile table-medical-info",attrs:{"hide-default-header":"","dense":"","headers":_vm.headers,"items":_vm.list,"item-key":"name","items-per-page":_vm.perPage,"footer-props":_vm.footerProps,"mobile-breakpoint":"0","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(header_item){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',{},_vm._l((header_item.props.headers),function(header){return _c('th',{key:header.value,staticClass:"header-padding",class:`${header.className} ${
              header.value == 'current' || header.value == 'previous'
                ? 'td-stretch'
                : ''
            } `},[_c('div',{staticClass:"d-flex align-start py-2 text-no-wrap",class:`${header.className} ${
                header.className == 'text-center' ? 'justify-center' : ''
              } ${header.className == 'text-left' ? 'justify-start' : ''} ${
                header.className == 'text-right' ? 'justify-end' : ''
              }`},[_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(header.value == "current" ? _vm.getCurrentDate() : "")+" "+_vm._s(header.value == "previous" ? _vm.getPreviousDate() : "")+" ")])])}),0)])]}},{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticClass:"td-stretch pt-2 pt-sm-0 link",staticStyle:{"width":"150px"}},[_c('localized-link',{staticClass:"link-detail pt-2 text-uppercase text-no-wrap",attrs:{"to":`/patient/medical-record/${item.key}`}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('td',{staticClass:"pt-2 text-uppercase",attrs:{"valign":"top"}},[_vm._v(_vm._s(item.unit))]),_c('td',{staticClass:"text-right pt-2",attrs:{"valign":"top"}},[_vm._v(" "+_vm._s(item.previous_result != null && !_vm.checkObjectIsEmpty(item.current_result) ? parseFloat(item.previous_result.value).toFixed(2) : _vm.$t("no_data"))+" ")]),_c('td',{staticClass:"text-right pt-2",attrs:{"valign":"top"}},[_vm._v(" "+_vm._s(item.current_result != null && !_vm.checkObjectIsEmpty(item.current_result) ? parseFloat(item.current_result.value).toFixed(2) : _vm.$t("no_data"))+" ")]),_c('td',{staticClass:"text-right pt-2",attrs:{"valign":"top"}},[_vm._v(" "+_vm._s(_vm.checkObjectIsEmpty(item.current_result) || _vm.checkObjectIsEmpty(item.previous_result) || item.previous_result == null || item.current_result == null ? "-" : parseFloat( parseFloat(item.current_result.value) - parseFloat( !_vm.checkObjectIsEmpty(item.previous_result) ? item.previous_result.value : 0 ).toFixed(2) ))+" ")]),_c('td',{staticClass:"pt-2 text-uppercase",class:{
            'text-danger':
              !_vm.checkObjectIsEmpty(item.current_result) &&
              item.current_result.min != null &&
              item.current_result.max != null
                ? parseFloat(item.current_result.value) >
                    parseFloat(item.current_result.max) ||
                  parseFloat(item.current_result.value) <
                    parseFloat(item.current_result.min)
                : false,
            'text-success':
              !_vm.checkObjectIsEmpty(item.current_result) &&
              item.current_result.min != null &&
              item.current_result.max != null
                ? parseFloat(item.current_result.value) >=
                    parseFloat(item.current_result.min) &&
                  parseFloat(item.current_result.value) <=
                    parseFloat(item.current_result.max)
                : false,
          },attrs:{"valign":"top"}},[_vm._v(" "+_vm._s(_vm.checkObjectIsEmpty(item.current_result) || item.current_result == null ? "-" : item.current_result.min != null && item.current_result.max != null ? item.current_result.value >= item.current_result.min && item.current_result.max >= item.current_result.value ? _vm.$t("good") : _vm.$t("warning") : "-")+" ")]),_c('td',[_c('localized-link',{staticClass:"link-detail pt-2 text-uppercase",attrs:{"to":`/patient/medical-record/${item.key}${
              _vm.medicalNumber == '' ? '' : `?medical=${_vm.medicalNumber}`
            }`}},[_c('ChartMedicalRecord',{attrs:{"data":_vm.getDataChart(item.list_history)}})],1)],1)])]}}],null,false,2033009088)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }