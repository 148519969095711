<template>
  <v-form>
    <v-card class="reschedule">
      <v-card-title>{{ $t("page.profile.reschedule_book") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p class="mb-0">*{{ $t("field.rules_rescheduled") }}</p>
            <p class="mb-0">
              *{{ $t("field.max_rescheduled_company") }}
              {{ data.max_reschedule }} {{ $t("field.times") }}
            </p>
            <p
              v-if="data.reschedule_allowed < data.max_reschedule"
              class="mb-0"
            >
              *{{ $t("field.attemp_rescheduled") }}
              <b>
                {{ data.max_reschedule - data.reschedule_allowed }}
              </b>
              {{ $t("field.times") }}
            </p>
          </v-col>
          <v-col cols="12">
            <!-- <v-select
              :items="branch"
              ref="branch"
              :label="$t('field.branch')"
              item-text="name"
              item-value="id"
              v-model="payload.branch"
              dense
              outlined
              :rules="[v => !!v || `${$t('field.branch')} ${$t('is_required')}`]"
            ></v-select> -->
            <v-menu
              v-model="opendatepicker"
              :close-on-content-click="false"
              :close-on-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  ref="booking_date"
                  :label="$t('field.booking_date')"
                  outlined
                  append-icon="mdi-calendar-range"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    v =>
                      !!v || `${$t('field.booking_date')} ${$t('is_required')}`,
                  ]"
                ></v-text-field>
              </template>
              <c-datepicker
                :plant="payload.branch"
                checkQueue
                :minDate="minDate"
                :maxDate="maxDate"
                :value="payload.booking_date"
                :holiday="getHoliday"
                :enableDays="getEnableDays"
                @changeValue="payload.booking_date = $event"
                @change="opendatepicker = false"
              ></c-datepicker>
            </v-menu>
            <div class="d-flex justify-end">
              <!-- <c-button
                class="mx-2 py-2"
                rounded
                outline
                @click="$emit('closeReschedule')"
                >{{ $t("cancel") }}</c-button
              > -->
              <c-button
                class="mx-2 py-2"
                roundedSmall
                :disabled="disabledSave"
                @click="saveReschedule()"
                >{{ $t("save") }}</c-button
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import CButton from "@/components/v2/button/index.vue";
import CDatepicker from "@/components/v3/date/custom-datepicker.vue";
import moment from "moment-timezone";

export default {
  components: {
    CButton,
    CDatepicker,
  },
  data: () => ({
    branch: [],
    disabledSave: true,
    payload: {
      booking_date: "",
      branch: "",
    },
    opendatepicker: false,
    formHasErrors: false,
    // start available date
    availableDates: [],
    // end available date
  }),
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    "payload.booking_date": function () {
      this.disabledSave =
        moment(this.data.booking_date).format("DD-MM-YYYY") ==
        moment(this.payload.booking_date).format("DD-MM-YYYY");
    },
  },
  computed: {
    // start reschedule data
    getDataReschedule() {
      return this.$store.state.rescheduleData;
    },
    // end reschedule data
    // start holiday
    getHoliday() {
      return this.getDataPlant ? this.getDataPlant.upcoming_holidays : [];
    },
    // end holiday

    // start enable days
    getEnableDays() {
      let enableDays = [];
      const days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      const { opening_hours } = !this.getDataPlant || this.getDataPlant;
      for (let i = 0; i < days.length; i++) {
        const indexDays =
          opening_hours == undefined
            ? -1
            : opening_hours.findIndex(item => item.day == days[i]);
        if (indexDays > -1) {
          enableDays.push(i);
        }
      }
      return enableDays;
    },
    // end enable days
    // start get plant detail
    getDataPlant() {
      const indexPlant = this.branch.findIndex(
        item => item.id == this.data.plant_id
      );
      if (indexPlant > -1) {
        return this.branch[indexPlant];
      }
      return null;
    },
    // end get plant detail

    minDate() {
      // Set the start and end time boundaries
      if (this.getDataPlant) {
        let currentTime = moment();
        if (currentTime.isBefore(this.data.booking_date))
          return this.calculateMinReschedule(
            this.data.booking_date,
            this.getDataReschedule.expired_days_before
          );
        const timezone = moment.tz.guess();
        if (this.getDataPlant.timezone_area == timezone) {
          currentTime = moment();
        } else {
          const now = moment().format("HH:mm:ss");
          currentTime = moment(
            this.convertTime(
              now,
              moment.tz.guess(),
              this.getDataPlant.timezone_area
            ),
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        let startTime = "";
        let endTime = "";
        const { opening_hours } = this.getDataPlant;
        const indexByDayName = opening_hours.findIndex(
          item => item.day == currentTime.locale("ID").format("dddd")
        );
        if (indexByDayName > -1) {
          const close = opening_hours[indexByDayName].hours
            .split("-")[1]
            .replace(":00", "");
          startTime = moment()
            .hour(0) // mulai jam 00:00
            .minute(0)
            .second(0);
          endTime = moment().hour(close).minute(0).second(0);
        }
        // Check if the current time is between the start and end time
        const isWithinTimeRange = currentTime.isBetween(startTime, endTime);

        // Output the result
        let add = 0;
        while (
          add < 10 &&
          this.getHoliday.some(
            data =>
              data.date ==
              currentTime.clone().add(add, "days").format("YYYY-MM-DD")
          )
        ) {
          add++;
        }
        if (!isWithinTimeRange) {
          return moment()
            .add(add + 1, "days")
            .format("YYYY-MM-DD");
        } else {
          return moment().add(add, "days").format("YYYY-MM-DD");
        }
      }
      return moment().format("YYYY-MM-DD");
    },
    maxDate() {
      if (this.getDataPlant) {
        const bookingTime = moment(
          this.calculateMaxReschedule(
            this.data.booking_date,
            this.getDataReschedule.expired_days
          )
        );
        return bookingTime.format("YYYY-MM-DD");
      }
      return null;
    },
    dateFormatted() {
      return this.formatDate(this.payload.booking_date);
    },
    form() {
      return {
        booking_date: this.payload.booking_date,
        // branch: this.payload.branch,
      };
    },
  },
  created() {
    this.getPlant();
    this.payload.booking_date = moment(this.data.booking_date).format(
      "YYYY-MM-DD"
    );
    this.payload.branch = this.data.plant_id;
  },
  methods: {
    checkIsHoliday(param) {
      const date = moment(param);
      return this.getHoliday.some(holiday => date.isSame(holiday.date));
    },
    // start calculate reschedule min
    calculateMinReschedule(date, min) {
      const initialDate = moment(date);
      let excMin = 0;
      while (min > excMin) {
        initialDate.subtract(1, "days");
        if (
          !this.checkIsHoliday(initialDate) &&
          this.getEnableDays.includes(initialDate.day())
        ) {
          excMin++;
        }
      }
      return initialDate.format("YYYY-MM-DD");
    },
    // end calculate reschedule min
    // start calculate reschedule max
    calculateMaxReschedule(date, max) {
      const initialDate = moment(date);
      let excMax = 0;
      while (max > excMax) {
        initialDate.add(1, "days");
        if (
          !this.checkIsHoliday(initialDate) &&
          this.getEnableDays.includes(initialDate.day())
        ) {
          excMax++;
        }
      }
      return initialDate.format("YYYY-MM-DD");
    },
    // end calculate reschedule max

    // start available date
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate: function (val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;

      for (let i = day; i <= totalDay; i++) {
        let date = moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (moment(date).day() !== 0) availableDates.push(date);
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
    // end available date

    async dateDiffInDays(a, b) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = new Date(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = new Date(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    },
    async saveReschedule() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        try {
          const dataReady = {
            registration_number:
              this.data.parent_registration_number ||
              this.data.registration_number[0],
            plant_id: this.payload.branch,
            date: this.payload.booking_date,
          };
          const resp = await API.post(
            `${process.env.VUE_APP_API_TRUST}registration/reschedule`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            dataReady
          );
          if (resp.statusCode == 200) {
            this.toast("success", this.$t("success_reschedule"));
            this.$emit("updateData");
            // this.payload = { booking_date: "", branch: "" };
            this.$emit("closeReschedule");
          } else {
            this.$swal({
              icon: "error",
              text: this.$t("error_reschedule"),
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    formatDate(date) {
      return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY");
    },
    async getPlant() {
      const resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.statusCode == 200) {
        this.branch = resp.results;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rechedule {
  background: white;
}
</style>
