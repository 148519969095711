<template>
  <div class="chart-title">
    <icons :icon="icon" class="chart-title__icon" :color="color" />
    <span> {{ title }} </span>
  </div>
</template>

<script>
import icons from "@/components/elements/Icons.vue";

export default {
  components: {
    icons,
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.chart-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: #1f3c87;
  &__icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: 20px;
    color: #00a4b3;
  }
}
</style>
