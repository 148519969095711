var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-text',{staticClass:"px-3 px-md-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t("R.examination_result")))]),_c('card',{attrs:{"rounded":"","shadow":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-simple-table',{staticClass:"super-dense",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"no-pad"},[_c('tr',{staticClass:"grey-color head-vir2 text-no-wrap"},[_c('th',{staticClass:"text-center tb-wide",staticStyle:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t("R.examination_code"))+" ")]),_c('th',{staticClass:"text-center tb-wide text-no-wrap",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t("R.examination_name"))+" ")]),_c('th',{staticClass:"text-center tb-wide text-no-wrap",staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$t("result"))+" ")]),_c('th',{staticClass:"text-center tb-wide text-no-wrap",staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$t("R.reference_result"))+" ")]),_c('th',{staticClass:"text-center text-no-wrap",staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(_vm.$t("R.unit"))+" ")]),_c('th',{staticClass:"text-center text-no-wrap",staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$t("R.notes"))+" ")])])]),_c('tbody',_vm._l((_vm.listLabResult),function(item,index){return _c('tr',{key:index},[(item.type == 'category')?_c('td',{staticClass:"category-blue",attrs:{"colspan":"6"}},[_vm._v(" "+_vm._s(item.category_id)+" | "+_vm._s(item.category_en)+" ")]):(item.type == 'product')?_c('td',{staticClass:"grey-color bold",attrs:{"colspan":"6"}},[_vm._v(" "+_vm._s(item.product)+" ")]):[_c('td',{staticClass:"text-left td-custom text-no-wrap",class:{
                        true: item.status == 'Critical',
                        false: item.status !== 'Critical',
                      }},[(item.status == 'Critical')?_c('v-icon',{staticStyle:{"font-size":"12px !important"},attrs:{"color":"red","small":""}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(item.product_code)+" ")],1),_c('td',{staticClass:"text-left text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "id" ? item[`product_item_name`] : item[`product_item_name_${_vm.$i18n.locale}`])+" ")]),_c('td',{staticClass:"text-right text-no-wrap",class:{
                        true: item.status == 'Critical',
                        false: item.status != 'Critical',
                      }},[_vm._v(" "+_vm._s(item.result_value_post == null ? "-" : item.result_value_post)+" ")]),_c('td',{staticClass:"text-right text-no-wrap"},[(_vm.checkIsJsonString(item.reference_text))?_vm._l((JSON.parse(
                            item.reference_text
                          )),function(reference,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(index > 0 ? "," : "")+" "+_vm._s(reference.reference_text)+" ")])}):[(Array.isArray(item.reference_text))?_vm._l((item.reference_text),function(reference,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(index > 0 ? "," : "")+" "+_vm._s(reference.reference_text)+" ")])}):(
                            Array.isArray(_vm.preformatText(item.reference_text))
                          )?_vm._l((_vm.preformatText(
                              item.reference_text
                            )),function(reference,index){return _c('span',{key:index,staticClass:"d-block"},[_vm._v(" "+_vm._s(reference)+" ")])}):[_vm._v(" "+_vm._s(item.reference_text)+" ")]]],2),_c('td',{staticClass:"text-right text-no-wrap"},[_vm._v(" "+_vm._s(item.item_unit == null ? "-" : item.item_unit)+" ")]),_c('td',{staticClass:"text-left text-no-wrap"},[_vm._v(" "+_vm._s(item.status)+" ")])]],2)}),0)]},proxy:true}])})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }